import UnitOperatorTypeEnum from "../enums/UnitOperatorTypeEnum";
import DropDownItemType from "../types/DropDownItemType";
import UnitLdto from "../types/res/list/UnitLdto";

export default class UnitHelper {

	static getUnitsForDd(listUnits: UnitLdto[]) {

		let finalArr: DropDownItemType[] = [];
		listUnits.forEach(item => {
			finalArr.push({
				id: item.id,
				name: item.name
			});
		});
		return finalArr;

	}

	static getUnitForDd(unit: UnitLdto): DropDownItemType {
		return {
			id: unit.id,
			name: unit.name
		};
	}

	static getUnitMap(itemArr: UnitLdto[]) {
		let map = new Map<number, UnitLdto>([]);
		for (let index = 0; index < itemArr.length; index++) {
			const item = itemArr[index];
			map.set(item.id, item);
		}
		return map;
	}

	static convert(srcQty: number, srcUnit: UnitLdto, destUnit: UnitLdto): number {

		let destQty: number = 0;

		if (srcUnit.id === destUnit.id) {
			destQty = srcQty;
		}
		else {

			// Km to meter | 1km = 1000 x m
			// cm to meter | 1cm = 1 / 100 m
			if (srcUnit.isDerived && !destUnit.isDerived) {
				if (srcUnit.operatorId === UnitOperatorTypeEnum.MULTIPLY.id) {
					destQty = srcQty * srcUnit.num;
				}
				else if (srcUnit.operatorId === UnitOperatorTypeEnum.DIVIDE.id) {
					destQty = srcQty / srcUnit.num;
				}
			}
			// meter to km | 1km = 1000 x m
			// meter to cm | 1cm = 1 / 100 m
			else if (!srcUnit.isDerived && destUnit.isDerived) {
				if (destUnit.operatorId === UnitOperatorTypeEnum.MULTIPLY.id) {
					destQty = srcQty / destUnit.num;
				}
				else if (destUnit.operatorId === UnitOperatorTypeEnum.DIVIDE.id) {
					destQty = srcQty * destUnit.num;
				}
			}
			else if (srcUnit.isDerived && destUnit.isDerived) {
				// meter to kilo meter | 1Km = 1000 x m
				// meter to Million meter | 1Mm = 1 000 000 x m
				if (srcUnit.operatorId === UnitOperatorTypeEnum.MULTIPLY.id
					&& destUnit.operatorId === UnitOperatorTypeEnum.MULTIPLY.id) {
					destQty = srcQty * srcUnit.num / destUnit.num;
				}
				// meter to kilo meter | 1Km = 1000 x m
				// meter to centi meter | 1cm = 1 / 100 x m
				else if (srcUnit.operatorId === UnitOperatorTypeEnum.MULTIPLY.id
					&& destUnit.operatorId === UnitOperatorTypeEnum.DIVIDE.id) {
					destQty = srcQty * srcUnit.num * destUnit.num;
				}
				// meter to centi meter | 1cm = 1 / 100 x m
				// meter to kilo meter | 1Km = 1000 x m
				else if (srcUnit.operatorId === UnitOperatorTypeEnum.DIVIDE.id
					&& destUnit.operatorId === UnitOperatorTypeEnum.MULTIPLY.id) {
					destQty = srcQty / srcUnit.num * destUnit.num;
				}
				// meter to centi meter | 1cm = 1 / 100 x m
				// meter to mili meter | 1mm = 1 / 1000 x m
				else if (srcUnit.operatorId === UnitOperatorTypeEnum.DIVIDE.id
					&& destUnit.operatorId === UnitOperatorTypeEnum.DIVIDE.id) {
					destQty = srcQty * destUnit.num / srcUnit.num;
				}
			}
		}
		return destQty;

	}

}
