import { useState } from "react";
import DropDownItemType from "../../types/DropDownItemType";
import PurchaseOrderItemType from "../../types/PurchaseOrderItemType";
import { PriceListRateResType } from "../../types/res/PriceListRateResType";
import UnitLdto from "../../types/res/list/UnitLdto";
import ProdForEsimateLdto from "../../types/res/list/ProdForEsimateLdto";
import TaxCatLdto from "../../types/res/list/TaxCatLdto";
import TaxLdto from "../../types/res/list/TaxLdto";
import AddPurchaseOrderItemFormSv from "./AddPurchaseOrderItemFormSv";
import AddPurchaseOrderItemsTable from "./AddPurchaseOrderItemsTable";

interface AddPurchaseOrderItemsSvProps {
	products: ProdForEsimateLdto[];
	productsMap: Map<number, ProdForEsimateLdto>;
	productsForDd: DropDownItemType[];
	units: UnitLdto[];
	unitsMap: Map<number, UnitLdto>;
	handleAddItem: (addPurchaseOrderItem: PurchaseOrderItemType) => void;
	handleUpdateItem: (index: number, purchaseOrderItem: PurchaseOrderItemType) => void;
	handleDeleteItem: (index: number) => void;
	addPurchaseOrderItems: Array<PurchaseOrderItemType>;
	id?: number;
}

function AddPurchaseOrderItemsSv({
	products,
	productsMap,
	productsForDd,
	units,
	unitsMap,
	handleAddItem,
	handleUpdateItem,
	handleDeleteItem,
	addPurchaseOrderItems,
	id
}: AddPurchaseOrderItemsSvProps) {

	const [selectedIndex, setSelectedIndex] = useState<number>(-1);
	const [modeAdd, setModeAdd] = useState<boolean>(true);
	const [purchaseOrderItemToEdit, setPurchaseOrderItemToEdit] = useState<PurchaseOrderItemType | null>(null);

	const handleItemChange = (purchaseOrderItem: PurchaseOrderItemType) => {
		if (modeAdd) {
			handleAddItem(purchaseOrderItem);
		}
		else {
			handleUpdateItem(selectedIndex, purchaseOrderItem);
		}
		setModeAdd(true);
	}

	const handleClickEdit = (index: number) => {
		setModeAdd(false);
		setSelectedIndex(index);
		setPurchaseOrderItemToEdit(addPurchaseOrderItems[index]);
	}

	return (
		<>
			<AddPurchaseOrderItemFormSv
				products={products}
				productsForDd={productsForDd}
				productsMap={productsMap}
				units={units}
				unitsMap={unitsMap}
				handleItemChange={handleItemChange}
				purchaseOrderItemToEdit={purchaseOrderItemToEdit}
			/>

			<AddPurchaseOrderItemsTable
				addPurchaseOrderItems={addPurchaseOrderItems}
				productsMap={productsMap}
				unitsMap={unitsMap}
				handleClickEditParent={handleClickEdit}
				handleClickDeleteParent={handleDeleteItem}
			/>

		</>
	);
}

export default AddPurchaseOrderItemsSv;
