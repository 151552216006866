export default class LstCustomerEnumCol {

	public static readonly NAME = new LstCustomerEnumCol("name");
	public static readonly ACCT_TYPE_NAME = new LstCustomerEnumCol("acct_type_name");
	public static readonly OB_TYPE_ID = new LstCustomerEnumCol("ob_type_id");
	public static readonly OB = new LstCustomerEnumCol("ob");
	public static readonly NAME_TO_PRINT = new LstCustomerEnumCol("name_to_print");
	public static readonly ADDRESS = new LstCustomerEnumCol("address");
	public static readonly CITY = new LstCustomerEnumCol("city");
	public static readonly STATE_NAME = new LstCustomerEnumCol("state_name");
	public static readonly PIN = new LstCustomerEnumCol("pin");
	public static readonly EMAIL = new LstCustomerEnumCol("email");
	public static readonly GST_NO = new LstCustomerEnumCol("gst_no");
	public static readonly BANK_NAME = new LstCustomerEnumCol("bank_name");
	public static readonly BANK_BRANCH_NAME = new LstCustomerEnumCol("bank_branch_name");
	public static readonly BANK_ACCT_NAME = new LstCustomerEnumCol("bank_acct_name");
	public static readonly BANK_ACCT_NO = new LstCustomerEnumCol("bank_acct_no");
	public static readonly BANK_IFSC = new LstCustomerEnumCol("bank_ifsc");
	public static readonly CREDIT_DAYS = new LstCustomerEnumCol("credit_days");
	public static readonly CREDIT_AMT_LIMIT = new LstCustomerEnumCol("credit_amt_limit");
	public static readonly PREF_TRANS_NAME = new LstCustomerEnumCol("pref_trans_name");
	public static readonly PREF_AGENT_NAME = new LstCustomerEnumCol("pref_agent_name");
	public static readonly CREATED_BY = new LstCustomerEnumCol("created_by");
	public static readonly CREATED_AT = new LstCustomerEnumCol("created_at");

	private constructor(
		public readonly key: string) {
	}

	static get values(): LstCustomerEnumCol[] {
		return [
			this.NAME,
			this.ACCT_TYPE_NAME,
			this.OB_TYPE_ID,
			this.OB,
			this.NAME_TO_PRINT,
			this.ADDRESS,
			this.CITY,
			this.STATE_NAME,
			this.PIN,
			this.EMAIL,
			this.GST_NO,
			this.BANK_NAME,
			this.BANK_BRANCH_NAME,
			this.BANK_ACCT_NAME,
			this.BANK_ACCT_NO,
			this.BANK_IFSC,
			this.CREDIT_DAYS,
			this.CREDIT_AMT_LIMIT,
			this.PREF_TRANS_NAME,
			this.PREF_AGENT_NAME,
			this.CREATED_BY,
			this.CREATED_AT,
		];
	}

	static getByKey(key: string): LstCustomerEnumCol | null {
		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.key === key) {
				return element;
			}
		}
		return null;
	}
}
