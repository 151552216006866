import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import AcctApi from "../../api/AcctApi";
import EstimateStatusApi from "../../api/EstimateStatusApi";
import StateApi from "../../api/StateApi";
import YesNoEnum from "../../enums/YesNoEnum";
import AcctHelper from "../../helpers/AcctHelper";
import EstimateStatusHelper from "../../helpers/EstimateStatusHelper";
import StateHelper from "../../helpers/StateHelper";
import StringHelper2 from "../../helpers/StringHelper2";
import YesNoHelper from "../../helpers/YesNoHelper";
import DropDownItemType from "../../types/DropDownItemType";
import AcctForCustomerHdto from "../../types/res/list/AcctForCustomerHdto";
import EstimateStatusLdto from "../../types/res/list/EstimateStatusLdto";
import StateLdto from "../../types/res/list/StateLdto";
import ListEstimateSm from "../../types/sm/ListEstimateSm";
import DownshiftSelect from "../DownshiftSelectV2";
import MessageDialog from "../dialogs/MessageDialog";


interface ListEstimateSdPropsType {
	showModal: boolean;
	setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
	searchModel: ListEstimateSm;
	processOnSuccess: (searchModel: ListEstimateSm) => void;
}

function ListEstimateSd({
	showModal,
	setShowModal,
	searchModel,
	processOnSuccess
}: ListEstimateSdPropsType) {

	const [noStr, setNoStr] = useState<string>(searchModel.no?.toString() ?? "");
	const [dateFrom, setDateFrom] = useState<Date | null>(searchModel.dateFrom ?? null);
	const [dateTo, setDateTo] = useState(searchModel.dateTo);
	const [customerId, setCustomerId] = useState<number | null>(searchModel.customerId ?? null);
	const [customerName, setCustomerName] = useState(searchModel.customerName);
	const [city, setCity] = useState(searchModel.city);
	const [stateId, setStateId] = useState<number | null>(searchModel.stateId ?? null);
	const [state, setState] = useState(searchModel.state);
	const [note, setNote] = useState(searchModel.note);
	const [isConvertedToSo, setIsConvertedToSo] = useState(searchModel.isConvertedToSo);
	const [statusId, setStatusId] = useState<number | null>(searchModel.statusId ?? null);
	//
	const [accounts, setAccounts] = useState<AcctForCustomerHdto[] | null>(null);
	const [accountsForDd, setAcctsForDd] = useState<DropDownItemType[] | null>(null);
	const [accountsMap, setAccountsMap] = useState<Map<number, AcctForCustomerHdto> | null>(null);

	const [states, setStates] = useState<StateLdto[] | null>(null);
	const [statesForDd, setStatesForDd] = useState<DropDownItemType[] | null>(null);
	const [statesMap, setStatesMap] = useState<Map<number, StateLdto> | null>(null);

	const [estimateStatusListResArr, setEstimateStatusListArr] = useState<EstimateStatusLdto[] | null>(null);
	const [estimateStatusListResArrForDd, setEstimateStatusListArrForDd] = useState<DropDownItemType[] | null>(null);
	const [estimateStatusListResMap, setEstimateStatusListResMap] = useState<Map<number, EstimateStatusLdto> | null>(null);

	const [messageDialogMessage, setMessageDialogMessage] = useState("");
	const [showMessageDialog, setShowMessageDialog] = useState(false);

	useEffect(() => {
		async function process() {

			AcctApi.getAcctForCustomerHdtos().then((accounts) => {
				if (accounts.isSuccess) {
					setAccounts(accounts.items!);
					setAcctsForDd(AcctHelper.getArrForDd(accounts.items!));
					setAccountsMap(AcctHelper.getMap(accounts.items!));
				}
			});

			StateApi.getStateLdtos().then((states) => {
				if (states.isSuccess) {
					setStates(states.items!);
					setStatesForDd(StateHelper.getStateListResArrForDd(states.items!));
					setStatesMap(StateHelper.getStateListResMap(states.items!));
				}
			});

			EstimateStatusApi.getEstimateStatusLdtos().then((estimateStatusListResArr) => {
				if (estimateStatusListResArr.isSuccess) {
					setEstimateStatusListArr(estimateStatusListResArr.items!);
					setEstimateStatusListArrForDd(EstimateStatusHelper.getEstimateStatusListResArrForDd(estimateStatusListResArr.items!));
					setEstimateStatusListResMap(EstimateStatusHelper.getEstimateStatusListResMap(estimateStatusListResArr.items!));
				}
			});

		}
		process();
	}, []);

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		handleClickSearch();
	}

	const handleClickSearch = () => {
		if (!isValid) {
			return;
		}

		let searchModelNew = new ListEstimateSm();

		if (noStr != null && noStr !== "") {
			searchModelNew.no = parseInt(noStr);
		}
		searchModelNew.dateFrom = dateFrom;
		searchModelNew.dateTo = dateTo;
		searchModelNew.customerId = customerId;
		searchModelNew.customerName = customerName;
		searchModelNew.city = city;
		searchModelNew.customerId = customerId;
		searchModelNew.customerName = customerName;
		searchModelNew.city = city;
		searchModelNew.stateId = stateId;
		searchModelNew.note = note;
		searchModelNew.isConvertedToSo = isConvertedToSo;
		searchModelNew.statusId = statusId;

		setShowModal(false);

		processOnSuccess(searchModelNew);
	}

	const handleClickClose = () => {
		setShowModal(false);
	};

	const isValid = (): boolean => {

		if (noStr != null && noStr !== "" && !StringHelper2.isInt(noStr)) {
			setMessageDialogMessage("Invalid number");
			setShowMessageDialog(true);
			return false;
		}

		return true;
	}

	return (
		<>
			<Modal show={showModal} onHide={handleClickClose}>
				<Modal.Header closeButton>
					<Modal.Title>Filter</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group as={Row} className="mb-3">
							<Form.Label column sm="3">
								No
							</Form.Label>
							<Col sm="9">
								<Form.Control
									type="text"
									value={noStr} onChange={(e) => setNoStr(e.target.value)}
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} className="mb-3">
							<Form.Label column sm="3">
								Date From
							</Form.Label>
							<Col sm="9">
								<ReactDatePicker
									id="dpDate"
									dateFormat="dd-MM-yyyy"
									selected={dateFrom}
									onChange={(e) => setDateFrom(e)}
									className="form-control"
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} className="mb-3">
							<Form.Label column sm="3">
								Date To
							</Form.Label>
							<Col sm="9">
								<ReactDatePicker
									id="dpDate"
									dateFormat="dd-MM-yyyy"
									selected={dateTo}
									onChange={(e) => setDateTo(e)}
									className="form-control"
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} className="mb-3">
							<Form.Label column sm="3">
								Customer
							</Form.Label>
							<Col sm="9">
								<DownshiftSelect
									inputId={"cmbCustomer"}
									value={customerId == null || accountsMap?.get(customerId) == null ? null : AcctHelper.getOneForDd(accountsMap.get(customerId)!)}
									onChange={setCustomerId}
									options={accountsForDd ?? []}
									isLoading={false}
									isDisabled={false}
									placeholder=""
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} className="mb-3">
							<Form.Label column sm="3">
								Customer
							</Form.Label>
							<Col sm="9">
								<Form.Control
									type="text"
									value={customerName == null ? "" : customerName}
									onChange={(e) => setCustomerName(e.target.value)} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} className="mb-3">
							<Form.Label column sm="3">
								City
							</Form.Label>
							<Col sm="9">
								<Form.Control
									type="text"
									value={city == null ? "" : city}
									onChange={(e) => setCity(e.target.value)} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} className="mb-3">
							<Form.Label column sm="3">
								State
							</Form.Label>
							<Col sm="9">
								<DownshiftSelect
									inputId={"cmbState"}
									value={stateId == null || statesMap?.get(stateId) == null ? null : StateHelper.getStateListResForDd(statesMap.get(stateId)!)}
									onChange={setStateId}
									options={statesForDd ?? []}
									isLoading={false}
									isDisabled={false}
									placeholder=""
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} className="mb-3">
							<Form.Label column sm="3">
								Note
							</Form.Label>
							<Col sm="9">
								<Form.Control
									type="text"
									value={note == null ? "" : note}
									onChange={(e) => setNote(e.target.value)} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} className="mb-3">
							<Form.Label column sm="3">
								Is Converted to SO?
							</Form.Label>
							<Col sm="9">
								<DownshiftSelect
									inputId={"cmbIsConvertedToSo"}
									value={isConvertedToSo == null ? null : YesNoHelper.getYesNoForDd(YesNoEnum.getByVal(isConvertedToSo)!)}
									onChange={(e) => { if (e != null) setIsConvertedToSo(YesNoEnum.getById(e)?.val!) }}
									options={YesNoHelper.getYesNosForDd()}
									isLoading={false}
									isDisabled={false}
									placeholder=""
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} className="mb-3">
							<Form.Label column sm="3">
								Status
							</Form.Label>
							<Col sm="9">
								<DownshiftSelect
									inputId={"cmbStatusId"}
									value={statusId == null || estimateStatusListResMap?.get(statusId) == null ? null : EstimateStatusHelper.getEstimateStatusListResForDd(estimateStatusListResMap.get(statusId)!)}
									onChange={setStatusId}
									options={estimateStatusListResArrForDd ?? []}
									isLoading={false}
									isDisabled={false}
									placeholder=""
								/>
							</Col>
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClickClose}>
						Cancel
					</Button>
					<Button variant="primary" type="submit" onClick={handleClickSearch}>
						SAVE
					</Button>
				</Modal.Footer>
			</Modal>

			<MessageDialog
				showDialog={showMessageDialog}
				modalMessage={messageDialogMessage}
				setShowDialog={setShowMessageDialog} />
		</>
	);
}

export default ListEstimateSd;

