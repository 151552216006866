export default class RptAcctSummByAcctTypeSm {

	dateFrom: Date | undefined;
	dateTo: Date | undefined;
	acctTypeId: number | undefined;
	acctId: number | undefined;

	reset(): void {
		this.dateFrom = undefined;
		this.dateTo = undefined;

		this.acctTypeId = undefined;
		this.acctId = undefined;

	}

	isBlank(): boolean {
		return this.dateFrom === undefined
			&& this.dateTo === undefined
			&& this.acctTypeId === undefined
			&& this.acctId === undefined;
	}
}
