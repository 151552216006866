import { BASE_URL, TOKEN } from "../config";
import ServerNotReachableErrorCommonCudObj from "../default_objects/ServerNotReachableErrorCommonCudObj";
import ServerSideErrorCommonCudObj from "../default_objects/ServerSideErrorCommonCudObj";
import OrderTypeEnum from "../enums/OrderTypeEnum";
import ResourceEnum from "../enums/ResourceEnum";
import strFormat from "../helpers/StringHelper";
import { getQueryString } from "../helpers/UrlSearchParamHelper";
import CommonCudType from "../types/CommonCudType";
import CommonGetListType from "../types/CommonGetListType";
import ErrorResType from "../types/res/ErrorResType";
import PurchaseOrderLdto from "../types/res/list/PurchaseOrderLdto";
import RptPurchaseOrderLedgerHdto from "../types/res/list/RptPurchaseOrderLedgerHdto";
import RptPurchaseOrderPendingSummByProductHdto from "../types/res/list/RptPurchaseOrderPendingSummByProductHdto";
import RptPurchaseOrderPendingSummBySupplierHdto from "../types/res/list/RptPurchaseOrderPendingSummBySupplierHdto";
import RptPurchaseOrderPendingByAcctHdto from "../types/res/list/RptSalesOrderPendingByAcctHdto";
import PurchaseOrderRrHoDto from "../types/rr/PurchaseOrderRrHoDto";
import ListPurchaseOrderSm from "../types/sm/ListPurchaseOrderSm";
import RptPurchaseOrderLedgerSm from "../types/sm/RptPurchaseOrderLedgerSm";
import RptPurchaseOrderPendingSummByProdSm from "../types/sm/RptPurchaseOrderPendingSummByProdSm";
import RptPurchaseOrderPendingSummBySupplierSm from "../types/sm/RptPurchaseOrderPendingSummBySupplierSm";

export default class PurchaseOrderApi {

	static async getPurchaseOrderLdtos(listPurchaseOrderSm: ListPurchaseOrderSm): Promise<CommonGetListType<PurchaseOrderLdto>> {
		try {

			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.PURCHASE_ORDER.name)
				+ getQueryString(listPurchaseOrderSm)
				, requestOptions);

			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as PurchaseOrderLdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async createPurchaseOrder(purchaseOrderRrHoDto: PurchaseOrderRrHoDto): Promise<CommonCudType> {

		const requestOptions = {
			method: "POST",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(purchaseOrderRrHoDto)
		}

		const res = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.PURCHASE_ORDER.name), requestOptions);

		if (res.status !== 201) {
			const data: ErrorResType = await res.json();
			return {
				isSuccess: false,
				errorRes: data
			}
		}

		return {
			isSuccess: true,
		}

	}

	static async updatePurchaseOrder(id: number, purchaseOrderRrHoDto: PurchaseOrderRrHoDto): Promise<CommonCudType> {

		const requestOptions = {
			method: "PUT",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(purchaseOrderRrHoDto)
		}

		const res = await fetch(strFormat("%s/%s/%s", BASE_URL, ResourceEnum.PURCHASE_ORDER.name, id), requestOptions);

		if (res.status !== 201) {
			const data: ErrorResType = await res.json();
			return {
				isSuccess: false,
				errorRes: data
			}
		}

		return {
			isSuccess: true,
		}

	}

	static async getById(id: number) {

		const requestOptions = {
			method: "GET",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			}
		}

		const res = await fetch(strFormat("%s/%s/%s", BASE_URL, ResourceEnum.PURCHASE_ORDER.name, id), requestOptions);
		const data: PurchaseOrderRrHoDto = await res.json();
		return data;

	}

	static async delete(id: number): Promise<CommonCudType> {

		try {
			const requestOptions = {
				method: "DELETE",
				headers: {
					"Authorization": "Bearer " + TOKEN.val,
					"Content-Type": "application/json"
				}
			}

			const response = await fetch(strFormat("%s/%s/%s", BASE_URL, ResourceEnum.PURCHASE_ORDER.name, id), requestOptions);

			if (response.status === 200) {
				return {
					isSuccess: true
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}

	}

	static async print(ids: number[]) {

		const requestOptions = {
			method: "GET",
			headers: {
				Authorization: "Bearer " + TOKEN.val,
				"Content-Type": "application/json",
			},
		};
		const res = await fetch(strFormat("%s/%s/print", BASE_URL, ResourceEnum.PURCHASE_ORDER.name)
			+ getQueryString({
				ids: ids,
			})
			, requestOptions);

		var blob = await res.blob();
		return new Blob([blob], { type: 'application/pdf' });
	}

	// report
	static async getRptPurchaseOrderPendingSummByProdHdtos(searchModel: RptPurchaseOrderPendingSummByProdSm): Promise<CommonGetListType<RptPurchaseOrderPendingSummByProductHdto>> {
		try {

			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s/%s/%s",
					BASE_URL,
					ResourceEnum.PURCHASE_ORDER.name,
					"reports",
					"summary",
					"pending",
					"by-product"
				)
				+ getQueryString({
					...searchModel,
					orderType: OrderTypeEnum.PURCHASE.enumName,
				})
				, requestOptions);

			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptPurchaseOrderPendingSummByProductHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async getRptPurchaseOrderPendingSummBySupplierHdtos(searchModel: RptPurchaseOrderPendingSummBySupplierSm): Promise<CommonGetListType<RptPurchaseOrderPendingSummBySupplierHdto>> {
		try {

			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s/%s/%s",
					BASE_URL,
					ResourceEnum.PURCHASE_ORDER.name,
					"reports",
					"summary",
					"pending",
					"by-supplier"
				)
				+ getQueryString({
					...searchModel,
					orderType: OrderTypeEnum.PURCHASE.enumName,
				})
				, requestOptions);

			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptPurchaseOrderPendingByAcctHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async getRptPurchaseOrderLedgerHdtos(searchModel: RptPurchaseOrderLedgerSm): Promise<CommonGetListType<RptPurchaseOrderLedgerHdto>> {
		try {

			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s",
					BASE_URL,
					ResourceEnum.PURCHASE_ORDER.name,
					"reports",
					"ledger",
				)
				+ getQueryString({
					...searchModel,
					orderType: OrderTypeEnum.PURCHASE.enumName,
				})
				, requestOptions);

			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptPurchaseOrderLedgerHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}
}