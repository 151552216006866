import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Modal, Tab, Tabs } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import AcctApi from "../../api/AcctApi";
import CompanyProfileApi from "../../api/CompanyProfileApi";
import ProdApi from "../../api/ProdApi";
import PurchaseReturnApi from "../../api/PurchaseReturnApi";
import TaxApi from "../../api/TaxApi";
import TaxCatApi from "../../api/TaxCatApi";
import TransporterApi from "../../api/TransporterApi";
import UnitApi from "../../api/UnitApi";
import VouOthChApi from "../../api/VouOthChApi";
import VoucherApi from "../../api/VoucherApi";
import CalcOnTypeEnum from "../../enums/CalcOnTypeEnum";
import VoucherTypeEnum from "../../enums/VoucherTypeEnum";
import AcctHelper from "../../helpers/AcctHelper";
import AcctJournalSumHelper from "../../helpers/AcctJournalSumHelper";
import CompanyProfileHelper from "../../helpers/CompanyProfileHelper";
import ProdHelper from "../../helpers/ProdHelper";
import PurchaseReturnHelper from "../../helpers/PurchaseReturnHelper";
import PurchaseReturnItemHelper from "../../helpers/PurchaseReturnItemHelper";
import TaxCatHelper from "../../helpers/TaxCatHelper";
import TaxHelper from "../../helpers/TaxHelper";
import TransporterHelper from "../../helpers/TransporterHelper";
import UnitHelper from "../../helpers/UnitHelper";
import VouOthChHelper from "../../helpers/VouOthChHelper";
import CommonCudType from "../../types/CommonCudType";
import DropDownItemType from "../../types/DropDownItemType";
import PurchaseReturnItemType from "../../types/PurchaseReturnItemType";
import VouOthChNonTaxableType from "../../types/VouOthChNonTaxableType";
import VouOthChOnItemType from "../../types/VouOthChOnItemType";
import AcctForCustomerHdto from "../../types/res/list/AcctForCustomerHdto";
import CompanyProfileLdto from "../../types/res/list/CompanyProfileLdto";
import ProdForEsimateLdto from "../../types/res/list/ProdForEsimateLdto";
import TaxCatLdto from "../../types/res/list/TaxCatLdto";
import TaxLdto from "../../types/res/list/TaxLdto";
import TransporterLdto from "../../types/res/list/TransporterLdto";
import UnitLdto from "../../types/res/list/UnitLdto";
import VouOthChargeLdto from "../../types/res/list/VouOthChargeLdto";
import PurchaseReturnRrHoDto from "../../types/rr/PurchaseReturnRrHoDto";
import ListCompanyProfileSm from "../../types/sm/ListCompanyProfileSm";
import ListTaxSm from "../../types/sm/ListTaxSm";
import ListTransporterSm from "../../types/sm/ListTransporterSm";
import DownshiftSelect from "../DownshiftSelectV2";
import CustomerSelectDialog from "../dialogs/CustomerSelectDialog";
import AddPurchaseReturnItemsSv from "./AddPurchaseReturnItemsSv";
import AddPusaOthChNonTaxableSv from "./AddPusaOthChNonTaxableSv";
import AddPusaOthChOnItemSv from "./AddPusaOthChOnItemSv";
import AddPusaTaxSv from "./AddPusaTaxSv";
import AddPurchaseReturnTotalSv from "./AddPusaTotalSv";

interface AddPurchaseReturnProps {
	id?: number
	purchaseReturnOrderId?: number
}

function AddPurchaseReturn({
	id,
	purchaseReturnOrderId
}: AddPurchaseReturnProps): JSX.Element {


	const [acctId, setAcctId] = useState<number | null>(null);
	const [noStr, setNoStr] = useState("");
	const [date, setDate] = useState<Date | null>(new Date());

	const [acctForSupplier, setAcctForSupplier] = useState<AcctForCustomerHdto | null>(null);

	const [supplierInvoiceNo, setSupplierInvoiceNo] = useState("");
	const [supplierInvoiceDate, setSupplierInvoiceDate] = useState<Date | null>(new Date());

	const [lrNo, setLrNo] = useState("");
	const [lrDate, setLrDate] = useState<Date | null>(new Date());
	const [transporterId, setTransporterId] = useState<number | null>(-1);

	const [companyProfileId, setCompanyProfileId] = useState<number | null>(-1);
	const [note, setNote] = useState("");

	const [noOfCartonStr, setNoOfCartonStr] = useState("");

	const [purchaseReturnItems, setPurchaseReturnItems] = useState<PurchaseReturnItemType[]>([]);
	const [purchaseReturnOthChOnItemArr, setPurchaseReturnOthChOnItemArr] = useState<VouOthChOnItemType[]>([]);

	const [isAddTax, setIsAddTax] = useState<boolean>(true);
	const [isIntra, setIsIntra] = useState<boolean>(true);

	const [purchaseReturnOthChNonTaxableArr, setPurchaseReturnOthChNonTaxableArr] = useState<VouOthChNonTaxableType[]>([]);
	//
	const [accounts, setAccounts] = useState<AcctForCustomerHdto[] | null>(null);
	const [accountDdis, setAccountDdis] = useState<DropDownItemType[] | null>(null);
	const [accountsMap, setAccountsMap] = useState<Map<number, AcctForCustomerHdto> | null>(null);

	const [companyProfileListResArr, setCompanyProfileListResArr] = useState<CompanyProfileLdto[] | null>(null);
	const [companyProfileListResArrForDd, setCompanyProfileListResArrForDd] = useState<DropDownItemType[] | null>(null);
	const [companyProfileListResMap, setCompanyProfileListResMap] = useState<Map<number, CompanyProfileLdto> | null>(null);

	const [taxListResArr, setTaxListArr] = useState<TaxLdto[] | null>(null);
	const [taxListResArrForDd, setTaxListArrForDd] = useState<DropDownItemType[] | null>(null);
	const [taxListResMap, setTaxListResMap] = useState<Map<number, TaxLdto> | null>(null);

	const [taxCats, setTaxCats] = useState<TaxCatLdto[] | null>(null);
	const [taxCatMap, setTaxCatMap] = useState<Map<number, TaxCatLdto> | null>(null);

	const [transporterListResArr, setTransporterListArr] = useState<TransporterLdto[] | null>(null);
	const [transporterListResArrForDd, setTransporterListArrForDd] = useState<DropDownItemType[] | null>(null);
	const [transporterListResMap, setTransporterListResMap] = useState<Map<number, TransporterLdto> | null>(null);

	const [vouOthChOnItemResArr, setVouOthChOnItemResArr] = useState<VouOthChargeLdto[] | null>(null);
	const [vouOthChOnItemResArrForDd, setVouOthChOnItemResArrForDd] = useState<DropDownItemType[] | null>(null);
	const [vouOthChOnItemResMap, setVouOthChOnItemResMap] = useState<Map<number, VouOthChargeLdto> | null>(null);

	const [vouOthChNonTaxableResArr, setVouOthChNonTaxableResArr] = useState<VouOthChargeLdto[] | null>(null);
	const [vouOthChNonTaxableResArrForDd, setVouOthChNonTaxableResArrForDd] = useState<DropDownItemType[] | null>(null);
	const [vouOthChNonTaxableResMap, setVouOthChNonTaxableResMap] = useState<Map<number, VouOthChargeLdto> | null>(null);

	const [productsMap, setProductsMap] = useState<Map<number, ProdForEsimateLdto> | null>(null);
	const [products, setProducts] = useState<ProdForEsimateLdto[] | null>(null);
	const [productsForDd, setProductsForDd] = useState<DropDownItemType[] | null>(null);

	const [unitsMap, setUnitsMap] = useState<Map<number, UnitLdto> | null>(null);
	const [units, setUnits] = useState<UnitLdto[] | null>(null);

	//
	const [showSupplierDialog, setShowSupplierDialog] = useState(false);

	const [messageDialogMessage, setMessageDialogMessage] = useState("");
	const [showMessageDialog, setShowMessageDialog] = useState(false);

	useEffect(() => {
		async function process() {

			AcctApi.getAcctForCustomerHdtos().then((accounts) => {
				if (accounts.isSuccess) {
					setAccounts(accounts.items!);
					setAccountDdis(AcctHelper.getArrForDd(accounts.items!));
					setAccountsMap(AcctHelper.getMap(accounts.items!));
				}
			});

			CompanyProfileApi.getCompanyProfileLdtos(new ListCompanyProfileSm()).then((listCompanyProfiles) => {
				if (listCompanyProfiles.isSuccess) {
					setCompanyProfileListResArr(listCompanyProfiles.items!);
					setCompanyProfileListResArrForDd(CompanyProfileHelper.getCompanyProfilesForDd(listCompanyProfiles.items!));
					setCompanyProfileListResMap(CompanyProfileHelper.getCompanyProfileListResMap(listCompanyProfiles.items!));
				}
			});

			TaxApi.getTaxLdtos(new ListTaxSm()).then((taxListArr) => {
				if (taxListArr.isSuccess) {
					setTaxListArr(taxListArr.items!);
					setTaxListArrForDd(TaxHelper.getTaxListForDd(taxListArr.items!));
					setTaxListResMap(TaxHelper.getTaxMap(taxListArr.items!));
				}
			});

			TaxCatApi.getTaxCatLdtos().then((listTaxCats) => {
				if (listTaxCats.isSuccess) {
					setTaxCats(listTaxCats.items!);
					setTaxCatMap(TaxCatHelper.getTaxCatListResMap(listTaxCats.items!));
				}
			});

			TransporterApi.getTransporterLdtos(new ListTransporterSm()).then((transporterListResArr) => {
				if (transporterListResArr.isSuccess) {
					setTransporterListArr(transporterListResArr.items!);
					setTransporterListArrForDd(TransporterHelper.getTransporterListResArrForDd(transporterListResArr.items!));
					setTransporterListResMap(TransporterHelper.getTransporterListResMap(transporterListResArr.items!));
				}
			});

			VouOthChApi.getVouOthChargeResDtos(CalcOnTypeEnum.ITEM, false).then((vouOthChOnItemArray) => {
				if (vouOthChOnItemArray.isSuccess) {
					setVouOthChOnItemResArr(vouOthChOnItemArray.items!);
					setVouOthChOnItemResArrForDd(VouOthChHelper.getVouOthChArrForDd(vouOthChOnItemArray.items!));
					setVouOthChOnItemResMap(VouOthChHelper.getVouOthChListResMap(vouOthChOnItemArray.items!));
				}
			});

			VouOthChApi.getVouOthChargeResDtos(CalcOnTypeEnum.VOUCHER, false).then((vouOthChNonTaxableResArr) => {
				if (vouOthChNonTaxableResArr.isSuccess) {
					setVouOthChNonTaxableResArr(vouOthChNonTaxableResArr.items!);
					setVouOthChNonTaxableResArrForDd(VouOthChHelper.getVouOthChArrForDd(vouOthChNonTaxableResArr.items!));
					setVouOthChNonTaxableResMap(VouOthChHelper.getVouOthChListResMap(vouOthChNonTaxableResArr.items!));
				}
			});

			ProdApi.getProdForEstimate().then((listProducts) => {
				setProducts(listProducts);
				setProductsForDd(ProdHelper.getProdDdis(listProducts));
				setProductsMap(ProdHelper.getProductMap2(listProducts));

			});

			UnitApi.getUnitLdtos().then((listUnits) => {
				if (listUnits.isSuccess) {
					setUnits(listUnits.items!);
					setUnitsMap(UnitHelper.getUnitMap(listUnits.items!));
				}
			});

			if (id == null && purchaseReturnOrderId == null) {
				let nextNoRes = await VoucherApi.getNextNo(VoucherTypeEnum.PURCHASE_RETURN);
				if (nextNoRes.isSuccess) {
					setNoStr(nextNoRes.item!.no);
				}
			}
		}
		process();

	}, []);

	useEffect(() => {
		async function process() {
			if (
				accounts != null &&
				accountsMap != null &&
				companyProfileListResArr != null &&
				companyProfileListResArrForDd != null &&
				companyProfileListResMap != null &&
				taxListResArr != null &&
				taxListResArrForDd != null &&
				taxListResMap != null &&
				taxCats != null &&
				taxCatMap != null &&
				transporterListResArr != null &&
				transporterListResArrForDd != null &&
				transporterListResMap != null &&
				vouOthChOnItemResArr != null &&
				vouOthChOnItemResArrForDd != null &&
				vouOthChOnItemResMap != null &&
				vouOthChNonTaxableResArr != null &&
				vouOthChNonTaxableResArrForDd != null &&
				vouOthChNonTaxableResMap != null &&
				productsMap != null &&
				products != null &&
				productsForDd != null &&
				unitsMap != null &&
				units != null
			) {

				if (id != null) {
					PurchaseReturnApi.getById(id).then((result) => fillInUi(result));
				}
			}
		}

		process();
	}, [accounts,
		accountsMap,
		companyProfileListResArr,
		companyProfileListResArrForDd,
		companyProfileListResMap,
		taxListResArr,
		taxListResArrForDd,
		taxListResMap,
		taxCats,
		taxCatMap,
		transporterListResArr,
		transporterListResArrForDd,
		transporterListResMap,
		vouOthChOnItemResArr,
		vouOthChOnItemResArrForDd,
		vouOthChOnItemResMap,
		vouOthChNonTaxableResArr,
		vouOthChNonTaxableResArrForDd,
		vouOthChNonTaxableResMap,
		productsMap,
		products,
		productsForDd,
		unitsMap,
		units,
	]);

	const handleAddItem = (AddPurchaseReturnItem: PurchaseReturnItemType) => {
		setPurchaseReturnItems(PurchaseReturnItemHelper.calItems(
			isAddTax,
			isIntra,
			taxListResMap ?? new Map(),
			taxCatMap ?? new Map(),
			unitsMap ?? new Map(),
			productsMap ?? new Map(),
			[...purchaseReturnItems, AddPurchaseReturnItem], purchaseReturnOthChOnItemArr
		));
	}

	const handleUpdateItem = (index: number, PurchaseReturnItem: PurchaseReturnItemType) => {
		purchaseReturnItems[index] = PurchaseReturnItem;
		setPurchaseReturnItems(PurchaseReturnItemHelper.calItems(
			isAddTax,
			isIntra,
			taxListResMap ?? new Map(),
			taxCatMap ?? new Map(),
			unitsMap ?? new Map(),
			productsMap ?? new Map(),
			purchaseReturnItems ?? new Map(),
			purchaseReturnOthChOnItemArr
		));
	}

	const handleDeleteItem = (index: number) => {
		let PurchaseReturnItemsLoc = [...purchaseReturnItems];
		PurchaseReturnItemsLoc.splice(index, 1);
		setPurchaseReturnItems(PurchaseReturnItemHelper.calItems(
			isAddTax,
			isIntra,
			taxListResMap ?? new Map(),
			taxCatMap ?? new Map(),
			unitsMap ?? new Map(),
			productsMap ?? new Map(),
			PurchaseReturnItemsLoc ?? new Map(),
			purchaseReturnOthChOnItemArr
		));
	}

	const handleOthChOnItemChange = (PurchaseReturnOthChOnItemArr: VouOthChOnItemType[]) => {
		setPurchaseReturnItems(PurchaseReturnItemHelper.calItems(
			isAddTax,
			isIntra,
			taxListResMap ?? new Map(),
			taxCatMap ?? new Map(),
			unitsMap ?? new Map(),
			productsMap ?? new Map(),
			purchaseReturnItems ?? new Map(),
			PurchaseReturnOthChOnItemArr
		));
		setPurchaseReturnOthChOnItemArr(PurchaseReturnOthChOnItemArr);
	}

	const handleOthChNonTaxableChange = (PurchaseReturnOthChNonTaxableArr: VouOthChNonTaxableType[]) => {
		PurchaseReturnHelper.calOthChNonTax(purchaseReturnItems, PurchaseReturnOthChNonTaxableArr);
		setPurchaseReturnOthChNonTaxableArr(PurchaseReturnOthChNonTaxableArr);
	}

	const handleClickSave = async (event: React.FormEvent<HTMLFormElement> | KeyboardEvent) => {

		event.preventDefault();

		if (!isValid()) {
			return;
		}


		let purchaseReturnReqHo: PurchaseReturnRrHoDto = {
			purchaseReturnRrDto: {
				date: date!,
				no: noStr,
				acctId: acctId!,
				supplierId: acctForSupplier?.id!,
				isIntra: isIntra,
				supplierInvoiceNo: supplierInvoiceNo,
				supplierInvoiceDate: supplierInvoiceDate,
				lrNo: lrNo,
				lrDate: lrDate,
				transporterId: transporterId,
				isTax: isAddTax,
				note: note,
				companyProfileId: companyProfileId === -1 ? null : companyProfileId
			},
			purchaseReturnItemRrDtos: purchaseReturnItems,
			purchaseReturnOthChOnItemRrDtos: purchaseReturnOthChOnItemArr,
			purchaseReturnOthChTaxableRrDtos: [],
			purchaseReturnOthChNonTaxableRrDtos: purchaseReturnOthChNonTaxableArr,
		}

		let res: CommonCudType;

		if (id == null) {
			res = await PurchaseReturnApi.createPurchaseReturn(purchaseReturnReqHo);
		}
		else {
			res = await PurchaseReturnApi.updatePurchaseReturn(id, purchaseReturnReqHo);
		}

		if (!res!.isSuccess) {
			setMessageDialogMessage(res!.errorRes?.message!);
			setShowMessageDialog(true);
		}
		else {
			setMessageDialogMessage("Saved");
			setShowMessageDialog(true);
			clearBoxes();
		}

	}

	const fillInUi = (purchaseReturnRrHoDto: PurchaseReturnRrHoDto) => {
		setAcctForSupplier(accountsMap?.get(purchaseReturnRrHoDto.purchaseReturnRrDto.supplierId)!);

		setAcctId(purchaseReturnRrHoDto.purchaseReturnRrDto.acctId)
		setNoStr(purchaseReturnRrHoDto.purchaseReturnRrDto.no.toString())
		setDate(moment(purchaseReturnRrHoDto.purchaseReturnRrDto.date).toDate());

		setSupplierInvoiceNo(purchaseReturnRrHoDto.purchaseReturnRrDto.supplierInvoiceNo ?? "");
		setSupplierInvoiceDate(purchaseReturnRrHoDto.purchaseReturnRrDto.supplierInvoiceDate ?? null);

		setLrNo(purchaseReturnRrHoDto.purchaseReturnRrDto.lrNo ?? "");
		setLrDate(purchaseReturnRrHoDto.purchaseReturnRrDto.lrDate ?? null);
		setTransporterId(purchaseReturnRrHoDto.purchaseReturnRrDto.transporterId ?? null);

		setCompanyProfileId(purchaseReturnRrHoDto.purchaseReturnRrDto.companyProfileId ?? null);
		setNote(purchaseReturnRrHoDto.purchaseReturnRrDto.note);
		setNoOfCartonStr(purchaseReturnRrHoDto.purchaseReturnRrDto.noOfCarton?.toString() ?? "");

		let purchaseReturnItemsCalculated = PurchaseReturnItemHelper.calItems(
			purchaseReturnRrHoDto.purchaseReturnRrDto.isTax,
			purchaseReturnRrHoDto.purchaseReturnRrDto.isIntra,
			taxListResMap ?? new Map(),
			taxCatMap ?? new Map(),
			unitsMap ?? new Map(),
			productsMap ?? new Map(),
			purchaseReturnRrHoDto.purchaseReturnItemRrDtos,
			purchaseReturnRrHoDto.purchaseReturnOthChOnItemRrDtos);
		setPurchaseReturnItems(purchaseReturnItemsCalculated);
		setPurchaseReturnOthChOnItemArr(purchaseReturnRrHoDto.purchaseReturnOthChOnItemRrDtos);
		setIsAddTax(purchaseReturnRrHoDto.purchaseReturnRrDto.isTax);
		setIsIntra(purchaseReturnRrHoDto.purchaseReturnRrDto.isIntra);
		let PurchaseReturnOthChNonTaxableArrLoc = purchaseReturnRrHoDto.purchaseReturnOthChNonTaxableRrDtos;
		PurchaseReturnHelper.calOthChNonTax(
			purchaseReturnItemsCalculated,
			PurchaseReturnOthChNonTaxableArrLoc);
		setPurchaseReturnOthChNonTaxableArr(PurchaseReturnOthChNonTaxableArrLoc);
	};

	const handleClose = () => setShowMessageDialog(false);

	const handleDateChange = (date: Date | null) => {
		setDate(date);
	}

	const clearBoxes = () => {

		setNoStr("");
		setDate(new Date());
		setAcctForSupplier(null);
		setCompanyProfileId(-1);
		setTransporterId(-1);
		setNote("");

		setPurchaseReturnItems([]);
		setPurchaseReturnOthChOnItemArr([]);
		setPurchaseReturnOthChNonTaxableArr([]);
		setIsAddTax(true);
		setIsIntra(true);
	};


	const mySetAcctForSupplier = (acctForSupplierSelected: AcctForCustomerHdto | null) => {

		let creditDaysLimitLoc = "";
		let creditDaysCurrentStatusLoc = "";
		let overdueBalLoc = "";
		let isCreditDaysRightLoc = false;

		let creditAmtLoc = "";
		let currentBalLoc = "";
		let currenBalDiffLoc = "";
		let isCreditAmtRightLoc = false;

		if (acctForSupplierSelected !== null) {

			setIsIntra(13 === acctForSupplierSelected.stateId)

			if (acctForSupplierSelected.creditDays !== undefined
				&& acctForSupplierSelected.creditDays !== null) {
				creditDaysLimitLoc = acctForSupplierSelected.creditDays.toLocaleString('en-IN');
			}

			if (acctForSupplierSelected.overdueDays !== undefined
				&& acctForSupplierSelected.overdueDays !== null
			) {
				creditDaysCurrentStatusLoc = acctForSupplierSelected.overdueDays.toLocaleString('en-IN');
			}

			if (acctForSupplierSelected.overdueAmt !== undefined
				&& acctForSupplierSelected.overdueAmt !== null
				&& acctForSupplierSelected.overdueAmt !== 0
			) {
				overdueBalLoc = acctForSupplierSelected.overdueAmt.toLocaleString('en-IN');
			}

			if (acctForSupplierSelected.overdueAmt !== undefined
				&& acctForSupplierSelected.overdueAmt !== null
				&& acctForSupplierSelected.overdueAmt <= 0
			) {
				isCreditDaysRightLoc = true;
			}

			//

			if (acctForSupplierSelected.creditAmtLimit !== undefined
				&& acctForSupplierSelected.creditAmtLimit !== null

			) {
				creditAmtLoc = acctForSupplierSelected.creditAmtLimit.toLocaleString('en-IN');
			}

			let acctJournalSumHelper = new AcctJournalSumHelper(acctForSupplierSelected.sumDebit,
				acctForSupplierSelected.sumCredit);

			if (acctJournalSumHelper.isCrDrSame()) {
				currentBalLoc = "0";
			}
			else if (acctJournalSumHelper.isCrBig()) {
				currentBalLoc = " - " + acctJournalSumHelper.getCrBal().toLocaleString('en-IN');
			}
			else if (acctJournalSumHelper.isDrBig()) {
				currentBalLoc = acctJournalSumHelper.getDrBal().toLocaleString('en-IN');
			}

			if (acctForSupplierSelected.creditAmtLimit !== null) {
				acctJournalSumHelper.credit = acctJournalSumHelper.credit + acctForSupplierSelected.creditAmtLimit;
			}

			let acctJournalSumHelperTemp = new AcctJournalSumHelper(
				acctJournalSumHelper.debit,
				acctJournalSumHelper.credit
			);

			currenBalDiffLoc = acctJournalSumHelperTemp.getCrBal().toLocaleString('en-IN');

			if (acctJournalSumHelperTemp.getCrBal() >= 0) {
				isCreditAmtRightLoc = true;
			}

		}

		setAcctForSupplier(acctForSupplierSelected)
	}

	const isValid = (): boolean => {

		if (date == null) {
			setMessageDialogMessage("Select date");
			setShowMessageDialog(true);
			return false;
		}

		if (acctForSupplier == null) {
			setMessageDialogMessage("Select supplier");
			setShowMessageDialog(true);
			return false;
		}

		if (purchaseReturnItems == null || purchaseReturnItems.length == 0) {
			setMessageDialogMessage("Add items");
			setShowMessageDialog(true);
			return false;
		}

		return true;
	}

	return (
		<>
			<div className="container-fluid">
				<form onSubmit={(event) => handleClickSave(event)}>



					<div className="row col-sm-6">

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="cmbAcctId" className="col-form-label">Account</label>
							</div>
							<div className="col-sm-10">
								<DownshiftSelect
									inputId={"cmbAcctId"}
									value={acctId == null || accountsMap?.get(acctId) == null ? null : AcctHelper.getOneForDd(accountsMap.get(acctId)!)}
									onChange={setAcctId}
									options={accountDdis ?? []}
									isLoading={false}
									isDisabled={false}
									placeholder=""
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="txtNo" className="col-form-label">No.</label>
							</div>
							<div className="col-sm-10">
								<input
									id="txtNo"
									type="text"
									className="form-control"
									value={noStr}
									onChange={(e) => setNoStr(e.target.value)}
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="dpDate" className="col-form-label">Date</label>
							</div>
							<div className="col-sm-10">
								<DatePicker
									id="dpDate"
									dateFormat="dd-MM-yyyy"
									selected={date}
									onChange={(e) => handleDateChange(e)}
									className="form-control"
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="txtSupplier" className="col-form-label">Supplier</label>
							</div>
							<div className="col-sm-10">
								<div className="input-group">
									<input
										id="txtSupplier"
										type="text"
										className="form-control"
										value={acctForSupplier?.name ?? ""}
										readOnly={true}
									/>
									<button
										className="btn btn-outline-secondary" type="button"
										onClick={(e) => setShowSupplierDialog(true)}
									>...</button>
								</div>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="txtAddress" className="col-form-label">Address</label>
							</div>
							<div className="col-sm-10">
								<textarea
									id="txtAddress"
									className="form-control"
									rows={3}
									value={acctForSupplier?.address ?? ""}
									readOnly={true}
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="txtCity" className="col-form-label">City</label>
							</div>
							<div className="col-sm-10">
								<input
									id="txtCity"
									type="text"
									className="form-control"
									value={acctForSupplier?.city ?? ""}
									readOnly={true}
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label className="col-form-label">State</label>
							</div>
							<div className="col-sm-10">
								<input
									type="text"
									className="form-control"
									value={acctForSupplier?.stateName}
									readOnly={true}
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label className="col-form-label">Supplier Invoice No</label>
							</div>
							<div className="col-sm-10">
								<input
									type="text"
									className="form-control"
									value={supplierInvoiceNo}
									onChange={(e) => setSupplierInvoiceNo(e.target.value)}
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="dpSupplierInvoiceDate" className="col-form-label">Supplier Invoice Date</label>
							</div>
							<div className="col-sm-10">
								<DatePicker
									id="dpSupplierInvoiceDate"
									dateFormat="dd-MM-yyyy"
									selected={supplierInvoiceDate}
									onChange={(e) => setSupplierInvoiceDate(e)}
									className="form-control"
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label className="col-form-label">LR No</label>
							</div>
							<div className="col-sm-10">
								<input
									type="text"
									className="form-control"
									value={lrNo}
									onChange={(e) => setLrNo(e.target.value)}
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="dpDate" className="col-form-label">LR Date</label>
							</div>
							<div className="col-sm-10">
								<DatePicker
									id="dpDate"
									dateFormat="dd-MM-yyyy"
									selected={lrDate}
									onChange={(e) => setLrDate(e)}
									className="form-control"
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="cmbTransporter" className="col-form-label">Transporter</label>
							</div>
							<div className="col-sm-10">
								<DownshiftSelect
									inputId={"cmbTransporter"}
									value={transporterId == null || transporterListResMap?.get(transporterId) == null ? null : TransporterHelper.getTransporterListResForDd(transporterListResMap.get(transporterId)!)}
									onChange={setTransporterId}
									options={transporterListResArrForDd ?? []}
									isLoading={false}
									isDisabled={false}
									placeholder=""
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="cmbCompanyProfile" className="col-form-label">Company Profile</label>
							</div>
							<div className="col-sm-10">
								<DownshiftSelect
									inputId={"cmbCompanyProfile"}
									value={companyProfileId == null || companyProfileListResMap?.get(companyProfileId) == null ? null : CompanyProfileHelper.getCompanyProfileListResForDd(companyProfileListResMap.get(companyProfileId)!)}
									onChange={setCompanyProfileId}
									options={companyProfileListResArrForDd ?? []}
									isLoading={false}
									isDisabled={false}
									placeholder=""
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label className="col-form-label">Note</label>
							</div>
							<div className="col-sm-10">
								<input
									type="text"
									className="form-control"
									value={note}
									onChange={(e) => setNote(e.target.value)}
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label className="col-form-label">No Of Carton</label>
							</div>
							<div className="col-sm-10">
								<input
									type="text"
									className="form-control"
									value={noOfCartonStr}
									onChange={(e) => setNoOfCartonStr(e.target.value)}
								/>
							</div>
						</div>

					</div>

					<Tabs
						defaultActiveKey="tab-item-details"
						id="uncontrolled-tab-example"
						className="mb-3"
					>
						<Tab eventKey="tab-item-details" title="Item Details">

							<AddPurchaseReturnItemsSv
								products={products ?? []}
								productsMap={productsMap ?? new Map()}
								productsForDd={productsForDd ?? []}
								units={units ?? []}
								unitsMap={unitsMap ?? new Map()}
								taxMap={taxListResMap ?? new Map()}
								taxListResMap={taxListResMap ?? new Map()}
								taxCatMap={taxCatMap ?? new Map()}
								handleAddItem={handleAddItem}
								handleUpdateItem={handleUpdateItem}
								handleDeleteItem={handleDeleteItem}
								addPurchaseReturnItems={purchaseReturnItems}
							/>
						</Tab>
						<Tab eventKey="tab-pending-items" title="Pending Items">
							Tab content for Profile
						</Tab>
					</Tabs>

					<hr />

					<div className="d-flex mb-3 g-3 justify-content-end">
						<div className="col-auto g-3">
							<label className="col-form-label">Total</label>
						</div>
						<div className="col-auto">
							<input
								value={PurchaseReturnHelper.getSumAmtAfterItemDisc(purchaseReturnItems)}
								type="text"
								className="form-control"
								readOnly={true}
							/>
						</div>
					</div>

					<hr />

					<AddPusaOthChOnItemSv
						vouOthChOnItemResMap={vouOthChOnItemResMap ?? new Map()}
						vouOthChOnItemResArrForDd={vouOthChOnItemResArrForDd ?? []}
						vouOthChOnItemArr={purchaseReturnOthChOnItemArr}
						handleOthChOnItemChange={handleOthChOnItemChange}
					/>

					<hr />

					<div className="d-flex mb-3 g-3 justify-content-end">
						<div className="col-auto g-3">
							<label className="col-form-label">Total</label>
						</div>
						<div className="col-auto">
							<input
								value={PurchaseReturnHelper.getSumItemTaxableAmt(purchaseReturnItems)}
								type="text"
								className="form-control"
								readOnly={true}
							/>
						</div>
					</div>

					<hr />

					<AddPusaTaxSv
						isAddTax={isAddTax}
						setIsAddTax={setIsAddTax}
						isIntra={isIntra}
						taxAmtContainer={PurchaseReturnHelper.getTaxSum(purchaseReturnItems)}
					/>

					<hr />

					<AddPusaOthChNonTaxableSv
						vouOthChNonTaxableResMap={vouOthChNonTaxableResMap ?? new Map()}
						vouOthChNonTaxableResArrForDd={vouOthChNonTaxableResArrForDd ?? []}
						vouOthChNonTaxableArr={purchaseReturnOthChNonTaxableArr}
						handleOthChNonTaxableChange={handleOthChNonTaxableChange}
					/>

					<hr />

					<AddPurchaseReturnTotalSv
						totalAmtContainer={PurchaseReturnHelper.getTotalAmt(purchaseReturnItems, purchaseReturnOthChNonTaxableArr)}
					/>

					<button type="submit" className="btn btn-primary">Submit</button>
				</form>

			</div >

			<Modal show={showMessageDialog} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Message</Modal.Title>
				</Modal.Header>
				<Modal.Body>{messageDialogMessage}</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						OK
					</Button>
				</Modal.Footer>
			</Modal>


			<CustomerSelectDialog
				showDialog={showSupplierDialog}
				setShowDialog={setShowSupplierDialog}
				accounts={accounts ?? []}
				setAcctForCustomer={mySetAcctForSupplier}
			/>

		</>
	);
}

export default AddPurchaseReturn;
