import KbShortcutEnum from "../enums/KbShortcutEnum";
import MenuEnum from "../enums/MenuEnum";
import KbShortcutModel from "../types/res/KbShortcutModel";

export default class KbscHelper {

	static findKbShortcut(event: React.KeyboardEvent<HTMLTableElement> | KeyboardEvent, kbShortcutModels: KbShortcutModel[]) {

		for (let i = 0; i < kbShortcutModels.length; i++) {
			let kbShortcutModel = kbShortcutModels[i];

			if (!KbscHelper.isNull(kbShortcutModel)
				&& event.key != null
			) {
				let keyStr = event.key.toLowerCase();
				if (keyStr === "escape") {
					keyStr = "esc";
				}

				if (kbShortcutModel.alt === event.altKey
					&& kbShortcutModel.ctrl === event.ctrlKey
					&& kbShortcutModel.shift === event.shiftKey
					&& kbShortcutModel.keycode.toLowerCase() === keyStr
				) {
					return kbShortcutModel;
				}
			}
		}
		return null;
	}

	static isNull(kbShortcutModel: KbShortcutModel) {
		if (kbShortcutModel.keycode === null || kbShortcutModel.keycode === "")
			return true;
	}

	static getDisplayStr(kbShortcutModel: KbShortcutModel) {
		if (this.isNull(kbShortcutModel))
			return "";

		let finalStr = "";
		if (kbShortcutModel.ctrl)
			finalStr += "C";
		if (kbShortcutModel.alt)
			finalStr += "A";
		if (kbShortcutModel.shift)
			finalStr += "S";
		if (finalStr.trim() === "")
			finalStr += kbShortcutModel.keycode;
		else {
			finalStr += "+";
			finalStr += kbShortcutModel.keycode;
		}
		return finalStr.trim();
	}

	static findKbShortcutModelFromKbShortcutEnum(kbShortcutEnum: KbShortcutEnum, kbShortcutModels: KbShortcutModel[]) {
		for (let i = 0; i < kbShortcutModels.length; i++) {
			let kbShortcutModel = kbShortcutModels[i];

			if (kbShortcutModel.menuKey === kbShortcutEnum.key) {
				return kbShortcutModel;
			}
		}
	}

	static findKbShortcutEnumFromHomeMenu(homeMenu: MenuEnum, isAdd: boolean) {
		switch (homeMenu) {
			case MenuEnum.COMMNC_BOOK:
				return KbShortcutEnum.COMMNC_BOOK;
			case MenuEnum.REMINDER:
				if (isAdd)
					return KbShortcutEnum.REMINDER_ADD;
				else
					return KbShortcutEnum.REMINDER;
			case MenuEnum.COMMNC:
				if (isAdd)
					return KbShortcutEnum.COMMNC_ADD;
				else
					return KbShortcutEnum.COMMNC;
			case MenuEnum.LEAD:
				if (isAdd)
					return KbShortcutEnum.LEAD_ADD;
				else
					return KbShortcutEnum.LEAD;
			case MenuEnum.JOURNAL:
				if (isAdd)
					return KbShortcutEnum.JOURNAL_ADD;
				else
					return KbShortcutEnum.JOURNAL;
			case MenuEnum.CONTRA:
				if (isAdd)
					return KbShortcutEnum.CONTRA_ADD;
				else
					return KbShortcutEnum.CONTRA;
			case MenuEnum.RECEIPT:
				if (isAdd)
					return KbShortcutEnum.RECEIPT_ADD;
				else
					return KbShortcutEnum.RECEIPT;
			case MenuEnum.PAYMENT:
				if (isAdd)
					return KbShortcutEnum.PAYMENT_ADD;
				else
					return KbShortcutEnum.PAYMENT;
			case MenuEnum.SALES_RETURN:
				if (isAdd)
					return KbShortcutEnum.SALES_RETURN_ADD;
				else
					return KbShortcutEnum.SALES_RETURN;
			case MenuEnum.SALES:
				if (isAdd)
					return KbShortcutEnum.SALES_ADD;
				else
					return KbShortcutEnum.SALES;
			case MenuEnum.SALES_ORDER:
				if (isAdd)
					return KbShortcutEnum.SALES_ORDER_ADD;
				else
					return KbShortcutEnum.SALES_ORDER;
			case MenuEnum.ESTIMATE:
				if (isAdd)
					return KbShortcutEnum.ESTIMATE_ADD;
				else
					return KbShortcutEnum.ESTIMATE;
			case MenuEnum.PURCHASE_RETURN:
				if (isAdd)
					return KbShortcutEnum.PURCHASE_RETURN_ADD;
				else
					return KbShortcutEnum.PURCHASE_RETURN;
			case MenuEnum.PURCHASE:
				if (isAdd)
					return KbShortcutEnum.PURCHASE_ADD;
				else
					return KbShortcutEnum.PURCHASE;
			case MenuEnum.PURCHASE_ORDER:
				if (isAdd)
					return KbShortcutEnum.PURCHASE_ORDER_ADD;
				else
					return KbShortcutEnum.PURCHASE_ORDER;
			case MenuEnum.COMM_VOU:
				if (isAdd)
					return KbShortcutEnum.COMM_VOU_ADD;
				else
					return KbShortcutEnum.COMM_VOU;
			case MenuEnum.BANK:
				if (isAdd)
					return KbShortcutEnum.BANK_ADD;
				else
					return KbShortcutEnum.BANK;
			case MenuEnum.CUSTOMER:
				if (isAdd)
					return KbShortcutEnum.CUSTOMER_ADD;
				else
					return KbShortcutEnum.CUSTOMER;
			case MenuEnum.SUPPLIER:
				if (isAdd)
					return KbShortcutEnum.SUPPLIER_ADD;
				else
					return KbShortcutEnum.SUPPLIER;
			case MenuEnum.AGENT:
				if (isAdd)
					return KbShortcutEnum.AGENT_ADD;
				else
					return KbShortcutEnum.AGENT;
			case MenuEnum.ACCT:
				if (isAdd)
					return KbShortcutEnum.ACCT_ADD;
				else
					return KbShortcutEnum.ACCT;
			case MenuEnum.ACCT_TYPE:
				if (isAdd)
					return KbShortcutEnum.ACCT_TYPE_ADD;
				else
					return KbShortcutEnum.ACCT_TYPE;
			case MenuEnum.INV_ADJUST:
				if (isAdd)
					return KbShortcutEnum.INV_ADJUST_ADD;
				else
					return KbShortcutEnum.INV_ADJUST;
			case MenuEnum.COMM_LIST:
				if (isAdd)
					return KbShortcutEnum.COMM_LIST_ADD;
				else
					return KbShortcutEnum.COMM_LIST;
			case MenuEnum.SALES_PRICE_LIST:
				if (isAdd)
					return KbShortcutEnum.SALES_PRICE_LIST_ADD;
				else
					return KbShortcutEnum.SALES_PRICE_LIST;
			case MenuEnum.SERVICE:
				if (isAdd)
					return KbShortcutEnum.SERVICE_ADD;
				else
					return KbShortcutEnum.SERVICE;
			case MenuEnum.PROD:
				if (isAdd)
					return KbShortcutEnum.PRODUCT_ADD;
				else
					return KbShortcutEnum.PRODUCT;
			case MenuEnum.TAX_CAT:
				if (isAdd)
					return KbShortcutEnum.TAX_CAT_ADD;
				else
					return KbShortcutEnum.TAX_CAT;
			case MenuEnum.UNIT:
				if (isAdd)
					return KbShortcutEnum.UNIT_ADD;
				else
					return KbShortcutEnum.UNIT;
			case MenuEnum.RPT_SALES_RETURN_BY_AGENT:
				return KbShortcutEnum.RPT_SALES_RETURN_BY_AGENT;
			case MenuEnum.RPT_SALES_RETURN_BY_CITY:
				return KbShortcutEnum.RPT_SALES_RETURN_BY_CITY;
			case MenuEnum.RPT_SALES_RETURN_BY_CUSTOMER:
				return KbShortcutEnum.RPT_SALES_RETURN_BY_CUSTOMER;
			case MenuEnum.RPT_SALES_RETURN_BY_DAY:
				return KbShortcutEnum.RPT_SALES_RETURN_BY_DAY;
			case MenuEnum.RPT_SALES_RETURN_BY_MONTH:
				return KbShortcutEnum.RPT_SALES_RETURN_BY_MONTH;
			case MenuEnum.RPT_SALES_RETURN_BY_PRODUCT:
				return KbShortcutEnum.RPT_SALES_RETURN_BY_PRODUCT;
			case MenuEnum.RPT_SALES_RETURN_BY_STATE:
				return KbShortcutEnum.RPT_SALES_RETURN_BY_STATE;
			case MenuEnum.RPT_SALES_BY_AGENT:
				return KbShortcutEnum.RPT_SALES_BY_AGENT;
			case MenuEnum.RPT_SALES_BY_CITY:
				return KbShortcutEnum.RPT_SALES_BY_CITY;
			case MenuEnum.RPT_SALES_BY_CUSTOMER:
				return KbShortcutEnum.RPT_SALES_BY_CUSTOMER;
			case MenuEnum.RPT_SALES_BY_DAY:
				return KbShortcutEnum.RPT_SALES_BY_DAY;
			case MenuEnum.RPT_SALES_BY_MONTH:
				return KbShortcutEnum.RPT_SALES_BY_MONTH;
			case MenuEnum.RPT_SALES_BY_STATE:
				return KbShortcutEnum.RPT_SALES_BY_STATE;
			case MenuEnum.RPT_PURCHASE_RETURN_BY_CITY:
				return KbShortcutEnum.RPT_PURCHASE_RETURN_BY_CITY;
			case MenuEnum.RPT_PURCHASE_RETURN_BY_SUPPLIER:
				return KbShortcutEnum.RPT_PURCHASE_RETURN_BY_SUPPLIER;
			case MenuEnum.RPT_PURCHASE_RETURN_BY_DAY:
				return KbShortcutEnum.RPT_PURCHASE_RETURN_BY_DAY;
			case MenuEnum.RPT_PURCHASE_RETURN_BY_MONTH:
				return KbShortcutEnum.RPT_PURCHASE_RETURN_BY_MONTH;
			case MenuEnum.RPT_PURCHASE_RETURN_BY_STATE:
				return KbShortcutEnum.RPT_PURCHASE_RETURN_BY_STATE;
			case MenuEnum.RPT_PURCHASE_BY_CITY:
				return KbShortcutEnum.RPT_PURCHASE_BY_CITY;
			case MenuEnum.RPT_PURCHASE_BY_SUPPLIER:
				return KbShortcutEnum.RPT_PURCHASE_BY_SUPPLIER;
			case MenuEnum.RPT_PURCHASE_BY_DAY:
				return KbShortcutEnum.RPT_PURCHASE_BY_DAY;
			case MenuEnum.RPT_PURCHASE_BY_MONTH:
				return KbShortcutEnum.RPT_PURCHASE_BY_MONTH;
			case MenuEnum.RPT_PURCHASE_BY_STATE:
				return KbShortcutEnum.RPT_PURCHASE_BY_STATE;
			case MenuEnum.DUE_RCVABLE_BY_SALES:
				return KbShortcutEnum.DUE_RCVABLE_BY_SALES;
			case MenuEnum.DUE_RCVABLE_BY_CUSTOMER:
				return KbShortcutEnum.DUE_RCVABLE_BY_CUSTOMER;
			case MenuEnum.RPT_IO_SUMM_BY_PROD_CAT:
				return KbShortcutEnum.RPT_IO_SUMM_BY_PROD_CAT;
			case MenuEnum.RPT_IO_SUMM_BY_PROD:
				return KbShortcutEnum.RPT_IO_SUMM_BY_PROD;
			case MenuEnum.RPT_IO_SUMM_BY_MONTH:
				return KbShortcutEnum.RPT_IO_SUMM_BY_MONTH;
			case MenuEnum.RPT_IO_DET:
				return KbShortcutEnum.RPT_IO_DET;
			case MenuEnum.LEDGER:
				return KbShortcutEnum.LEDGER;
			case MenuEnum.INVENTORY_LEDGER:
				return KbShortcutEnum.INVENTORY_LEDGER;
			case MenuEnum.CURRENT_STOCK:
				return KbShortcutEnum.CURRENT_STOCK;
			case MenuEnum.CURRENT_STOCK_VAL:
				return KbShortcutEnum.CURRENT_STOCK_VAL;
			case MenuEnum.LOW_STOCK:
				return KbShortcutEnum.LOW_STOCK;
			case MenuEnum.TRANSPORTER:
				if (isAdd)
					return KbShortcutEnum.TRANSPORTER_ADD;
				else
					return KbShortcutEnum.TRANSPORTER;
			case MenuEnum.TAX:
				if (isAdd)
					return KbShortcutEnum.TAX_ADD;
				else
					return KbShortcutEnum.TAX;
			case MenuEnum.MSG_TEMPLATE:
				if (isAdd)
					return KbShortcutEnum.MSG_TEMPLATE_ADD;
				else
					return KbShortcutEnum.MSG_TEMPLATE;
			case MenuEnum.COMPANY_PROFILE:
				if (isAdd)
					return KbShortcutEnum.COMPANY_PROFILE_ADD;
				else
					return KbShortcutEnum.COMPANY_PROFILE;
		}
	}

}
