export default class CalcTypeEnum {
	public static readonly ADD = new CalcTypeEnum(1, "Add", 1);
	public static readonly SUBTRACT = new CalcTypeEnum(2, "Subtract", -1);

	private constructor(
		public readonly id: number,
		public readonly name: string,
		public readonly value: number,
	) {
	}

	static get values(): CalcTypeEnum[] {
		return [
			this.ADD,
			this.SUBTRACT,
		];
	}

	static getById(id: number): CalcTypeEnum | null {
		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.id === id) {
				return element;
			}
		}
		return null;
	}

	static getByName(name: string): CalcTypeEnum | null {
		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.name === name) {
				return element;
			}
		}
		return null;
	}

	static getByValue(value: number): CalcTypeEnum | null {
		this.values.forEach(element => {
			if (element.value === value) {
				return element;
			}
		});
		return null;
	}

}
