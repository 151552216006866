export default class ListEstimateSm {

	no: number | undefined;
	dateFrom: Date | undefined | null;
	dateTo: Date | undefined | null;
	customerId: number | undefined | null;
	customerName: string | undefined;
	isConvertedToSo: boolean | undefined;
	city: string | undefined;
	stateId: number | undefined | null;
	state: string | undefined;
	agentId: number | undefined;
	agentName: string | undefined;
	note: string | undefined;
	statusId: number | undefined | null;

	constructor(
		statusId?: number
	) {
		this.statusId = statusId;
	}


	reset(): void {

		this.no = undefined;
		this.dateFrom = undefined;
		this.dateTo = undefined;
		this.customerId = undefined;
		this.customerName = undefined;
		this.isConvertedToSo = undefined;
		this.city = undefined;
		this.stateId = undefined;
		this.state = undefined;
		this.agentId = undefined;
		this.agentName = undefined;
		this.note = undefined;
		this.statusId = undefined;
	}

	isBlank(): boolean {
		return this.no === undefined
			&& this.dateFrom === undefined
			&& this.dateTo === undefined
			&& this.customerId === undefined
			&& this.customerName === undefined
			&& this.isConvertedToSo === undefined
			&& this.city === undefined
			&& this.stateId === undefined
			&& this.state === undefined
			&& this.agentId === undefined
			&& this.agentName === undefined
			&& this.note === undefined
			&& this.statusId === undefined;
	}
}
