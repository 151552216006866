import RptIoDetSortByEnum from "../../enums/sort_by/RptIoDetSortByEnum";
import SortOrderEnum from "../../enums/SortOrderEnum";

export default class RptInvIoDetailedSm {

	dateFrom: Date | undefined;
	dateTo: Date | undefined;
	prodId: number | undefined;
	acctId: number | undefined;
	rptIoDetSortByEnum: RptIoDetSortByEnum | undefined;
	sortOrderEnum: SortOrderEnum | undefined;

	reset(): void {
		this.dateFrom = undefined;
		this.dateTo = undefined;
		this.prodId = undefined;
		this.acctId = undefined;
		this.rptIoDetSortByEnum = undefined;
		this.sortOrderEnum = undefined;
	}

	isBlank(): boolean {
		return this.dateFrom === undefined
			&& this.dateTo === undefined
			&& this.prodId === undefined
			&& this.acctId === undefined
			&& this.rptIoDetSortByEnum === undefined
			&& this.sortOrderEnum === undefined;
	}
}
