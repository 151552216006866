export default class LstAgentEnumCol {

	public static readonly NAME = new LstAgentEnumCol("name");
	public static readonly ACCT_TYPE_NAME = new LstAgentEnumCol("acct_type_name");
	public static readonly OB = new LstAgentEnumCol("ob");
	public static readonly OB_TYPE_ID = new LstAgentEnumCol("ob_type_id");
	public static readonly NAME_TO_PRINT = new LstAgentEnumCol("name_to_print");
	public static readonly ADDRESS = new LstAgentEnumCol("address");
	public static readonly CITY = new LstAgentEnumCol("city");
	public static readonly STATE_NAME = new LstAgentEnumCol("state_name");
	public static readonly PIN = new LstAgentEnumCol("pin");
	public static readonly EMAIL = new LstAgentEnumCol("email");
	public static readonly GST_NO = new LstAgentEnumCol("gst_no");
	public static readonly BANK_NAME = new LstAgentEnumCol("bank_name");
	public static readonly BANK_BRANCH_NAME = new LstAgentEnumCol("bank_branch_name");
	public static readonly BANK_ACCT_NAME = new LstAgentEnumCol("bank_acct_name");
	public static readonly BANK_ACCT_NO = new LstAgentEnumCol("bank_acct_no");
	public static readonly BANK_IFSC = new LstAgentEnumCol("bank_ifsc");
	public static readonly CREATED_BY = new LstAgentEnumCol("created_by");
	public static readonly CREATED_AT = new LstAgentEnumCol("created_at");

	private constructor(
		public readonly key: string) {
	}

	static get values(): LstAgentEnumCol[] {
		return [
			this.NAME,
			this.ACCT_TYPE_NAME,
			this.OB,
			this.OB_TYPE_ID,
			this.NAME_TO_PRINT,
			this.ADDRESS,
			this.CITY,
			this.STATE_NAME,
			this.PIN,
			this.EMAIL,
			this.GST_NO,
			this.BANK_NAME,
			this.BANK_BRANCH_NAME,
			this.BANK_ACCT_NAME,
			this.BANK_ACCT_NO,
			this.BANK_IFSC,
			this.CREATED_BY,
			this.CREATED_AT,
		];
	}

	static getByKey(key: string): LstAgentEnumCol | null {
		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.key === key) {
				return element;
			}
		}
		return null;
	}
}
