
export default class GetAcctsObjectTypeEnum {

	public static readonly ACCT = new GetAcctsObjectTypeEnum(1, "ACCT", "");
	public static readonly ACCT_LIST_FOR_CUSTOMER_HDTO = new GetAcctsObjectTypeEnum(2, "ACCT_LIST_FOR_CUSTOMER_HDTO", "");

	private constructor(
		public readonly id: number,
		public readonly enumName: string,
		public readonly name: string) {
	}

	static get values(): GetAcctsObjectTypeEnum[] {
		return [
			this.ACCT,
			this.ACCT_LIST_FOR_CUSTOMER_HDTO,
		];
	}

	static getById(id: number): GetAcctsObjectTypeEnum | null {
		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.id === id) {
				return element;
			}
		}
		return null;
	}

}
