import PriceListTypeEnum from "../enums/PriceListTypeEnum";
import RateTypeEnum from "../enums/RateTypeEnum";
import SalesItemType from "../types/SalesItemType";
import VouOthChOnItemType from "../types/VouOthChOnItemType";
import ProdForEsimateLdto from "../types/res/list/ProdForEsimateLdto";
import TaxCatLdto from "../types/res/list/TaxCatLdto";
import TaxLdto from "../types/res/list/TaxLdto";
import UnitLdto from "../types/res/list/UnitLdto";
import TaxHelper from "./TaxHelper";

export default class SalesItemHelper {

	static calItems(
		isAddTax: boolean,
		isIntra: boolean,
		taxMap: Map<number, TaxLdto>,
		taxCatMap: Map<number, TaxCatLdto>,
		unitMap: Map<number, UnitLdto>,
		productMap: Map<number, ProdForEsimateLdto>,
		salesItems: SalesItemType[],
		salesOthChOnItemArr: VouOthChOnItemType[],
	) {
		if (salesItems !== undefined && salesItems !== null && salesItems.length > 0) {

			let sumAmtAfterDisc = 0;
			for (let i = 0; i < salesItems.length; i++) {
				let salesItem = salesItems[i];

				let product = productMap.get(salesItem.productId);

				let taxId: number;

				if (product?.taxCatId === null) {
					taxId = product.taxId;
				}
				else {
					let taxCat = taxCatMap.get(product?.taxCatId!);
					taxId = taxCat?.taxId!;
				}
				salesItem.derivedTaxId = taxId;

				let tax = taxMap.get(salesItem.derivedTaxId);

				if (salesItem.priceListTypeId === PriceListTypeEnum.A.id) {
					salesItem.derivedRate = Math.floor(salesItem.priceListRate * 100 / (100 + tax!.igst) * 100) / 100;
				}
				else {
					salesItem.derivedRate = salesItem.priceListRate;
				}

				salesItem.derivedQxr = salesItem.qty * salesItem.derivedRate;

				if (salesItem.discRate === undefined || salesItem.discRate === null) {
					salesItem.derivedDiscA = 0;
				}
				else {
					switch (salesItem.discRateTypeId) {
						case RateTypeEnum.FLAT.id:
							salesItem.derivedDiscA = salesItem.discRate * salesItem.qty;
							break;
						case RateTypeEnum.PERCENT.id:
							salesItem.derivedDiscA = salesItem.discRate * salesItem.derivedQxr / 100;
							break;
						default:
							break;
					}
				}

				salesItem.derivedAmtAfterDisc = salesItem.derivedQxr - salesItem.derivedDiscA!;

				salesItem.derivedTaxableAmt = salesItem.derivedAmtAfterDisc;

				sumAmtAfterDisc = sumAmtAfterDisc + salesItem.derivedAmtAfterDisc;

				if (isAddTax) {

					if (salesItem.priceListTypeId === PriceListTypeEnum.A.id) {
						salesItem.derivedTaxId = tax?.id;
						salesItem.derivedTaxRate = tax?.igst;
					}
					else {
						salesItem.derivedTaxId = 1;
						salesItem.derivedTaxRate = 0;
					}

				}
			}



			if (salesOthChOnItemArr !== undefined && salesOthChOnItemArr !== null && salesOthChOnItemArr.length > 0) {
				for (let i = 0; i < salesOthChOnItemArr.length; i++) {
					let vouOthChOnItem = salesOthChOnItemArr[i];

					vouOthChOnItem.derivedAmt = 0;

					for (let j = 0; j < salesItems.length; j++) {
						let salesItem = salesItems[j];
						this.applyOthChOnItem(sumAmtAfterDisc, salesItem, vouOthChOnItem);
					}
					if (vouOthChOnItem.rateTypeId === RateTypeEnum.FLAT.id) {
						vouOthChOnItem.derivedAmt = vouOthChOnItem.rate;
					}
				}
			}

			if (isAddTax) {
				for (let i = 0; i < salesItems.length; i++) {
					let salesItem = salesItems[i];

					let taxAmtContainer = TaxHelper.calTaxAmt(
						taxMap.get(salesItem.derivedTaxId!)!,
						salesItem.derivedTaxRate!,
						isIntra,
						salesItem.derivedTaxableAmt!
					)

					salesItem.derivedCgstA = taxAmtContainer.cgstA;
					salesItem.derivedSgstA = taxAmtContainer.sgstA;
					salesItem.derivedIgstA = taxAmtContainer.igstA;
					salesItem.derivedTaxAmt = taxAmtContainer.cgstA + taxAmtContainer.sgstA + taxAmtContainer.igstA;
					salesItem.derivedAmt = taxAmtContainer.amtWithTax;
				}
			}
		}

		return salesItems;
	}

	private static applyOthChOnItem(sumAmtAftDisc: number, salesItem: SalesItemType, vouOthChOnItem: VouOthChOnItemType) {

		let itemOthChAmt = 0;

		switch (vouOthChOnItem.rateTypeId) {
			case RateTypeEnum.FLAT.id:
				itemOthChAmt = vouOthChOnItem.rate
					* salesItem.derivedAmtAfterDisc!
					/ sumAmtAftDisc;
				break;
			case RateTypeEnum.PERCENT.id:
				itemOthChAmt = salesItem.derivedTaxableAmt!
					* vouOthChOnItem.rate
					/ 100;

				vouOthChOnItem.derivedAmt = vouOthChOnItem.derivedAmt! + itemOthChAmt;
				break;
		}

		salesItem.derivedTaxableAmt = salesItem.derivedTaxableAmt! + itemOthChAmt;
	}

}