import { BASE_URL, TOKEN } from "../config";
import ServerNotReachableErrorCommonCudObj from "../default_objects/ServerNotReachableErrorCommonCudObj";
import ServerSideErrorCommonCudObj from "../default_objects/ServerSideErrorCommonCudObj";
import CalcOnTypeEnum from "../enums/CalcOnTypeEnum";
import GetVouOthChObjectTypeEnum from "../enums/GetVouOthChObjectTypeEnum";
import ResourceEnum from "../enums/ResourceEnum";
import strFormat from "../helpers/StringHelper";
import { getQueryString } from "../helpers/UrlSearchParamHelper";
import CommonGetListType from "../types/CommonGetListType";
import ErrorResType from "../types/res/ErrorResType";
import VouOthChargeResDto from "../types/res/VouOthChargeResDto";
import VouOthChargeLdto from "../types/res/list/VouOthChargeLdto";
import ListVouOthChargeSm from "../types/sm/ListVouOthChargeSm";

export default class VouOthChApi {

	static async getVouOthChargeLdtos(listVouOthChargeSm: ListVouOthChargeSm): Promise<CommonGetListType<VouOthChargeLdto>> {
		try {

			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.VOU_OTH_CHARGE.name)
				+ getQueryString(listVouOthChargeSm)
				, requestOptions);

			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as VouOthChargeLdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async getVouOthChargeResDtos(calOnType: CalcOnTypeEnum, isTaxable: boolean): Promise<CommonGetListType<VouOthChargeResDto>> {

		try {
			const requestOptions = {
				method: "GET",
				headers: {
					"Authorization": "Bearer " + TOKEN.val,
					"Content-Type": "application/json"
				}
			}

			const response = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.VOU_OTH_CHARGE.name)
				+ getQueryString({
					returnObjectType: GetVouOthChObjectTypeEnum.VOU_OTH_CH_FOR_ESTIMATE.enumName.toUpperCase(),
					calOnType: calOnType.enumName.toUpperCase(),
					isTaxable: isTaxable
				}),
				requestOptions);

			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as VouOthChargeLdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}

	}

}