import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import SalesPrintTypeEnum from "../../enums/SalesPrintTypeEnum";

interface EstimatePrintDialogProps {
	showDialog: boolean;
	setShowDialog: React.Dispatch<React.SetStateAction<boolean>>;
	print: (isPrintEstimate: boolean, isPrintPendingItems: boolean, salesPrintType: SalesPrintTypeEnum) => void;
}

export default function EstimatePrintDialog({
	showDialog,
	setShowDialog,
	print
}: EstimatePrintDialogProps): JSX.Element {

	const [isPrintEstimate, setIsPrintEstimate] = useState(true);
	const [isPrintPendingItems, setIsPrintPendingItems] = useState(false);
	const [salesPrintType, setSalesPrintType] = useState<SalesPrintTypeEnum>(SalesPrintTypeEnum.TYPE_A);

	const handleClose = () => setShowDialog(false);

	const handleOk = (): void => {
		setShowDialog(false);
		print(isPrintEstimate, isPrintPendingItems, salesPrintType);
	};

	return (
		<Modal
			show={showDialog}
			onHide={handleClose}
			className="modal-md"
			scrollable={true}
		>
			<Modal.Header closeButton>
				<Modal.Title>Estimate Print Options</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<form>
					<div className="form-check">
						<input
							className="form-check-input"
							type="radio"
							name="printWhat"
							id="rdoPendingItems"
							checked={isPrintPendingItems}
							onChange={(e) => {
								setIsPrintPendingItems(e.target.checked);
								setIsPrintEstimate(!e.target.checked);
							}}
						/>
						<label className="form-check-label" htmlFor="rdoPendingItems">
							Pending Items
						</label>
					</div>
					<div className="form-check">
						<input
							className="form-check-input"
							type="radio"
							name="printWhat"
							id="rdoEstimate"
							checked={isPrintEstimate}
							onChange={(e) => {
								setIsPrintEstimate(e.target.checked)
								setIsPrintPendingItems(!e.target.checked);
							}}
						/>
						<label className="form-check-label" htmlFor="rdoEstimate">
							Estimate
						</label>
					</div>
					<p>Print Type</p>
					<div className="form-check">
						<input
							className="form-check-input"
							type="radio"
							name="printType"
							id="rdoPrintTypeA"
							checked={salesPrintType === SalesPrintTypeEnum.TYPE_A}
							onChange={(e) => setSalesPrintType(SalesPrintTypeEnum.TYPE_A)}
						/>
						<label className="form-check-label" htmlFor="rdoPrintTypeA">
							Type A
						</label>
					</div>
					<div className="form-check">
						<input
							className="form-check-input"
							type="radio"
							name="printType"
							id="rdoPrintTypeB"
							checked={salesPrintType === SalesPrintTypeEnum.TYPE_B}
							onChange={(e) => setSalesPrintType(SalesPrintTypeEnum.TYPE_B)}
						/>
						<label className="form-check-label" htmlFor="rdoPrintTypeB">
							Type B
						</label>
					</div>
					<div className="form-check">
						<input
							className="form-check-input"
							type="radio"
							name="printType"
							id="rdoPrintTypeC"
							checked={salesPrintType === SalesPrintTypeEnum.TYPE_C}
							onChange={(e) => setSalesPrintType(SalesPrintTypeEnum.TYPE_C)}
						/>
						<label className="form-check-label" htmlFor="rdoPrintTypeC">
							Type C
						</label>
					</div>
				</form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={handleClose}>
					Cancel
				</Button>
				<Button variant="primary" onClick={handleOk}>
					Ok
				</Button>
			</Modal.Footer>
		</Modal >
	);
}
