import { BASE_URL, TOKEN } from "../config";
import ServerNotReachableErrorCommonCudObj from "../default_objects/ServerNotReachableErrorCommonCudObj";
import ServerSideErrorCommonCudObj from "../default_objects/ServerSideErrorCommonCudObj";
import ResourceEnum from "../enums/ResourceEnum";
import strFormat from "../helpers/StringHelper";
import CommonGetListType from "../types/CommonGetListType";
import ErrorResType from "../types/res/ErrorResType";
import AgentLdto from "../types/res/list/AgentLdto";

export default class AgentApi {

	static async getAgentLdtos(): Promise<CommonGetListType<AgentLdto>> {

		try {

			const requestOptions = {
				method: "GET",
				headers: {
					"Authorization": "Bearer " + TOKEN.val,
					"Content-Type": "appliion/json"
				}
			}
			const response = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.AGENT.name), requestOptions);
			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as AgentLdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}

			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

}
