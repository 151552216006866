export default class YrMonthHelper {
	private month!: number;
	private yr!: number;

	constructor();
	constructor(monthYr: number);
	constructor(dt: Date);
	constructor(month: number, yr: number);
	constructor(arg1?: number | Date, arg2?: number) {
		if (arg1 === undefined) {
			this.month = 0;
			this.yr = 0;
		} else if (typeof arg1 === 'number' && arg2 === undefined) {
			this.month = arg1 % 100;
			this.yr = Math.floor(arg1 / 100);
		} else if (arg1 instanceof Date) {
			this.setByDate(arg1);
		} else if (typeof arg1 === 'number' && typeof arg2 === 'number') {
			this.month = arg1;
			this.yr = arg2;
		}
	}

	setByDate(dt: Date): void {
		this.month = dt.getMonth() + 1;
		this.yr = dt.getFullYear();
	}

	getMonth(): number {
		return this.month;
	}

	setMonth(month: number): void {
		this.month = month;
	}

	getYr(): number {
		return this.yr;
	}

	setYr(yr: number): void {
		this.yr = yr;
	}

	getMonthYr(): number {
		return this.yr * 100 + this.month;
	}

	getFirstDate(): Date {
		const dt = new Date(this.yr, this.month - 1, 1);
		return dt;
	}

	getLastDate(): Date {
		const dt = new Date(this.yr, this.month, 0);
		return dt;
	}

	hashCode(): number {
		let hash = 5;
		hash = 79 * hash + this.month;
		hash = 79 * hash + this.yr;
		return hash;
	}

	equals(obj: any): boolean {
		if (this === obj) {
			return true;
		}
		if (obj === null || typeof obj !== 'object' || this.constructor !== obj.constructor) {
			return false;
		}
		const other = obj as YrMonthHelper;
		return this.month === other.month && this.yr === other.yr;
	}

	isMonthSame(dt?: Date): boolean {
		const compareMonthYrHdto = dt ? new YrMonthHelper(dt) : new YrMonthHelper();
		return this.equals(compareMonthYrHdto);
	}

}
