export default class ListProdSmType {

	name: string | undefined;
	unitId: number | undefined;
	withCategory: boolean | undefined;
	categoryId: number | undefined;
	searchInSubCat: boolean | undefined;
	categoryName: string | undefined;
	taxCatId: number | undefined;
	isHosiery: boolean | undefined;
	isMfg: boolean | undefined;
	isService: boolean | undefined;

	reset(): void {
		this.name = undefined;
		this.unitId = undefined;
		this.withCategory = undefined;
		this.categoryId = undefined;
		this.searchInSubCat = undefined;
		this.categoryName = undefined;
		this.taxCatId = undefined;
		this.isHosiery = undefined;
		this.isMfg = undefined;
		this.isService = undefined;
	}

	isBlank(): boolean {
		return this.name === undefined
			&& this.unitId === undefined
			&& this.withCategory === undefined
			&& this.categoryId === undefined
			&& this.searchInSubCat === undefined
			&& this.categoryName === undefined
			&& this.taxCatId === undefined
			&& this.isHosiery === undefined
			&& this.isMfg === undefined
			&& this.isService === undefined;
	}
}
