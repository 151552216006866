import { useState } from "react";
import MessageDialog from "../dialogs/MessageDialog";
import DropDownItemType from "../../types/DropDownItemType";
import EstimateItemType from "../../types/EstimateItemType";
import { PriceListRateResType } from "../../types/res/PriceListRateResType";
import UnitLdto from "../../types/res/list/UnitLdto";
import ProdForEsimateLdto from "../../types/res/list/ProdForEsimateLdto";
import TaxCatLdto from "../../types/res/list/TaxCatLdto";
import TaxLdto from "../../types/res/list/TaxLdto";
import AddEstimateItemFormSv from "./AddEstimateItemFormSv";
import AddEstimateItemsTable from "./AddEstimateItemsTable";

interface AddEstimateItemsSvProps {
	products: ProdForEsimateLdto[];
	productsMap: Map<number, ProdForEsimateLdto>;
	productsForDd: DropDownItemType[];
	units: UnitLdto[];
	unitsMap: Map<number, UnitLdto>;
	taxMap: Map<number, TaxLdto>;
	taxListResMap: Map<number, TaxLdto>;
	taxCatMap: Map<number, TaxCatLdto>;
	handleAddItem: (addEstimateItem: EstimateItemType) => void;
	handleUpdateItem: (index: number, estimateItem: EstimateItemType) => void;
	handleDeleteItem: (index: number) => void;
	customerPriceListAMap: Map<number, PriceListRateResType>;
	customerPriceListBMap: Map<number, PriceListRateResType>;
	addEstimateItems: Array<EstimateItemType>;
	id?: number;
}

function AddEstimateItemsSv({
	products,
	productsMap,
	productsForDd,
	units,
	unitsMap,
	taxMap,
	taxListResMap,
	taxCatMap,
	handleAddItem,
	handleUpdateItem,
	handleDeleteItem,
	customerPriceListAMap,
	customerPriceListBMap,
	addEstimateItems,
	id
}: AddEstimateItemsSvProps) {

	const [selectedIndex, setSelectedIndex] = useState<number>(-1);
	const [modeAdd, setModeAdd] = useState<boolean>(true);
	const [estimateItemToEdit, setEstimateItemToEdit] = useState<EstimateItemType | null>(null);

	const handleItemChange = (estimateItem: EstimateItemType) => {
		if (modeAdd) {
			handleAddItem(estimateItem);
		}
		else {
			handleUpdateItem(selectedIndex, estimateItem);
		}
		setModeAdd(true);
	}

	const handleClickEdit = (index: number) => {
		setModeAdd(false);
		setSelectedIndex(index);
		setEstimateItemToEdit(addEstimateItems[index]);
	}

	const handleClickCancelEdit = () => {
		setModeAdd(false);
		setSelectedIndex(-1);
		setEstimateItemToEdit(null);
	}

	return (
		<>
			<AddEstimateItemFormSv
				products={products}
				productsForDd={productsForDd}
				productsMap={productsMap}
				units={units}
				unitsMap={unitsMap}
				taxMap={taxListResMap}
				taxCatMap={taxCatMap}
				handleItemChange={handleItemChange}
				customerPriceListAMap={customerPriceListAMap}
				customerPriceListBMap={customerPriceListBMap}
				estimateItemToEdit={estimateItemToEdit}
				handleClickCancelEdit={handleClickCancelEdit}
			/>

			<AddEstimateItemsTable
				taxMap={taxListResMap}
				taxCatMap={taxCatMap}
				addEstimateItems={addEstimateItems}
				productsMap={productsMap}
				unitsMap={unitsMap}
				handleClickEditParent={handleClickEdit}
				handleClickDeleteParent={handleDeleteItem}
			/>

		</>
	);
}

export default AddEstimateItemsSv;
