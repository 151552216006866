import { BASE_URL, TOKEN } from "../config";
import ServerNotReachableErrorCommonCudObj from "../default_objects/ServerNotReachableErrorCommonCudObj";
import ServerSideErrorCommonCudObj from "../default_objects/ServerSideErrorCommonCudObj";
import ResourceEnum from "../enums/ResourceEnum";
import VoucherTypeEnum from "../enums/VoucherTypeEnum";
import strFormat from "../helpers/StringHelper";
import { getQueryString } from "../helpers/UrlSearchParamHelper";
import CommonGetOneType from "../types/CommonGetOneType";
import ErrorResType from "../types/res/ErrorResType";
import NextNoResType from "../types/res/NextNoResType";

export default class VoucherApi {

	static async getNextNo(voucherType: VoucherTypeEnum): Promise<CommonGetOneType<NextNoResType>> {

		try {
			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat("%s/%s/%s", BASE_URL, ResourceEnum.VOUCHER.name, "next-no")
				+ getQueryString({ voucherType: voucherType.enumName.toUpperCase() }),
				requestOptions);

			if (response.status === 200) {
				return {
					isSuccess: true,
					item: await response.json() as NextNoResType
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}


}