import React, { useEffect, useState } from "react";
import RateTypeEnum from "../../enums/RateTypeEnum";
import StringHelper2 from "../../helpers/StringHelper2";
import DropDownItemType from "../../types/DropDownItemType";
import PusaOthChNonTaxableRowHolder from "../../types/PusaOthChNonTaxableRowHolder";
import VouOthChNonTaxableType from "../../types/VouOthChNonTaxableType";
import VouOthChargeLdto from "../../types/res/list/VouOthChargeLdto";
import AddPusaOthChNonTaxableRowSv from "./AddPusaOthChNonTaxableRowSv";

interface AddPusaOthChNonTaxableSvProps {
	vouOthChNonTaxableResMap: Map<number, VouOthChargeLdto>;
	vouOthChNonTaxableResArrForDd: DropDownItemType[];
	vouOthChNonTaxableArr: VouOthChNonTaxableType[];
	handleOthChNonTaxableChange: (vouOthChNonTaxableArr: VouOthChNonTaxableType[]) => void;
}

function AddPusaOthChNonTaxableSv({
	vouOthChNonTaxableResMap,
	vouOthChNonTaxableResArrForDd,
	vouOthChNonTaxableArr,
	handleOthChNonTaxableChange
}: AddPusaOthChNonTaxableSvProps): JSX.Element {

	const [pusaOthChNonTaxableRowHolders, setPusaOthChNonTaxableRowHolders] = useState<PusaOthChNonTaxableRowHolder[]>([]);

	useEffect(() => {

		let vouOthChNonTaxableArrLoc: VouOthChNonTaxableType[] = [];
		for (let i = 0; i < pusaOthChNonTaxableRowHolders.length; i++) {
			let pusaOthChNonTaxableRowHolder = pusaOthChNonTaxableRowHolders[i];

			if (!isValid(pusaOthChNonTaxableRowHolder)) {
				return;
			}

			vouOthChNonTaxableArrLoc.push({
				othChId: pusaOthChNonTaxableRowHolder.othChId!,
				rate: parseFloat(pusaOthChNonTaxableRowHolder.rateStr!),
				rateTypeId: pusaOthChNonTaxableRowHolder.rateTypeId!
			});
		}

		handleOthChNonTaxableChange(vouOthChNonTaxableArrLoc);

	}, [pusaOthChNonTaxableRowHolders]);

	const handleClickAdd = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		event.preventDefault();

		setPusaOthChNonTaxableRowHolders([...pusaOthChNonTaxableRowHolders, {}])
	};

	const handleUpdateRow = (index: number, pusaOthChNonTaxableRowHolder: PusaOthChNonTaxableRowHolder) => {

		let newPusaOthChNonTaxableRowHolder = [...pusaOthChNonTaxableRowHolders];
		newPusaOthChNonTaxableRowHolder[index] = pusaOthChNonTaxableRowHolder;
		setPusaOthChNonTaxableRowHolders(newPusaOthChNonTaxableRowHolder);

	};

	const handleClickRemove = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => {
		event.preventDefault();

		let newPusaOthChNonTaxableRowHolder = [...pusaOthChNonTaxableRowHolders];
		newPusaOthChNonTaxableRowHolder.splice(index, 1);
		setPusaOthChNonTaxableRowHolders(newPusaOthChNonTaxableRowHolder);
	};

	const isValid = (pusaOthChNonTaxableRowHolder: PusaOthChNonTaxableRowHolder): boolean => {

		if (
			pusaOthChNonTaxableRowHolder.othChId === undefined
			|| pusaOthChNonTaxableRowHolder.othChId === null
			|| pusaOthChNonTaxableRowHolder.othChId === -1
			|| vouOthChNonTaxableResMap?.get(pusaOthChNonTaxableRowHolder.othChId) === undefined
			|| vouOthChNonTaxableResMap?.get(pusaOthChNonTaxableRowHolder.othChId) === null
		) {
			return false;
		}

		if (pusaOthChNonTaxableRowHolder.rateStr === undefined
			|| pusaOthChNonTaxableRowHolder.rateStr === null
			|| !StringHelper2.isNum(pusaOthChNonTaxableRowHolder.rateStr)
		) {
			return false;
		}

		if (
			pusaOthChNonTaxableRowHolder.rateTypeId === undefined
			|| pusaOthChNonTaxableRowHolder.rateTypeId === null
			|| pusaOthChNonTaxableRowHolder.rateTypeId === -1
			|| RateTypeEnum.getById(pusaOthChNonTaxableRowHolder.rateTypeId) === undefined
			|| RateTypeEnum.getById(pusaOthChNonTaxableRowHolder.rateTypeId) === null
		) {
			return false;
		}

		return true;

	}

	return (
		<div className="d-flex g-3 justify-content-end">
			<div className="card">
				<div className="card-header">
					Other harge (After Tax)
				</div>
				<div className="card-body">

					{pusaOthChNonTaxableRowHolders.map((pusaOthChNonTaxableRowHolder, index) => (
						<div key={index}>
							<AddPusaOthChNonTaxableRowSv
								vouOthChNonTaxableResMap={vouOthChNonTaxableResMap}
								vouOthChNonTaxableResArrForDd={vouOthChNonTaxableResArrForDd}
								vouOthChNonTaxable={index < vouOthChNonTaxableArr.length ? vouOthChNonTaxableArr[index] : null}
								pusaOthChNonTaxableRowHolder={pusaOthChNonTaxableRowHolder}
								handleClickRemove={handleClickRemove}
								handleUpdateRow={handleUpdateRow}
								myIndex={index}
								key={index}
							/>
						</div>
					))}

					<a
						onClick={(event) => handleClickAdd(event)}
						href="#"
						className="btn btn-primary">
						Add
					</a>
				</div>
			</div>
		</div>
	);
}

export default AddPusaOthChNonTaxableSv;
