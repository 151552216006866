import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Modal, Tab, Tabs } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import AcctApi from "../../api/AcctApi";
import ProdApi from "../../api/ProdApi";
import PurchaseApi from "../../api/PurchaseApi";
import SupplierPriceListApi from "../../api/SupplierPriceListApi";
import TaxApi from "../../api/TaxApi";
import TaxCatApi from "../../api/TaxCatApi";
import UnitApi from "../../api/UnitApi";
import VouOthChApi from "../../api/VouOthChApi";
import VoucherApi from "../../api/VoucherApi";
import CalcOnTypeEnum from "../../enums/CalcOnTypeEnum";
import VoucherTypeEnum from "../../enums/VoucherTypeEnum";
import AcctHelper from "../../helpers/AcctHelper";
import AcctJournalSumHelper from "../../helpers/AcctJournalSumHelper";
import ProdHelper from "../../helpers/ProdHelper";
import PurchaseHelper from "../../helpers/PurchaseHelper";
import PurchaseItemHelper from "../../helpers/PurchaseItemHelper";
import TaxCatHelper from "../../helpers/TaxCatHelper";
import TaxHelper from "../../helpers/TaxHelper";
import UnitHelper from "../../helpers/UnitHelper";
import VouOthChHelper from "../../helpers/VouOthChHelper";
import CommonCudType from "../../types/CommonCudType";
import DropDownItemType from "../../types/DropDownItemType";
import PurchaseItemType from "../../types/PurchaseItemType";
import VouOthChNonTaxableType from "../../types/VouOthChNonTaxableType";
import { PriceListRateResType } from "../../types/res/PriceListRateResType";
import AcctForCustomerHdto from "../../types/res/list/AcctForCustomerHdto";
import ProdForEsimateLdto from "../../types/res/list/ProdForEsimateLdto";
import TaxCatLdto from "../../types/res/list/TaxCatLdto";
import TaxLdto from "../../types/res/list/TaxLdto";
import UnitLdto from "../../types/res/list/UnitLdto";
import VouOthChargeLdto from "../../types/res/list/VouOthChargeLdto";
import PurchaseRrHoDto from "../../types/rr/PurchaseRrHoDto";
import DownshiftSelect from "../DownshiftSelectV2";
import CustomerSelectDialog from "../dialogs/CustomerSelectDialog";
import AddPurchaseItemsSv from "./AddPurchaseItemsSv";
import AddPusaOthChNonTaxableSv from "./AddPusaOthChNonTaxableSv";
import AddPusaTaxSv from "./AddPusaTaxSv";
import AddPurchaseTotalSv from "./AddPusaTotalSv";
import ListTaxSm from "../../types/sm/ListTaxSm";

interface AddPurchaseProps {
	id?: number
	purchaseOrderId?: number
}

function AddPurchase({
	id,
	purchaseOrderId
}: AddPurchaseProps): JSX.Element {


	const [acctId, setAcctId] = useState<number | null>(null);
	const [noStr, setNoStr] = useState("");
	const [date, setDate] = useState<Date | null>(new Date());

	const [acctForSupplier, setAcctForSupplier] = useState<AcctForCustomerHdto | null>(null);

	const [orderNo, setOrderNo] = useState("");
	const [orderDate, setOrderDate] = useState<Date | null>(null);

	const [note, setNote] = useState("");

	const [noOfCartonStr, setNoOfCartonStr] = useState("");

	const [purchaseItems, setPurchaseItems] = useState<PurchaseItemType[]>([]);

	const [isAddTax, setIsAddTax] = useState<boolean>(true);
	const [isIntra, setIsIntra] = useState<boolean>(true);

	const [purchaseOthChNonTaxableArr, setPurchaseOthChNonTaxableArr] = useState<VouOthChNonTaxableType[]>([]);
	//
	const [accounts, setAccounts] = useState<AcctForCustomerHdto[] | null>(null);
	const [accountDdis, setAccountDdis] = useState<DropDownItemType[] | null>(null);
	const [accountsMap, setAccountsMap] = useState<Map<number, AcctForCustomerHdto> | null>(null);

	const [taxListResArr, setTaxListArr] = useState<TaxLdto[] | null>(null);
	const [taxListResArrForDd, setTaxListArrForDd] = useState<DropDownItemType[] | null>(null);
	const [taxListResMap, setTaxListResMap] = useState<Map<number, TaxLdto> | null>(null);

	const [taxCats, setTaxCats] = useState<TaxCatLdto[] | null>(null);
	const [taxCatMap, setTaxCatMap] = useState<Map<number, TaxCatLdto> | null>(null);

	const [vouOthChOnItemResArr, setVouOthChOnItemResArr] = useState<VouOthChargeLdto[] | null>(null);
	const [vouOthChOnItemResArrForDd, setVouOthChOnItemResArrForDd] = useState<DropDownItemType[] | null>(null);
	const [vouOthChOnItemResMap, setVouOthChOnItemResMap] = useState<Map<number, VouOthChargeLdto> | null>(null);

	const [vouOthChNonTaxableResArr, setVouOthChNonTaxableResArr] = useState<VouOthChargeLdto[] | null>(null);
	const [vouOthChNonTaxableResArrForDd, setVouOthChNonTaxableResArrForDd] = useState<DropDownItemType[] | null>(null);
	const [vouOthChNonTaxableResMap, setVouOthChNonTaxableResMap] = useState<Map<number, VouOthChargeLdto> | null>(null);

	const [productsMap, setProductsMap] = useState<Map<number, ProdForEsimateLdto> | null>(null);
	const [products, setProducts] = useState<ProdForEsimateLdto[] | null>(null);
	const [productsForDd, setProductsForDd] = useState<DropDownItemType[] | null>(null);

	const [unitsMap, setUnitsMap] = useState<Map<number, UnitLdto> | null>(null);
	const [units, setUnits] = useState<UnitLdto[] | null>(null);

	const [supplierPriceListMap, setSupplierPriceListMap] = useState<Map<number, PriceListRateResType>>(new Map());

	//
	const [showSupplierDialog, setShowSupplierDialog] = useState(false);

	const [messageDialogMessage, setMessageDialogMessage] = useState("");
	const [showMessageDialog, setShowMessageDialog] = useState(false);

	useEffect(() => {
		async function process() {

			AcctApi.getAcctForCustomerHdtos().then((accounts) => {
				if (accounts.isSuccess) {
					setAccounts(accounts.items!);
					setAccountDdis(AcctHelper.getArrForDd(accounts.items!));
					setAccountsMap(AcctHelper.getMap(accounts.items!));
				}
			});

			TaxApi.getTaxLdtos(new ListTaxSm()).then((taxListArr) => {
				if (taxListArr.isSuccess) {
					setTaxListArr(taxListArr.items!);
					setTaxListArrForDd(TaxHelper.getTaxListForDd(taxListArr.items!));
					setTaxListResMap(TaxHelper.getTaxMap(taxListArr.items!));
				}
			});

			TaxCatApi.getTaxCatLdtos().then((listTaxCats) => {
				if (listTaxCats.isSuccess) {
					setTaxCats(listTaxCats.items!);
					setTaxCatMap(TaxCatHelper.getTaxCatListResMap(listTaxCats.items!));
				}
			});

			VouOthChApi.getVouOthChargeResDtos(CalcOnTypeEnum.ITEM, false).then((vouOthChOnItemArray) => {
				if (vouOthChOnItemArray.isSuccess) {
					setVouOthChOnItemResArr(vouOthChOnItemArray.items!);
					setVouOthChOnItemResArrForDd(VouOthChHelper.getVouOthChArrForDd(vouOthChOnItemArray.items!));
					setVouOthChOnItemResMap(VouOthChHelper.getVouOthChListResMap(vouOthChOnItemArray.items!));
				}
			});

			VouOthChApi.getVouOthChargeResDtos(CalcOnTypeEnum.VOUCHER, false).then((vouOthChNonTaxableResArr) => {
				if (vouOthChNonTaxableResArr.isSuccess) {
					setVouOthChNonTaxableResArr(vouOthChNonTaxableResArr.items!);
					setVouOthChNonTaxableResArrForDd(VouOthChHelper.getVouOthChArrForDd(vouOthChNonTaxableResArr.items!));
					setVouOthChNonTaxableResMap(VouOthChHelper.getVouOthChListResMap(vouOthChNonTaxableResArr.items!));
				}
			});

			ProdApi.getProdForEstimate().then((listProducts) => {
				setProducts(listProducts);
				setProductsForDd(ProdHelper.getProdDdis(listProducts));
				setProductsMap(ProdHelper.getProductMap2(listProducts));

			});

			UnitApi.getUnitLdtos().then((listUnits) => {
				if (listUnits.isSuccess) {
					setUnits(listUnits.items!);
					setUnitsMap(UnitHelper.getUnitMap(listUnits.items!));
				}
			});

			if (id == null && purchaseOrderId == null) {
				let nextNoRes = await VoucherApi.getNextNo(VoucherTypeEnum.PURCHASE);
				if (nextNoRes.isSuccess) {
					setNoStr(nextNoRes.item!.no);
				}
			}
		}
		process();

	}, []);

	useEffect(() => {
		async function process() {
			if (
				accounts != null &&
				accountsMap != null &&
				taxListResArr != null &&
				taxListResArrForDd != null &&
				taxListResMap != null &&
				taxCats != null &&
				taxCatMap != null &&
				vouOthChOnItemResArr != null &&
				vouOthChOnItemResArrForDd != null &&
				vouOthChOnItemResMap != null &&
				vouOthChNonTaxableResArr != null &&
				vouOthChNonTaxableResArrForDd != null &&
				vouOthChNonTaxableResMap != null &&
				productsMap != null &&
				products != null &&
				productsForDd != null &&
				unitsMap != null &&
				units != null
			) {

				if (id != null) {
					PurchaseApi.getById(id).then((result) => fillInUi(result));
				}
			}
		}

		process();
	}, [accounts,
		accountsMap,
		taxListResArr,
		taxListResArrForDd,
		taxListResMap,
		taxCats,
		taxCatMap,
		vouOthChOnItemResArr,
		vouOthChOnItemResArrForDd,
		vouOthChOnItemResMap,
		vouOthChNonTaxableResArr,
		vouOthChNonTaxableResArrForDd,
		vouOthChNonTaxableResMap,
		productsMap,
		products,
		productsForDd,
		unitsMap,
		units,
	]);

	const handleAddItem = (AddPurchaseItem: PurchaseItemType) => {
		setPurchaseItems(PurchaseItemHelper.calItems(
			isAddTax,
			isIntra,
			taxListResMap ?? new Map(),
			taxCatMap ?? new Map(),
			unitsMap ?? new Map(),
			productsMap ?? new Map(),
			[...purchaseItems, AddPurchaseItem],
		));
	}

	const handleUpdateItem = (index: number, PurchaseItem: PurchaseItemType) => {
		purchaseItems[index] = PurchaseItem;
		setPurchaseItems(PurchaseItemHelper.calItems(
			isAddTax,
			isIntra,
			taxListResMap ?? new Map(),
			taxCatMap ?? new Map(),
			unitsMap ?? new Map(),
			productsMap ?? new Map(),
			purchaseItems ?? new Map(),
		));
	}

	const handleDeleteItem = (index: number) => {
		let PurchaseItemsLoc = [...purchaseItems];
		PurchaseItemsLoc.splice(index, 1);
		setPurchaseItems(PurchaseItemHelper.calItems(
			isAddTax,
			isIntra,
			taxListResMap ?? new Map(),
			taxCatMap ?? new Map(),
			unitsMap ?? new Map(),
			productsMap ?? new Map(),
			PurchaseItemsLoc ?? new Map(),
		));
	}



	const handleOthChNonTaxableChange = (PurchaseOthChNonTaxableArr: VouOthChNonTaxableType[]) => {
		PurchaseHelper.calOthChNonTax(purchaseItems, PurchaseOthChNonTaxableArr);
		setPurchaseOthChNonTaxableArr(PurchaseOthChNonTaxableArr);
	}

	const handleClickSave = async (event: React.FormEvent<HTMLFormElement> | KeyboardEvent) => {

		event.preventDefault();

		if (!isValid()) {
			return;
		}

		let purchaseReqHo: PurchaseRrHoDto = {
			purchaseRrDto: {
				acctId: acctId!,
				date: date!,
				no: noStr,
				supplierId: acctForSupplier?.id!,
				isIntra: isIntra,
				isTax: isAddTax,
				note: note,
			},
			purchaseItemRrDtos: purchaseItems,
			purchaseOthChTaxableRrDtos: [],
			purchaseOthChNonTaxableRrDtos: purchaseOthChNonTaxableArr,
		}

		let res: CommonCudType;

		if (id == null) {
			res = await PurchaseApi.createPurchase(purchaseReqHo);
		}
		else {
			res = await PurchaseApi.updatePurchase(id, purchaseReqHo);
		}

		if (!res!.isSuccess) {
			setMessageDialogMessage(res!.errorRes?.message!);
			setShowMessageDialog(true);
		}
		else {
			setMessageDialogMessage("Saved");
			setShowMessageDialog(true);
			clearBoxes();
		}

	}

	const fillInUi = (purchaseRrHoDto: PurchaseRrHoDto) => {
		setAcctForSupplier(accountsMap?.get(purchaseRrHoDto.purchaseRrDto.supplierId)!);

		setNoStr(purchaseRrHoDto.purchaseRrDto.no.toString())
		setDate(moment(purchaseRrHoDto.purchaseRrDto.date).toDate());

		setOrderNo(purchaseRrHoDto.purchaseRrDto.orderNo ?? "");
		setOrderDate(purchaseRrHoDto.purchaseRrDto.orderDate ?? null);

		setNote(purchaseRrHoDto.purchaseRrDto.note);
		setNoOfCartonStr(purchaseRrHoDto.purchaseRrDto.noOfCarton?.toString() ?? "");

		let purchaseItemsCalculated = PurchaseItemHelper.calItems(
			purchaseRrHoDto.purchaseRrDto.isTax,
			purchaseRrHoDto.purchaseRrDto.isIntra,
			taxListResMap ?? new Map(),
			taxCatMap ?? new Map(),
			unitsMap ?? new Map(),
			productsMap ?? new Map(),
			purchaseRrHoDto.purchaseItemRrDtos);
		setPurchaseItems(purchaseItemsCalculated);
		setIsAddTax(purchaseRrHoDto.purchaseRrDto.isTax);
		setIsIntra(purchaseRrHoDto.purchaseRrDto.isIntra);
		let PurchaseOthChNonTaxableArrLoc = purchaseRrHoDto.purchaseOthChNonTaxableRrDtos;
		PurchaseHelper.calOthChNonTax(
			purchaseItemsCalculated,
			PurchaseOthChNonTaxableArrLoc);
		setPurchaseOthChNonTaxableArr(PurchaseOthChNonTaxableArrLoc);
	};

	const handleClose = () => setShowMessageDialog(false);

	const handleDateChange = (date: Date | null) => {
		setDate(date);

		loadSupplierPriceListA(acctForSupplier?.id, date);
	}

	const clearBoxes = () => {

		setNoStr("");
		setDate(new Date());
		setAcctForSupplier(null);
		setNote("");

		setPurchaseItems([]);
		setPurchaseOthChNonTaxableArr([]);
		setIsAddTax(true);
		setIsIntra(true);
	};


	const mySetAcctForSupplier = (acctForSupplierSelected: AcctForCustomerHdto | null) => {

		let creditDaysLimitLoc = "";
		let creditDaysCurrentStatusLoc = "";
		let overdueBalLoc = "";
		let isCreditDaysRightLoc = false;

		let creditAmtLoc = "";
		let currentBalLoc = "";
		let currenBalDiffLoc = "";
		let isCreditAmtRightLoc = false;

		if (acctForSupplierSelected !== null) {

			loadSupplierPriceListA(acctForSupplierSelected.id, date);

			setIsIntra(13 === acctForSupplierSelected.stateId)

			if (acctForSupplierSelected.creditDays !== undefined
				&& acctForSupplierSelected.creditDays !== null) {
				creditDaysLimitLoc = acctForSupplierSelected.creditDays.toLocaleString('en-IN');
			}

			if (acctForSupplierSelected.overdueDays !== undefined
				&& acctForSupplierSelected.overdueDays !== null
			) {
				creditDaysCurrentStatusLoc = acctForSupplierSelected.overdueDays.toLocaleString('en-IN');
			}

			if (acctForSupplierSelected.overdueAmt !== undefined
				&& acctForSupplierSelected.overdueAmt !== null
				&& acctForSupplierSelected.overdueAmt !== 0
			) {
				overdueBalLoc = acctForSupplierSelected.overdueAmt.toLocaleString('en-IN');
			}

			if (acctForSupplierSelected.overdueAmt !== undefined
				&& acctForSupplierSelected.overdueAmt !== null
				&& acctForSupplierSelected.overdueAmt <= 0
			) {
				isCreditDaysRightLoc = true;
			}

			//

			if (acctForSupplierSelected.creditAmtLimit !== undefined
				&& acctForSupplierSelected.creditAmtLimit !== null

			) {
				creditAmtLoc = acctForSupplierSelected.creditAmtLimit.toLocaleString('en-IN');
			}

			let acctJournalSumHelper = new AcctJournalSumHelper(acctForSupplierSelected.sumDebit,
				acctForSupplierSelected.sumCredit);

			if (acctJournalSumHelper.isCrDrSame()) {
				currentBalLoc = "0";
			}
			else if (acctJournalSumHelper.isCrBig()) {
				currentBalLoc = " - " + acctJournalSumHelper.getCrBal().toLocaleString('en-IN');
			}
			else if (acctJournalSumHelper.isDrBig()) {
				currentBalLoc = acctJournalSumHelper.getDrBal().toLocaleString('en-IN');
			}

			if (acctForSupplierSelected.creditAmtLimit !== null) {
				acctJournalSumHelper.credit = acctJournalSumHelper.credit + acctForSupplierSelected.creditAmtLimit;
			}

			let acctJournalSumHelperTemp = new AcctJournalSumHelper(
				acctJournalSumHelper.debit,
				acctJournalSumHelper.credit
			);

			currenBalDiffLoc = acctJournalSumHelperTemp.getCrBal().toLocaleString('en-IN');

			if (acctJournalSumHelperTemp.getCrBal() >= 0) {
				isCreditAmtRightLoc = true;
			}

		}


		setAcctForSupplier(acctForSupplierSelected)
	}

	const loadSupplierPriceListA = (supplierId: number | null | undefined, asOnDate: Date | null) => {

		if (supplierId == null || supplierId <= 0 || asOnDate === null) {
			setSupplierPriceListMap(new Map());
		}
		else {
			SupplierPriceListApi.getSupplierPriceListArr(supplierId, asOnDate).then((priceListRateArr) => {
				if (priceListRateArr.isSuccess) {
					let priceListMap = new Map<number, PriceListRateResType>([]);
					for (let index = 0; index < priceListRateArr.items!.length; index++) {
						const priceListRate = priceListRateArr.items![index];
						priceListMap.set(priceListRate.productId, priceListRate);
					}

					setSupplierPriceListMap(priceListMap);
				}
			});

		}
	}

	const isValid = (): boolean => {

		if (acctId == null) {
			setMessageDialogMessage("Select purchase account");
			setShowMessageDialog(true);
			return false;
		}

		if (date == null) {
			setMessageDialogMessage("Select date");
			setShowMessageDialog(true);
			return false;
		}

		if (acctForSupplier == null) {
			setMessageDialogMessage("Select supplier");
			setShowMessageDialog(true);
			return false;
		}

		if (purchaseItems == null || purchaseItems.length == 0) {
			setMessageDialogMessage("Add items");
			setShowMessageDialog(true);
			return false;
		}

		return true;
	}

	return (
		<>
			<div className="container-fluid">
				<form onSubmit={(event) => handleClickSave(event)}>

					<div className="row col-sm-6">

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="cmbAcctId" className="col-form-label">Account</label>
							</div>
							<div className="col-sm-10">
								<DownshiftSelect
									inputId={"cmbAcctId"}
									value={acctId == null || accountsMap?.get(acctId) == null ? null : AcctHelper.getOneForDd(accountsMap.get(acctId)!)}
									onChange={setAcctId}
									options={accountDdis ?? []}
									isLoading={false}
									isDisabled={false}
									placeholder=""
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="txtNo" className="col-form-label">No.</label>
							</div>
							<div className="col-sm-10">
								<input
									id="txtNo"
									type="text"
									className="form-control"
									value={noStr}
									onChange={(e) => setNoStr(e.target.value)}
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="dpDate" className="col-form-label">Date</label>
							</div>
							<div className="col-sm-10">
								<DatePicker
									id="dpDate"
									dateFormat="dd-MM-yyyy"
									selected={date}
									onChange={(e) => handleDateChange(e)}
									className="form-control"
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="txtSupplier" className="col-form-label">Supplier</label>
							</div>
							<div className="col-sm-10">
								<div className="input-group">
									<input
										id="txtSupplier"
										type="text"
										className="form-control"
										value={acctForSupplier?.name ?? ""}
										readOnly={true}
									/>
									<button
										className="btn btn-outline-secondary" type="button"
										onClick={(e) => setShowSupplierDialog(true)}
									>...</button>
								</div>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="txtAddress" className="col-form-label">Address</label>
							</div>
							<div className="col-sm-10">
								<textarea
									id="txtAddress"
									className="form-control"
									rows={3}
									value={acctForSupplier?.address ?? ""}
									readOnly={true}
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="txtCity" className="col-form-label">City</label>
							</div>
							<div className="col-sm-10">
								<input
									id="txtCity"
									type="text"
									className="form-control"
									value={acctForSupplier?.city ?? ""}
									readOnly={true}
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label className="col-form-label">State</label>
							</div>
							<div className="col-sm-10">
								<input
									type="text"
									className="form-control"
									value={acctForSupplier?.stateName}
									readOnly={true}
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label className="col-form-label">Order No</label>
							</div>
							<div className="col-sm-10">
								<input
									type="text"
									className="form-control"
									value={orderNo}
									onChange={(e) => setOrderNo(e.target.value)}
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="dpDate" className="col-form-label">Order Date</label>
							</div>
							<div className="col-sm-10">
								<DatePicker
									id="dpDate"
									dateFormat="dd-MM-yyyy"
									selected={orderDate}
									onChange={(e) => setOrderDate(e)}
									className="form-control"
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label className="col-form-label">Note</label>
							</div>
							<div className="col-sm-10">
								<input
									type="text"
									className="form-control"
									value={note}
									onChange={(e) => setNote(e.target.value)}
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label className="col-form-label">No Of Carton</label>
							</div>
							<div className="col-sm-10">
								<input
									type="text"
									className="form-control"
									value={noOfCartonStr}
									onChange={(e) => setNoOfCartonStr(e.target.value)}
								/>
							</div>
						</div>


					</div>

					<div className="row mb-2">
						<div className="col-md-2">
							&nbsp;
						</div>
						<div className="col-md-3">
							Limit
						</div>
						<div className="col-md-3">
							Current Status
						</div>
						<div className="col-md-3">
							Balance
						</div>
						<div className="col-md-3">
							&nbsp;
						</div>
					</div>

					<Tabs
						defaultActiveKey="tab-item-details"
						id="uncontrolled-tab-example"
						className="mb-3"
					>
						<Tab eventKey="tab-item-details" title="Item Details">

							<AddPurchaseItemsSv
								products={products ?? []}
								productsMap={productsMap ?? new Map()}
								productsForDd={productsForDd ?? []}
								units={units ?? []}
								unitsMap={unitsMap ?? new Map()}
								taxMap={taxListResMap ?? new Map()}
								taxListResMap={taxListResMap ?? new Map()}
								taxCatMap={taxCatMap ?? new Map()}
								handleAddItem={handleAddItem}
								handleUpdateItem={handleUpdateItem}
								handleDeleteItem={handleDeleteItem}
								supplierPriceListMap={supplierPriceListMap}
								addPurchaseItems={purchaseItems}
							/>
						</Tab>
						<Tab eventKey="tab-pending-items" title="Pending Items">
							Tab content for Profile
						</Tab>
					</Tabs>

					<hr />

					<div className="d-flex mb-3 g-3 justify-content-end">
						<div className="col-auto g-3">
							<label className="col-form-label">Total</label>
						</div>
						<div className="col-auto">
							<input
								value={PurchaseHelper.getSumAmtAfterItemDisc(purchaseItems)}
								type="text"
								className="form-control"
								readOnly={true}
							/>
						</div>
					</div>

					<hr />

					<div className="d-flex mb-3 g-3 justify-content-end">
						<div className="col-auto g-3">
							<label className="col-form-label">Total</label>
						</div>
						<div className="col-auto">
							<input
								value={PurchaseHelper.getSumItemTaxableAmt(purchaseItems)}
								type="text"
								className="form-control"
								readOnly={true}
							/>
						</div>
					</div>

					<hr />

					<AddPusaTaxSv
						isAddTax={isAddTax}
						setIsAddTax={setIsAddTax}
						isIntra={isIntra}
						taxAmtContainer={PurchaseHelper.getTaxSum(purchaseItems)}
					/>

					<hr />

					<AddPusaOthChNonTaxableSv
						vouOthChNonTaxableResMap={vouOthChNonTaxableResMap ?? new Map()}
						vouOthChNonTaxableResArrForDd={vouOthChNonTaxableResArrForDd ?? []}
						vouOthChNonTaxableArr={purchaseOthChNonTaxableArr}
						handleOthChNonTaxableChange={handleOthChNonTaxableChange}
					/>

					<hr />

					<AddPurchaseTotalSv
						totalAmtContainer={PurchaseHelper.getTotalAmt(purchaseItems, purchaseOthChNonTaxableArr)}
					/>

					<button type="submit" className="btn btn-primary">Submit</button>
				</form>

			</div >

			<Modal show={showMessageDialog} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Message</Modal.Title>
				</Modal.Header>
				<Modal.Body>{messageDialogMessage}</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						OK
					</Button>
				</Modal.Footer>
			</Modal>


			<CustomerSelectDialog
				showDialog={showSupplierDialog}
				setShowDialog={setShowSupplierDialog}
				accounts={accounts ?? []}
				setAcctForCustomer={mySetAcctForSupplier}
			/>

		</>
	);
}

export default AddPurchase;
