export default class LstReceiptEnumCol {

	public static readonly NO = new LstReceiptEnumCol("no");
	public static readonly DATE = new LstReceiptEnumCol("date");
	public static readonly FROM_ACCT_NAME = new LstReceiptEnumCol("from_acct_name");
	public static readonly TO_ACCT_NAME = new LstReceiptEnumCol("to_acct_name");
	public static readonly NOTE = new LstReceiptEnumCol("note");
	public static readonly AMOUNT = new LstReceiptEnumCol("amount");
	public static readonly AMT_CLRD = new LstReceiptEnumCol("amt_clrd");
	public static readonly AMT_UNCLRD = new LstReceiptEnumCol("amt_unclrd");
	public static readonly CREATED_BY = new LstReceiptEnumCol("created_by");
	public static readonly CREATED_AT = new LstReceiptEnumCol("created_at");

	private constructor(
		public readonly key: string) {
	}

	static get values(): LstReceiptEnumCol[] {
		return [
			this.NO,
			this.DATE,
			this.FROM_ACCT_NAME,
			this.TO_ACCT_NAME,
			this.NOTE,
			this.AMOUNT,
			this.AMT_CLRD,
			this.AMT_UNCLRD,
			this.CREATED_BY,
			this.CREATED_AT,
		];
	}

	static getByKey(key: string): LstReceiptEnumCol | null {
		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.key === key) {
				return element;
			}
		}
		return null;
	}
}
