export default class RptSalesOrderPendingSummByProdEnumCol {

	public static readonly PROD = new RptSalesOrderPendingSummByProdEnumCol("prod");
	public static readonly CUSTOMER_CNT = new RptSalesOrderPendingSummByProdEnumCol("customer_cnt");
	public static readonly SUM_QTY = new RptSalesOrderPendingSummByProdEnumCol("sum_qty");

	private constructor(
		public readonly key: string) {
	}

	static get values(): RptSalesOrderPendingSummByProdEnumCol[] {
		return [
			this.PROD,
			this.CUSTOMER_CNT,
			this.SUM_QTY,
		];
	}

	static getByKey(key: string): RptSalesOrderPendingSummByProdEnumCol | null {
		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.key === key) {
				return element;
			}
		}
		return null;
	}
}
