import React from "react";
import { Button, Modal } from "react-bootstrap";

interface MessageDialogProps {
	showDialog: boolean,
	modalMessage: string,
	setShowDialog: React.Dispatch<React.SetStateAction<boolean>>,
}

function MessageDialog({
	showDialog,
	modalMessage,
	setShowDialog,
}: MessageDialogProps): JSX.Element {

	const handleClose = () => setShowDialog(false);

	const handleEscapeKeyPress = (event: KeyboardEvent) => {
		if (!event.altKey
			&& !event.ctrlKey
			&& !event.metaKey
			&& !event.shiftKey
			&& event.key === "Escape"
		) {
			event.preventDefault();
			event.stopPropagation();
			setShowDialog(false);
		}
	};

	return (
		<Modal
			show={showDialog}
			onHide={handleClose}
			onEscapeKeyDown={handleEscapeKeyPress}
		>
			<Modal.Header closeButton>
				<Modal.Title>Message</Modal.Title>
			</Modal.Header>
			<Modal.Body>{modalMessage}</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={handleClose}>
					OK
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default MessageDialog;
