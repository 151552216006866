export default class ListPurchaseSm {

	no: number | undefined;
	dateFrom: Date | undefined | null;
	dateTo: Date | undefined | null;
	supplierId: number | undefined;
	supplierName: string | undefined;
	city: string | undefined;
	stateId: number | undefined;
	state: string | undefined;
	orderNo: string | undefined;
	orderDateFrom: Date | undefined;
	orderDateTo: Date | undefined;
	note: string | undefined;

	constructor(
		dateFrom?: Date | undefined | null,
		dateTo?: Date | undefined | null
	) {
		this.dateFrom = dateFrom;
		this.dateTo = dateTo;
	}

	reset(): void {
		this.no = undefined;
		this.dateFrom = undefined;
		this.dateTo = undefined;
		this.supplierId = undefined;
		this.supplierName = undefined;
		this.city = undefined;
		this.stateId = undefined;
		this.state = undefined;
		this.orderNo = undefined;
		this.orderDateFrom = undefined;
		this.orderDateTo = undefined;
		this.note = undefined;
	}

	isBlank(): boolean {
		return this.no === undefined
			&& this.dateFrom === undefined
			&& this.dateTo === undefined
			&& this.supplierId === undefined
			&& this.supplierName === undefined
			&& this.city === undefined
			&& this.stateId === undefined
			&& this.state === undefined
			&& this.orderNo === undefined
			&& this.orderDateFrom === undefined
			&& this.orderDateTo === undefined
			&& this.note === undefined;
	}
}
