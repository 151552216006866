import RptIoSummByProdCatSortByEnum from "../../enums/sort_by/RptIoSummByProdCatSortByEnum";
import SortOrderEnum from "../../enums/SortOrderEnum";

export default class RptInvIoSummByProdCatSm {

	dateFrom: Date | undefined;
	dateTo: Date | undefined;
	parentProdCatId: number | undefined;
	acctId: number | undefined;
	rptIoSummByProdCatSortByEnum: RptIoSummByProdCatSortByEnum | undefined;
	sortOrderEnum: SortOrderEnum | undefined;

	reset(): void {
		this.dateFrom = undefined;
		this.dateTo = undefined;
		this.parentProdCatId = undefined;
		this.acctId = undefined;
		this.rptIoSummByProdCatSortByEnum = undefined;
		this.sortOrderEnum = undefined;
	}

	isBlank(): boolean {
		return this.dateFrom === undefined
			&& this.dateTo === undefined
			&& this.parentProdCatId === undefined
			&& this.acctId === undefined
			&& this.rptIoSummByProdCatSortByEnum === undefined
			&& this.sortOrderEnum === undefined;
	}
}
