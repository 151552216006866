export default class LstPurchaseEnumCol {

	public static readonly NO = new LstPurchaseEnumCol("no", "Number");
	public static readonly PURCHASE_LEDGER = new LstPurchaseEnumCol("purchase_ledger", "Purchase Ledger");
	public static readonly DATE = new LstPurchaseEnumCol("date", "Date");
	public static readonly SUPPLIER_NAME = new LstPurchaseEnumCol("supplier_name", "Supplier");
	public static readonly CITY = new LstPurchaseEnumCol("city", "City");
	public static readonly STATE_NAME = new LstPurchaseEnumCol("state_name", "State");
	public static readonly ORDER_NO = new LstPurchaseEnumCol("order_no", "Order No");
	public static readonly ORDER_DATE = new LstPurchaseEnumCol("order_date", "Order Date");
	public static readonly SUM_QTY = new LstPurchaseEnumCol("sum_qty", "Quantity");
	public static readonly SUM_AMT_AFTER_DISC = new LstPurchaseEnumCol("sum_amt_after_disc", "Taxable Amount");
	public static readonly CGST_A = new LstPurchaseEnumCol("cgst_a", "CGST Amount");
	public static readonly SGST_A = new LstPurchaseEnumCol("sgst_a", "SGST Amount");
	public static readonly IGST_A = new LstPurchaseEnumCol("igst_a", "IGST Amount");
	public static readonly SUM_AMT_AFTER_TAX = new LstPurchaseEnumCol("sum_amt_after_tax", "Amount with Tax");
	public static readonly TOTAL_AMT = new LstPurchaseEnumCol("total_amt", "Total Amount");
	public static readonly ROUND_OFF = new LstPurchaseEnumCol("round_off", "Round Off");
	public static readonly AMT_PAYABLE = new LstPurchaseEnumCol("amt_payable", "Amount Payable");
	public static readonly AMT_CLRD = new LstPurchaseEnumCol("amt_clrd", "Amount Cleared");
	public static readonly AMT_UNCLRD = new LstPurchaseEnumCol("amt_unclrd", "Amount Due");
	public static readonly DAYS_CNT = new LstPurchaseEnumCol("days_cnt", "Days Count");
	public static readonly NOTE = new LstPurchaseEnumCol("note", "Note");
	public static readonly CREATED_BY = new LstPurchaseEnumCol("created_by", "Created By");
	public static readonly CREATED_AT = new LstPurchaseEnumCol("created_at", "Created At");

	private constructor(public readonly key: string, public readonly name: string) {
		
	}

	static get values(): LstPurchaseEnumCol[] {
		return [
			this.NO,
			this.PURCHASE_LEDGER,
			this.DATE,
			this.SUPPLIER_NAME,
			this.CITY,
			this.STATE_NAME,
			this.ORDER_NO,
			this.ORDER_DATE,
			this.SUM_QTY,
			this.SUM_AMT_AFTER_DISC,
			this.CGST_A,
			this.SGST_A,
			this.IGST_A,
			this.SUM_AMT_AFTER_TAX,
			this.TOTAL_AMT,
			this.ROUND_OFF,
			this.AMT_PAYABLE,
			this.AMT_CLRD,
			this.AMT_UNCLRD,
			this.DAYS_CNT,
			this.NOTE,
			this.CREATED_BY,
			this.CREATED_AT,
		];
	}

	static getByKey(key: string): LstPurchaseEnumCol | null {
		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.key === key) {
				return element;
			}
		}
		return null;
	}
}
