export default class LstEstimatePendingDetailedEnumCol {

	public static readonly DATE = new LstEstimatePendingDetailedEnumCol("date", "Date");
	public static readonly NO = new LstEstimatePendingDetailedEnumCol("no", "Number");
	public static readonly PRODUCT_NAME = new LstEstimatePendingDetailedEnumCol("product_name", "Product");
	public static readonly CUSTOMER_NAME = new LstEstimatePendingDetailedEnumCol("customer_name", "Customer");
	public static readonly QTY = new LstEstimatePendingDetailedEnumCol("qty", "Quantity");
	public static readonly CURRENT_STOCK_QTY = new LstEstimatePendingDetailedEnumCol("current_stock_qty", "Current Stock");

	private constructor(public readonly key: string, public readonly name: string) {

	}

	static get values(): LstEstimatePendingDetailedEnumCol[] {
		return [
			this.DATE,
			this.NO,
			this.PRODUCT_NAME,
			this.CUSTOMER_NAME,
			this.QTY,
			this.CURRENT_STOCK_QTY,
		];
	}

	static getByKey(key: string): LstEstimatePendingDetailedEnumCol | null {
		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.key === key) {
				return element;
			}
		}
		return null;
	}
}
