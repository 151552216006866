export default class LstInvAdjustEnumCol {

	public static readonly NO = new LstInvAdjustEnumCol("no");
	public static readonly DATE = new LstInvAdjustEnumCol("date");
	public static readonly TYPE = new LstInvAdjustEnumCol("type");
	public static readonly PRODUCT_NAME = new LstInvAdjustEnumCol("product_name");
	public static readonly QTY = new LstInvAdjustEnumCol("qty");
	public static readonly AMT = new LstInvAdjustEnumCol("amt");
	public static readonly NOTE = new LstInvAdjustEnumCol("note");
	public static readonly CREATED_BY = new LstInvAdjustEnumCol("created_by");
	public static readonly CREATED_AT = new LstInvAdjustEnumCol("created_at");

	private constructor(
		public readonly key: string) {
	}

	static get values(): LstInvAdjustEnumCol[] {
		return [
			this.NO,
			this.DATE,
			this.TYPE,
			this.PRODUCT_NAME,
			this.QTY,
			this.AMT,
			this.NOTE,
			this.CREATED_BY,
			this.CREATED_AT,
		];
	}

	static getByKey(key: string): LstInvAdjustEnumCol | null {
		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.key === key) {
				return element;
			}
		}
		return null;
	}
}
