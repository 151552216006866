import DropDownItemType from "../types/DropDownItemType";
import CompanyProfileLdto from "../types/res/list/CompanyProfileLdto";

export default class CompanyProfileHelper {

	static getCompanyProfilesForDd(listCompanyProfiles: CompanyProfileLdto[]) {

		let finalArr: DropDownItemType[] = [];
		listCompanyProfiles.forEach(item => {
			finalArr.push({
				id: item.id,
				name: item.name
			});
		});
		return finalArr;

	}

	static getCompanyProfileListResForDd(transporter: CompanyProfileLdto): DropDownItemType {
		return {
			id: transporter.id,
			name: transporter.name
		};
	}

	static getCompanyProfileListResMap(itemArr: CompanyProfileLdto[]) {
		let map = new Map<number, CompanyProfileLdto>([]);
		for (let index = 0; index < itemArr.length; index++) {
			const item = itemArr[index];
			map.set(item.id, item);
		}
		return map;
	}

}