import { useEffect, useState } from "react";
import { useGvContext } from "../GvContext";
import MenuEnum from "../enums/MenuEnum";
import KbscHelper from "../helpers/KbscHelper";
import MenuResType from "../types/res/MenuResType";

interface MenuButtonWiPlusProps {
	index: number;
	menu: MenuResType;
}

function MenuButtonWiPlus({
	index,
	menu,
}: MenuButtonWiPlusProps): JSX.Element {

	const gv = useGvContext();

	const [isKbShortcut, setIsKbShortcut] = useState(false);
	const [kbShortcutStr, setKbShortcutStr] = useState("");

	const [isAddKbShortcut, setIsAddKbShortcut] = useState(false);
	const [addKbShortcutStr, setAddKbShortcutStr] = useState("");

	useEffect(() => {

		setKbsc();
		setAddKbsc();

	}, [gv]);

	function setKbsc() {

		let menuEnum = MenuEnum.getByKey(menu.name);

		if (menuEnum == null)
			return;

		let kbscEnum = KbscHelper.findKbShortcutEnumFromHomeMenu(menuEnum, false);

		if (kbscEnum == null)
			return;

		let kbscModel = KbscHelper.findKbShortcutModelFromKbShortcutEnum(kbscEnum, gv.kbKbShortcutModel);

		if (kbscModel == null)
			return;

		if (!KbscHelper.isNull(kbscModel)) {
			setIsKbShortcut(true);
			setKbShortcutStr(KbscHelper.getDisplayStr(kbscModel));
		}
		else {
			setIsKbShortcut(false);
			setKbShortcutStr("");
		}
	}

	function setAddKbsc() {

		let menuEnum = MenuEnum.getByKey(menu.name);

		if (menuEnum == null)
			return;

		let kbscEnum = KbscHelper.findKbShortcutEnumFromHomeMenu(menuEnum, true);

		if (kbscEnum == null)
			return;

		let kbscModel = KbscHelper.findKbShortcutModelFromKbShortcutEnum(kbscEnum, gv.kbKbShortcutModel);

		if (kbscModel == null)
			return;

		if (!KbscHelper.isNull(kbscModel)) {
			setIsAddKbShortcut(true);
			setAddKbShortcutStr(KbscHelper.getDisplayStr(kbscModel));
		}
		else {
			setIsAddKbShortcut(false);
			setAddKbShortcutStr("");
		}
	}

	return (
		<div className="col-md-4 mb-4 menu-btn-wrapper">
			<div className="menu-btn-container">
				<div
					className="btn-group d-flex"
					role="group"
					aria-label="Basic example"
				>
					<button
						className="btn btn-secondary menu-btn justify-content-between flex-grow-1"
						type="button"
						onClick={() => gv.menuHelper.openListView(MenuEnum.getByKey(menu.name)!)}
					>
						<span className="d-flex">
							<span className="flex-grow-1">
								<span className="fw-bold">
									{index + 1}.{" "}
									{menu.displayName === null || menu.displayName === ""
										? menu.name
										: menu.displayName}
								</span>
								{isKbShortcut ? <span className="d-block kbs">{kbShortcutStr}</span> : null}
							</span>
						</span>
					</button>
					<button
						className="btn btn-secondary menu-btn-plus"
						type="button"
						onClick={() => gv.menuHelper.openAddView(MenuEnum.getByKey(menu.name)!)}
					>
						<i className="fa-solid fa-plus fa-lg"></i>
						{isAddKbShortcut ? <span className="d-block kbs">{addKbShortcutStr}</span> : null}
					</button>
				</div>
			</div>
		</div>
	);
}

export default MenuButtonWiPlus;
