import RateTypeEnum from "../enums/RateTypeEnum";
import PurchaseItemType from "../types/PurchaseItemType";
import TaxAmtContainerType from "../types/TaxAmtContainerType";
import TotalAmtContainerType from "../types/TotalAmtContainerType";
import VouOthChNonTaxableType from "../types/VouOthChNonTaxableType";

export default class PurchaseHelper {

	static getSumAmtAfterItemDisc(
		purchaseItems: PurchaseItemType[],
	) {
		if (purchaseItems !== undefined && purchaseItems !== null && purchaseItems.length > 0) {

			let sumAmtAfterDisc = 0;
			for (let i = 0; i < purchaseItems.length; i++) {
				let purchaseItem = purchaseItems[i];

				sumAmtAfterDisc = sumAmtAfterDisc + purchaseItem.derivedAmtAfterDisc!;
			}
			return sumAmtAfterDisc;
		}
		return 0;
	}

	static getSumItemTaxableAmt(
		purchaseItemArr: PurchaseItemType[],
	) {
		if (purchaseItemArr !== undefined && purchaseItemArr !== null && purchaseItemArr.length > 0) {

			let sumItemTaxableAmt = 0;
			for (let i = 0; i < purchaseItemArr.length; i++) {
				let purchaseItem = purchaseItemArr[i];

				sumItemTaxableAmt = sumItemTaxableAmt + purchaseItem.derivedTaxableAmt!;
			}
			return sumItemTaxableAmt;
		}
		return 0;
	}

	public static getSumAmtAfterTax(
		purchaseItemArr: PurchaseItemType[]
	) {

		let sumAmtAfterTax = 0;

		if (purchaseItemArr !== undefined && purchaseItemArr !== null && purchaseItemArr.length > 0) {
			for (let i = 0; i < purchaseItemArr.length; i++) {
				let purchaseItem = purchaseItemArr[i];

				sumAmtAfterTax = sumAmtAfterTax + purchaseItem.derivedAmt!;
			}
		}

		return sumAmtAfterTax;
	}

	public static getTaxSum(
		purchaseItemArr: PurchaseItemType[]
	): TaxAmtContainerType {

		let cgstA = 0;
		let sgstA = 0;
		let igstA = 0;

		if (purchaseItemArr !== undefined && purchaseItemArr !== null && purchaseItemArr.length > 0) {

			for (let i = 0; i < purchaseItemArr.length; i++) {
				let purchaseItem = purchaseItemArr[i];

				cgstA = cgstA + purchaseItem.derivedCgstA!;
				sgstA = sgstA + purchaseItem.derivedSgstA!;
				igstA = igstA + purchaseItem.derivedIgstA!;
			}
		}

		return {
			cgstA: cgstA,
			sgstA: sgstA,
			igstA: igstA,
			amtWithTax: 0,
		};
	}

	public static calOthChNonTax(
		purchaseItemArr: PurchaseItemType[],
		purchaseOthChNonTaxableArr: VouOthChNonTaxableType[]
	) {
		let sumAmtAfterTax = this.getSumAmtAfterTax(purchaseItemArr);

		for (let i = 0; i < purchaseOthChNonTaxableArr.length; i++) {
			let purchaseOthChNonTaxable = purchaseOthChNonTaxableArr[i];

			if (purchaseOthChNonTaxable.rate == null) {
				purchaseOthChNonTaxable.derivedAmt = 0;
			}
			else {
				if (purchaseOthChNonTaxable.rateTypeId === RateTypeEnum.FLAT.id) {
					purchaseOthChNonTaxable.derivedAmt = purchaseOthChNonTaxable.rate;
				}
				else {
					if (purchaseOthChNonTaxable.rateTypeId === RateTypeEnum.PERCENT.id) {
						purchaseOthChNonTaxable.derivedAmt = sumAmtAfterTax * purchaseOthChNonTaxable.rate / 100;
					}
				}
			}
		}
	}

	public static getTotalAmt(
		purchaseItemArr: PurchaseItemType[],
		purchaseOthChNonTaxableArr: VouOthChNonTaxableType[]
	): TotalAmtContainerType {

		let totalAmt = 0;
		let roundOff = 0;
		let amtPayable = 0;


		totalAmt = 0;
		//
		for (let i = 0; i < purchaseItemArr.length; i++) {
			let purchaseItem = purchaseItemArr[i];
			{
				totalAmt = totalAmt + purchaseItem.derivedAmt!;
			}
		}

		for (let i = 0; i < purchaseOthChNonTaxableArr.length; i++) {
			let purchaseOthChNonTaxable = purchaseOthChNonTaxableArr[i];
			{
				totalAmt = totalAmt + purchaseOthChNonTaxable.derivedAmt!;
			}
		}

		amtPayable = totalAmt;
		roundOff = amtPayable - totalAmt;


		return {
			totalAmt: totalAmt,
			roundOff: roundOff,
			amtPayable: amtPayable,
		};
	}

}