export default class DueRcvableBySalesEnumCol {

	public static readonly NO = new DueRcvableBySalesEnumCol("no", "Number");
	public static readonly DATE = new DueRcvableBySalesEnumCol("date", "Date");
	public static readonly CUSTOMER_NAME = new DueRcvableBySalesEnumCol("customer_name", "Customer Name");
	public static readonly CITY = new DueRcvableBySalesEnumCol("city", "City");
	public static readonly STATE_NAME = new DueRcvableBySalesEnumCol("state_name", "State Name");
	public static readonly ORDER_NO = new DueRcvableBySalesEnumCol("order_no", "Order No");
	public static readonly ORDER_DATE = new DueRcvableBySalesEnumCol("order_date", "Order Date");
	public static readonly AGENT_NAME = new DueRcvableBySalesEnumCol("agent_name", "Agent");
	public static readonly LR_NO = new DueRcvableBySalesEnumCol("lr_no", "LR No");
	public static readonly LR_DATE = new DueRcvableBySalesEnumCol("lr_date", "LR Date");
	public static readonly TRANSPORTER_NAME = new DueRcvableBySalesEnumCol("transporter_name", "Transport");
	public static readonly AMT_PAYABLE = new DueRcvableBySalesEnumCol("amt_payable", "Amount Payable");
	public static readonly AMT_CLRD = new DueRcvableBySalesEnumCol("amt_clrd", "Amount Cleared");
	public static readonly AMT_UNCLRD = new DueRcvableBySalesEnumCol("amt_unclrd", "Amount Uncleared");
	public static readonly CREDIT_DAYS = new DueRcvableBySalesEnumCol("credit_days", "Credit Days");
	public static readonly AGE_DAYS = new DueRcvableBySalesEnumCol("age_days", "Age Days");
	public static readonly OVERDUE_DAYS = new DueRcvableBySalesEnumCol("overdue_days", "Overdue Days");
	public static readonly DUE_DATE = new DueRcvableBySalesEnumCol("due_date", "Due Date");
	public static readonly NOTE = new DueRcvableBySalesEnumCol("note", "Note");
	public static readonly COMPANY_PROFILE = new DueRcvableBySalesEnumCol("company_profile", "Company Profile");
	public static readonly FINAL_INVOICE_NO = new DueRcvableBySalesEnumCol("final_invoice_no", "Final Invoice No");
	public static readonly CREATED_BY = new DueRcvableBySalesEnumCol("created_by", "Created By");
	public static readonly CREATED_AT = new DueRcvableBySalesEnumCol("created_at", "Created At");

	private constructor(
		public readonly key: string,
		public readonly name: string) {
	}


	static get values(): DueRcvableBySalesEnumCol[] {
		return [
			this.NO,
			this.DATE,
			this.CUSTOMER_NAME,
			this.CITY,
			this.STATE_NAME,
			this.ORDER_NO,
			this.ORDER_DATE,
			this.AGENT_NAME,
			this.LR_NO,
			this.LR_DATE,
			this.TRANSPORTER_NAME,
			this.AMT_PAYABLE,
			this.AMT_CLRD,
			this.AMT_UNCLRD,
			this.CREDIT_DAYS,
			this.AGE_DAYS,
			this.OVERDUE_DAYS,
			this.DUE_DATE,
			this.NOTE,
			this.COMPANY_PROFILE,
			this.FINAL_INVOICE_NO,
			this.CREATED_BY,
			this.CREATED_AT,
		];
	}

	static getByKey(key: string): DueRcvableBySalesEnumCol | null {
		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.key === key) {
				return element;
			}
		}
		return null;
	}
}
