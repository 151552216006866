import { useState } from "react";
import PriceListTypeEnum from "../../enums/PriceListTypeEnum";
import PurchaseItemType from "../../types/PurchaseItemType";
import UnitLdto from "../../types/res/list/UnitLdto";
import ProdForEsimateLdto from "../../types/res/list/ProdForEsimateLdto";
import TaxCatLdto from "../../types/res/list/TaxCatLdto";
import TaxLdto from "../../types/res/list/TaxLdto";
import useConfirm from "../dialogs/ConfirmDialog";
import MessageDialog from "../dialogs/MessageDialog";

interface AddPurchaseItemsTableProps {
	taxMap: Map<number, TaxLdto>;
	taxCatMap: Map<number, TaxCatLdto>;
	productsMap: Map<number, ProdForEsimateLdto>;
	unitsMap: Map<number, UnitLdto>;
	addPurchaseItems: Array<PurchaseItemType>;
	handleClickEditParent: (index: number) => void;
	handleClickDeleteParent: (index: number) => void;
}

function AddPurchaseItemsTable({
	taxMap,
	taxCatMap,
	productsMap,
	unitsMap,
	addPurchaseItems,
	handleClickEditParent,
	handleClickDeleteParent
}: AddPurchaseItemsTableProps) {

	const [selectedIndex, setSelectedIndex] = useState<number>(-1);
	//
	const [messageDialogMessage, setMessageDialogMessage] = useState("");
	const [showMessageDialog, setShowMessageDialog] = useState(false);

	const confirm = useConfirm();

	const handleClickEdit = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {

		event.preventDefault();

		if (
			selectedIndex == null
			|| selectedIndex < 0
			|| selectedIndex >= addPurchaseItems.length
		) {
			setMessageDialogMessage("Select a row to edit");
			setShowMessageDialog(true);
			return false;
		}

		handleClickEditParent(selectedIndex);

	}

	const handleClickDelete = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {

		event.preventDefault();

		if (
			selectedIndex == null
			|| selectedIndex < 0
			|| selectedIndex >= addPurchaseItems.length
		) {
			setMessageDialogMessage("Select a row to delete");
			setShowMessageDialog(true);
			return false;
		}

		let choice = await confirm!({
			message: "Are you sure you want to delete?",
			title: "Delete confirmation"
		});

		if (choice === undefined || choice === null || choice === false)
			return;

		handleClickDeleteParent(selectedIndex);

	}

	return (
		<>
			<div className="flex-grow-1 mb-2">
				<button
					type="button"
					className="btn btn-primary me-2"
					onClick={handleClickEdit}
				>
					Edit
				</button>
				<button
					type="button"
					className="btn btn-primary me-2"
					onClick={handleClickDelete}
				>
					Delete
				</button>
				<button type="button" className="btn btn-primary me-2">Up</button>
				<button type="button" className="btn btn-primary me-2">Down</button>
			</div>

			<div className="flex-grow-1 mb-2 table-responsive text-nowrap">
				<table
					id="navigate"
					className="table table-hover table-bordered table-sm overflow-x-auto m-0 fixTableHead"
				>
					<thead className="bg-body-tertiary">
						<tr>
							<th scope="col">
								<input
									className="form-check-input"
									type="checkbox"
									id="chkCheckAll"
								/>
							</th>
							<th scope="col">Sr. No.</th>
							<th scope="col">Product</th>
							<th scope="col">Description</th>
							<th scope="col">Qty.</th>
							<th scope="col">Unit</th>
							<th scope="col">Rate</th>
							<th scope="col">Disc.</th>
							<th scope="col">Amt</th>
							<th scope="col">Tax</th>
						</tr>
					</thead>
					<tbody className="">
						{addPurchaseItems.map((addPurchaseItem, i) => (
							<tr key={i} onClick={(e) => setSelectedIndex(i)}>
								<td>
									<div>
										<input
											className="form-check-input"
											type="checkbox"
											value=""
										/>
									</div>
								</td>
								<td tabIndex={0}>
									<div>{i + 1}</div>
								</td>
								<td tabIndex={0}><div>{productsMap.get(addPurchaseItem.productId)?.name}</div></td>
								<td tabIndex={0}><div>{addPurchaseItem.desc}</div></td>
								<td tabIndex={0}><div>{addPurchaseItem.qty.toLocaleString('en-IN')}</div></td>
								<td tabIndex={0}><div>{unitsMap.get(productsMap.get(addPurchaseItem.productId)?.unitId!)?.name}</div></td>
								<td tabIndex={0}><div>{addPurchaseItem.rate}</div></td>
								<td tabIndex={0}><div>{
									addPurchaseItem.discRate !== undefined
										&& addPurchaseItem.discRate !== null ?
										addPurchaseItem.discRate.toLocaleString('en-IN') + " %"
										:
										""
								}</div></td>
								<td tabIndex={0}><div>{addPurchaseItem.derivedAmtAfterDisc?.toLocaleString('en-IN')}</div></td>
								<td tabIndex={0}><div>{addPurchaseItem.derivedTaxId !== undefined && addPurchaseItem.derivedTaxId !== null ? taxMap.get(addPurchaseItem.derivedTaxId)?.name : ""}</div></td>
							</tr>
						))}
					</tbody>
					<tfoot className="bg-body-tertiary">
						<tr>
							<th scope="row">Total</th>
							<th>{addPurchaseItems.length}</th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
						</tr>
					</tfoot>
				</table>
			</div >

			<MessageDialog
				showDialog={showMessageDialog}
				modalMessage={messageDialogMessage}
				setShowDialog={setShowMessageDialog} />
		</>
	);
}

export default AddPurchaseItemsTable;
