import RptCurrentStockReportByEnum from "../enums/RptCurrentStockReportByEnum";
import DropDownItemType from "../types/DropDownItemType";

export default class RptCurrentStockReportByHelper {

	static getRptCurrentStockReportByDdis() {
		let finalArr: DropDownItemType[] = [];
		for (let rptCurrentStockReportByEnum of RptCurrentStockReportByEnum.values) {
			finalArr.push({
				id: rptCurrentStockReportByEnum.id,
				name: rptCurrentStockReportByEnum.name
			});
		}
		return finalArr;
	}

	static getRptCurrentStockReportByDdi(paymentTerm: RptCurrentStockReportByEnum): DropDownItemType {
		return {
			id: paymentTerm.id,
			name: paymentTerm.name
		};
	}

	static getRptCurrentStockReportByMap(itemArr: RptCurrentStockReportByEnum[]) {
		let map = new Map<number, RptCurrentStockReportByEnum>([]);
		for (let index = 0; index < itemArr.length; index++) {
			const item = itemArr[index];
			map.set(item.id, item);
		}
		return map;
	}

}
