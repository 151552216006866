import OrderReportByTypeEnum from "../enums/OrderReportByTypeEnum";
import DropDownItemType from "../types/DropDownItemType";

export default class OrderReportByTypeHelper {

	static getOrderReportByTypeDdis() {
		let finalArr: DropDownItemType[] = [];
		for (let item of OrderReportByTypeEnum.values) {
			finalArr.push({
				id: item.id,
				name: item.name
			});
		}
		return finalArr;
	}

	static getOrderReportByTypeDdi(rateType: OrderReportByTypeEnum): DropDownItemType {
		return {
			id: rateType.id,
			name: rateType.name
		};
	}

}