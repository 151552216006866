export default class RptDueRcvableBySalesSm {

	no: number | undefined;
	dateFrom: Date | undefined;
	dateTo: Date | undefined;
	asOnDate: Date | undefined;
	customerId: number | undefined;
	isOverdue: boolean | undefined;
	customerName: string | undefined;
	city: string | undefined;
	stateId: number | undefined;
	state: string | undefined;
	orderNo: string | undefined;
	orderDateFrom: Date | undefined;
	orderDateTo: Date | undefined;
	agentId: number | undefined;
	agentName: string | undefined;
	lrNo: string | undefined;
	lrDateFrom: Date | undefined;
	lrDateTo: Date | undefined;
	transporterId: number | undefined;
	transporterName: string | undefined;
	companyProfileId: number | undefined;
	note: string | undefined;

	reset(): void {
		this.no = undefined;
		this.dateFrom = undefined;
		this.dateTo = undefined;
		this.asOnDate = undefined;
		this.customerId = undefined;
		this.isOverdue = undefined;
		this.customerName = undefined;
		this.city = undefined;
		this.stateId = undefined;
		this.state = undefined;
		this.orderNo = undefined;
		this.orderDateFrom = undefined;
		this.orderDateTo = undefined;
		this.agentId = undefined;
		this.agentName = undefined;
		this.lrNo = undefined;
		this.lrDateFrom = undefined;
		this.lrDateTo = undefined;
		this.transporterId = undefined;
		this.transporterName = undefined;
		this.companyProfileId = undefined;
		this.note = undefined;
	}

	isBlank(): boolean {
		return this.no === undefined
			&& this.dateFrom === undefined
			&& this.dateTo === undefined
			&& this.asOnDate === undefined
			&& this.customerId === undefined
			&& this.isOverdue === undefined
			&& this.customerName === undefined
			&& this.city === undefined
			&& this.stateId === undefined
			&& this.state === undefined
			&& this.orderNo === undefined
			&& this.orderDateFrom === undefined
			&& this.orderDateTo === undefined
			&& this.agentId === undefined
			&& this.agentName === undefined
			&& this.lrNo === undefined
			&& this.lrDateFrom === undefined
			&& this.lrDateTo === undefined
			&& this.transporterId === undefined
			&& this.transporterName === undefined
			&& this.companyProfileId === undefined
			&& this.note === undefined;
	}
}
