import { useEffect, useState } from "react";
import { useGvContext } from "../../GvContext";
import MsgTemplateApi from "../../api/MsgTemplateApi";
import { BASE_URL, TOKEN } from "../../config";
import KbShortcutEnum from "../../enums/KbShortcutEnum";
import ResourceEnum from "../../enums/ResourceEnum";
import KbscHelper from "../../helpers/KbscHelper";
import strFormat from "../../helpers/StringHelper";
import RowType from "../../types/RowType";
import ListMsgTemplateSm from "../../types/sm/ListMsgTemplateSm";
import ListViewButtonBar from "../ListViewButtonBar";
import ListViewTable from "../ListViewTable";
import useConfirm from "../dialogs/ConfirmDialog";
import MessageDialog from "../dialogs/MessageDialog";
import ListMsgTemplateSd from "../sd/ListMsgTemplateSd";

function ListMsgTemplate(): JSX.Element {

	const resourceName = ResourceEnum.MSG_TEMPLATE.name;

	const [rows, setRows] = useState<RowType[]>([]);
	const [selectedId, setSelectedId] = useState<number | null>(null);

	const [searchModel, setSearchModel] = useState<ListMsgTemplateSm>(new ListMsgTemplateSm());

	const [showSd, setShowSd] = useState<boolean>(false);

	const [messageDialogMessage, setMessageDialogMessage] = useState("");
	const [showMessageDialog, setShowMessageDialog] = useState(false);

	const confirm = useConfirm();
	const gv = useGvContext();

	const colNames = [
		"Name",
		"Note",
	];

	useEffect(() => {
		async function process() {
			loadData();
		}
		process();
	}, [searchModel]);

	useEffect(() => {

		const handleKeyDown = (event: React.KeyboardEvent<HTMLTableElement> | KeyboardEvent) => {

			if (showSd || showMessageDialog) {
				return;
			}

			let kbShortcutModel = KbscHelper.findKbShortcut(event, gv.kbKbShortcutModel);
			if (kbShortcutModel == null) {
				return;
			}

			let kbShortcutEnum = KbShortcutEnum.getById(kbShortcutModel.id);
			if (kbShortcutEnum == null) {
				return;
			}

			switch (kbShortcutEnum) {
				case KbShortcutEnum.ADD:
					handleClickAdd();
					event.preventDefault();
					event.stopPropagation();
					break;
				case KbShortcutEnum.EDIT:
					handleClickEdit();
					event.preventDefault();
					event.stopPropagation();
					break;
				case KbShortcutEnum.DELETE:
					handleClickDelete();
					event.preventDefault();
					event.stopPropagation();
					break;
				case KbShortcutEnum.EXPORT:
					handleClickExport();
					event.preventDefault();
					event.stopPropagation();
					break;
				case KbShortcutEnum.FIND:
					handleClickFilter();
					event.preventDefault();
					event.stopPropagation();
					break;
				case KbShortcutEnum.CLEAR_FILTER:
					handleClickClearFilter();
					event.preventDefault();
					event.stopPropagation();
					break;
			}
		};

		window.addEventListener('keydown', handleKeyDown);

		// Clean up event listener on component unmount
		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	}, [gv.kbKbShortcutModel, showSd, showMessageDialog]);

	const handleClickAdd = () => {
		gv.menuHelper.openAddMsgTemplate();
	};

	const handleClickEdit = () => {

		if (selectedId === null) {
			setMessageDialogMessage("Select atleast one row");
			setShowMessageDialog(true);
			return;
		}

		gv.menuHelper.openAddMsgTemplate(selectedId);
	};

	const handleClickDelete = async () => {
		if (selectedId === null) {
			setMessageDialogMessage("Select atleast one row");
			setShowMessageDialog(true);
			return;
		}

		let choice = await confirm!({
			message: "Are you sure you want to delete?",
			title: "Delete confirmation"
		});

		if (choice === undefined || choice === null || choice === false)
			return;

		const requestOptions = {
			method: "DELETE",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			}
		}

		const res = await fetch(strFormat("%s/%s/%s", BASE_URL, resourceName, selectedId), requestOptions);

		if (res.status !== 204) {
			const data = await res.json();
			setMessageDialogMessage(data.message);
			setShowMessageDialog(true);
		}
		else {
			loadData();
		}
	};

	const handleClickExport = () => {
		console.log("handleClickExport");
	};

	const handleClickFilter = () => {
		setShowSd(true);
	};

	const handleClickClearFilter = () => {
		let searchModelLoc = new ListMsgTemplateSm();
		searchModelLoc.reset();
		setSearchModel(searchModelLoc);
	};

	const loadData = async () => {

		let res = await MsgTemplateApi.getMsgTemplateLdtos(searchModel);

		if (res.isSuccess) {

			let dataToReturn: RowType[] = [];
			for (let i = 0; i < res.items?.length!; i++) {

				let row = res.items![i];

				let oneRowArray: string[] = [];
				oneRowArray.push(row.name);
				oneRowArray.push(row.message);
				dataToReturn.push({
					id: row.id,
					data: oneRowArray
				});
			};

			setRows(dataToReturn);
		}
		else {
			setShowMessageDialog(true);
			setMessageDialogMessage(res.errorRes?.message!);
		}

	}

	return (
		<>
			<ListViewButtonBar
				handleClickAdd={handleClickAdd}
				handleClickEdit={handleClickEdit}
				handleClickDelete={handleClickDelete}
				handleClickExport={handleClickExport}
				handleClickFilter={handleClickFilter}
				handleClickClearFilter={handleClickClearFilter}
			/>

			<ListViewTable
				colNames={colNames}
				rows={rows}
				setSelectedId={setSelectedId}
			/>

			<ListMsgTemplateSd
				showModal={showSd}
				setShowModal={setShowSd}
				searchModel={searchModel}
				processOnSuccess={(searchModel) => setSearchModel(searchModel)}
			/>

			<MessageDialog
				showDialog={showMessageDialog}
				modalMessage={messageDialogMessage}
				setShowDialog={setShowMessageDialog} />
		</>
	);
}

export default ListMsgTemplate;
