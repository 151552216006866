import PriceListTypeEnum from "../enums/PriceListTypeEnum";
import DropDownItemType from "../types/DropDownItemType";

export default class PriceListTypeHelper {

	static getPriceListTypesForDd() {
		let finalArr: DropDownItemType[] = [];
		PriceListTypeEnum.values.forEach(item => {
			finalArr.push({
				id: item.id,
				name: item.name
			});
		});
		return finalArr;
	}

	static getPriceListTypeForDd(priceListType: PriceListTypeEnum): DropDownItemType {
		return {
			id: priceListType.id,
			name: priceListType.name
		};
	}

}