import { useState } from "react";
import Cookies from "universal-cookie";
import LoginApi from "../api/LoginApi";
import { TOKEN } from "../config";
import loginImage from "../login.jpg";

interface LoginProps {
	setLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
}

function Login({ setLoggedIn }: LoginProps): JSX.Element {
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		let res = await LoginApi.login(username, password);

		if (res.isSuccess) {
			const cookies = new Cookies();
			cookies.set("token", res.item?.accessToken);
			TOKEN.val = res.item?.accessToken!;
			setLoggedIn(true);
		}

	};

	return (
		<div
			className="d-flex justify-content-center align-items-start min-vh-100 pt-5"
			style={{
				backgroundImage:
					'url("https://picsum.photos/1920/1080")',
				// 'url("https://images.pexels.com/photos/924824/pexels-photo-924824.jpeg?cs=srgb&dl=pexels-jakub-novacek-924824.jpg&fm=jpg&w=1920&h=1280")',
			}}
		>
			<div className="d-flex flex-column shadow-lg bg-body-tertiary rounded">
				<div className="flex-fill">
					<img src={loginImage} className="rounded" alt="background" />
				</div>
				<div className="flex-fill p-4">
					<form onSubmit={handleSubmit}>
						<div className="input-group mb-3">
							<input
								type="text"
								value={username}
								onChange={(e) => setUsername(e.target.value)}
								className="form-control form-control"
								placeholder="Username"
								aria-label="Username"
								aria-describedby="basic-addon1"
							/>
							<span className="input-group-text" id="basic-addon1">
								<i className="fa-solid fa-user"></i>
							</span>
						</div>
						<div className="input-group mb-3">
							<input
								type="password"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								className="form-control form-control"
								placeholder="Password"
								aria-label="Password"
								aria-describedby="basic-addon1"
							/>
							<span className="input-group-text" id="basic-addon1">
								<i className="fa-solid fa-key"></i>
							</span>
						</div>
						<div className="d-grid">
							<button type="submit" className="btn btn-primary">
								<i className="fa-solid fa-right-to-bracket"></i> Enter
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
}

export default Login;
