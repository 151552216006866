import SortOrderEnum from "../../enums/SortOrderEnum";
import RptPurchaseByDaySortByEnum from "../../enums/sort_by/RptPurchaseByDaySortByEnum";

export default class RptPurchaseByDaySm {

	dateFrom: Date | undefined;
	dateTo: Date | undefined;

	supplierId: number | undefined;
	city: string | undefined;
	cityExact: string | undefined;
	stateId: number | undefined;

	parentProdCatId: number | undefined;
	prodCatId: number | undefined;
	prodId: number | undefined;

	rptPurchaseSortByEnum: RptPurchaseByDaySortByEnum | undefined;
	sortOrderEnum: SortOrderEnum | undefined;

	doSet(searchModel: RptPurchaseByDaySm): void {
		this.dateFrom = searchModel.dateFrom;
		this.dateTo = searchModel.dateTo;

		this.supplierId = searchModel.supplierId;
		this.city = searchModel.city;
		this.cityExact = searchModel.cityExact;
		this.stateId = searchModel.stateId;

		this.parentProdCatId = searchModel.parentProdCatId;
		this.prodCatId = searchModel.prodCatId;
		this.prodId = searchModel.prodId;

		this.rptPurchaseSortByEnum = searchModel.rptPurchaseSortByEnum;
		this.sortOrderEnum = searchModel.sortOrderEnum;
	}

	reset(): void {
		this.dateFrom = undefined;
		this.dateTo = undefined;

		this.supplierId = undefined;
		this.city = undefined;
		this.cityExact = undefined;
		this.stateId = undefined;

		this.parentProdCatId = undefined;
		this.prodCatId = undefined;
		this.prodId = undefined;

		this.rptPurchaseSortByEnum = undefined;
		this.sortOrderEnum = undefined;
	}

	isBlank(): boolean {
		return this.dateFrom === undefined
			&& this.dateTo === undefined
			&& this.supplierId === undefined
			&& this.city === undefined
			&& this.cityExact === undefined
			&& this.stateId === undefined
			&& this.parentProdCatId === undefined
			&& this.prodCatId === undefined
			&& this.prodId === undefined
			&& this.rptPurchaseSortByEnum === undefined
			&& this.sortOrderEnum === undefined;
	}

}
