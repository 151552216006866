import moment from "moment";
import DateFormatEnum from "../enums/DateFormatEnum";

const dateToString = (date: Date, format: DateFormatEnum): string => {
	return moment(date).format(format.format);
};

export const stringToDate = (dateString: string, format: DateFormatEnum): string => {
	return moment(dateString).format(format.format);
};


export default dateToString;