import MenuResType from "./types/res/MenuResType";

export const BASE_URL: string = process.env.REACT_APP_API_BASE_URL!;

export let TOKEN = { val: "" };
export let MENUS: { val: MenuResType } = { val: null! };

export let MENU_STACK: Array<JSX.Element> = [];
export let TITLE_STACK: string[] = [];

export let CURRENT_LAYOUT: { val: JSX.Element; } = { val: null! };
export let CURRENT_TITLE = { val: "" };