import { BASE_URL, TOKEN } from "../config";
import ServerNotReachableErrorCommonCudObj from "../default_objects/ServerNotReachableErrorCommonCudObj";
import ServerSideErrorCommonCudObj from "../default_objects/ServerSideErrorCommonCudObj";
import GetAcctsObjectTypeEnum from "../enums/GetAcctsObjectTypeEnum";
import ResourceEnum from "../enums/ResourceEnum";
import strFormat from "../helpers/StringHelper";
import { getQueryString } from "../helpers/UrlSearchParamHelper";
import CommonGetListType from "../types/CommonGetListType";
import DropDownItemType from "../types/DropDownItemType";
import ErrorResType from "../types/res/ErrorResType";
import AcctForCustomerHdto from "../types/res/list/AcctForCustomerHdto";
import AcctLdto from "../types/res/list/AcctLdto";
import RptAcctSummHdto from "../types/res/list/RptAcctSummHdto";
import RptAcctSummByAcctTypeSm from "../types/sm/RptAcctSummByAcctTypeSm";

export default class AcctApi {

	static getAcctDdis(items: AcctLdto[]) {
		let finalArr: DropDownItemType[] = [];
		for (const item of items) {
			finalArr.push({
				id: item.id,
				name: item.name,
			});
		}
		return finalArr;
	}

	static getAcctLdtoMap(itemArr: AcctLdto[]) {
		let map = new Map<number, AcctLdto>([]);
		for (let index = 0; index < itemArr.length; index++) {
			const item = itemArr[index];
			map.set(item.id, item);
		}
		return map;
	}

	static async getAcctLdtos(): Promise<CommonGetListType<AcctLdto>> {

		try {

			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat("%s/%s", BASE_URL, ResourceEnum.ACCT.name)
				, requestOptions);

			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as AcctLdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}

			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async getAcctForCustomerHdtos(): Promise<CommonGetListType<AcctForCustomerHdto>> {

		try {

			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat("%s/%s", BASE_URL, ResourceEnum.ACCT.name)
				+ getQueryString({ returnObjectType: GetAcctsObjectTypeEnum.ACCT_LIST_FOR_CUSTOMER_HDTO.enumName.toUpperCase() }),
				requestOptions);

			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as AcctForCustomerHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}

			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async getAcctSummByAcctType(searchModel: RptAcctSummByAcctTypeSm): Promise<CommonGetListType<RptAcctSummHdto>> {

		try {

			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s",
					BASE_URL,
					ResourceEnum.ACCT.name,
					"summary",
					"by-account-type"
				)
				+ getQueryString(searchModel)
				, requestOptions);
			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptAcctSummHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}

			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

}