import RptLowStockReportByEnum from "../enums/RptLowStockReportByEnum";
import DropDownItemType from "../types/DropDownItemType";

export default class RptLowStockReportByHelper {

	static getRptLowStockReportByDdis() {
		let finalArr: DropDownItemType[] = [];
		for (let rptLowStockReportByEnum of RptLowStockReportByEnum.values) {
			finalArr.push({
				id: rptLowStockReportByEnum.id,
				name: rptLowStockReportByEnum.name
			});
		}
		return finalArr;
	}

	static getRptLowStockReportByDdi(paymentTerm: RptLowStockReportByEnum): DropDownItemType {
		return {
			id: paymentTerm.id,
			name: paymentTerm.name
		};
	}

	static getRptLowStockReportByMap(itemArr: RptLowStockReportByEnum[]) {
		let map = new Map<number, RptLowStockReportByEnum>([]);
		for (let index = 0; index < itemArr.length; index++) {
			const item = itemArr[index];
			map.set(item.id, item);
		}
		return map;
	}

}
