import DropDownItemType from "../types/DropDownItemType";
import StateLdto from "../types/res/list/StateLdto";

export default class StateHelper {

	static getStateListResArrForDd(listStates: StateLdto[]) {

		let finalArr: DropDownItemType[] = [];
		listStates.forEach(item => {
			finalArr.push({
				id: item.id,
				name: item.name
			});
		});
		return finalArr;

	}

	static getStateListResForDd(state: StateLdto): DropDownItemType {
		return {
			id: state.id,
			name: state.name
		};
	}

	static getStateListResMap(itemArr: StateLdto[]) {
		let map = new Map<number, StateLdto>([]);
		for (let index = 0; index < itemArr.length; index++) {
			const item = itemArr[index];
			map.set(item.id, item);
		}
		return map;
	}

}