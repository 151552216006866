export default class LstJournalEnumCol {

	public static readonly NO = new LstJournalEnumCol("no");
	public static readonly DATE = new LstJournalEnumCol("date");
	public static readonly TX_TYPE_ID = new LstJournalEnumCol("tx_type_id");
	public static readonly ACCT_NAME = new LstJournalEnumCol("acct_name");
	public static readonly AMT = new LstJournalEnumCol("amt");
	public static readonly NOTE = new LstJournalEnumCol("note");
	public static readonly CREATED_BY = new LstJournalEnumCol("created_by");
	public static readonly CREATED_AT = new LstJournalEnumCol("created_at");

	private constructor(
		public readonly key: string) {
	}

	static get values(): LstJournalEnumCol[] {
		return [
			this.NO,
			this.DATE,
			this.TX_TYPE_ID,
			this.ACCT_NAME,
			this.AMT,
			this.NOTE,
			this.CREATED_BY,
			this.CREATED_AT,
		];
	}

	static getByKey(key: string): LstJournalEnumCol | null {
		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.key === key) {
				return element;
			}
		}
		return null;
	}
}
