import Downshift, { ControllerStateAndHelpers, DownshiftState, StateChangeOptions } from "downshift";
import { forwardRef, useEffect, useRef, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import DropDownItemType from "../types/DropDownItemType";
import { Option, OptionsList } from "./presentational2";

interface DownshiftSelectProps {
	inputId: string;
	value: DropDownItemType | null;
	onChange: (itemId: number | null) => void;
	options: DropDownItemType[];
	isLoading: boolean;
	isDisabled: boolean;
	placeholder: string;
	forwardedRef?: React.MutableRefObject<HTMLInputElement | null>;
}

const DownshiftSelect = forwardRef(({
	inputId,
	value,
	onChange,
	options,
	isLoading,
	isDisabled,
	placeholder,
	forwardedRef
}: DownshiftSelectProps, ref) => {

	const [items, setItems] = useState(options);

	const inputRef = useRef<HTMLInputElement | null>(null);

	useEffect(() => {
		setItems(options);
	}, [options]);

	useEffect(() => {
		if (forwardedRef) {
			forwardedRef.current = inputRef.current;
		}
	}, [forwardedRef]);

	const itemToString = (i: DropDownItemType | null) => (i ? i.name : "");

	const filterItems = (items: DropDownItemType[], value: string) => {
		return items.filter(item =>
			item.name.toLowerCase().includes(value.toLowerCase())
		);
	}

	const handleStateChange = (
		changes: Partial<DownshiftState<DropDownItemType>>,
		downshiftStateAndHelpers: ControllerStateAndHelpers<DropDownItemType>
	) => {
		if (
			changes.hasOwnProperty("inputValue") &&
			!(changes.hasOwnProperty("isOpen") && !changes.isOpen)
		) {
			setItems(filterItems(options, changes.inputValue || ""));
		}
		if (changes.hasOwnProperty("isOpen") && !changes.isOpen) {
			setItems(options);
		}
	};


	const handleChange = (selectedItem: DropDownItemType | null) => {
		onChange(selectedItem ? selectedItem.id : null);
		// setItems(options);
	};

	const stateReducer = (
		state: DownshiftState<DropDownItemType>,
		changes: StateChangeOptions<DropDownItemType>
	) => {
		if (changes.hasOwnProperty("isOpen") && changes.isOpen) {
			return {
				...changes,
				highlightedIndex:
					items.indexOf(
						state.selectedItem as DropDownItemType
					),
			};
		}
		return changes;
	};

	useEffect(() => {
		if (inputRef.current && isLoading) {
			inputRef.current.blur();
		}
	}, [isLoading]);

	const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>, isOpen: boolean, selectedItem: DropDownItemType | null) => {
		if (!event.altKey
			&& !event.ctrlKey
			&& !event.metaKey
			&& !event.shiftKey
			&& event.key === "Escape"
			&& event.defaultPrevented
			&& (selectedItem != null
				|| isOpen)
		) {
			event.stopPropagation();
		}
	};

	return (
		<Downshift
			inputId={inputId}
			labelId={`${inputId}-label`}
			menuId={`${inputId}-menu`}
			selectedItem={value}
			itemToString={itemToString}
			onStateChange={handleStateChange}
			onChange={handleChange}
			stateReducer={stateReducer}
		>
			{({
				getInputProps,
				getToggleButtonProps,
				getItemProps,
				getMenuProps,
				isOpen,
				clearSelection,
				selectedItem,
				highlightedIndex,
				openMenu,
				getRootProps,
				inputValue,
				closeMenu,
				selectItem
			}) => (
				<InputGroup
					size="sm"
					{...getRootProps()}
					onKeyDown={(e) => handleKeyDown(e, isOpen, selectedItem)}>
					<Form.Control
						size="sm"
						{...getInputProps({
							placeholder: placeholder || "SELECT...",
							onClick: (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
								openMenu();
							},
							disabled: isDisabled || isLoading,
							onKeyDown: (event) => {
								if (!event.altKey
									&& !event.ctrlKey
									&& !event.metaKey
									&& !event.shiftKey
									&& event.key === "Tab"
									&& !event.defaultPrevented
									&& isOpen
									&& highlightedIndex != null
									&& highlightedIndex >= 0
									&& highlightedIndex < items.length
								) {
									selectItem(items[highlightedIndex]);
								}
							}
						})}
						ref={inputRef}
					/>

					{selectedItem ? (
						<Button
							size="sm"
							variant="light"
							onClick={() => {
								clearSelection();
								inputRef.current?.focus();
							}}
							disabled={isDisabled || isLoading}
							tabIndex={-1}
						>
							<i className="bi bi-x"></i>
						</Button>
					) : null}
					<Button
						size="sm"
						{...getToggleButtonProps({
							variant: "light",
							onClick: () => !isOpen && inputRef.current?.focus(),
							disabled: isDisabled || isLoading,
							tabIndex: -1
						})}
					>
						{isOpen ? (
							<i className="bi bi-chevron-up"></i>
						) : (
							<i className="bi bi-chevron-down"></i>
						)}
					</Button>
					{!isOpen ? null : (
						<OptionsList {...getMenuProps()}>
							{items.map((item, index) => (
								<Option
									key={item.id}
									index={index}
									highlightedIndex={highlightedIndex}
									item={item.name}
									selectedItem={selectedItem === null ? "" : selectedItem.name || ""}
									inputValue={inputValue === null ? "" : inputValue}
									{...getItemProps({
										item,
										index,
									})}
								>
									{itemToString(item)}
								</Option>
							))}
						</OptionsList>
					)}
				</InputGroup>
			)}
		</Downshift>
	);
});

export default DownshiftSelect;