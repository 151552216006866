import { useEffect, useState } from "react";
import { useGvContext } from "../../GvContext";
import EstimateApi from "../../api/EstimateApi";
import MenuApi from "../../api/MenuApi";
import { BASE_URL, TOKEN } from "../../config";
import DateFormatEnum from "../../enums/DateFormatEnum";
import ListMenuEnum from "../../enums/ListMenuEnum";
import ResourceEnum from "../../enums/ResourceEnum";
import SalesPrintTypeEnum from "../../enums/SalesPrintTypeEnum";
import LstEstimateEnumCol from "../../enums/col/LstEstimateEnumCol";
import dateToString from "../../helpers/DateHelper";
import strFormat from "../../helpers/StringHelper";
import RowType from "../../types/RowType";
import ColSettingResType from "../../types/res/ColSettingResType";
import ListEstimateSm from "../../types/sm/ListEstimateSm";
import ListViewButtonBar from "../ListViewButtonBar";
import ListViewTable from "../ListViewTable";
import useConfirm from "../dialogs/ConfirmDialog";
import EstimatePrintDialog from "../dialogs/EstimatePrintDialog";
import MessageDialog from "../dialogs/MessageDialog";
import ListEstimateSd from "../sd/ListEstimateSd";

function ListEstimate(): JSX.Element {

	const resourceName = ResourceEnum.ESTIMATE.name;

	const [colSettings, setColSettings] = useState<ColSettingResType[]>([]);
	const [colNames, setColNames] = useState<string[]>([]);

	const [rows, setRows] = useState<RowType[]>([]);
	const [selectedId, setSelectedId] = useState<number | null>(null);

	const [searchModel, setSearchModel] = useState<ListEstimateSm>(new ListEstimateSm(2));
	//
	const [showSd, setShowSd] = useState<boolean>(false);
	
	const [messageDialogMessage, setMessageDialogMessage] = useState("");
	const [showMessageDialog, setShowMessageDialog] = useState(false);

	const [showEstimatePrintDialog, setShowEstimatePrintDialog] = useState(false);

	const confirm = useConfirm();
	const gv = useGvContext();

	useEffect(() => {
		async function process() {

			let res = await MenuApi.getColSettingDtos(ListMenuEnum.ESTIMATE);

			if (res.isSuccess) {

				setColSettings(res.items!);

				let colNamesLocal: string[] = [];
				res.items!.forEach((item) => {
					colNamesLocal.push(item.colName);
				});
				setColNames(colNamesLocal);

			}
			else {
				setShowMessageDialog(true);
				setMessageDialogMessage(res.errorRes?.message!);
			}
		}
		process();
	}, []);

	useEffect(() => {
		if (colSettings != null && colSettings.length > 0) {
			loadData();
		}
	}, [searchModel, colSettings]);

	const getListViewOptions = () => {
		return [
			{
				name: "Create Sales Order",
				myCallbackFunction: handleClickBtnConvertToSo
			},
			{
				name: "Create Sales",
				myCallbackFunction: handleClickBtnConvertToSales
			},
			{
				name: "Send text Message on Whatsapp",
				myCallbackFunction: handleClickMiSendWhatsapp
			},
			{
				name: "Send invoice on Whatsapp",
				myCallbackFunction: handleClickMiWhatsappBill
			},
			{
				name: "Duplicate",
				myCallbackFunction: handleClickDuplicateVoucher
			},
		];
	}

	const handleClickAdd = () => {
		gv.menuHelper.openAddEstimate();
	};

	const handleClickEdit = () => {

		if (selectedId === null) {
			setMessageDialogMessage("Select atleast one row");
			setShowMessageDialog(true);
			return;
		}

		gv.menuHelper.openAddEstimate(selectedId);
	};

	const handleClickDelete = async () => {
		if (selectedId === null) {
			setMessageDialogMessage("Select atleast one row");
			setShowMessageDialog(true);
			return;
		}

		let choice = await confirm!({
			message: "Are you sure you want to delete?",
			title: "Delete confirmation"
		});

		if (choice === undefined || choice === null || choice === false)
			return;

		const requestOptions = {
			method: "DELETE",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			}
		}

		const res = await fetch(strFormat("%s/%s/%s", BASE_URL, resourceName, selectedId), requestOptions);

		if (res.status !== 204) {
			const data = await res.json();
			setMessageDialogMessage(data.message);
			setShowMessageDialog(true);
		}
		else {
			loadData();
		}
	};

	const handleClickExport = () => {
		console.log("handleClickExport");
	};

	const handleClickPrint = () => {

		if (selectedId === null) {
			setMessageDialogMessage("Select atleast one row");
			setShowMessageDialog(true);
			return;
		}

		setShowEstimatePrintDialog(true);
	};

	const handleClickBtnConvertToSo = () => {

		if (selectedId === null) {
			setMessageDialogMessage("Select atleast one row");
			setShowMessageDialog(true);
			return;
		}

		gv.menuHelper.openAddSalesOrderByEstimateId(selectedId);
	};
	const handleClickBtnConvertToSales = () => {

	};
	const handleClickMiSendWhatsapp = () => {

	};
	const handleClickMiWhatsappBill = () => {

	};
	const handleClickDuplicateVoucher = () => {

	};

	const print = async (isPrintEstimate: boolean, isPrintPendingItems: boolean, salesPrintType: SalesPrintTypeEnum) => {

		if (selectedId === null) {
			setMessageDialogMessage("Select atleast one row");
			setShowMessageDialog(true);
			return;
		}

		setShowEstimatePrintDialog(true);

		const fileURL = window.URL.createObjectURL(await EstimateApi.print([selectedId], isPrintEstimate, isPrintPendingItems, salesPrintType));
		// const a = document.createElement('a');
		// a.href = fileURL;
		// a.download = "download";
		// a.target = '_blank';
		// a.click();
		// window.URL.revokeObjectURL(fileURL);
		//Open the URL on new Window
		const pdfWindow = window.open();
		pdfWindow!.location.href = fileURL;
	};

	const handleClickOpen = () => {
		console.log("handleClickOpen");
	};

	const handleClickFilter = () => {
		setShowSd(true);
	};

	const handleClickClearFilter = () => {
		console.log("handleClickClearFilter");
	};

	const handleClickColSettings = () => {
		console.log("handleClickColSettings");
	};

	const loadData = async () => {

		let res = await EstimateApi.getEstimateLdtos(searchModel);

		if (res.isSuccess) {

			let dataToReturn: RowType[] = [];

			for (let i = 0; i < res.items?.length!; i++) {
				let estimateDto = res.items![i];

				let oneRowArray: string[] = [];

				for (let j = 0; j < colSettings.length; j++) {
					let colSetting = colSettings[j];

					switch (LstEstimateEnumCol.getByKey(colSetting.colKey)) {
						case LstEstimateEnumCol.CITY:
							oneRowArray.push(estimateDto.city == null ? "" : estimateDto.city);
							break;
						case LstEstimateEnumCol.CUSTOMER:
							oneRowArray.push(estimateDto.customerName);
							break;
						case LstEstimateEnumCol.DATE:
							oneRowArray.push(dateToString(estimateDto.date, DateFormatEnum.APP_DATETIME));
							break;
						case LstEstimateEnumCol.NO:
							oneRowArray.push(estimateDto.no.toString());
							break;
						case LstEstimateEnumCol.STATE:
							oneRowArray.push(estimateDto.stateName == null ? "" : estimateDto.stateName);
							break;
						case LstEstimateEnumCol.NOTE:
							oneRowArray.push(estimateDto.note);
							break;
						case LstEstimateEnumCol.QTY:
							oneRowArray.push(estimateDto.sumQty == null ? "" : estimateDto.sumQty.toString());
							break;
						case LstEstimateEnumCol.SALES_ORDER_NO:
							oneRowArray.push(estimateDto.salesOrderNo);
							break;
						case LstEstimateEnumCol.STATUS:
							oneRowArray.push(estimateDto.stateName == null ? "" : estimateDto.statusName);
							break;
						case LstEstimateEnumCol.LOCK_STOCK_FOR_DAYS:
							oneRowArray.push(estimateDto.lockStatusForDays == null ? "" : estimateDto.lockStatusForDays.toString());
							break;
						case LstEstimateEnumCol.CREATED_BY:
							oneRowArray.push(estimateDto.createdByUserName);
							break;
						case LstEstimateEnumCol.CREATED_AT:
							oneRowArray.push(dateToString(estimateDto.createdAt, DateFormatEnum.APP_DATETIME));
							break;
					}
				};

				dataToReturn.push({
					id: estimateDto.id,
					data: oneRowArray
				});
			};

			setRows(dataToReturn);
		}
		else {
			setShowMessageDialog(true);
			setMessageDialogMessage(res.errorRes?.message!);
		}

	}

	return (
		<>
			<ListViewButtonBar
				listViewOptions={getListViewOptions()}
				handleClickEdit={handleClickEdit}
				handleClickDelete={handleClickDelete}
				handleClickExport={handleClickExport}
				handleClickPrint={handleClickPrint}
				handleClickOpen={handleClickOpen}
				handleClickFilter={handleClickFilter}
				handleClickClearFilter={handleClickClearFilter}
				handleClickColSettings={handleClickColSettings}
			/>
			<ListViewTable
				colNames={colNames}
				rows={rows}
				setSelectedId={setSelectedId}
			/>

			<ListEstimateSd
				showModal={showSd}
				setShowModal={setShowSd}
				searchModel={searchModel}
				processOnSuccess={(searchModel) => setSearchModel(searchModel)}
			/>

			<MessageDialog
				showDialog={showMessageDialog}
				modalMessage={messageDialogMessage}
				setShowDialog={setShowMessageDialog} />

			<EstimatePrintDialog
				showDialog={showEstimatePrintDialog}
				setShowDialog={setShowEstimatePrintDialog}
				print={print}
			/>
		</>
	);
}

export default ListEstimate;
