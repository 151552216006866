import DropDownItemType from "../types/DropDownItemType";
import TaxCatLdto from "../types/res/list/TaxCatLdto";

export default class TaxCatHelper {

	static getTaxCatsForDd(listTaxCats: TaxCatLdto[]) {

		let finalArr: DropDownItemType[] = [];
		listTaxCats.forEach(item => {
			finalArr.push({
				id: item.id,
				name: item.name
			});
		});
		return finalArr;

	}

	static getTaxCatListResForDd(taxCat: TaxCatLdto): DropDownItemType {
		return {
			id: taxCat.id,
			name: taxCat.name
		};
	}

	static getTaxCatListResMap(itemArr: TaxCatLdto[]) {
		let map = new Map<number, TaxCatLdto>([]);
		for (let index = 0; index < itemArr.length; index++) {
			const item = itemArr[index];
			map.set(item.id, item);
		}
		return map;
	}


}
