
export default class RptEstimatePendingDetailedSm {

	dateFrom: Date | undefined;
	dateTo: Date | undefined;

	customerId: number | undefined;
	productId: number | undefined;

	doSet(searchModel: RptEstimatePendingDetailedSm): void {
		this.dateFrom = searchModel.dateFrom;
		this.dateTo = searchModel.dateTo;

		this.customerId = searchModel.customerId;
		this.productId = searchModel.productId;
	}

	reset(): void {
		this.dateFrom = undefined;
		this.dateTo = undefined;

		this.customerId = undefined;
		this.productId = undefined;
	}

	isBlank(): boolean {
		return this.dateFrom === undefined
			&& this.dateTo === undefined
			&& this.customerId === undefined
			&& this.productId === undefined;
	}

}
