export default class SalesOrderPrintTypeEnum {
	public static readonly SALES_ORDER = new SalesOrderPrintTypeEnum(1, "SALES_ORDER");
	public static readonly PICK_LIST = new SalesOrderPrintTypeEnum(2, "PICK_LIST");

	private constructor(
		public readonly id: number,
		public readonly enumName: string) {
	}

}
