export default class RptSalesProfitBySalesItemSm {

	//
	dateFrom: Date | undefined;
	dateTo: Date | undefined;

	customerId: number | undefined;
	prodId: number | undefined;

	no: string | undefined;
	//
	salesId: number | undefined;

	doSet(searchModel: RptSalesProfitBySalesItemSm): void {
		this.dateFrom = searchModel.dateFrom;
		this.dateTo = searchModel.dateTo;

		this.customerId = searchModel.customerId;
		this.prodId = searchModel.prodId;

		this.no = searchModel.no;
		//
		this.salesId = searchModel.salesId;
	}

	reset(): void {
		this.dateFrom = undefined;
		this.dateTo = undefined;

		this.customerId = undefined;
		this.prodId = undefined;

		this.no = undefined;
		//
		this.salesId = undefined;
	}

	isBlank(): boolean {
		return this.dateFrom === undefined
			&& this.dateTo === undefined
			&& this.customerId === undefined
			&& this.prodId === undefined
			&& this.no === undefined
			//
			&& this.salesId === undefined;
	}

}
