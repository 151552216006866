export default class RptSalesProfitByProdSm {

	dateFrom: Date | undefined;
	dateTo: Date | undefined;

	customerId: number | undefined;
	prodId: number | undefined;

	doSet(searchModel: RptSalesProfitByProdSm): void {
		this.dateFrom = searchModel.dateFrom;
		this.dateTo = searchModel.dateTo;

		this.customerId = searchModel.customerId;
		this.prodId = searchModel.prodId;
	}

	reset(): void {
		this.dateFrom = undefined;
		this.dateTo = undefined;

		this.customerId = undefined;
		this.prodId = undefined;
	}

	isBlank(): boolean {
		return this.dateFrom === undefined
			&& this.dateTo === undefined
			&& this.customerId === undefined
			&& this.prodId === undefined;
	}

}
