import { BASE_URL, TOKEN } from "../config";
import ServerNotReachableErrorCommonCudObj from "../default_objects/ServerNotReachableErrorCommonCudObj";
import ServerSideErrorCommonCudObj from "../default_objects/ServerSideErrorCommonCudObj";
import ResourceEnum from "../enums/ResourceEnum";
import strFormat from "../helpers/StringHelper";
import { getQueryString } from "../helpers/UrlSearchParamHelper";
import CommonCudType from "../types/CommonCudType";
import CommonGetListType from "../types/CommonGetListType";
import ErrorResType from "../types/res/ErrorResType";
import PurchaseReturnLdto from "../types/res/list/PurchaseReturnLdto";
import RptPurchaseReturnByCityHdto from "../types/res/list/RptPurchaseReturnByCityHdto";
import RptPurchaseReturnByDayHdto from "../types/res/list/RptPurchaseReturnByDayHdto";
import RptPurchaseReturnByMonthHdto from "../types/res/list/RptPurchaseReturnByMonthHdto";
import RptPurchaseReturnByProductCategoryHdto from "../types/res/list/RptPurchaseReturnByProductCategoryHdto";
import RptPurchaseReturnByProductHdto from "../types/res/list/RptPurchaseReturnByProductHdto";
import RptPurchaseReturnByStateHdto from "../types/res/list/RptPurchaseReturnByStateHdto";
import RptPurchaseReturnBySupplierHdto from "../types/res/list/RptPurchaseReturnBySupplierHdto";
import RptPurchaseReturnDetailedByProductHdto from "../types/res/list/RptPurchaseReturnDetailedByProductHdto";
import RptPurchaseReturnDetailedByVoucherHdto from "../types/res/list/RptPurchaseReturnDetailedByVoucherHdto";
import PurchaseReturnRrHoDto from "../types/rr/PurchaseReturnRrHoDto";
import ListPurchaseReturnSm from "../types/sm/ListPurchaseReturnSm";
import RptPurchaseReturnByCitySm from "../types/sm/RptPurchaseReturnByCitySm";
import RptPurchaseReturnByDaySm from "../types/sm/RptPurchaseReturnByDaySm";
import RptPurchaseReturnByMonthSm from "../types/sm/RptPurchaseReturnByMonthSm";
import RptPurchaseReturnByProductCategorySm from "../types/sm/RptPurchaseReturnByProdCatSm";
import RptPurchaseReturnByProductSm from "../types/sm/RptPurchaseReturnByProdSm";
import RptPurchaseReturnByStateSm from "../types/sm/RptPurchaseReturnByStateSm";
import RptPurchaseReturnBySupplierSm from "../types/sm/RptPurchaseReturnBySupplierSm";
import RptPurchaseReturnDetailedByProductSm from "../types/sm/RptPurchaseReturnDetailedByProdSm";
import RptPurchaseReturnDetailedByVoucherSm from "../types/sm/RptPurchaseReturnDetailedByVoucherSm";

export default class PurchaseReturnApi {

	static async getPurchaseReturnLdtos(listPurchaseReturnSm: ListPurchaseReturnSm): Promise<CommonGetListType<PurchaseReturnLdto>> {
		try {

			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.PURCHASE_RETURN.name)
				+ getQueryString(listPurchaseReturnSm)
				, requestOptions);

			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as PurchaseReturnLdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async createPurchaseReturn(purchaseReturnRrHoDto: PurchaseReturnRrHoDto): Promise<CommonCudType> {

		const requestOptions = {
			method: "POST",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(purchaseReturnRrHoDto)
		}

		const res = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.PURCHASE_RETURN.name), requestOptions);

		if (res.status !== 201) {
			const data: ErrorResType = await res.json();
			return {
				isSuccess: false,
				errorRes: data
			}
		}

		return {
			isSuccess: true,
		}

	}

	static async updatePurchaseReturn(id: number, purchaseReturnRrHoDto: PurchaseReturnRrHoDto): Promise<CommonCudType> {

		const requestOptions = {
			method: "PUT",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(purchaseReturnRrHoDto)
		}

		const res = await fetch(strFormat("%s/%s/%s", BASE_URL, ResourceEnum.PURCHASE_RETURN.name, id), requestOptions);

		if (res.status !== 201) {
			const data: ErrorResType = await res.json();
			return {
				isSuccess: false,
				errorRes: data
			}
		}

		return {
			isSuccess: true,
		}

	}

	static async getById(id: number) {

		const requestOptions = {
			method: "GET",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			}
		}

		const res = await fetch(strFormat("%s/%s/%s", BASE_URL, ResourceEnum.PURCHASE_RETURN.name, id), requestOptions);
		const data: PurchaseReturnRrHoDto = await res.json();
		return data;

	}

	static async delete(id: number): Promise<CommonCudType> {

		try {
			const requestOptions = {
				method: "DELETE",
				headers: {
					"Authorization": "Bearer " + TOKEN.val,
					"Content-Type": "application/json"
				}
			}

			const response = await fetch(strFormat("%s/%s/%s", BASE_URL, ResourceEnum.PURCHASE_RETURN.name, id), requestOptions);

			if (response.status === 200) {
				return {
					isSuccess: true
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}


	}

	static async print(ids: number[]) {

		const requestOptions = {
			method: "GET",
			headers: {
				Authorization: "Bearer " + TOKEN.val,
				"Content-Type": "application/json",
			},
		};
		const res = await fetch(strFormat("%s/%s/print", BASE_URL, ResourceEnum.PURCHASE_RETURN.name)
			+ getQueryString({
				ids: ids,
			})
			, requestOptions);

		var blob = await res.blob();
		return new Blob([blob], { type: 'application/pdf' });
	}

	// report
	static async getRptPurchaseReturnByMonthHdtos(searchModel: RptPurchaseReturnByMonthSm): Promise<CommonGetListType<RptPurchaseReturnByMonthHdto>> {
		try {

			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s/%s",
					BASE_URL,
					ResourceEnum.PURCHASE_RETURN.name,
					"reports",
					"summary",
					"by-month"
				)
				+ getQueryString(searchModel)
				, requestOptions);

			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptPurchaseReturnByMonthHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async getRptPurchaseReturnByDayHdtos(searchModel: RptPurchaseReturnByDaySm): Promise<CommonGetListType<RptPurchaseReturnByDayHdto>> {
		try {

			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s/%s",
					BASE_URL,
					ResourceEnum.PURCHASE_RETURN.name,
					"reports",
					"summary",
					"by-day"
				)
				+ getQueryString(searchModel)
				, requestOptions);

			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptPurchaseReturnByDayHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async getRptPurchaseReturnByCityHdtos(searchModel: RptPurchaseReturnByCitySm): Promise<CommonGetListType<RptPurchaseReturnByCityHdto>> {
		try {

			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s/%s",
					BASE_URL,
					ResourceEnum.PURCHASE_RETURN.name,
					"reports",
					"summary",
					"by-city"
				)
				+ getQueryString(searchModel)
				, requestOptions);

			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptPurchaseReturnByCityHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async getRptPurchaseReturnBySupplierHdtos(searchModel: RptPurchaseReturnBySupplierSm): Promise<CommonGetListType<RptPurchaseReturnBySupplierHdto>> {
		try {

			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s/%s",
					BASE_URL,
					ResourceEnum.PURCHASE_RETURN.name,
					"reports",
					"summary",
					"by-supplier"
				)
				+ getQueryString(searchModel)
				, requestOptions);

			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptPurchaseReturnBySupplierHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async getRptPurchaseReturnByProdCatHdtos(searchModel: RptPurchaseReturnByProductCategorySm): Promise<CommonGetListType<RptPurchaseReturnByProductCategoryHdto>> {
		try {

			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s/%s",
					BASE_URL,
					ResourceEnum.PURCHASE_RETURN.name,
					"reports",
					"summary",
					"by-product-category"
				)
				+ getQueryString(searchModel)
				, requestOptions);

			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptPurchaseReturnByProductCategoryHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async getRptPurchaseReturnByProdHdtos(searchModel: RptPurchaseReturnByProductSm): Promise<CommonGetListType<RptPurchaseReturnByProductHdto>> {
		try {

			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s/%s",
					BASE_URL,
					ResourceEnum.PURCHASE_RETURN.name,
					"reports",
					"summary",
					"by-product"
				)
				+ getQueryString(searchModel)
				, requestOptions);

			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptPurchaseReturnByProductHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async getRptPurchaseReturnByStateHdtos(searchModel: RptPurchaseReturnByStateSm): Promise<CommonGetListType<RptPurchaseReturnByStateHdto>> {
		try {

			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s/%s",
					BASE_URL,
					ResourceEnum.PURCHASE_RETURN.name,
					"reports",
					"summary",
					"by-state"
				)
				+ getQueryString(searchModel)
				, requestOptions);

			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptPurchaseReturnByStateHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async getRptPurchaseReturnDetailedByVoucherHdtos(searchModel: RptPurchaseReturnDetailedByVoucherSm): Promise<CommonGetListType<RptPurchaseReturnDetailedByVoucherHdto>> {
		try {

			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s/%s",
					BASE_URL,
					ResourceEnum.PURCHASE_RETURN.name,
					"reports",
					"detailed",
					"by-voucher"
				)
				+ getQueryString(searchModel)
				, requestOptions);

			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptPurchaseReturnDetailedByVoucherHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async getRptPurchaseReturnDetailedByProdHdtos(searchModel: RptPurchaseReturnDetailedByProductSm): Promise<CommonGetListType<RptPurchaseReturnDetailedByProductHdto>> {
		try {

			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s/%s",
					BASE_URL,
					ResourceEnum.PURCHASE_RETURN.name,
					"reports",
					"detailed",
					"by-product"
				)
				+ getQueryString(searchModel)
				, requestOptions);

			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptPurchaseReturnDetailedByProductHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

}