import { useEffect, useState } from "react";
import { useGvContext } from "../../GvContext";
import PurchaseReturnApi from "../../api/PurchaseReturnApi";
import RowType from "../../types/RowType";
import RptPurchaseReturnDetailedByVoucherSm from "../../types/sm/RptPurchaseReturnDetailedByVoucherSm";
import ListViewButtonBar from "../ListViewButtonBar";
import ListViewTable from "../ListViewTable";
import useConfirm from "../dialogs/ConfirmDialog";
import MessageDialog from "../dialogs/MessageDialog";
import RptPurchaseReturnDetailedByVoucherSd from "../sd/RptPurchaseReturnDetailedByVoucherSd";

function RptPurchaseReturnDetailedByVoucher(): JSX.Element {

	const [rows, setRows] = useState<RowType[]>([]);
	const [selectedId, setSelectedId] = useState<number | null>(null);

	const [searchModel, setSearchModel] = useState(new RptPurchaseReturnDetailedByVoucherSm());
	//
	const [showSd, setShowSd] = useState<boolean>(false);
	
	const [messageDialogMessage, setMessageDialogMessage] = useState("");
	const [showMessageDialog, setShowMessageDialog] = useState(false);

	const confirm = useConfirm();
	const gv = useGvContext();

	const colNames = [
		"Number",
		"Date",
		"Cutomer",
		"City",
		"State",
		"Product Count",
		"Sum Quantity",
		"Sum Item Value",
		"Amount Payable"
	];

	useEffect(() => {
		async function process() {
			loadData();
		}
		process();
	}, [searchModel]);

	const handleClickExport = () => {
		console.log("handleClickExport");
	};

	const handleClickOpen = () => {
		console.log("handleClickOpen");
	};

	const handleClickFilter = () => {
		setShowSd(true);
	};

	const handleClickClearFilter = () => {
		let searchModelLoc = new RptPurchaseReturnDetailedByVoucherSm();
		searchModelLoc.reset();
		setSearchModel(searchModelLoc);
	};

	const loadData = async () => {

		let res = await PurchaseReturnApi.getRptPurchaseReturnDetailedByVoucherHdtos(searchModel);

		if (res.isSuccess) {

			let dataToReturn: RowType[] = [];

			for (let i = 0; i < res.items?.length!; i++) {
				let row = res.items![i];

				if (row == null)
					continue;

				let oneRowArray: string[] = [];

				oneRowArray.push(row.no == null ? "" : row.no.toString());
				oneRowArray.push(row.date == null ? "" : row.date.toString());
				oneRowArray.push(row.supplierName == null ? "" : row.supplierName.toString());
				oneRowArray.push(row.city == null ? "" : row.city.toString());
				oneRowArray.push(row.stateName == null ? "" : row.stateName.toString());
				oneRowArray.push(row.prodCnt == null ? "" : row.prodCnt.toString());
				oneRowArray.push(row.sumQty == null ? "" : row.sumQty.toString());
				oneRowArray.push(row.sumItemVal == null ? "" : row.sumItemVal.toString());
				oneRowArray.push(row.amtPayable == null ? "" : row.amtPayable.toString());

				dataToReturn.push({
					id: row.purchaseReturnId,
					data: oneRowArray
				});
			};

			setRows(dataToReturn);
		}
		else {
			setShowMessageDialog(true);
			setMessageDialogMessage(res.errorRes?.message!);
		}

	}

	return (
		<>
			<ListViewButtonBar
				handleClickExport={handleClickExport}
				handleClickOpen={handleClickOpen}
				handleClickFilter={handleClickFilter}
				handleClickClearFilter={handleClickClearFilter}
			/>

			<ListViewTable
				colNames={colNames}
				rows={rows}
				setSelectedId={setSelectedId}
			/>

			<RptPurchaseReturnDetailedByVoucherSd
				showModal={showSd}
				setShowModal={setShowSd}
				searchModel={searchModel}
				processOnSuccess={(searchModel) => setSearchModel(searchModel)}
			/>

			<MessageDialog
				showDialog={showMessageDialog}
				modalMessage={messageDialogMessage}
				setShowDialog={setShowMessageDialog} />
		</>
	);
}

export default RptPurchaseReturnDetailedByVoucher;
