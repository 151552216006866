export default class AdjustTypeEnum {

	public static readonly STOCK = new AdjustTypeEnum(1, "Stock");
	public static readonly MFG = new AdjustTypeEnum(2, "Manufacturing");

	private constructor(
		public readonly id: number,
		public readonly name: string) {
	}

	static get values(): AdjustTypeEnum[] {
		return [
			this.STOCK,
			this.MFG,
		];
	}

	static getById(id: number): AdjustTypeEnum | null {
		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.id === id) {
				return element;
			}
		}
		return null;
	}

	static getByName(name: string): AdjustTypeEnum | null {
		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.name === name) {
				return element;
			}
		}
		return null;
	}

}
