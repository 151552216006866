import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, FormControl, Row } from "react-bootstrap";
import PriceListTypeEnum from "../../enums/PriceListTypeEnum";
import RateTypeEnum from "../../enums/RateTypeEnum";
import YesNoEnum from "../../enums/YesNoEnum";
import PriceListTypeHelper from "../../helpers/PriceListTypeHelper";
import ProdHelper from "../../helpers/ProdHelper";
import StringHelper2 from "../../helpers/StringHelper2";
import UnitHelper from "../../helpers/UnitHelper";
import YesNoHelper from "../../helpers/YesNoHelper";
import DropDownItemType from "../../types/DropDownItemType";
import EstimateItemType from "../../types/EstimateItemType";
import { PriceListRateResType } from "../../types/res/PriceListRateResType";
import UnitLdto from "../../types/res/list/UnitLdto";
import ProdForEsimateLdto from "../../types/res/list/ProdForEsimateLdto";
import TaxCatLdto from "../../types/res/list/TaxCatLdto";
import TaxLdto from "../../types/res/list/TaxLdto";
import DownshiftSelect from "../DownshiftSelectV2";
import MessageDialog from "../dialogs/MessageDialog";
import useYesNoDialog from "../dialogs/useYesNoDialog";

interface AddEstimateItemFormSvProps {
	products: ProdForEsimateLdto[];
	productsMap: Map<number, ProdForEsimateLdto>;
	productsForDd: DropDownItemType[];
	units: UnitLdto[];
	unitsMap: Map<number, UnitLdto>;
	taxMap: Map<number, TaxLdto>;
	taxCatMap: Map<number, TaxCatLdto>;
	handleItemChange: (addEstimateItem: EstimateItemType) => void;
	customerPriceListAMap: Map<number, PriceListRateResType>;
	customerPriceListBMap: Map<number, PriceListRateResType>;
	estimateItemToEdit: EstimateItemType | null;
	handleClickCancelEdit: () => void;
	id?: number
}

function AddEstimateItemFormSv({
	products,
	productsMap,
	productsForDd,
	units,
	unitsMap,
	taxMap,
	taxCatMap,
	handleItemChange,
	customerPriceListAMap,
	customerPriceListBMap,
	estimateItemToEdit,
	handleClickCancelEdit,
	id
}: AddEstimateItemFormSvProps): JSX.Element {

	const [qtyCurrent, setQtyCurrent] = useState<number | null>(null);
	const [qtyPendingSales, setPendingSales] = useState<number | null>(null);
	const [qtyPendingEstimate, setPendingEstimate] = useState<number | null>(null);
	const [qtyFinal, setFinal] = useState<number | null>(null);

	const [productId, setProductId] = useState<number | null>(null);
	const [qtyStr, setQtyStr] = useState<string>("");
	const [unitId, setUnitId] = useState<number | null>();
	const [priceListTypeId, setPriceListTypeId] = useState<number | null>(null);
	const [rateStr, setRateStr] = useState<string>("");
	const [discStr, setDiscStr] = useState<string>("");
	const [isPending, setIsPending] = useState<boolean>(false);
	const [desc, setDesc] = useState<string>("");
	//


	const [yesNosForDd, setYesNosForDd] = useState<DropDownItemType[]>([]);

	const [unitsForDd, setUnitsForDd] = useState<DropDownItemType[]>([]);

	const [priceListTypesForDd, setPriceListTypesForDd] = useState<DropDownItemType[]>([]);

	//
	const [getConfirmation, YesNoDialog] = useYesNoDialog();
	//
	const cmbProductRef = useRef<HTMLInputElement | null>(null);

	const [messageDialogMessage, setMessageDialogMessage] = useState("");
	const [showMessageDialog, setShowMessageDialog] = useState(false);

	useEffect(() => {
		async function process() {

			setPriceListTypesForDd(PriceListTypeHelper.getPriceListTypesForDd());
			setYesNosForDd(YesNoHelper.getYesNosForDd());

		}
		process();
	}, []);

	useEffect(() => {
		if (estimateItemToEdit != null) {
			setProductId(estimateItemToEdit.productId);
			handleProdChange(estimateItemToEdit.productId);
			setQtyStr(estimateItemToEdit.orderQty.toString());
			setUnitId(estimateItemToEdit.orderUnitId);
			setPriceListTypeId(estimateItemToEdit.priceListTypeId);
			setRateStr(estimateItemToEdit.priceListRate.toString());
			if (estimateItemToEdit.discRate != null)
				setDiscStr(estimateItemToEdit.discRate.toString());
			setIsPending(estimateItemToEdit.isPending);
			setDesc(estimateItemToEdit.desc);
		}
	}, [estimateItemToEdit]);

	const handleProdChange = (prodIdLoc: number | null) => {

		let qtyCurrentLoc = null;
		let qtyPendingSalesLoc = null;
		let qtyPendingEstimateLoc = null;
		let qtyFinalLoc = null;
		let unitsForDdLoc: UnitLdto[] = [];

		if (
			prodIdLoc != null &&
			products !== undefined &&
			products !== null &&
			productsMap !== undefined &&
			productsMap !== null &&
			products.length > 0) {

			let product = productsMap.get(prodIdLoc);

			qtyCurrentLoc = product?.qtyCurrent!;
			qtyPendingSalesLoc = product?.pendingQtySales!;
			qtyPendingEstimateLoc = product?.pendingQtyEstimate!;

			if (qtyCurrentLoc === undefined || qtyCurrentLoc === null) {
				qtyCurrentLoc = 0;
			}
			if (qtyPendingSalesLoc === undefined || qtyPendingSalesLoc === null) {
				qtyPendingSalesLoc = 0;
			}
			if (qtyPendingEstimateLoc === undefined || qtyPendingEstimateLoc === null) {
				qtyPendingEstimateLoc = 0;
			}

			qtyFinalLoc = qtyCurrentLoc - qtyPendingSalesLoc - qtyPendingEstimateLoc;


			if (product?.unitId != null) {
				unitsForDdLoc.push(unitsMap?.get(product.unitId)!);

				if (product.salesOrderBoxUnitId != null) {
					unitsForDdLoc.push(unitsMap?.get(product.salesOrderBoxUnitId)!);
				}
			}

			checkAndSetRate(prodIdLoc, priceListTypeId == null ? null : PriceListTypeEnum.getById(priceListTypeId));

		}

		setQtyCurrent(qtyCurrentLoc);
		setPendingSales(qtyPendingSalesLoc);
		setPendingEstimate(qtyPendingEstimateLoc);
		setFinal(qtyFinalLoc);

		setProductId(prodIdLoc);

		setUnitsForDd(UnitHelper.getUnitsForDd(unitsForDdLoc));
	};

	const handlePriceListChange = (priceListIdLoc: number | null) => {

		setPriceListTypeId(priceListIdLoc);
		checkAndSetRate(productId, priceListIdLoc == null ? null : PriceListTypeEnum.getById(priceListIdLoc));

	};

	const _handleClickSave = async (event: React.FormEvent<HTMLFormElement>) => {

		event.preventDefault();

		if (!isValid()) {
			return;
		}

	}

	const fillInUi = (/*productResType: EstimateResType*/) => {

	};

	const handleClose = () => setShowMessageDialog(false);
	const handleShow = () => setShowMessageDialog(true);

	const clearBoxes = () => {
		setProductId(-1);
		setQtyStr("");
		setUnitId(-1);
		setPriceListTypeId(-1);
		setRateStr("");
		setDiscStr("");
		setIsPending(false);
		setDesc("");
	};

	const handleSave = async (e: React.MouseEvent) => {
		e.preventDefault();

		if (!await isValid()) {
			return;
		}

		let productIdLoc: number = productId!;
		let qtyLoc: number = parseFloat(qtyStr);
		let unitIdLoc: number = unitId!;
		let priceListTypeIdLoc: number = priceListTypeId!;
		let rateLoc: number = parseFloat(rateStr);
		let discLoc: number = 0;
		let isPendingLoc: boolean = isPending;
		let descLoc: string = desc;

		if (discStr !== undefined
			&& discStr !== null
			&& StringHelper2.isNum(discStr)
		) {
			discLoc = parseFloat(discStr);
		}

		let addEstimateItemLoc: EstimateItemType = {
			productId: productIdLoc,
			desc: descLoc,
			orderQty: qtyLoc,
			orderUnitId: unitIdLoc,
			priceListTypeId: priceListTypeIdLoc,
			priceListRate: rateLoc,
			discRate: discLoc,
			discRateTypeId: RateTypeEnum.PERCENT.id,
			isPending: isPendingLoc,
		};

		handleItemChange(addEstimateItemLoc);

		clearBoxes();

		cmbProductRef.current?.focus();

	};

	const handleCancel = async (e: React.MouseEvent) => {
		e.preventDefault();
		clearBoxes();
		handleClickCancelEdit();

		cmbProductRef.current?.focus();
	}

	const isValid = async (): Promise<boolean> => {

		if (
			productId === undefined
			|| productId === null
			|| productsMap?.get(productId) === undefined
			|| productsMap?.get(productId) === null
		) {
			setMessageDialogMessage("Select product");
			setShowMessageDialog(true);
			return false;
		}

		if (qtyStr === undefined
			|| qtyStr === null
			|| !StringHelper2.isNum(qtyStr)
		) {
			setMessageDialogMessage("Invalid quantity");
			setShowMessageDialog(true);
			return false;
		}

		if (
			unitId === undefined
			|| unitId === null
			|| unitsMap?.get(unitId) === undefined
			|| unitsMap?.get(unitId) === null
		) {
			setMessageDialogMessage("Select unit");
			setShowMessageDialog(true);
			return false;
		}

		if (
			priceListTypeId === undefined
			|| priceListTypeId === null
			|| PriceListTypeEnum.getById(priceListTypeId) === null
		) {
			setMessageDialogMessage("Select price list type");
			setShowMessageDialog(true);
			return false;
		}

		if (
			rateStr === undefined
			|| rateStr === null
			|| !StringHelper2.isNum(rateStr)) {
			setMessageDialogMessage("Invalid rate");
			setShowMessageDialog(true);
			return false;
		}

		let qty = parseFloat(qtyStr);
		qty = UnitHelper.convert(qty, unitsMap.get(unitId)!, unitsMap.get(productsMap.get(productId)?.unitId!)!);
		let qtyAvlbl = 0;
		if (qtyFinal != null) {
			qtyAvlbl = qtyFinal;
		}
		if (qty > qtyAvlbl) {
			let confirmResult = await getConfirmation("Confirm", "This will create negative stock. Do you want to proceed?")
			if (!confirmResult)
				return false;
		}

		return true;
	}

	const checkAndSetRate = (productId: number | null, priceListType: PriceListTypeEnum | null) => {

		let rateStrLoc = "";
		let discStrLoc = "";

		if (
			productId == null
			|| productsMap?.get(productId) == null
		) {
			return;
		}

		if (
			priceListType == null
		) {
			return;
		}

		let priceListRate;
		if (priceListType === PriceListTypeEnum.A) {
			priceListRate = customerPriceListAMap.get(productId);
		}
		else {
			priceListRate = customerPriceListBMap.get(productId);
		}

		if (priceListRate == null) {
			return;
		}

		if (priceListRate.rate !== null) {
			rateStrLoc = priceListRate.rate.toString();
		}

		if (priceListRate.disc !== null) {
			discStrLoc = priceListRate.disc.toString();
		}

		setRateStr(rateStrLoc);
		setDiscStr(discStrLoc);

	}

	return (
		<>
			<div className="row g-3 align-items-center mb-3">
				<div className="col-auto">
					<label className="col-form-label">Quantity</label>
				</div>
				<div className="col-auto">
					<input
						type="text"
						className="form-control form-control-sm"
						readOnly={true}
						tabIndex={-1}
						value={qtyCurrent ? qtyCurrent.toLocaleString('en-IN') : ""}
					/>
				</div>
				<div className="col-auto">
					<label className="col-form-label">-</label>
				</div>
				<div className="col-auto">
					<input type="text" className="form-control form-control-sm" readOnly={true}
						tabIndex={-1}
						value={qtyPendingSales ? qtyPendingSales.toLocaleString('en-IN') : ""}
					/>
				</div>
				<div className="col-auto">
					<label className="col-form-label">-</label>
				</div>
				<div className="col-auto">
					<input type="text" className="form-control form-control-sm" readOnly={true}
						tabIndex={-1}
						value={qtyPendingEstimate ? qtyPendingEstimate.toLocaleString('en-IN') : ""}
					/>
				</div>
				<div className="col-auto">
					<label className="col-form-label form-control-sm">=</label>
				</div>
				<div className="col-auto">
					<input type="text" className="form-control form-control-sm" readOnly={true}
						tabIndex={-1}
						value={qtyFinal ? qtyFinal.toLocaleString('en-IN') : ""}
					/>
				</div>

			</div>

			<Row className="mb-3" xs="auto">
				<Form.Group as={Col} xs={3}>
					<Form.Label>Product</Form.Label>
					<DownshiftSelect
						inputId={"product"}
						value={productId != null && productsMap?.get(productId) ? ProdHelper.getProdDdi(productsMap?.get(productId)!) : null}
						onChange={handleProdChange}
						options={productsForDd}
						isLoading={false}
						isDisabled={false}
						placeholder=""
						forwardedRef={cmbProductRef}
					/>
				</Form.Group>

				<Form.Group
					as={Col}>
					<Form.Label>&nbsp;</Form.Label>
					<FormControl
						size="sm" as={Button}
					>...</FormControl>
				</Form.Group>

				<Form.Group as={Col}
					xs={1}
				>
					<Form.Label>Quantity</Form.Label>
					<FormControl
						size="sm"
						type="text"
						value={qtyStr}
						onChange={(e) => setQtyStr(e.target.value)}
					/>
				</Form.Group>

				<Form.Group as={Col}>
					<Form.Label>Unit</Form.Label>
					<DownshiftSelect
						inputId={"unit"}
						value={unitId != null && unitId > 0 && unitsMap?.get(unitId) ? UnitHelper.getUnitForDd(unitsMap?.get(unitId)!) : null}
						onChange={setUnitId}
						options={unitsForDd}
						isLoading={false}
						isDisabled={false}
						placeholder=""
					/>
				</Form.Group>

				<Form.Group as={Col}
					xs={1}>
					<Form.Label>Price List Type</Form.Label>
					<DownshiftSelect
						inputId={"price_list_type"}
						value={priceListTypeId != null && priceListTypeId > 0 && PriceListTypeEnum.getById(priceListTypeId) !== null ? PriceListTypeHelper.getPriceListTypeForDd(PriceListTypeEnum.getById(priceListTypeId)!) : null}
						onChange={(e) => handlePriceListChange(e)}
						options={priceListTypesForDd}
						isLoading={false}
						isDisabled={false}
						placeholder=""
					/>
				</Form.Group>

				<Form.Group as={Col}
					xs={1}>
					<Form.Label>Rate</Form.Label>
					<FormControl
						size="sm"
						type="text"
						value={rateStr}
						onChange={(e) => setRateStr(e.target.value)}
					/>
				</Form.Group>

				<Form.Group as={Col}
					xs={1}>
					<Form.Label>Discount</Form.Label>
					<FormControl
						size="sm"
						type="text"
						value={discStr}
						onChange={(e) => setDiscStr(e.target.value)}
					/>
				</Form.Group>


				<Form.Group as={Col}
					xs={1}>
					<Form.Label>Is Pending?</Form.Label>
					<DownshiftSelect
						inputId={"is_pending"}
						value={isPending ? YesNoHelper.getYesNoForDd(YesNoEnum.getByVal(isPending)!) : null}
						onChange={(e) => setIsPending(e == null ? false : YesNoEnum.getById(e)?.val!)}
						options={yesNosForDd}
						isLoading={false}
						isDisabled={false}
						placeholder=""
					/>
				</Form.Group>

				<Form.Group as={Col}>
					<Form.Label>&nbsp;</Form.Label>
					<FormControl
						size="sm"
						as={Button}
						onClick={handleSave}
					>
						Save
					</FormControl>
				</Form.Group>

				<Form.Group as={Col}>
					<Form.Label>&nbsp;</Form.Label>
					<FormControl
						size="sm"
						as={Button}
						onClick={handleCancel}
					>Cancel</FormControl>
				</Form.Group>

				<Form.Group as={Col}>
					<Form.Label>Description</Form.Label>
					<FormControl
						size="sm"
						type="text"
						onChange={(e) => setDesc(e.target.value)}
					/>
				</Form.Group>
			</Row>

			<MessageDialog
				modalMessage={messageDialogMessage}
				showDialog={showMessageDialog}
				setShowDialog={setShowMessageDialog}
			/>

			{YesNoDialog()}

		</>
	);
}

export default AddEstimateItemFormSv;
