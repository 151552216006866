export default class LstAcctTypeEnumCol {

	public static readonly NAME = new LstAcctTypeEnumCol("name");
	public static readonly PARENT_NAME = new LstAcctTypeEnumCol("parent_name");
	public static readonly CREATED_BY = new LstAcctTypeEnumCol("created_by");
	public static readonly CREATED_AT = new LstAcctTypeEnumCol("created_at");

	private constructor(
		public readonly key: string) {
	}

	static get values(): LstAcctTypeEnumCol[] {
		return [
			this.NAME,
			this.PARENT_NAME,
			this.CREATED_BY,
			this.CREATED_AT,
		];
	}

	static getByKey(key: string): LstAcctTypeEnumCol | null {
		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.key === key) {
				return element;
			}
		}
		return null;
	}
}
