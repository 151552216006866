import { BASE_URL, TOKEN } from "../config";
import ServerNotReachableErrorCommonCudObj from "../default_objects/ServerNotReachableErrorCommonCudObj";
import ServerSideErrorCommonCudObj from "../default_objects/ServerSideErrorCommonCudObj";
import OrderTypeEnum from "../enums/OrderTypeEnum";
import ResourceEnum from "../enums/ResourceEnum";
import SalesOrderPrintTypeEnum from "../enums/SalesOrderPrintTypeEnum";
import SalesPrintTypeEnum from "../enums/SalesPrintTypeEnum";
import strFormat from "../helpers/StringHelper";
import { getQueryString } from "../helpers/UrlSearchParamHelper";
import CommonCudType from "../types/CommonCudType";
import CommonGetListType from "../types/CommonGetListType";
import ErrorResType from "../types/res/ErrorResType";
import RptSalesOrderLedgerHdto from "../types/res/list/RptSalesOrderLedgerHdto";
import RptSalesOrderPendingByAcctHdto from "../types/res/list/RptSalesOrderPendingByAcctHdto";
import RptSalesOrderPendingSummByProductHdto from "../types/res/list/RptSalesOrderPendingSummByProductHdto";
import RptSaleseOrderPendingSummByCustomerHdto from "../types/res/list/RptSaleseOrderPendingSummByCustomerHdto";
import SalesOrderLdto from "../types/res/list/SalesOrderLdto";
import SalesOrderRrHoType from "../types/rr/SalesOrderRrHoType";
import ListSalesOrderSm from "../types/sm/ListSalesOrderSm";
import RptSalesOrderLedgerSm from "../types/sm/RptSalesOrderLedgerSm";
import RptSalesOrderPendingSummByCustomerSm from "../types/sm/RptSalesOrderPendingSummByCustomerSm";
import RptSalesOrderPendingSummByProdSm from "../types/sm/RptSalesOrderPendingSummByProdSm";

export default class SalesOrderApi {

	static async getSalesOrderLdtos(listSalesOrderSm: ListSalesOrderSm): Promise<CommonGetListType<SalesOrderLdto>> {
		try {

			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.SALES_ORDER.name)
				+ getQueryString(listSalesOrderSm)
				, requestOptions);

			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as SalesOrderLdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async createSalesOrder(salesOrderReqHo: SalesOrderRrHoType): Promise<CommonCudType> {

		const requestOptions = {
			method: "POST",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(salesOrderReqHo)
		}

		const res = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.SALES_ORDER.name), requestOptions);

		if (res.status !== 201) {
			const data: ErrorResType = await res.json();
			return {
				isSuccess: false,
				errorRes: data
			}
		}

		return {
			isSuccess: true,
		}

	}

	static async updateSalesOrder(id: number, salesOrderReqHo: SalesOrderRrHoType): Promise<CommonCudType> {

		const requestOptions = {
			method: "PUT",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(salesOrderReqHo)
		}

		const res = await fetch(strFormat("%s/%s/%s", BASE_URL, ResourceEnum.SALES_ORDER.name, id), requestOptions);

		if (res.status !== 201) {
			const data: ErrorResType = await res.json();
			return {
				isSuccess: false,
				errorRes: data
			}
		}

		return {
			isSuccess: true,
		}

	}

	static async getById(id: number) {

		const requestOptions = {
			method: "GET",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			}
		}

		const res = await fetch(strFormat("%s/%s/%s", BASE_URL, ResourceEnum.SALES_ORDER.name, id), requestOptions);
		const data: SalesOrderRrHoType = await res.json();
		return data;

	}

	static async delete(id: number): Promise<CommonCudType> {

		try {
			const requestOptions = {
				method: "DELETE",
				headers: {
					"Authorization": "Bearer " + TOKEN.val,
					"Content-Type": "application/json"
				}
			}

			const response = await fetch(strFormat("%s/%s/%s", BASE_URL, ResourceEnum.SALES_ORDER.name, id), requestOptions);

			if (response.status === 200) {
				return {
					isSuccess: true
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}


	}

	static async print(salesOrderPrintType: SalesOrderPrintTypeEnum, ids: number[], salesPrintType: SalesPrintTypeEnum | null) {

		const requestOptions = {
			method: "GET",
			headers: {
				Authorization: "Bearer " + TOKEN.val,
				"Content-Type": "application/json",
			},
		};
		const res = await fetch(strFormat("%s/%s/print", BASE_URL, ResourceEnum.SALES_ORDER.name)
			+ getQueryString({
				salesOrderPrintType: salesOrderPrintType.enumName,
				ids: ids,
				salesPrintType: salesPrintType == null ? null : salesPrintType.enumName
			})
			, requestOptions);

		var blob = await res.blob();
		return new Blob([blob], { type: 'application/pdf' });
	}

	// report
	static async getRptSalesOrderPendingSummByProdHdtos(searchModel: RptSalesOrderPendingSummByProdSm): Promise<CommonGetListType<RptSalesOrderPendingSummByProductHdto>> {
		try {

			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s/%s/%s",
					BASE_URL,
					ResourceEnum.SALES_ORDER.name,
					"reports",
					"summary",
					"pending",
					"by-product"
				)
				+ getQueryString({
					...searchModel,
					orderType: OrderTypeEnum.SALES.enumName,
				})
				, requestOptions);

			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptSalesOrderPendingSummByProductHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async getRptSalesOrderPendingSummByCustomerHdtos(searchModel: RptSalesOrderPendingSummByCustomerSm): Promise<CommonGetListType<RptSaleseOrderPendingSummByCustomerHdto>> {
		try {

			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s/%s/%s",
					BASE_URL,
					ResourceEnum.SALES_ORDER.name,
					"reports",
					"summary",
					"pending",
					"by-customer"
				)
				+ getQueryString({
					...searchModel,
					orderType: OrderTypeEnum.SALES.enumName,
				})
				, requestOptions);

			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptSalesOrderPendingByAcctHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async getRptSalesOrderLedgerHdtos(searchModel: RptSalesOrderLedgerSm): Promise<CommonGetListType<RptSalesOrderLedgerHdto>> {
		try {

			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s",
					BASE_URL,
					ResourceEnum.SALES_ORDER.name,
					"reports",
					"ledger",
				)
				+ getQueryString({
					...searchModel,
					orderType: OrderTypeEnum.SALES.enumName,
				})
				, requestOptions);

			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptSalesOrderLedgerHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

}