export default class LstSalesOrderEnumCol {

	public static readonly NO = new LstSalesOrderEnumCol("no", "Number");
	public static readonly DATE = new LstSalesOrderEnumCol("date", "Date");
	public static readonly CUSTOMER = new LstSalesOrderEnumCol("customer", "Customer Name");
	public static readonly CITY = new LstSalesOrderEnumCol("city", "City");
	public static readonly STATE = new LstSalesOrderEnumCol("state", "State Name");
	public static readonly QTY = new LstSalesOrderEnumCol("qty", "Total Quantity");
	public static readonly AMT_PAYABLE = new LstSalesOrderEnumCol("amt_payable", "Amount Payable");
	public static readonly NOTE = new LstSalesOrderEnumCol("note", "Note");
	public static readonly COMPANY_PROFILE = new LstSalesOrderEnumCol("company_profile", "Company Profile");
	public static readonly SALES_NO = new LstSalesOrderEnumCol("sales_no", "Sales No");
	public static readonly COMPLETED = new LstSalesOrderEnumCol("completed", "Completed");
	public static readonly CREATED_BY = new LstSalesOrderEnumCol("created_by", "Created By");
	public static readonly CREATED_AT = new LstSalesOrderEnumCol("created_at", "Created At");

	private constructor(public readonly key: string, public readonly name: string) {
		
	}

	static get values(): LstSalesOrderEnumCol[] {
		return [
			this.NO,
			this.DATE,
			this.CUSTOMER,
			this.CITY,
			this.STATE,
			this.QTY,
			this.AMT_PAYABLE,
			this.NOTE,
			this.COMPANY_PROFILE,
			this.SALES_NO,
			this.COMPLETED,
			this.CREATED_BY,
			this.CREATED_AT,
		];
	}

	static getByKey(key: string): LstSalesOrderEnumCol | null {
		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.key === key) {
				return element;
			}
		}
		return null;
	}
}
