import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import AcctApi from "../../api/AcctApi";
import AcctHelper from "../../helpers/AcctHelper";
import DropDownItemType from "../../types/DropDownItemType";
import AcctLdto from "../../types/res/list/AcctLdto";
import RptCommVouLedgerSm from "../../types/sm/RptCommVouLedgerSm";
import DownshiftSelect from "../DownshiftSelectV2";

interface RptCommVouLedgerSdPropsType {
	showModal: boolean;
	setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
	searchModel: RptCommVouLedgerSm;
	processOnSuccess: (searchModel: RptCommVouLedgerSm) => void;
}

function RptCommVouLedgerSd({
	showModal,
	setShowModal,
	searchModel,
	processOnSuccess
}: RptCommVouLedgerSdPropsType) {

	const [dateFrom, setDateFrom] = useState<Date | null>(searchModel.dateFrom ?? null);
	const [dateTo, setDateTo] = useState(searchModel.dateTo ?? null);
	const [agentId, setAgentId] = useState<number | null>(searchModel.agentId ?? null);
	const [customerId, setCustomerId] = useState<number | null>(searchModel.customerId ?? null);

	//
	const [acctLdtos, setAcctLdtos] = useState<AcctLdto[] | null>(null);
	const [acctLdtoMap, setAcctLdtoMap] = useState<Map<number, AcctLdto> | null>(null);
	const [acctDdis, setAcctDdis] = useState<DropDownItemType[] | null>(null);

	useEffect(() => {
		async function process() {
			AcctApi.getAcctLdtos().then((acctLdtos) => {
				if (acctLdtos.isSuccess) {
					setAcctLdtos(acctLdtos.items!);
					setAcctLdtoMap(AcctHelper.getAcctLdtoMap(acctLdtos.items!));
					setAcctDdis(AcctHelper.getAcctDdis(acctLdtos.items!));
				}
			});
		}
		process();
	}, []);


	const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		event.stopPropagation();
		doSearch();
	}

	const handleClickClose = () => {
		doCancel();
	};

	const handleEscapeKeyPress = (event: KeyboardEvent) => {
		if (!event.altKey
			&& !event.ctrlKey
			&& !event.metaKey
			&& !event.shiftKey
			&& event.key === "Escape"
		) {
			event.preventDefault();
			event.stopPropagation();
			doCancel();
		}
	};

	const isValid = (): boolean => {
		return true;
	}

	const doSearch = () => {

		if (!isValid) {
			return;
		}

		let searchModelNew = new RptCommVouLedgerSm();

		searchModelNew.dateFrom = dateFrom;
		searchModelNew.dateTo = dateTo;
		searchModelNew.agentId = agentId;
		searchModelNew.customerId = customerId;

		setShowModal(false);

		processOnSuccess(searchModelNew);
	}

	const doCancel = () => {
		setShowModal(false);

		setDateFrom(searchModel.dateFrom ?? null);
		setDateTo(searchModel.dateTo ?? null);
		setAgentId(searchModel.agentId ?? null);
		setCustomerId(searchModel.customerId ?? null);
	}


	return (
		<Modal
			show={showModal}
			onHide={handleClickClose}
			onEscapeKeyDown={handleEscapeKeyPress}
			size="lg"
		>
			<Modal.Header closeButton>
				<Modal.Title>Message</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={handleFormSubmit} id="formSearch">

					<Form.Group as={Row} className="mb-3">
						<Form.Label column sm="3">
							Date From
						</Form.Label>
						<Col sm="9">
							<ReactDatePicker
								id="dpDate"
								dateFormat="dd-MM-yyyy"
								selected={dateFrom}
								onChange={(e) => setDateFrom(e)}
								className="form-control"
							/>
						</Col>
					</Form.Group>

					<Form.Group as={Row} className="mb-3">
						<Form.Label column sm="3">
							Date To
						</Form.Label>
						<Col sm="9">
							<ReactDatePicker
								id="dpDate"
								dateFormat="dd-MM-yyyy"
								selected={dateTo}
								onChange={(e) => setDateTo(e)}
								className="form-control"
							/>
						</Col>
					</Form.Group>

					<Form.Group as={Row} className="mb-3">
						<Form.Label column sm="3">
							Agent
						</Form.Label>
						<Col sm="9">
							<DownshiftSelect
								inputId={"cmbProd"}
								value={agentId == null || acctLdtoMap?.get(agentId) == null ? null : AcctHelper.getAcctDdi(acctLdtoMap.get(agentId)!)}
								onChange={setAgentId}
								options={acctDdis ?? []}
								isLoading={false}
								isDisabled={false}
								placeholder=""
							/>
						</Col>
					</Form.Group>

					<Form.Group as={Row} className="mb-3">
						<Form.Label column sm="3">
							Customer
						</Form.Label>
						<Col sm="9">
							<DownshiftSelect
								inputId={"cmbCustomer"}
								value={customerId == null || acctLdtoMap?.get(customerId) == null ? null : AcctHelper.getAcctDdi(acctLdtoMap.get(customerId)!)}
								onChange={setCustomerId}
								options={acctDdis ?? []}
								isLoading={false}
								isDisabled={false}
								placeholder=""
							/>
						</Col>
					</Form.Group>

				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={handleClickClose}>
					Cancel
				</Button>
				<Button variant="primary" type="submit" form="formSearch">
					SAVE
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default RptCommVouLedgerSd;

