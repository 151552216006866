import { useEffect, useState } from "react";
import { useGvContext } from "../../GvContext";
import SalesApi from "../../api/SalesApi";
import RowType from "../../types/RowType";
import RptSalesByDaySm from "../../types/sm/RptSalesByDaySm";
import ListViewButtonBar from "../ListViewButtonBar";
import ListViewTable from "../ListViewTable";
import useConfirm from "../dialogs/ConfirmDialog";
import MessageDialog from "../dialogs/MessageDialog";
import RptSalesByDaySd from "../sd/RptSalesByDaySd";

function RptSalesByDay(): JSX.Element {

	const [rows, setRows] = useState<RowType[]>([]);
	const [selectedId, setSelectedId] = useState<number | null>(null);

	const [searchModel, setSearchModel] = useState(new RptSalesByDaySm());
	//
	const [showSd, setShowSd] = useState<boolean>(false);
	
	const [messageDialogMessage, setMessageDialogMessage] = useState("");
	const [showMessageDialog, setShowMessageDialog] = useState(false);

	const confirm = useConfirm();
	const gv = useGvContext();

	const colNames = [
		"Date",
		"Customer Count",
		"City Count",
		"State Count",
		"Agent Count",
		"Month Count",
		"Day Count",
		"Sales Count",
		"Product Count",
		"Quantity",
		"Item Value",
		"Amount Payable",
	];

	useEffect(() => {
		async function process() {
			loadData();
		}
		process();
	}, [searchModel]);

	const handleClickExport = () => {
		console.log("handleClickExport");
	};

	const handleClickOpen = () => {
		console.log("handleClickOpen");
	};

	const handleClickFilter = () => {
		setShowSd(true);
	};

	const handleClickClearFilter = () => {
		let searchModelLoc = new RptSalesByDaySm();
		searchModelLoc.reset();
		setSearchModel(searchModelLoc);
	};

	const loadData = async () => {

		let res = await SalesApi.getRptSalesByDayHdtos(searchModel);

		if (res.isSuccess) {

			let dataToReturn: RowType[] = [];

			for (let i = 0; i < res.items?.length!; i++) {
				let row = res.items![i];

				let oneRowArray: string[] = [];

				oneRowArray.push(row.date == null ? "" : row.date.toString());
				oneRowArray.push(row.customerCnt == null ? "" : row.customerCnt.toString());
				oneRowArray.push(row.cityCnt == null ? "" : row.cityCnt.toString());
				oneRowArray.push(row.stateCnt == null ? "" : row.stateCnt.toString());
				oneRowArray.push(row.agentCnt == null ? "" : row.agentCnt.toString());
				oneRowArray.push(row.monthCnt == null ? "" : row.monthCnt.toString());
				oneRowArray.push(row.dayCnt == null ? "" : row.dayCnt.toString());
				oneRowArray.push(row.salesCnt == null ? "" : row.salesCnt.toString());
				oneRowArray.push(row.prodCnt == null ? "" : row.prodCnt.toString());
				oneRowArray.push(row.sumQty == null ? "" : row.sumQty.toString());
				oneRowArray.push(row.sumItemVal == null ? "" : row.sumItemVal.toString());
				oneRowArray.push(row.sumAmtPayable == null ? "" : row.sumAmtPayable.toString());

				
				dataToReturn.push({
					id: 0,
					data: oneRowArray
				});
			};

			setRows(dataToReturn);
		}
		else {
			setShowMessageDialog(true);
			setMessageDialogMessage(res.errorRes?.message!);
		}

	}

	return (
		<>
			<ListViewButtonBar
				handleClickExport={handleClickExport}
				handleClickOpen={handleClickOpen}
				handleClickFilter={handleClickFilter}
				handleClickClearFilter={handleClickClearFilter}
			/>

			<ListViewTable
				colNames={colNames}
				rows={rows}
				setSelectedId={setSelectedId}
			/>

			<RptSalesByDaySd
				showModal={showSd}
				setShowModal={setShowSd}
				searchModel={searchModel}
				processOnSuccess={(searchModel) => setSearchModel(searchModel)}
			/>

			<MessageDialog
				showDialog={showMessageDialog}
				modalMessage={messageDialogMessage}
				setShowDialog={setShowMessageDialog} />
		</>
	);
}

export default RptSalesByDay;
