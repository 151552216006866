import { useEffect, useState } from "react";
import { useGvContext } from "../../GvContext";
import ProductCatApi from "../../api/ProductCatApi";
import RowType from "../../types/RowType";
import RptLowStockSm from "../../types/sm/RptLowStockSm";
import ListViewButtonBar from "../ListViewButtonBar";
import ListViewTable from "../ListViewTable";
import useConfirm from "../dialogs/ConfirmDialog";
import MessageDialog from "../dialogs/MessageDialog";
import RptLowStockSd from "../sd/RptLowStockSd";

function RptLowStock(): JSX.Element {

	const [rows, setRows] = useState<RowType[]>([]);
	const [selectedId, setSelectedId] = useState<number | null>(null);

	const [searchModel, setSearchModel] = useState(new RptLowStockSm());
	//
	const [showSd, setShowSd] = useState<boolean>(false);

	const [messageDialogMessage, setMessageDialogMessage] = useState("");
	const [showMessageDialog, setShowMessageDialog] = useState(false);

	const confirm = useConfirm();
	const gv = useGvContext();

	const colNames = [
		"Name",
		"Qty",
		"Pending Qty (Purchase)",
		"Pending Qty (Sales)",
		"Final Qty",
		"Reorder Level",
		"Shortfall",
		"Min Reorder Qty",
		"Reorder Qty (FIXED)",
		"Final Order Quantity",
		"Suppliers",
	];

	useEffect(() => {
		async function process() {
			loadData();
		}
		process();
	}, [searchModel]);

	const handleClickExport = () => {
		console.log("handleClickExport");
	};

	const handleClickOpen = () => {
		console.log("handleClickOpen");
	};

	const handleClickFilter = () => {
		setShowSd(true);
	};

	const handleClickClearFilter = () => {
		let searchModelLoc = new RptLowStockSm();
		searchModelLoc.reset();
		setSearchModel(searchModelLoc);
	};

	const loadData = async () => {

		let res = await ProductCatApi.getRptLowStockHdto(searchModel);

		if (res.isSuccess) {

			let dataToReturn: RowType[] = [];

			for (let i = 0; i < res.items?.length!; i++) {
				let row = res.items![i];

				if (row == null)
					continue;

				let oneRowArray: string[] = [];

				oneRowArray.push(row.name == null ? "" : row.name.toString());
				oneRowArray.push(row.qty == null ? "" : row.qty.toString());
				oneRowArray.push(row.pendingQtyPurchase == null ? "" : row.pendingQtyPurchase.toString());
				oneRowArray.push(row.pendingQtySales == null ? "" : row.pendingQtySales.toString());
				oneRowArray.push(row.finalQty == null ? "" : row.finalQty.toString());
				oneRowArray.push(row.reorderLevel == null ? "" : row.reorderLevel.toString());
				oneRowArray.push(row.shortfall == null ? "" : row.shortfall.toString());
				oneRowArray.push(row.minReorderQty == null ? "" : row.minReorderQty.toString());
				oneRowArray.push(row.reorderQty == null ? "" : row.reorderQty.toString());
				oneRowArray.push(row.finalQty == null ? "" : row.finalQty.toString());
				oneRowArray.push(row.suppliers == null ? "" : row.suppliers.toString());

				dataToReturn.push({
					id: 0,
					data: oneRowArray
				});
			};

			setRows(dataToReturn);
		}
		else {
			setShowMessageDialog(true);
			setMessageDialogMessage(res.errorRes?.message!);
		}

	}

	return (
		<>
			<ListViewButtonBar
				handleClickExport={handleClickExport}
				handleClickOpen={handleClickOpen}
				handleClickFilter={handleClickFilter}
				handleClickClearFilter={handleClickClearFilter}
			/>

			<ListViewTable
				colNames={colNames}
				rows={rows}
				setSelectedId={setSelectedId}
			/>

			<RptLowStockSd
				showModal={showSd}
				setShowModal={setShowSd}
				searchModel={searchModel}
				processOnSuccess={(searchModel) => setSearchModel(searchModel)}
			/>

			<MessageDialog
				showDialog={showMessageDialog}
				modalMessage={messageDialogMessage}
				setShowDialog={setShowMessageDialog} />
		</>
	);
}

export default RptLowStock;
