import RateTypeEnum from "../enums/RateTypeEnum";
import PurchaseItemType from "../types/PurchaseItemType";
import VouOthChOnItemType from "../types/VouOthChOnItemType";
import ProdForEsimateLdto from "../types/res/list/ProdForEsimateLdto";
import TaxCatLdto from "../types/res/list/TaxCatLdto";
import TaxLdto from "../types/res/list/TaxLdto";
import UnitLdto from "../types/res/list/UnitLdto";
import TaxHelper from "./TaxHelper";

export default class PurchaseItemHelper {

	static calItems(
		isAddTax: boolean,
		isIntra: boolean,
		taxMap: Map<number, TaxLdto>,
		taxCatMap: Map<number, TaxCatLdto>,
		unitMap: Map<number, UnitLdto>,
		productMap: Map<number, ProdForEsimateLdto>,
		purchaseItems: PurchaseItemType[],
	) {
		if (purchaseItems !== undefined && purchaseItems !== null && purchaseItems.length > 0) {

			let sumAmtAfterDisc = 0;
			for (let i = 0; i < purchaseItems.length; i++) {
				let purchaseItem = purchaseItems[i];

				let product = productMap.get(purchaseItem.productId);

				let taxId: number;

				if (product?.taxCatId === null) {
					taxId = product.taxId;
				}
				else {
					let taxCat = taxCatMap.get(product?.taxCatId!);
					taxId = taxCat?.taxId!;
				}
				purchaseItem.derivedTaxId = taxId;

				let tax = taxMap.get(purchaseItem.derivedTaxId);

				purchaseItem.derivedQxr = purchaseItem.qty * purchaseItem.rate;

				if (purchaseItem.discRate === undefined || purchaseItem.discRate === null) {
					purchaseItem.derivedDiscA = 0;
				}
				else {
					switch (purchaseItem.discRateTypeId) {
						case RateTypeEnum.FLAT.id:
							purchaseItem.derivedDiscA = purchaseItem.discRate * purchaseItem.qty;
							break;
						case RateTypeEnum.PERCENT.id:
							purchaseItem.derivedDiscA = purchaseItem.discRate * purchaseItem.derivedQxr / 100;
							break;
						default:
							break;
					}
				}

				purchaseItem.derivedAmtAfterDisc = purchaseItem.derivedQxr - purchaseItem.derivedDiscA!;

				purchaseItem.derivedTaxableAmt = purchaseItem.derivedAmtAfterDisc;

				sumAmtAfterDisc = sumAmtAfterDisc + purchaseItem.derivedAmtAfterDisc;

				if (isAddTax) {
					purchaseItem.derivedTaxId = tax?.id;
					purchaseItem.derivedTaxRate = tax?.igst;
				}
			}

			if (isAddTax) {
				for (let i = 0; i < purchaseItems.length; i++) {
					let purchaseItem = purchaseItems[i];

					let taxAmtContainer = TaxHelper.calTaxAmt(
						taxMap.get(purchaseItem.derivedTaxId!)!,
						purchaseItem.derivedTaxRate!,
						isIntra,
						purchaseItem.derivedTaxableAmt!
					)

					purchaseItem.derivedCgstA = taxAmtContainer.cgstA;
					purchaseItem.derivedSgstA = taxAmtContainer.sgstA;
					purchaseItem.derivedIgstA = taxAmtContainer.igstA;
					purchaseItem.derivedTaxAmt = taxAmtContainer.cgstA + taxAmtContainer.sgstA + taxAmtContainer.igstA;
					purchaseItem.derivedAmt = taxAmtContainer.amtWithTax;
				}
			}



		}

		return purchaseItems;
	}

	private static applyOthChOnItem(sumAmtAftDisc: number, purchaseItem: PurchaseItemType, vouOthChOnItem: VouOthChOnItemType) {

		let itemOthChAmt = 0;

		switch (vouOthChOnItem.rateTypeId) {
			case RateTypeEnum.FLAT.id:
				itemOthChAmt = vouOthChOnItem.rate
					* purchaseItem.derivedAmtAfterDisc!
					/ sumAmtAftDisc;
				break;
			case RateTypeEnum.PERCENT.id:
				itemOthChAmt = purchaseItem.derivedTaxableAmt!
					* vouOthChOnItem.rate
					/ 100;

				vouOthChOnItem.derivedAmt = vouOthChOnItem.derivedAmt! + itemOthChAmt;
				break;
		}

		purchaseItem.derivedTaxableAmt = purchaseItem.derivedTaxableAmt! + itemOthChAmt;
	}

}