export default class LstOrderAdjustEnumCol {

	public static readonly NO = new LstOrderAdjustEnumCol("no");
	public static readonly DATE = new LstOrderAdjustEnumCol("date");
	public static readonly ORDER_TYPE = new LstOrderAdjustEnumCol("order_type");
	public static readonly ACCT_NAME = new LstOrderAdjustEnumCol("acct_name");
	public static readonly NOTE = new LstOrderAdjustEnumCol("note");
	public static readonly CREATED_BY = new LstOrderAdjustEnumCol("created_by");
	public static readonly CREATED_AT = new LstOrderAdjustEnumCol("created_at");

	private constructor(
		public readonly key: string) {
	}

	static get values(): LstOrderAdjustEnumCol[] {
		return [
			this.NO,
			this.DATE,
			this.ORDER_TYPE,
			this.ACCT_NAME,
			this.NOTE,
			this.CREATED_BY,
			this.CREATED_AT,
		];
	}

	static getByKey(key: string): LstOrderAdjustEnumCol | null {
		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.key === key) {
				return element;
			}
		}
		return null;
	}
}
