import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, FormControl, Row } from "react-bootstrap";
import MessageDialog from "../dialogs/MessageDialog";
import PriceListTypeEnum from "../../enums/PriceListTypeEnum";
import RateTypeEnum from "../../enums/RateTypeEnum";
import PriceListTypeHelper from "../../helpers/PriceListTypeHelper";
import ProdHelper from "../../helpers/ProdHelper";
import StringHelper2 from "../../helpers/StringHelper2";
import UnitHelper from "../../helpers/UnitHelper";
import YesNoHelper from "../../helpers/YesNoHelper";
import DropDownItemType from "../../types/DropDownItemType";
import SalesItemType from "../../types/SalesItemType";
import { PriceListRateResType } from "../../types/res/PriceListRateResType";
import UnitLdto from "../../types/res/list/UnitLdto";
import ProdForEsimateLdto from "../../types/res/list/ProdForEsimateLdto";
import TaxCatLdto from "../../types/res/list/TaxCatLdto";
import TaxLdto from "../../types/res/list/TaxLdto";
import DownshiftSelect from "../DownshiftSelectV2";

interface AddSalesItemFormSvProps {
	products: ProdForEsimateLdto[];
	productsMap: Map<number, ProdForEsimateLdto>;
	productsForDd: DropDownItemType[];
	units: UnitLdto[];
	unitsMap: Map<number, UnitLdto>;
	taxMap: Map<number, TaxLdto>;
	taxCatMap: Map<number, TaxCatLdto>;
	handleItemChange: (AddSalesItem: SalesItemType) => void;
	customerPriceListAMap: Map<number, PriceListRateResType>;
	customerPriceListBMap: Map<number, PriceListRateResType>;
	salesItemToEdit: SalesItemType | null;
	id?: number
}

function AddSalesItemFormSv({
	products,
	productsMap,
	productsForDd,
	units,
	unitsMap,
	taxMap,
	taxCatMap,
	handleItemChange,
	customerPriceListAMap,
	customerPriceListBMap,
	salesItemToEdit,
	id
}: AddSalesItemFormSvProps): JSX.Element {

	const [qtyCurrent, setQtyCurrent] = useState<number | null>(null);
	const [qtyPendingSales, setPendingSales] = useState<number | null>(null);
	const [qtyFinal, setFinal] = useState<number | null>(null);

	const [productId, setProductId] = useState<number | null>(null);
	const [qtyStr, setQtyStr] = useState<string>("");
	const [priceListTypeId, setPriceListTypeId] = useState<number | null>(null);
	const [rateStr, setRateStr] = useState<string>("");
	const [discStr, setDiscStr] = useState<string>("");
	const [desc, setDesc] = useState<string>("");
	//
	const [yesNosForDd, setYesNosForDd] = useState<DropDownItemType[]>([]);

	const [unitsForDd, setUnitsForDd] = useState<DropDownItemType[]>([]);

	const [priceListTypesForDd, setPriceListTypesForDd] = useState<DropDownItemType[]>([]);

	//
	const cmbProductRef = useRef<HTMLInputElement | null>(null);

	const [messageDialogMessage, setMessageDialogMessage] = useState("");
	const [showMessageDialog, setShowMessageDialog] = useState(false);

	useEffect(() => {
		async function process() {

			setPriceListTypesForDd(PriceListTypeHelper.getPriceListTypesForDd());
			setYesNosForDd(YesNoHelper.getYesNosForDd());

		}
		process();
	}, []);

	useEffect(() => {
		if (salesItemToEdit != null) {
			setProductId(salesItemToEdit.productId);
			handleProdChange(salesItemToEdit.productId);
			setQtyStr(salesItemToEdit.qty.toString());
			setPriceListTypeId(salesItemToEdit.priceListTypeId);
			setRateStr(salesItemToEdit.priceListRate.toString());
			if (salesItemToEdit.discRate != null)
				setDiscStr(salesItemToEdit.discRate.toString());
			setDesc(salesItemToEdit.desc);
		}
	}, [salesItemToEdit]);

	const handleProdChange = (prodIdLoc: number | null) => {

		let qtyCurrentLoc = null;
		let qtyPendingSalesLoc = null;
		let qtyFinalLoc = null;
		let unitsForDdLoc: UnitLdto[] = [];

		if (
			prodIdLoc != null &&
			prodIdLoc > 0 &&
			products !== undefined &&
			products !== null &&
			productsMap !== undefined &&
			productsMap !== null &&
			products.length > 0) {

			let product = productsMap.get(prodIdLoc);

			if (product != null) {

				qtyCurrentLoc = product.qtyCurrent!;
				qtyPendingSalesLoc = product.pendingQtySales;
				qtyPendingSalesLoc = product.pendingQtyEstimate;

				if (qtyCurrentLoc === undefined || qtyCurrentLoc === null) {
					qtyCurrentLoc = 0;
				}
				if (qtyPendingSalesLoc === undefined || qtyPendingSalesLoc === null) {
					qtyPendingSalesLoc = 0;
				}
				if (qtyPendingSalesLoc === undefined || qtyPendingSalesLoc === null) {
					qtyPendingSalesLoc = 0;
				}

				qtyFinalLoc = qtyCurrentLoc - qtyPendingSalesLoc - qtyPendingSalesLoc;

				if (product.unitId != null) {
					let u = unitsMap.get(product.unitId);
					if (u != null)
						unitsForDdLoc.push(u);
				}

				checkAndSetRate(prodIdLoc, priceListTypeId == null ? null : PriceListTypeEnum.getById(priceListTypeId));
			}
		}

		setQtyCurrent(qtyCurrentLoc);
		setPendingSales(qtyPendingSalesLoc);
		setPendingSales(qtyPendingSalesLoc);
		setFinal(qtyFinalLoc);

		setProductId(prodIdLoc);

		setUnitsForDd(UnitHelper.getUnitsForDd(unitsForDdLoc));
	};

	const handlePriceListChange = (priceListIdLoc: number | null) => {

		setPriceListTypeId(priceListIdLoc);
		checkAndSetRate(productId, priceListIdLoc == null ? null : PriceListTypeEnum.getById(priceListIdLoc));

	};

	const _handleClickSave = async (event: React.FormEvent<HTMLFormElement>) => {

		event.preventDefault();

		if (!isValid()) {
			return;
		}

	}

	const fillInUi = (/*productResType: SalesResType*/) => {

	};

	const handleClose = () => setShowMessageDialog(false);
	const handleShow = () => setShowMessageDialog(true);

	const clearBoxes = () => {
		setProductId(-1);
		setQtyStr("");
		setPriceListTypeId(-1);
		setRateStr("");
		setDiscStr("");
		setDesc("");
	};

	const handleSave = (e: React.MouseEvent) => {
		e.preventDefault();

		if (!isValid()) {
			return;
		}

		let productIdLoc: number = productId!;
		let qtyLoc: number = parseFloat(qtyStr);
		let priceListTypeIdLoc: number = priceListTypeId!;
		let rateLoc: number = parseFloat(rateStr);
		let discLoc: number = 0;
		let descLoc: string = desc;

		if (discStr !== undefined
			&& discStr !== null
			&& StringHelper2.isNum(discStr)
		) {
			discLoc = parseFloat(discStr);
		}

		let AddSalesItemLoc: SalesItemType = {
			productId: productIdLoc,
			desc: descLoc,
			qty: qtyLoc,
			priceListTypeId: priceListTypeIdLoc,
			priceListRate: rateLoc,
			discRate: discLoc,
			discRateTypeId: RateTypeEnum.PERCENT.id
		};

		handleItemChange(AddSalesItemLoc);

		clearBoxes();

		cmbProductRef.current?.focus();

	};

	const isValid = (): boolean => {

		if (
			productId === undefined
			|| productId === null
			|| productsMap?.get(productId) === undefined
			|| productsMap?.get(productId) === null
		) {
			setMessageDialogMessage("Select product");
			setShowMessageDialog(true);
			return false;
		}

		if (qtyStr === undefined
			|| qtyStr === null
			|| !StringHelper2.isNum(qtyStr)
		) {
			setMessageDialogMessage("Invalid quantity");
			setShowMessageDialog(true);
			return false;
		}

		if (
			priceListTypeId === undefined
			|| priceListTypeId === null
			|| PriceListTypeEnum.getById(priceListTypeId) === null
		) {
			setMessageDialogMessage("Select price list type");
			setShowMessageDialog(true);
			return false;
		}

		if (
			rateStr === undefined
			|| rateStr === null
			|| !StringHelper2.isNum(rateStr)) {
			setMessageDialogMessage("Invalid rate");
			setShowMessageDialog(true);
			return false;
		}

		return true;
	}

	const checkAndSetRate = (productId: number | null, priceListType: PriceListTypeEnum | null) => {

		let rateStrLoc = "";
		let discStrLoc = "";

		if (
			productId == null
			|| productsMap?.get(productId) == null
		) {
			return;
		}

		if (
			priceListType == null
		) {
			return;
		}

		let priceListRate;
		if (priceListType === PriceListTypeEnum.A) {
			priceListRate = customerPriceListAMap.get(productId);
		}
		else {
			priceListRate = customerPriceListBMap.get(productId);
		}

		if (priceListRate == null) {
			return;
		}

		if (priceListRate.rate !== null) {
			rateStrLoc = priceListRate.rate.toString();
		}

		if (priceListRate.disc !== null) {
			discStrLoc = priceListRate.disc.toString();
		}

		setRateStr(rateStrLoc);
		setDiscStr(discStrLoc);

	}

	return (
		<>
			<div className="row g-3 align-items-center mb-3">
				<div className="col-auto">
					<label className="col-form-label">Quantity</label>
				</div>
				<div className="col-auto">
					<input type="text" className="form-control form-control-sm" readOnly={true}
						value={qtyCurrent ? qtyCurrent.toLocaleString('en-IN') : ""}
					/>
				</div>
				<div className="col-auto">
					<label className="col-form-label">-</label>
				</div>
				<div className="col-auto">
					<input type="text" className="form-control form-control-sm" readOnly={true}
						value={qtyPendingSales ? qtyPendingSales.toLocaleString('en-IN') : ""}
					/>
				</div>
				<div className="col-auto">
					<label className="col-form-label">-</label>
				</div>
				<div className="col-auto">
					<input type="text" className="form-control form-control-sm" readOnly={true}
						value={qtyPendingSales ? qtyPendingSales.toLocaleString('en-IN') : ""}
					/>
				</div>
				<div className="col-auto">
					<label className="col-form-label form-control-sm">=</label>
				</div>
				<div className="col-auto">
					<input type="text" className="form-control" readOnly={true}
						value={qtyFinal ? qtyFinal.toLocaleString('en-IN') : ""}
					/>
				</div>

			</div>

			<Row className="mb-3" xs="auto">
				<Form.Group as={Col}>
					<Form.Label>Product</Form.Label>
					<DownshiftSelect
						inputId={"product"}
						value={productId != null && productId > 0 && productsMap?.get(productId) ? ProdHelper.getProdDdi(productsMap?.get(productId)!) : null}
						onChange={handleProdChange}
						options={productsForDd}
						isLoading={false}
						isDisabled={false}
						placeholder=""
						forwardedRef={cmbProductRef}
					/>
				</Form.Group>

				<Form.Group as={Col}>
					<Form.Label>&nbsp;</Form.Label>
					<FormControl
						size="sm" as={Button}
					>...</FormControl>
				</Form.Group>

				<Form.Group as={Col}>
					<Form.Label>Quantity</Form.Label>
					<FormControl
						size="sm"
						type="text"
						value={qtyStr}
						onChange={(e) => setQtyStr(e.target.value)}
					/>
				</Form.Group>

				<Form.Group as={Col}>
					<Form.Label>Price List Type</Form.Label>
					<DownshiftSelect
						inputId={"price_list_type"}
						value={priceListTypeId != null && priceListTypeId > 0 && PriceListTypeEnum.getById(priceListTypeId) !== null ? PriceListTypeHelper.getPriceListTypeForDd(PriceListTypeEnum.getById(priceListTypeId)!) : null}
						onChange={(e) => handlePriceListChange(e)}
						options={priceListTypesForDd}
						isLoading={false}
						isDisabled={false}
						placeholder=""
					/>
				</Form.Group>

				<Form.Group as={Col}>
					<Form.Label>Rate</Form.Label>
					<FormControl
						size="sm"
						type="text"
						value={rateStr}
						onChange={(e) => setRateStr(e.target.value)}
					/>
				</Form.Group>

				<Form.Group as={Col}>
					<Form.Label>Discount</Form.Label>
					<FormControl
						size="sm"
						type="text"
						value={discStr}
						onChange={(e) => setDiscStr(e.target.value)}
					/>
				</Form.Group>

				<Form.Group as={Col}>
					<Form.Label>&nbsp;</Form.Label>
					<FormControl
						size="sm"
						as={Button}
						onClick={handleSave}
					>
						Save
					</FormControl>
				</Form.Group>

				<Form.Group as={Col}>
					<Form.Label>&nbsp;</Form.Label>
					<FormControl
						size="sm"
						as={Button}>Cancel</FormControl>
				</Form.Group>

				<Form.Group as={Col}>
					<Form.Label>Description</Form.Label>
					<FormControl
						size="sm"
						type="text"
						onChange={(e) => setDesc(e.target.value)}
					/>
				</Form.Group>
			</Row>

			<MessageDialog
				modalMessage={messageDialogMessage}
				showDialog={showMessageDialog}
				setShowDialog={setShowMessageDialog}
			/>

		</>
	);
}

export default AddSalesItemFormSv;
