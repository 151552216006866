export default class DateHelper2 {

	public static getFirstDateOfCurrentMonth() {
		var date = new Date();
		return new Date(date.getFullYear(), date.getMonth(), 1);
	}

	public static getLastDateOfCurrentMonth() {
		var date = new Date();
		return new Date(date.getFullYear(), date.getMonth() + 1, 0);
	}
}