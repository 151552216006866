export default class ResourceEnum {

	public static readonly ACCESS_ROLE = new ResourceEnum(1, "access-roles");
	public static readonly ACCT_TYPE = new ResourceEnum(2, "account-types");
	public static readonly ACCT = new ResourceEnum(3, "accounts");
	public static readonly AGENT = new ResourceEnum(4, "agents");
	public static readonly AUTH = new ResourceEnum(5, "auth");
	public static readonly BANK = new ResourceEnum(6, "banks");
	public static readonly CDP_JOURNAL_VOU = new ResourceEnum(7, "cash-discount-penalty-vouchers");
	public static readonly CASH_DISC_VOU = new ResourceEnum(8, "cash-discount-vouchers");
	public static readonly COL_SETTING = new ResourceEnum(9, "col-settings");
	public static readonly COMM_LIST = new ResourceEnum(10, "commission-lists");
	public static readonly COMM_VOU = new ResourceEnum(11, "commission-vouchers");
	public static readonly COMMNC = new ResourceEnum(12, "communications");
	public static readonly COMPANY_PROFILE = new ResourceEnum(13, "company-profiles");
	public static readonly CONTRA = new ResourceEnum(14, "contras");
	public static readonly CUSTOMER_PRICE_LIST_A = new ResourceEnum(15, "customer-price-lists-a");
	public static readonly CUSTOMER_PRICE_LIST_B = new ResourceEnum(16, "customer-price-lists-b");
	public static readonly CUSTOMER = new ResourceEnum(17, "customers");
	public static readonly ESTIMATE_STATUS = new ResourceEnum(18, "estimate-statuses");
	public static readonly ESTIMATE = new ResourceEnum(19, "estimates");
	public static readonly INV_ADJUST = new ResourceEnum(20, "inventory-adjusts");
	public static readonly JOURNAL = new ResourceEnum(21, "journals");
	public static readonly KB_SHORTCUT = new ResourceEnum(22, "kb-shortcuts");
	public static readonly LEAD_SRC = new ResourceEnum(23, "lead-sources");
	public static readonly LEAD_STATUS = new ResourceEnum(24, "lead-statuses");
	public static readonly LEAD = new ResourceEnum(25, "leads");
	public static readonly LOGIN = new ResourceEnum(26, "login");
	public static readonly MENU = new ResourceEnum(27, "menus");
	public static readonly MSG_TEMPLATE = new ResourceEnum(28, "msg-templates");
	public static readonly ORDER_ADJUST = new ResourceEnum(29, "order-adjusts");
	public static readonly PAYMENT = new ResourceEnum(30, "payments");
	public static readonly PRODUCT_CAT = new ResourceEnum(31, "product-categories");
	public static readonly PRODUCT_OTH_CHARGE = new ResourceEnum(32, "product-other-charges");
	public static readonly PRODUCT = new ResourceEnum(33, "products");
	public static readonly PI = new ResourceEnum(34, "proforma-invoices");
	public static readonly PURCHASE_ORDER = new ResourceEnum(35, "purchase-orders");
	public static readonly PURCHASE_PRICE_LIST = new ResourceEnum(36, "purchase-price-lists");
	public static readonly PURCHASE_RETURN = new ResourceEnum(37, "purchase-returns");
	public static readonly PURCHASE = new ResourceEnum(38, "purchases");
	public static readonly RECEIPT = new ResourceEnum(39, "receipts");
	public static readonly REMINDER = new ResourceEnum(40, "reminders");
	public static readonly STATE = new ResourceEnum(41, "states");
	public static readonly SALES_ORDER = new ResourceEnum(42, "sales-orders");
	public static readonly SALES_PRICE_LIST = new ResourceEnum(43, "sales-price-lists");
	public static readonly SALES_RETURN = new ResourceEnum(44, "sales-returns");
	public static readonly SALES = new ResourceEnum(45, "sales");
	public static readonly SERVICE = new ResourceEnum(46, "services");
	public static readonly SUPPLIER = new ResourceEnum(47, "suppliers");
	public static readonly SUPPLIER_PRICE_LIST = new ResourceEnum(48, "supplier-price-lists");
	public static readonly TAX_CAL_TYPE = new ResourceEnum(49, "tax-cal-types");
	public static readonly TAX_CAT = new ResourceEnum(50, "tax-categories");
	public static readonly TAX = new ResourceEnum(51, "taxes");
	public static readonly TRANSPORTER = new ResourceEnum(52, "transporters");
	public static readonly UNIT = new ResourceEnum(53, "units");
	public static readonly USER = new ResourceEnum(54, "user");
	public static readonly VOU_OTH_CHARGE = new ResourceEnum(55, "voucher-other-charges");
	public static readonly VOUCHER = new ResourceEnum(56, "vouchers");

	private constructor(
		public readonly id: number,
		public readonly name: string) {
	}

}
