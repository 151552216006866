import DropDownItemType from "../types/DropDownItemType";
import ProdForEsimateLdto from "../types/res/list/ProdForEsimateLdto";
import ProdLdto from "../types/res/list/ProdLdto";

export default class ProdHelper {

	static getProdDdis(listProducts: ProdLdto[]) {

		let finalArr: DropDownItemType[] = [];
		listProducts.forEach(item => {
			finalArr.push({
				id: item.id,
				name: item.name
			});
		});
		return finalArr;

	}

	static getProdDdi(product: ProdLdto | ProdForEsimateLdto): DropDownItemType {
		return {
			id: product.id,
			name: product.name
		};
	}

	static getProdLdtoMap(itemArr: ProdLdto[]) {
		let map = new Map<number, ProdLdto>([]);
		for (let index = 0; index < itemArr.length; index++) {
			const item = itemArr[index];
			map.set(item.id, item);
		}
		return map;
	}

	static getProductMap2(itemArr: ProdForEsimateLdto[]) {
		let map = new Map<number, ProdForEsimateLdto>([]);
		for (let index = 0; index < itemArr.length; index++) {
			const item = itemArr[index];
			map.set(item.id, item);
		}
		return map;
	}

}
