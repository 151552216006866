import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, Tab, Tabs } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import AcctApi from "../../api/AcctApi";
import CompanyProfileApi from "../../api/CompanyProfileApi";
import CustomerPriceListAApi from "../../api/CustomerPriceListAApi";
import CustomerPriceListBApi from "../../api/CustomerPriceListBApi";
import EstimateApi from "../../api/EstimateApi";
import EstimateStatusApi from "../../api/EstimateStatusApi";
import ProdApi from "../../api/ProdApi";
import TaxApi from "../../api/TaxApi";
import TaxCatApi from "../../api/TaxCatApi";
import TransporterApi from "../../api/TransporterApi";
import UnitApi from "../../api/UnitApi";
import VouOthChApi from "../../api/VouOthChApi";
import VoucherApi from "../../api/VoucherApi";
import CalcOnTypeEnum from "../../enums/CalcOnTypeEnum";
import VoucherTypeEnum from "../../enums/VoucherTypeEnum";
import AcctHelper from "../../helpers/AcctHelper";
import AcctJournalSumHelper from "../../helpers/AcctJournalSumHelper";
import CompanyProfileHelper from "../../helpers/CompanyProfileHelper";
import EstimateHelper from "../../helpers/EstimateHelper";
import EstimateItemHelper from "../../helpers/EstimateItemHelper";
import EstimateStatusHelper from "../../helpers/EstimateStatusHelper";
import ProdHelper from "../../helpers/ProdHelper";
import TaxCatHelper from "../../helpers/TaxCatHelper";
import TaxHelper from "../../helpers/TaxHelper";
import TransporterHelper from "../../helpers/TransporterHelper";
import UnitHelper from "../../helpers/UnitHelper";
import VouOthChHelper from "../../helpers/VouOthChHelper";
import CommonCudType from "../../types/CommonCudType";
import DropDownItemType from "../../types/DropDownItemType";
import EstimateItemType from "../../types/EstimateItemType";
import VouOthChNonTaxableType from "../../types/VouOthChNonTaxableType";
import VouOthChOnItemType from "../../types/VouOthChOnItemType";
import { PriceListRateResType } from "../../types/res/PriceListRateResType";
import AcctForCustomerHdto from "../../types/res/list/AcctForCustomerHdto";
import CompanyProfileLdto from "../../types/res/list/CompanyProfileLdto";
import EstimateStatusLdto from "../../types/res/list/EstimateStatusLdto";
import ProdForEsimateLdto from "../../types/res/list/ProdForEsimateLdto";
import TaxCatLdto from "../../types/res/list/TaxCatLdto";
import TaxLdto from "../../types/res/list/TaxLdto";
import TransporterLdto from "../../types/res/list/TransporterLdto";
import UnitLdto from "../../types/res/list/UnitLdto";
import VouOthChargeLdto from "../../types/res/list/VouOthChargeLdto";
import EstimateRrHoType from "../../types/rr/EstimateRrHoType";
import ListCompanyProfileSm from "../../types/sm/ListCompanyProfileSm";
import ListTaxSm from "../../types/sm/ListTaxSm";
import ListTransporterSm from "../../types/sm/ListTransporterSm";
import DownshiftSelect from "../DownshiftSelectV2";
import CustomerSelectDialog from "../dialogs/CustomerSelectDialog";
import AddEstimateItemsSv from "./AddEstimateItemsSv";
import AddPusaOthChNonTaxableSv from "./AddPusaOthChNonTaxableSv";
import AddPusaOthChOnItemSv from "./AddPusaOthChOnItemSv";
import AddPusaTaxSv from "./AddPusaTaxSv";
import AddEstimateTotalSv from "./AddPusaTotalSv";

interface AddEstimateProps {
	id?: number
}

function AddEstimate({
	id
}: AddEstimateProps): JSX.Element {

	const [acctForCustomer, setAcctForCustomer] = useState<AcctForCustomerHdto | null>(null);

	const [noStr, setNoStr] = useState("");
	const [date, setDate] = useState<Date | null>(new Date());
	const [companyProfileId, setCompanyProfileId] = useState<number | null>(null);
	const [transporterId, setTransporterId] = useState<number | null>(null);
	const [statusId, setStatusId] = useState<number | null>(null);
	const [lockStockForDaysStr, setLockStockForDaysStr] = useState<string>("");
	const [note, setNote] = useState("");

	const [creditDaysLimit, setCreditDaysLimit] = useState<string>("");
	const [creditDaysCurrentStatus, setCreditDaysCurrentStatus] = useState<string>("");
	const [overdueBal, setOverdueBal] = useState<string>("");
	const [isCreditDaysRight, setIsCreditDaysRight] = useState<boolean>(true);

	const [creditAmt, setCreditAmt] = useState<string>("");
	const [currentBal, setCurrentBal] = useState<string>("");
	const [currentBalDiff, setCurrentBalDiff] = useState<string>("");
	const [isCreditAmtRight, setIsCreditAmtRight] = useState<boolean>(true);

	const [estimateItems, setEstimateItems] = useState<EstimateItemType[]>([]);
	const [estimateOthChOnItemArr, setEstimateOthChOnItemArr] = useState<VouOthChOnItemType[]>([]);

	const [isAddTax, setIsAddTax] = useState<boolean>(true);
	const [isIntra, setIsIntra] = useState<boolean>(true);

	const [estimateOthChNonTaxableArr, setEstimateOthChNonTaxableArr] = useState<VouOthChNonTaxableType[]>([]);
	//
	const [accounts, setAccounts] = useState<AcctForCustomerHdto[] | null>(null);
	const [accountsMap, setAccountsMap] = useState<Map<number, AcctForCustomerHdto> | null>(null);

	const [companyProfileListResArr, setCompanyProfileListResArr] = useState<CompanyProfileLdto[] | null>(null);
	const [companyProfileListResArrForDd, setCompanyProfileListResArrForDd] = useState<DropDownItemType[] | null>(null);
	const [companyProfileListResMap, setCompanyProfileListResMap] = useState<Map<number, CompanyProfileLdto> | null>(null);

	const [taxListResArr, setTaxListArr] = useState<TaxLdto[] | null>(null);
	const [taxListResArrForDd, setTaxListArrForDd] = useState<DropDownItemType[] | null>(null);
	const [taxListResMap, setTaxListResMap] = useState<Map<number, TaxLdto> | null>(null);

	const [taxCats, setTaxCats] = useState<TaxCatLdto[] | null>(null);
	const [taxCatMap, setTaxCatMap] = useState<Map<number, TaxCatLdto> | null>(null);

	const [transporterListResArr, setTransporterListArr] = useState<TransporterLdto[] | null>(null);
	const [transporterListResArrForDd, setTransporterListArrForDd] = useState<DropDownItemType[] | null>(null);
	const [transporterListResMap, setTransporterListResMap] = useState<Map<number, TransporterLdto> | null>(null);

	const [vouOthChOnItemResArr, setVouOthChOnItemResArr] = useState<VouOthChargeLdto[] | null>(null);
	const [vouOthChOnItemResArrForDd, setVouOthChOnItemResArrForDd] = useState<DropDownItemType[] | null>(null);
	const [vouOthChOnItemResMap, setVouOthChOnItemResMap] = useState<Map<number, VouOthChargeLdto> | null>(null);

	const [vouOthChNonTaxableResArr, setVouOthChNonTaxableResArr] = useState<VouOthChargeLdto[] | null>(null);
	const [vouOthChNonTaxableResArrForDd, setVouOthChNonTaxableResArrForDd] = useState<DropDownItemType[] | null>(null);
	const [vouOthChNonTaxableResMap, setVouOthChNonTaxableResMap] = useState<Map<number, VouOthChargeLdto> | null>(null);

	const [estimateStatusListResArr, setEstimateStatusListArr] = useState<EstimateStatusLdto[] | null>(null);
	const [estimateStatusListResArrForDd, setEstimateStatusListArrForDd] = useState<DropDownItemType[] | null>(null);
	const [estimateStatusListResMap, setEstimateStatusListResMap] = useState<Map<number, EstimateStatusLdto> | null>(null);

	const [productsMap, setProductsMap] = useState<Map<number, ProdForEsimateLdto> | null>(null);
	const [products, setProducts] = useState<ProdForEsimateLdto[] | null>(null);
	const [productsForDd, setProductsForDd] = useState<DropDownItemType[] | null>(null);

	const [unitsMap, setUnitsMap] = useState<Map<number, UnitLdto> | null>(null);
	const [units, setUnits] = useState<UnitLdto[] | null>(null);

	const [customerPriceListAArr, setCustomerPriceListAArr] = useState<PriceListRateResType[]>([]);
	const [customerPriceListAMap, setCustomerPriceListAMap] = useState<Map<number, PriceListRateResType>>(new Map());

	const [customerPriceListBArr, setCustomerPriceListBArr] = useState<PriceListRateResType[]>([]);
	const [customerPriceListBMap, setCustomerPriceListBMap] = useState<Map<number, PriceListRateResType>>(new Map());

	//
	const btnBrowseCustomerRef = useRef<HTMLButtonElement | null>(null);

	const [showCustomerDialog, setShowCustomerDialog] = useState(false);

	const [messageDialogMessage, setMessageDialogMessage] = useState("");
	const [showMessageDialog, setShowMessageDialog] = useState(false);

	useEffect(() => {
		async function process() {

			AcctApi.getAcctForCustomerHdtos().then((accounts) => {
				if (accounts.isSuccess) {
					setAccounts(accounts.items!);
					setAccountsMap(AcctHelper.getMap(accounts.items!));
				}
			});

			CompanyProfileApi.getCompanyProfileLdtos(new ListCompanyProfileSm()).then((listCompanyProfiles) => {
				if (listCompanyProfiles.isSuccess) {
					setCompanyProfileListResArr(listCompanyProfiles.items!);
					setCompanyProfileListResArrForDd(CompanyProfileHelper.getCompanyProfilesForDd(listCompanyProfiles.items!));
					setCompanyProfileListResMap(CompanyProfileHelper.getCompanyProfileListResMap(listCompanyProfiles.items!));
				}
			});

			TaxApi.getTaxLdtos(new ListTaxSm()).then((taxListArr) => {
				if (taxListArr.isSuccess) {
					setTaxListArr(taxListArr.items!);
					setTaxListArrForDd(TaxHelper.getTaxListForDd(taxListArr.items!));
					setTaxListResMap(TaxHelper.getTaxMap(taxListArr.items!));
				}
			});

			TaxCatApi.getTaxCatLdtos().then((listTaxCats) => {
				if (listTaxCats.isSuccess) {
					setTaxCats(listTaxCats.items!);
					setTaxCatMap(TaxCatHelper.getTaxCatListResMap(listTaxCats.items!));
				}
			});

			TransporterApi.getTransporterLdtos(new ListTransporterSm()).then((transporterListResArr) => {
				if (transporterListResArr.isSuccess) {
					setTransporterListArr(transporterListResArr.items!);
					setTransporterListArrForDd(TransporterHelper.getTransporterListResArrForDd(transporterListResArr.items!));
					setTransporterListResMap(TransporterHelper.getTransporterListResMap(transporterListResArr.items!));
				}
			});

			VouOthChApi.getVouOthChargeResDtos(CalcOnTypeEnum.ITEM, false).then((vouOthChOnItemArray) => {
				if (vouOthChOnItemArray.isSuccess) {
					setVouOthChOnItemResArr(vouOthChOnItemArray.items!);
					setVouOthChOnItemResArrForDd(VouOthChHelper.getVouOthChArrForDd(vouOthChOnItemArray.items!));
					setVouOthChOnItemResMap(VouOthChHelper.getVouOthChListResMap(vouOthChOnItemArray.items!));
				}
			});

			VouOthChApi.getVouOthChargeResDtos(CalcOnTypeEnum.VOUCHER, false).then((vouOthChNonTaxableResArr) => {
				if (vouOthChNonTaxableResArr.isSuccess) {
					setVouOthChNonTaxableResArr(vouOthChNonTaxableResArr.items!);
					setVouOthChNonTaxableResArrForDd(VouOthChHelper.getVouOthChArrForDd(vouOthChNonTaxableResArr.items!));
					setVouOthChNonTaxableResMap(VouOthChHelper.getVouOthChListResMap(vouOthChNonTaxableResArr.items!));
				}
			});

			EstimateStatusApi.getEstimateStatusLdtos().then((estimateStatusListResArr) => {
				if (estimateStatusListResArr.isSuccess) {
					setEstimateStatusListArr(estimateStatusListResArr.items!);
					setEstimateStatusListArrForDd(EstimateStatusHelper.getEstimateStatusListResArrForDd(estimateStatusListResArr.items!));
					setEstimateStatusListResMap(EstimateStatusHelper.getEstimateStatusListResMap(estimateStatusListResArr.items!));
				}
			});

			ProdApi.getProdForEstimate().then((listProducts) => {
				setProducts(listProducts);
				setProductsForDd(ProdHelper.getProdDdis(listProducts));
				setProductsMap(ProdHelper.getProductMap2(listProducts));

			});

			UnitApi.getUnitLdtos().then((listUnits) => {
				if (listUnits.isSuccess) {
					setUnits(listUnits.items!);
					setUnitsMap(UnitHelper.getUnitMap(listUnits.items!));
				}
			});

			if (id == null) {
				let nextNoRes = await VoucherApi.getNextNo(VoucherTypeEnum.ESTIMATE);
				if (nextNoRes.isSuccess) {
					setNoStr(nextNoRes.item!.no);
				}
			}
		}
		process();

		window.addEventListener('keydown', handleKeyDown);

		btnBrowseCustomerRef.current?.focus();

		// Clean up event listener on component unmount
		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};

	}, []);

	// Function to handle keyboard events
	const handleKeyDown = (event: KeyboardEvent) => {
		if (
			event.key === 's'
			&& event.ctrlKey
			&& !event.altKey
			&& !event.metaKey
			&& !event.shiftKey) {
			handleClickSave(event);
		}
		// Add more conditions for other shortcuts if needed
	};

	useEffect(() => {
		if (
			accounts != null &&
			accountsMap != null &&
			companyProfileListResArr != null &&
			companyProfileListResArrForDd != null &&
			companyProfileListResMap != null &&
			taxListResArr != null &&
			taxListResArrForDd != null &&
			taxListResMap != null &&
			taxCats != null &&
			taxCatMap != null &&
			transporterListResArr != null &&
			transporterListResArrForDd != null &&
			transporterListResMap != null &&
			vouOthChOnItemResArr != null &&
			vouOthChOnItemResArrForDd != null &&
			vouOthChOnItemResMap != null &&
			vouOthChNonTaxableResArr != null &&
			vouOthChNonTaxableResArrForDd != null &&
			vouOthChNonTaxableResMap != null &&
			estimateStatusListResArr != null &&
			estimateStatusListResArrForDd != null &&
			estimateStatusListResMap != null &&
			productsMap != null &&
			products != null &&
			productsForDd != null &&
			unitsMap != null &&
			units != null &&
			id !== undefined &&
			id !== null
		) {
			EstimateApi.getById(id).then((result) => fillInUi(result));
		}
	}, [accounts,
		accountsMap,
		companyProfileListResArr,
		companyProfileListResArrForDd,
		companyProfileListResMap,
		taxListResArr,
		taxListResArrForDd,
		taxListResMap,
		taxCats,
		taxCatMap,
		transporterListResArr,
		transporterListResArrForDd,
		transporterListResMap,
		vouOthChOnItemResArr,
		vouOthChOnItemResArrForDd,
		vouOthChOnItemResMap,
		vouOthChNonTaxableResArr,
		vouOthChNonTaxableResArrForDd,
		vouOthChNonTaxableResMap,
		estimateStatusListResArr,
		estimateStatusListResArrForDd,
		estimateStatusListResMap,
		productsMap,
		products,
		productsForDd,
		unitsMap,
		units,
	]);


	useEffect(() => {

		let creditDaysLimitLoc = "";
		let creditDaysCurrentStatusLoc = "";
		let overdueBalLoc = "";
		let isCreditDaysRightLoc = false;

		let creditAmtLoc = "";
		let currentBalLoc = "";
		let currenBalDiffLoc = "";
		let isCreditAmtRightLoc = false;

		if (acctForCustomer != null) {

			loadCustomerPriceListA(acctForCustomer.id, date);

			setIsIntra(13 === acctForCustomer.stateId)

			if (acctForCustomer.creditDays !== undefined
				&& acctForCustomer.creditDays !== null) {
				creditDaysLimitLoc = acctForCustomer.creditDays.toLocaleString('en-IN');
			}

			if (acctForCustomer.overdueDays !== undefined
				&& acctForCustomer.overdueDays !== null
			) {
				creditDaysCurrentStatusLoc = acctForCustomer.overdueDays.toLocaleString('en-IN');
			}

			if (acctForCustomer.overdueAmt !== undefined
				&& acctForCustomer.overdueAmt !== null
				&& acctForCustomer.overdueAmt !== 0
			) {
				overdueBalLoc = acctForCustomer.overdueAmt.toLocaleString('en-IN');
			}

			if (acctForCustomer.overdueAmt !== undefined
				&& acctForCustomer.overdueAmt !== null
				&& acctForCustomer.overdueAmt <= 0
			) {
				isCreditDaysRightLoc = true;
			}

			//

			if (acctForCustomer.creditAmtLimit !== undefined
				&& acctForCustomer.creditAmtLimit !== null

			) {
				creditAmtLoc = acctForCustomer.creditAmtLimit.toLocaleString('en-IN');
			}

			let acctJournalSumHelper = new AcctJournalSumHelper(acctForCustomer.sumDebit,
				acctForCustomer.sumCredit);

			if (acctJournalSumHelper.isCrDrSame()) {
				currentBalLoc = "0";
			}
			else if (acctJournalSumHelper.isCrBig()) {
				currentBalLoc = " - " + acctJournalSumHelper.getCrBal().toLocaleString('en-IN');
			}
			else if (acctJournalSumHelper.isDrBig()) {
				currentBalLoc = acctJournalSumHelper.getDrBal().toLocaleString('en-IN');
			}

			if (acctForCustomer.creditAmtLimit !== null) {
				acctJournalSumHelper.credit = acctJournalSumHelper.credit + acctForCustomer.creditAmtLimit;
			}

			let acctJournalSumHelperTemp = new AcctJournalSumHelper(
				acctJournalSumHelper.debit,
				acctJournalSumHelper.credit
			);

			currenBalDiffLoc = acctJournalSumHelperTemp.getCrBal().toLocaleString('en-IN');

			if (acctJournalSumHelperTemp.getCrBal() >= 0) {
				isCreditAmtRightLoc = true;
			}

		}

		setCreditDaysLimit(creditDaysLimitLoc);
		setCreditDaysCurrentStatus(creditDaysCurrentStatusLoc);
		setOverdueBal(overdueBalLoc);
		setIsCreditDaysRight(isCreditDaysRightLoc);

		setCreditAmt(creditAmtLoc);
		setCurrentBal(currentBalLoc);
		setCurrentBalDiff(currenBalDiffLoc);
		setIsCreditAmtRight(isCreditAmtRightLoc);

	}, [acctForCustomer]);

	const handleAddItem = (addEstimateItem: EstimateItemType) => {
		setEstimateItems(EstimateItemHelper.calItems(
			isAddTax,
			isIntra,
			taxListResMap ?? new Map(),
			taxCatMap ?? new Map(),
			unitsMap ?? new Map(),
			productsMap ?? new Map(),
			[...estimateItems, addEstimateItem], estimateOthChOnItemArr
		));
	}

	const handleUpdateItem = (index: number, estimateItem: EstimateItemType) => {
		let estimateItemsLoc = [...estimateItems];
		estimateItemsLoc[index] = estimateItem;
		setEstimateItems(EstimateItemHelper.calItems(
			isAddTax,
			isIntra,
			taxListResMap ?? new Map(),
			taxCatMap ?? new Map(),
			unitsMap ?? new Map(),
			productsMap ?? new Map(),
			estimateItemsLoc ?? new Map(),
			estimateOthChOnItemArr
		));
	}

	const handleDeleteItem = (index: number) => {
		let estimateItemsLoc = [...estimateItems];
		estimateItemsLoc.splice(index, 1);
		setEstimateItems(EstimateItemHelper.calItems(
			isAddTax,
			isIntra,
			taxListResMap ?? new Map(),
			taxCatMap ?? new Map(),
			unitsMap ?? new Map(),
			productsMap ?? new Map(),
			estimateItemsLoc ?? new Map(),
			estimateOthChOnItemArr
		));
	}

	const handleOthChOnItemChange = (estimateOthChOnItemArr: VouOthChOnItemType[]) => {
		setEstimateItems(EstimateItemHelper.calItems(
			isAddTax,
			isIntra,
			taxListResMap ?? new Map(),
			taxCatMap ?? new Map(),
			unitsMap ?? new Map(),
			productsMap ?? new Map(),
			estimateItems ?? new Map(),
			estimateOthChOnItemArr
		));
		setEstimateOthChOnItemArr(estimateOthChOnItemArr);
	}

	const handleOthChNonTaxableChange = (estimateOthChNonTaxableArr: VouOthChNonTaxableType[]) => {
		EstimateHelper.calOthChNonTax(estimateItems, estimateOthChNonTaxableArr);
		setEstimateOthChNonTaxableArr(estimateOthChNonTaxableArr);
	}

	const handleClickSave = async (event: React.FormEvent<HTMLFormElement> | KeyboardEvent) => {

		event.preventDefault();

		if (!isValid()) {
			return;
		}

		let estimateReqHo: EstimateRrHoType = {
			estimateRrDto: {
				date: date!,
				no: noStr,
				customerId: acctForCustomer?.id!,
				isIntra: isIntra,
				transporterId: transporterId,
				isTax: isAddTax,
				note: note,
				companyProfileId: companyProfileId,
				statusId: statusId,
				lockStockForDays: lockStockForDaysStr.trim() === "" ? null : parseInt(lockStockForDaysStr)
			},
			estimateItemRrDtos: estimateItems,
			estimateOthChOnItemRrDtos: estimateOthChOnItemArr,
			estimateOthChTaxableRrDtos: [],
			estimateOthChNonTaxableRrDtos: estimateOthChNonTaxableArr,
		}

		let res: CommonCudType;

		if (id === undefined || id === null) {
			res = await EstimateApi.createEstimate(estimateReqHo);
		}
		else {
			res = await EstimateApi.updateEstimate(id, estimateReqHo);
		}

		if (!res!.isSuccess) {
			setMessageDialogMessage(res!.errorRes?.message!);
			setShowMessageDialog(true);
		}
		else {
			setMessageDialogMessage("Saved");
			setShowMessageDialog(true);
			clearBoxes();
		}

	}

	const fillInUi = (estimateRrHo: EstimateRrHoType) => {

		setAcctForCustomer(accountsMap?.get(estimateRrHo.estimateRrDto.customerId)!);

		setNoStr(estimateRrHo.estimateRrDto.no.toString())
		setDate(moment(estimateRrHo.estimateRrDto.date).toDate());
		setCompanyProfileId(estimateRrHo.estimateRrDto.companyProfileId);
		setTransporterId(estimateRrHo.estimateRrDto.transporterId);
		setStatusId(estimateRrHo.estimateRrDto.statusId);
		if (estimateRrHo.estimateRrDto.lockStockForDays != null)
			setLockStockForDaysStr(estimateRrHo.estimateRrDto.lockStockForDays?.toString());
		setNote(estimateRrHo.estimateRrDto.note);

		let estimateItemsCalculated = EstimateItemHelper.calItems(
			estimateRrHo.estimateRrDto.isTax,
			estimateRrHo.estimateRrDto.isIntra,
			taxListResMap ?? new Map(),
			taxCatMap ?? new Map(),
			unitsMap ?? new Map(),
			productsMap ?? new Map(),
			estimateRrHo.estimateItemRrDtos,
			estimateRrHo.estimateOthChOnItemRrDtos);
		setEstimateItems(estimateItemsCalculated);
		setEstimateOthChOnItemArr(estimateRrHo.estimateOthChOnItemRrDtos);
		setIsAddTax(estimateRrHo.estimateRrDto.isTax);
		setIsIntra(estimateRrHo.estimateRrDto.isIntra);
		let estimateOthChNonTaxableArrLoc = estimateRrHo.estimateOthChNonTaxableRrDtos;
		EstimateHelper.calOthChNonTax(
			estimateItemsCalculated,
			estimateOthChNonTaxableArrLoc);
		setEstimateOthChNonTaxableArr(estimateOthChNonTaxableArrLoc);
	};

	const handleClose = () => setShowMessageDialog(false);
	const handleShow = () => setShowMessageDialog(true);

	const handleDateChange = (date: Date | null) => {
		setDate(date);

		loadCustomerPriceListA(acctForCustomer?.id, date);
	}

	const clearBoxes = () => {

		setNoStr("");
		setDate(new Date());
		setAcctForCustomer(null);
		setCompanyProfileId(-1);
		setTransporterId(-1);
		setStatusId(-1);
		setLockStockForDaysStr("");
		setNote("");

		setEstimateItems([]);
		setEstimateOthChOnItemArr([]);
		setEstimateOthChNonTaxableArr([]);
		setIsAddTax(true);
		setIsIntra(true);
	};




	const loadCustomerPriceListA = (customerId: number | null | undefined, asOnDate: Date | null) => {

		if (customerId == null || customerId <= 0 || asOnDate === null) {
			setCustomerPriceListAArr([]);
			setCustomerPriceListAMap(new Map());
		}
		else {
			CustomerPriceListAApi.getCustomerPricePriceListAArr(customerId, asOnDate).then((priceListRateArr) => {
				if (priceListRateArr.isSuccess) {
					let priceListMap = new Map<number, PriceListRateResType>([]);
					for (let index = 0; index < priceListRateArr.items!.length; index++) {
						const priceListRate = priceListRateArr.items![index];
						priceListMap.set(priceListRate.productId, priceListRate);
					}

					setCustomerPriceListAArr(priceListRateArr.items!);
					setCustomerPriceListAMap(priceListMap);
				}
			});

			CustomerPriceListBApi.getCustomerPricePriceListBArr(customerId, asOnDate).then((priceListRateArr) => {
				if (priceListRateArr.isSuccess) {
					let priceListMap = new Map<number, PriceListRateResType>([]);
					for (let index = 0; index < priceListRateArr.items!.length; index++) {
						const priceListRate = priceListRateArr.items![index];
						priceListMap.set(priceListRate.productId, priceListRate);
					}

					setCustomerPriceListBArr(priceListRateArr.items!);
					setCustomerPriceListBMap(priceListMap);
				}
			});
		}
	}

	const isValid = (): boolean => {

		if (date == null) {
			setMessageDialogMessage("Select date");
			setShowMessageDialog(true);
			return false;
		}

		if (acctForCustomer == null) {
			setMessageDialogMessage("Select customer");
			setShowMessageDialog(true);
			return false;
		}

		if (estimateItems == null || estimateItems.length == 0) {
			setMessageDialogMessage("Add items");
			setShowMessageDialog(true);
			return false;
		}

		return true;
	}

	return (
		<>
			<div className="container-fluid py-3 flex-grow-1 overflow-auto">
				<form id="mainForm" onSubmit={(event) => handleClickSave(event)}>
					<div className="row col-sm-6">
						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="txtNo" className="col-form-label col-form-label-sm">No.</label>
							</div>
							<div className="col-sm-10">
								<input
									id="txtNo"
									type="text"
									className="form-control form-control-sm"
									value={noStr}
									onChange={(e) => setNoStr(e.target.value)}
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="dpDate" className="col-form-label col-form-label-sm">Date</label>
							</div>
							<div className="col-sm-10">
								<DatePicker
									id="dpDate"
									dateFormat="dd-MM-yyyy"
									selected={date}
									onChange={(e) => handleDateChange(e)}
									className="form-control form-control-sm"
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="txtCustomer" className="col-form-label col-form-label-sm">Customer</label>
							</div>
							<div className="col-sm-10">
								<div className="input-group">
									<input
										id="txtCustomer"
										type="text"
										className="form-control form-control-sm"
										value={acctForCustomer?.name ?? ""}
										readOnly={true}
										tabIndex={-1}
									/>
									<button
										className="btn btn-outline-secondary" type="button"
										onClick={(e) => setShowCustomerDialog(true)}
										ref={btnBrowseCustomerRef}
									>...</button>
								</div>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="txtAddress" className="col-form-label col-form-label-sm">Address</label>
							</div>
							<div className="col-sm-10">
								<textarea
									id="txtAddress"
									className="form-control form-control-sm"
									rows={3}
									value={acctForCustomer?.address ?? ""}
									readOnly={true}
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="txtCity" className="col-form-label col-form-label-sm">City</label>
							</div>
							<div className="col-sm-10">
								<input
									id="txtCity"
									type="text"
									className="form-control form-control-sm"
									value={acctForCustomer?.city ?? ""}
									readOnly={true}
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label className="col-form-label col-form-label-sm">State</label>
							</div>
							<div className="col-sm-10">
								<input
									type="text"
									className="form-control form-control-sm"
									value={acctForCustomer?.stateName}
									readOnly={true}
								/>
							</div>
						</div>



						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="cmbCompanyProfile" className="col-form-label col-form-label-sm">Company Profile</label>
							</div>
							<div className="col-sm-10">
								<DownshiftSelect
									inputId={"cmbCompanyProfile"}
									value={companyProfileId == null || companyProfileListResMap?.get(companyProfileId) == null ? null : CompanyProfileHelper.getCompanyProfileListResForDd(companyProfileListResMap.get(companyProfileId)!)}
									onChange={setCompanyProfileId}
									options={companyProfileListResArrForDd ?? []}
									isLoading={false}
									isDisabled={false}
									placeholder=""
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="cmbTransporter" className="col-form-label col-form-label-sm">Transporter</label>
							</div>
							<div className="col-sm-10">
								<DownshiftSelect
									inputId={"cmbTransporter"}
									value={transporterId == null || transporterListResMap?.get(transporterId) == null ? null : TransporterHelper.getTransporterListResForDd(transporterListResMap.get(transporterId)!)}
									onChange={setTransporterId}
									options={transporterListResArrForDd ?? []}
									isLoading={false}
									isDisabled={false}
									placeholder=""
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="cmbStatusId" className="col-form-label col-form-label-sm">Status</label>
							</div>
							<div className="col-sm-10">
								<DownshiftSelect
									inputId={"cmbStatusId"}
									value={statusId == null || estimateStatusListResMap?.get(statusId) == null ? null : EstimateStatusHelper.getEstimateStatusListResForDd(estimateStatusListResMap.get(statusId)!)}
									onChange={setStatusId}
									options={estimateStatusListResArrForDd ?? []}
									isLoading={false}
									isDisabled={false}
									placeholder=""
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="txtLockStockForDays" className="col-form-label col-form-label-sm">Lock Stock for Days</label>
							</div>
							<div className="col-sm-10">
								<input
									id="txtLockStockForDays"
									type="text"
									className="form-control form-control-sm"
									onChange={(e) => setLockStockForDaysStr(e.target.value)}
									value={lockStockForDaysStr}
								/>
							</div>
						</div>

					</div>

					<div className="row mb-2">
						<div className="col-md-2">
							&nbsp;
						</div>
						<div className="col-md-3">
							Limit
						</div>
						<div className="col-md-3">
							Current Status
						</div>
						<div className="col-md-3">
							Balance
						</div>
						<div className="col-md-3">
							&nbsp;
						</div>
					</div>
					<div className="row mb-2">
						<div className="col-md-2">
							Credit Days
						</div>
						<div className="col-md-3">
							<input
								type="text"
								className="form-control form-control-sm"
								value={creditDaysLimit}
								readOnly={true}
								tabIndex={-1}
							/>
						</div>
						<div className="col-md-3">
							<input
								type="text"
								className="form-control form-control-sm"
								value={creditDaysCurrentStatus}
								readOnly={true}
								tabIndex={-1}
							/>
						</div>
						<div className="col-md-3">
							<input
								type="text"
								className="form-control form-control-sm"
								value={overdueBal}
								readOnly={true}
								tabIndex={-1}
							/>
						</div>
						<div className="col-md-1">
							{isCreditDaysRight ? "" : "X"}
						</div>
					</div>
					<div className="row mb-2">
						<div className="col-md-2">
							Credit Amount
						</div>
						<div className="col-md-3">
							<input
								type="text"
								className="form-control form-control-sm"
								value={creditAmt}
								readOnly={true}
								tabIndex={-1}
							/>
						</div>
						<div className="col-md-3">
							<input
								type="text"
								className="form-control form-control-sm"
								value={currentBal}
								readOnly={true}
								tabIndex={-1}
							/>
						</div>
						<div className="col-md-3">
							<input
								type="text"
								className="form-control form-control-sm"
								value={currentBalDiff}
								readOnly={true}
								tabIndex={-1}
							/>
						</div>
						<div className="col-md-1">
							{isCreditAmtRight ? "" : "X"}
						</div>
					</div>

					<Tabs
						defaultActiveKey="tab-item-details"
						id="uncontrolled-tab-example"
						className="mb-3"
					>
						<Tab eventKey="tab-item-details" title="Item Details">

							<AddEstimateItemsSv
								products={products ?? []}
								productsMap={productsMap ?? new Map()}
								productsForDd={productsForDd ?? []}
								units={units ?? []}
								unitsMap={unitsMap ?? new Map()}
								taxMap={taxListResMap ?? new Map()}
								taxListResMap={taxListResMap ?? new Map()}
								taxCatMap={taxCatMap ?? new Map()}
								handleAddItem={handleAddItem}
								handleUpdateItem={handleUpdateItem}
								handleDeleteItem={handleDeleteItem}
								customerPriceListAMap={customerPriceListAMap}
								customerPriceListBMap={customerPriceListBMap}
								addEstimateItems={estimateItems}
							/>
						</Tab>
						<Tab eventKey="tab-pending-items" title="Pending Items">
							Tab content for Profile
						</Tab>
					</Tabs>

					<hr />

					<div className="d-flex mb-3 g-3 justify-content-end">
						<div className="col-auto g-3">
							<label className="col-form-label col-form-label-sm">Total</label>
						</div>
						<div className="col-auto">
							<input
								value={EstimateHelper.getSumAmtAfterItemDisc(estimateItems)}
								type="text"
								className="form-control form-control-sm"
								readOnly={true}
							/>
						</div>
					</div>

					<hr />

					<AddPusaOthChOnItemSv
						vouOthChOnItemResMap={vouOthChOnItemResMap ?? new Map()}
						vouOthChOnItemResArrForDd={vouOthChOnItemResArrForDd ?? []}
						vouOthChOnItemArr={estimateOthChOnItemArr}
						handleOthChOnItemChange={handleOthChOnItemChange}
					/>

					<hr />

					<div className="d-flex mb-3 g-3 justify-content-end">
						<div className="col-auto g-3">
							<label className="col-form-label col-form-label-sm">Total</label>
						</div>
						<div className="col-auto">
							<input
								value={EstimateHelper.getSumItemTaxableAmt(estimateItems)}
								type="text"
								className="form-control form-control-sm"
								readOnly={true}
							/>
						</div>
					</div>

					<hr />

					<AddPusaTaxSv
						isAddTax={isAddTax}
						setIsAddTax={setIsAddTax}
						isIntra={isIntra}
						taxAmtContainer={EstimateHelper.getTaxSum(estimateItems)}
					/>

					<hr />

					<AddPusaOthChNonTaxableSv
						vouOthChNonTaxableResMap={vouOthChNonTaxableResMap ?? new Map()}
						vouOthChNonTaxableResArrForDd={vouOthChNonTaxableResArrForDd ?? []}
						vouOthChNonTaxableArr={estimateOthChNonTaxableArr}
						handleOthChNonTaxableChange={handleOthChNonTaxableChange}
					/>

					<hr />

					<AddEstimateTotalSv
						totalAmtContainer={EstimateHelper.getTotalAmt(estimateItems, estimateOthChNonTaxableArr)}
					/>

				</form>

			</div >

			<div className="p-3 bg-body-tertiary">
				<button form="mainForm" type="submit" className="btn btn-primary">Save</button>
			</div>

			<Modal show={showMessageDialog} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Message</Modal.Title>
				</Modal.Header>
				<Modal.Body>{messageDialogMessage}</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						OK
					</Button>
				</Modal.Footer>
			</Modal>


			<CustomerSelectDialog
				showDialog={showCustomerDialog}
				setShowDialog={setShowCustomerDialog}
				accounts={accounts ?? []}
				setAcctForCustomer={setAcctForCustomer}
			/>

		</>
	);
}

export default AddEstimate;
