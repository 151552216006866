import React, { Ref, forwardRef } from "react";
import { ButtonGroup, Dropdown } from "react-bootstrap";
import ListViewOptionType from "../types/ListViewOptionType";

interface ListViewButtonBarProps {
	listViewOptions?: ListViewOptionType[];
	handleClickAdd?: () => void;
	handleClickEdit?: () => void;
	handleClickDelete?: () => void;
	handleClickExport: () => void;
	handleClickPrint?: () => void;
	handleClickOpen?: () => void;
	handleClickFilter: () => void;
	handleClickClearFilter: () => void;
	handleClickColSettings?: () => void;
}

function ListViewButtonBar({
	listViewOptions,
	handleClickAdd,
	handleClickEdit,
	handleClickDelete,
	handleClickExport,
	handleClickPrint,
	handleClickOpen,
	handleClickFilter,
	handleClickClearFilter,
	handleClickColSettings,
}: ListViewButtonBarProps): JSX.Element {

	// The forwardRef is important!!
	// Dropdown needs access to the DOM node in order to position the Menu
	const CustomToggle = forwardRef<HTMLButtonElement, { children: React.ReactNode; onClick: React.MouseEventHandler<HTMLButtonElement> }>(
		({ children, onClick }, ref) => (
			<button
				className="btn btn-secondary px-2"
				type="button"
				ref={ref as Ref<HTMLButtonElement>}
				onClick={(e) => {
					e.preventDefault();
					onClick(e);
				}}
			>
				{children}
			</button>
		)
	);

	return (
		<div className="d-flex pt-2 justify-content-between">
			<div className="ms-2 mb-3 pe-2">
				<p className="m-0 d-inline-block align-middle">
					01-04-2022 to 31-03-2023 | Sunny Electricals | VION White Plate
				</p>
			</div>
			<div className="mb-3 pe-2">

				{handleClickAdd != null ? (
					<button
						type="button"
						className="btn btn-success px-2 me-1"
						onClick={() => handleClickAdd()}
					>
						<i className="fa-solid fa-fw fa-plus"></i>
					</button>
				) : null}

				{handleClickEdit != null ? (
					<button
						type="button"
						className="btn btn-secondary px-2 me-1"
						onClick={() => handleClickEdit()}
					>
						<i className="fa-solid fa-fw fa-pencil"></i>
					</button>
				) : null}
				{handleClickDelete != null ? (
					<button
						type="button"
						className="btn btn-danger px-2 me-1"
						onClick={() => handleClickDelete()}
					>
						<i className="fa-solid fa-fw fa-trash"></i>
					</button>
				) : null}
				{handleClickExport != null ? (
					<button
						type="button"
						className="btn btn-secondary px-2 me-1"
						onClick={() => handleClickExport()}
					>
						<i className="fa-solid fa-fw fa-file-export"></i>
					</button>
				) : null}
				{handleClickPrint != null ? (
					<button
						type="button"
						className="btn btn-secondary px-2 me-1"
						onClick={() => handleClickPrint()}
					>
						<i className="fa-solid fa-fw fa-print"></i>
					</button>
				) : null}
				{handleClickOpen != null ? (
					<button
						type="button"
						className="btn btn-secondary px-2 me-1"
						onClick={() => handleClickOpen()}
					>
						<i className="fa-solid fa-fw fa-folder-open"></i>
					</button>
				) : null}
				<button
					type="button"
					className="btn btn-secondary px-2 me-1"
					onClick={() => handleClickFilter()}
				>
					<i className="fa-solid fa-fw fa-filter"></i>
				</button>

				<button
					type="button"
					className="btn btn-secondary px-2 me-1"
					onClick={() => handleClickClearFilter()}
				>
					<i className="fa-solid fa-fw fa-filter-circle-xmark"></i>
				</button>

				{handleClickColSettings != null ? (
					<button
						type="button"
						className="btn btn-secondary px-2 me-1"
						onClick={() => handleClickColSettings()}
					>
						<i className="fa-solid fa-fw fa-table-cells"></i>
					</button>
				) : null}

				{listViewOptions != null && listViewOptions.length > 0 ? (
					<>
						<Dropdown
							as={ButtonGroup}>

							<Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
								<i className="fa-solid fa-fw fa-ellipsis-vertical"></i>
							</Dropdown.Toggle>

							<Dropdown.Menu>
								{listViewOptions.map((listViewOption, i) => (
									<Dropdown.Item
										key={i}
										onClick={() => listViewOption.myCallbackFunction()}
										href="#">
										{listViewOption.name}
									</Dropdown.Item>
								))}
							</Dropdown.Menu>
						</Dropdown>

					</>
				) : null}
			</div>
		</div>
	);
}

export default ListViewButtonBar;
