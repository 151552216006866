export default class RptCommVouLedgerSm {

	dateFrom: Date | undefined | null;
	dateTo: Date | undefined | null;

	agentId: number | undefined | null;
	customerId: number | undefined | null;

	doSet(searchModel: RptCommVouLedgerSm): void {
		this.dateFrom = searchModel.dateFrom;
		this.dateTo = searchModel.dateTo;

		this.agentId = searchModel.agentId;
		this.customerId = searchModel.customerId;
	}

	reset(): void {
		this.dateFrom = undefined;
		this.dateTo = undefined;

		this.agentId = undefined;
		this.customerId = undefined;
	}

	isBlank(): boolean {
		return this.dateFrom === undefined
			&& this.dateTo === undefined
			&& this.agentId === undefined
			&& this.customerId === undefined
			;
	}

}
