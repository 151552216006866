import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import RptPurchaseReturnByDaySm from "../../types/sm/RptPurchaseReturnByDaySm";
import MessageDialog from "../dialogs/MessageDialog";

interface RptPurchaseReturnByDaySdPropsType {
	showModal: boolean;
	setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
	searchModel: RptPurchaseReturnByDaySm;
	processOnSuccess: (searchModel: RptPurchaseReturnByDaySm) => void;
}

function RptPurchaseReturnByDaySd({
	showModal,
	setShowModal,
	searchModel,
	processOnSuccess
}: RptPurchaseReturnByDaySdPropsType) {

	// const [noStr, setNoStr] = useState<string>(searchModel.no?.toString() ?? "");
	//
	const [messageDialogMessage, setMessageDialogMessage] = useState("");
	const [showMessageDialog, setShowMessageDialog] = useState(false);

	useEffect(() => {
		async function process() {
		}
		process();
	}, []);

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		handleClickSearch();
	}

	const handleClickSearch = () => {
		if (!isValid) {
			return;
		}

		let searchModel = new RptPurchaseReturnByDaySm();

		//searchModel.dateFrom = dateFrom;

		setShowModal(false);

		processOnSuccess(searchModel);
	}

	const handleClickClose = () => {
		setShowModal(false);
	};

	const isValid = (): boolean => {

		// if (noStr != null && noStr !== "" && !StringHelper2.isInt(noStr)) {
		// 	setMessageDialogMessage("Invalid number");
		// 	setShowMessageDialog(true);
		// 	return false;
		// }

		return true;
	}

	return (
		<>
			<Modal show={showModal} onHide={handleClickClose}>
				<Modal.Header closeButton>
					<Modal.Title>Filter</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>

					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClickClose}>
						Cancel
					</Button>
					<Button variant="primary" type="submit" onClick={handleClickSearch}>
						SAVE
					</Button>
				</Modal.Footer>
			</Modal>

			<MessageDialog
				showDialog={showMessageDialog}
				modalMessage={messageDialogMessage}
				setShowDialog={setShowMessageDialog} />
		</>
	);
}

export default RptPurchaseReturnByDaySd;

