import { useEffect, useState } from "react";
import { useGvContext } from "../../GvContext";
import { BASE_URL, TOKEN } from "../../config";
import strFormat from "../../helpers/StringHelper";
import RowType from "../../types/RowType";
import TransporterResType from "../../types/res/TransporterResType";
import ListViewButtonBar from "../ListViewButtonBar";
import ListViewTable from "../ListViewTable";
import useConfirm from "../dialogs/ConfirmDialog";

function RptLedger(): JSX.Element {

	const [rows, setRows] = useState<RowType[]>([]);
	const [selectedId, setSelectedId] = useState<number | null>(null);

	const [messageDialogMessage, setMessageDialogMessage] = useState("");
	const [showMessageDialog, setShowMessageDialog] = useState(false);

	const [showSd, setShowSd] = useState<boolean>(false);
	
	const confirm = useConfirm();
	const gv = useGvContext();

	const colNames = [
		"Sr. No",
		"Date",
		"Entry Type",
		"Particulars",
		"No",
		"Amount (Dr)",
		"Amount (Cr)",
		"Balance",
	];

	useEffect(() => {
		async function process() {
			// loadData();
		}
		process();
	}, []);

	const handleClickEdit = () => {
		if (selectedId === null) {
			setMessageDialogMessage("Select atleast one row");
			setShowMessageDialog(true);
			return;
		}
	};

	const handleClickExport = () => {
		console.log("handleClickExport");
	};

	const handleClickPrint = () => {
		console.log("handleClickPrint");
	};

	const handleClickOpen = () => {
		console.log("handleClickOpen");
	};

	const handleClickFilter = () => {
		setShowSd(true);
	};

	const handleClickClearFilter = () => {
		console.log("handleClickClearFilter");
	};

	const handleClickColSettings = () => {
		console.log("handleClickColSettings");
	};

	return (
		<>
			<ListViewButtonBar
				handleClickEdit={handleClickEdit}
				handleClickExport={handleClickExport}
				handleClickPrint={handleClickPrint}
				handleClickOpen={handleClickOpen}
				handleClickFilter={handleClickFilter}
				handleClickClearFilter={handleClickClearFilter}
				handleClickColSettings={handleClickColSettings}
			/>
			<ListViewTable
				colNames={colNames}
				rows={rows}
				setSelectedId={setSelectedId}
			/>

		</>
	);
}

export default RptLedger;
