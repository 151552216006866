export default class ListPurchaseOrderSm {

	no: number | undefined;
	dateFrom: Date | undefined | null;
	dateTo: Date | undefined | null;
	supplierId: number | undefined;
	supplierName: string | undefined;
	city: string | undefined;
	stateId: number | undefined;
	state: string | undefined;
	agentId: number | undefined;
	agentName: string | undefined;
	note: string | undefined;

	constructor(
		dateFrom?: Date | undefined | null,
		dateTo?: Date | undefined | null
	) {
		this.dateFrom = dateFrom;
		this.dateTo = dateTo;
	}

	reset(): void {
		this.no = undefined;
		this.dateFrom = undefined;
		this.dateTo = undefined;
		this.supplierId = undefined;
		this.supplierName = undefined;
		this.city = undefined;
		this.stateId = undefined;
		this.state = undefined;
		this.agentId = undefined;
		this.agentName = undefined;
		this.note = undefined;
	}

	isBlank(): boolean {
		return this.no === undefined
			&& this.dateFrom === undefined
			&& this.dateTo === undefined
			&& this.supplierId === undefined
			&& this.supplierName === undefined
			&& this.city === undefined
			&& this.stateId === undefined
			&& this.state === undefined
			&& this.agentId === undefined
			&& this.agentName === undefined
			&& this.note === undefined;
	}
}
