import RateTypeEnum from "../enums/RateTypeEnum";
import PurchaseReturnItemType from "../types/PurchaseReturnItemType";
import VouOthChOnItemType from "../types/VouOthChOnItemType";
import ProdForEsimateLdto from "../types/res/list/ProdForEsimateLdto";
import TaxCatLdto from "../types/res/list/TaxCatLdto";
import TaxLdto from "../types/res/list/TaxLdto";
import UnitLdto from "../types/res/list/UnitLdto";
import TaxHelper from "./TaxHelper";

export default class PurchaseReturnItemHelper {

	static calItems(
		isAddTax: boolean,
		isIntra: boolean,
		taxMap: Map<number, TaxLdto>,
		taxCatMap: Map<number, TaxCatLdto>,
		unitMap: Map<number, UnitLdto>,
		productMap: Map<number, ProdForEsimateLdto>,
		purchaseReturnItems: PurchaseReturnItemType[],
		purchaseReturnOthChOnItemArr: VouOthChOnItemType[],
	) {
		if (purchaseReturnItems !== undefined && purchaseReturnItems !== null && purchaseReturnItems.length > 0) {

			let sumAmtAfterDisc = 0;
			for (let i = 0; i < purchaseReturnItems.length; i++) {
				let purchaseReturnItem = purchaseReturnItems[i];

				let product = productMap.get(purchaseReturnItem.productId);

				let taxId: number;

				if (product?.taxCatId === null) {
					taxId = product.taxId;
				}
				else {
					let taxCat = taxCatMap.get(product?.taxCatId!);
					taxId = taxCat?.taxId!;
				}
				purchaseReturnItem.derivedTaxId = taxId;

				let tax = taxMap.get(purchaseReturnItem.derivedTaxId);

				purchaseReturnItem.derivedQxr = purchaseReturnItem.qty * purchaseReturnItem.rate;

				if (purchaseReturnItem.discRate === undefined || purchaseReturnItem.discRate === null) {
					purchaseReturnItem.derivedDiscA = 0;
				}
				else {
					switch (purchaseReturnItem.discRateTypeId) {
						case RateTypeEnum.FLAT.id:
							purchaseReturnItem.derivedDiscA = purchaseReturnItem.discRate * purchaseReturnItem.qty;
							break;
						case RateTypeEnum.PERCENT.id:
							purchaseReturnItem.derivedDiscA = purchaseReturnItem.discRate * purchaseReturnItem.derivedQxr / 100;
							break;
						default:
							break;
					}
				}

				purchaseReturnItem.derivedAmtAfterDisc = purchaseReturnItem.derivedQxr - purchaseReturnItem.derivedDiscA!;

				purchaseReturnItem.derivedTaxableAmt = purchaseReturnItem.derivedAmtAfterDisc;

				sumAmtAfterDisc = sumAmtAfterDisc + purchaseReturnItem.derivedAmtAfterDisc;

				if (isAddTax) {
					purchaseReturnItem.derivedTaxId = tax?.id;
					purchaseReturnItem.derivedTaxRate = tax?.igst;
				}
			}

			if (purchaseReturnOthChOnItemArr !== undefined && purchaseReturnOthChOnItemArr !== null && purchaseReturnOthChOnItemArr.length > 0) {
				for (let i = 0; i < purchaseReturnOthChOnItemArr.length; i++) {
					let vouOthChOnItem = purchaseReturnOthChOnItemArr[i];

					vouOthChOnItem.derivedAmt = 0;

					for (let j = 0; j < purchaseReturnItems.length; j++) {
						let purchaseReturnItem = purchaseReturnItems[j];
						this.applyOthChOnItem(sumAmtAfterDisc, purchaseReturnItem, vouOthChOnItem);
					}
					if (vouOthChOnItem.rateTypeId === RateTypeEnum.FLAT.id) {
						vouOthChOnItem.derivedAmt = vouOthChOnItem.rate;
					}
				}
			}

			if (isAddTax) {
				for (let i = 0; i < purchaseReturnItems.length; i++) {
					let purchaseReturnItem = purchaseReturnItems[i];

					let taxAmtContainer = TaxHelper.calTaxAmt(
						taxMap.get(purchaseReturnItem.derivedTaxId!)!,
						purchaseReturnItem.derivedTaxRate!,
						isIntra,
						purchaseReturnItem.derivedTaxableAmt!
					)

					purchaseReturnItem.derivedCgstA = taxAmtContainer.cgstA;
					purchaseReturnItem.derivedSgstA = taxAmtContainer.sgstA;
					purchaseReturnItem.derivedIgstA = taxAmtContainer.igstA;
					purchaseReturnItem.derivedTaxAmt = taxAmtContainer.cgstA + taxAmtContainer.sgstA + taxAmtContainer.igstA;
					purchaseReturnItem.derivedAmt = taxAmtContainer.amtWithTax;
				}
			}



		}

		return purchaseReturnItems;
	}
static applyOthChOnItem(sumAmtAftDisc: number, purchaseReturnItem: PurchaseReturnItemType, vouOthChOnItem: VouOthChOnItemType) {

		let itemOthChAmt = 0;

		switch (vouOthChOnItem.rateTypeId) {
			case RateTypeEnum.FLAT.id:
				itemOthChAmt = vouOthChOnItem.rate
					* purchaseReturnItem.derivedAmtAfterDisc!
					/ sumAmtAftDisc;
				break;
			case RateTypeEnum.PERCENT.id:
				itemOthChAmt = purchaseReturnItem.derivedTaxableAmt!
					* vouOthChOnItem.rate
					/ 100;

				vouOthChOnItem.derivedAmt = vouOthChOnItem.derivedAmt! + itemOthChAmt;
				break;
		}

		purchaseReturnItem.derivedTaxableAmt = purchaseReturnItem.derivedTaxableAmt! + itemOthChAmt;
	}

}