export default class RptSalesOrderPendingSummByCustomerEnumCol {

	public static readonly CUSTOMER_NAME = new RptSalesOrderPendingSummByCustomerEnumCol("customer_name");
	public static readonly PROD_CNT = new RptSalesOrderPendingSummByCustomerEnumCol("prod_cnt");
	public static readonly SUM_QTY = new RptSalesOrderPendingSummByCustomerEnumCol("sum_qty");

	private constructor(
		public readonly key: string) {
	}

	static get values(): RptSalesOrderPendingSummByCustomerEnumCol[] {
		return [
			this.CUSTOMER_NAME,
			this.PROD_CNT,
			this.SUM_QTY,
		];
	}

	static getByKey(key: string): RptSalesOrderPendingSummByCustomerEnumCol | null {
		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.key === key) {
				return element;
			}
		}
		return null;
	}
}
