export default class VoucherTypeByDrCrEnum {
	public static readonly DR = new VoucherTypeByDrCrEnum(1, "Cleared");
	public static readonly CR = new VoucherTypeByDrCrEnum(2, "Cleared");
	public static readonly DR_CR = new VoucherTypeByDrCrEnum(3, "Cleared");

	private constructor(
		public readonly id: number,
		public readonly name: string) {
	}
}
