export default class RptCurrentStockSm {

	reportBy: string | undefined | null;
	catId: number | undefined | null;
	cat: string | undefined | null;
	prodId: number | undefined | null;
	prod: string | undefined | null;
	dateFrom: Date | undefined | null;
	dateTo: Date | undefined | null;

	reset(): void {
		this.reportBy = undefined;
		this.catId = undefined;
		this.cat = undefined;
		this.prodId = undefined;
		this.prod = undefined;
		this.dateFrom = undefined;
		this.dateTo = undefined;
	}

	isBlank(): boolean {
		return this.reportBy === undefined
			&& this.catId === undefined
			&& this.cat === undefined
			&& this.prodId === undefined
			&& this.prod === undefined
			&& this.dateFrom === undefined
			&& this.dateTo === undefined;
	}
}
