import { useState } from "react";
import DropDownItemType from "../../types/DropDownItemType";
import PurchaseReturnItemType from "../../types/PurchaseReturnItemType";
import UnitLdto from "../../types/res/list/UnitLdto";
import ProdForEsimateLdto from "../../types/res/list/ProdForEsimateLdto";
import TaxCatLdto from "../../types/res/list/TaxCatLdto";
import TaxLdto from "../../types/res/list/TaxLdto";
import AddPurchaseReturnItemFormSv from "./AddPurchaseReturnItemFormSv";
import AddPurchaseReturnItemsTable from "./AddPurchaseReturnItemsTable";


interface AddPurchaseReturnItemsSvProps {
	products: ProdForEsimateLdto[];
	productsMap: Map<number, ProdForEsimateLdto>;
	productsForDd: DropDownItemType[];
	units: UnitLdto[];
	unitsMap: Map<number, UnitLdto>;
	taxMap: Map<number, TaxLdto>;
	taxListResMap: Map<number, TaxLdto>;
	taxCatMap: Map<number, TaxCatLdto>;
	handleAddItem: (AddPurchaseReturnItem: PurchaseReturnItemType) => void;
	handleUpdateItem: (index: number, purchaseReturnItem: PurchaseReturnItemType) => void;
	handleDeleteItem: (index: number) => void;
	addPurchaseReturnItems: Array<PurchaseReturnItemType>;
	id?: number;
}

function AddPurchaseReturnItemsSv({
	products,
	productsMap,
	productsForDd,
	units,
	unitsMap,
	taxMap,
	taxListResMap,
	taxCatMap,
	handleAddItem,
	handleUpdateItem,
	handleDeleteItem,
	addPurchaseReturnItems,
	id
}: AddPurchaseReturnItemsSvProps) {

	const [selectedIndex, setSelectedIndex] = useState<number>(-1);
	const [modeAdd, setModeAdd] = useState<boolean>(true);
	const [purchaseReturnItemToEdit, setPurchaseReturnItemToEdit] = useState<PurchaseReturnItemType | null>(null);

	const handleItemChange = (purchaseReturnItem: PurchaseReturnItemType) => {
		if (modeAdd) {
			handleAddItem(purchaseReturnItem);
		}
		else {
			handleUpdateItem(selectedIndex, purchaseReturnItem);
		}
		setModeAdd(true);
	}

	const handleClickEdit = (index: number) => {
		setModeAdd(false);
		setSelectedIndex(index);
		setPurchaseReturnItemToEdit(addPurchaseReturnItems[index]);
	}

	return (
		<>
			<AddPurchaseReturnItemFormSv
				products={products}
				productsForDd={productsForDd}
				productsMap={productsMap}
				units={units}
				unitsMap={unitsMap}
				taxMap={taxListResMap}
				taxCatMap={taxCatMap}
				handleItemChange={handleItemChange}
				purchaseReturnItemToEdit={purchaseReturnItemToEdit}
			/>

			<AddPurchaseReturnItemsTable
				taxMap={taxListResMap}
				taxCatMap={taxCatMap}
				addPurchaseReturnItems={addPurchaseReturnItems}
				productsMap={productsMap}
				unitsMap={unitsMap}
				handleClickEditParent={handleClickEdit}
				handleClickDeleteParent={handleDeleteItem}
			/>

		</>
	);
}

export default AddPurchaseReturnItemsSv;
