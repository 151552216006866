import { useState } from "react";
import DropDownItemType from "../../types/DropDownItemType";
import PurchaseItemType from "../../types/PurchaseItemType";
import { PriceListRateResType } from "../../types/res/PriceListRateResType";
import UnitLdto from "../../types/res/list/UnitLdto";
import ProdForEsimateLdto from "../../types/res/list/ProdForEsimateLdto";
import TaxCatLdto from "../../types/res/list/TaxCatLdto";
import TaxLdto from "../../types/res/list/TaxLdto";
import AddPurchaseItemFormSv from "./AddPurchaseItemFormSv";
import AddPurchaseItemsTable from "./AddPurchaseItemsTable";


interface AddPurchaseItemsSvProps {
	products: ProdForEsimateLdto[];
	productsMap: Map<number, ProdForEsimateLdto>;
	productsForDd: DropDownItemType[];
	units: UnitLdto[];
	unitsMap: Map<number, UnitLdto>;
	taxMap: Map<number, TaxLdto>;
	taxListResMap: Map<number, TaxLdto>;
	taxCatMap: Map<number, TaxCatLdto>;
	handleAddItem: (AddPurchaseItem: PurchaseItemType) => void;
	handleUpdateItem: (index: number, purchaseItem: PurchaseItemType) => void;
	handleDeleteItem: (index: number) => void;
	supplierPriceListMap: Map<number, PriceListRateResType>;
	addPurchaseItems: Array<PurchaseItemType>;
	id?: number;
}

function AddPurchaseItemsSv({
	products,
	productsMap,
	productsForDd,
	units,
	unitsMap,
	taxMap,
	taxListResMap,
	taxCatMap,
	handleAddItem,
	handleUpdateItem,
	handleDeleteItem,
	supplierPriceListMap,
	addPurchaseItems,
	id
}: AddPurchaseItemsSvProps) {

	const [selectedIndex, setSelectedIndex] = useState<number>(-1);
	const [modeAdd, setModeAdd] = useState<boolean>(true);
	const [purchaseItemToEdit, setPurchaseItemToEdit] = useState<PurchaseItemType | null>(null);

	const handleItemChange = (purchaseItem: PurchaseItemType) => {
		if (modeAdd) {
			handleAddItem(purchaseItem);
		}
		else {
			handleUpdateItem(selectedIndex, purchaseItem);
		}
		setModeAdd(true);
	}

	const handleClickEdit = (index: number) => {
		setModeAdd(false);
		setSelectedIndex(index);
		setPurchaseItemToEdit(addPurchaseItems[index]);
	}

	return (
		<>
			<AddPurchaseItemFormSv
				products={products}
				productsForDd={productsForDd}
				productsMap={productsMap}
				units={units}
				unitsMap={unitsMap}
				taxMap={taxListResMap}
				taxCatMap={taxCatMap}
				handleItemChange={handleItemChange}
				supplierPriceListMap={supplierPriceListMap}
				purchaseItemToEdit={purchaseItemToEdit}
			/>

			<AddPurchaseItemsTable
				taxMap={taxListResMap}
				taxCatMap={taxCatMap}
				addPurchaseItems={addPurchaseItems}
				productsMap={productsMap}
				unitsMap={unitsMap}
				handleClickEditParent={handleClickEdit}
				handleClickDeleteParent={handleDeleteItem}
			/>

		</>
	);
}

export default AddPurchaseItemsSv;
