export default class RptPurchaseOrderLedgerEnumCol {

	public static readonly NO = new RptPurchaseOrderLedgerEnumCol("no");
	public static readonly DATE = new RptPurchaseOrderLedgerEnumCol("date");
	public static readonly VOUCHER_TYPE_ID = new RptPurchaseOrderLedgerEnumCol("voucher_type_id");
	public static readonly SUPPLIER_NAME = new RptPurchaseOrderLedgerEnumCol("supplier_name");
	public static readonly PRODUCT_NAME = new RptPurchaseOrderLedgerEnumCol("product_name");
	public static readonly QTY_ORDER = new RptPurchaseOrderLedgerEnumCol("qty_order");
	public static readonly QTY_PURCHASED = new RptPurchaseOrderLedgerEnumCol("qty_purchased");
	public static readonly QTY_PENDING = new RptPurchaseOrderLedgerEnumCol("qty_pending");

	private constructor(
		public readonly key: string) {
	}

	static get values(): RptPurchaseOrderLedgerEnumCol[] {
		return [
			this.NO,
			this.DATE,
			this.VOUCHER_TYPE_ID,
			this.SUPPLIER_NAME,
			this.PRODUCT_NAME,
			this.QTY_ORDER,
			this.QTY_PURCHASED,
			this.QTY_PENDING,
		];
	}

	static getByKey(key: string): RptPurchaseOrderLedgerEnumCol | null {
		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.key === key) {
				return element;
			}
		}
		return null;
	}
}
