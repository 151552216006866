import { BASE_URL, TOKEN } from "../config";
import ServerNotReachableErrorCommonCudObj from "../default_objects/ServerNotReachableErrorCommonCudObj";
import ServerSideErrorCommonCudObj from "../default_objects/ServerSideErrorCommonCudObj";
import GetProdObjectTypeEnum from "../enums/GetProdObjectTypeEnum";
import ResourceEnum from "../enums/ResourceEnum";
import strFormat from "../helpers/StringHelper";
import { getQueryString } from "../helpers/UrlSearchParamHelper";
import CommonCudType from "../types/CommonCudType";
import CommonGetListType from "../types/CommonGetListType";
import ProductReqType from "../types/req/ProductReqType";
import ErrorResType from "../types/res/ErrorResType";
import ProductResType from "../types/res/ProductResType";
import ProdForEsimateLdto from "../types/res/list/ProdForEsimateLdto";
import ProdLdto from "../types/res/list/ProdLdto";

export default class ProdApi {

	static async getProdLdtos(): Promise<CommonGetListType<ProdLdto>> {
		try {
			const requestOptions = {
				method: "GET",
				headers: {
					"Authorization": "Bearer " + TOKEN.val,
					"Content-Type": "application/json"
				}
			}
			const response = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.PRODUCT.name), requestOptions);

			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as ProdLdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async getProdForEstimate() {

		const requestOptions = {
			method: "GET",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			}
		}
		const res = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.PRODUCT.name)
			+ getQueryString({ returnObjectType: GetProdObjectTypeEnum.PROD_FOR_ESTIMATE.enumName.toUpperCase() }),
			requestOptions);
		const listProduct: ProdForEsimateLdto[] = await res.json();
		return listProduct

	}

	static async createProduct(product: ProductReqType): Promise<CommonCudType> {

		const requestOptions = {
			method: "POST",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(product)
		}

		const res = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.PRODUCT.name), requestOptions);

		if (res.status !== 201) {
			const data: ErrorResType = await res.json();
			return {
				isSuccess: false,
				errorRes: data
			}
		}

		return {
			isSuccess: true,
		}

	}

	static async updateProduct(id: number, product: ProductReqType): Promise<CommonCudType> {

		const requestOptions = {
			method: "PUT",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(product)
		}

		const res = await fetch(strFormat("%s/%s/%s", BASE_URL, ResourceEnum.PRODUCT.name, id), requestOptions);

		if (res.status !== 201) {
			const data: ErrorResType = await res.json();
			return {
				isSuccess: false,
				errorRes: data
			}
		}

		return {
			isSuccess: true,
		}

	}

	static async getProduct(id: number) {

		const requestOptions = {
			method: "GET",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			}
		}

		const res = await fetch(strFormat("%s/%s/%s", BASE_URL, ResourceEnum.PRODUCT.name, id), requestOptions);
		const data: ProductResType = await res.json();
		return data;

	}
}
