export default class RptPurchaseOrderPendingSummBySupplierEnumCol {

	public static readonly SUPPLIER_NAME = new RptPurchaseOrderPendingSummBySupplierEnumCol("supplier_name");
	public static readonly PROD_CNT = new RptPurchaseOrderPendingSummBySupplierEnumCol("prod_cnt");
	public static readonly SUM_QTY = new RptPurchaseOrderPendingSummBySupplierEnumCol("sum_qty");

	private constructor(
		public readonly key: string) {
	}

	static get values(): RptPurchaseOrderPendingSummBySupplierEnumCol[] {
		return [
			this.SUPPLIER_NAME,
			this.PROD_CNT,
			this.SUM_QTY,
		];
	}

	static getByKey(key: string): RptPurchaseOrderPendingSummBySupplierEnumCol | null {
		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.key === key) {
				return element;
			}
		}
		return null;
	}
}
