import RateTypeEnum from "../enums/RateTypeEnum";
import SalesItemType from "../types/SalesItemType";
import TaxAmtContainerType from "../types/TaxAmtContainerType";
import TotalAmtContainerType from "../types/TotalAmtContainerType";
import VouOthChNonTaxableType from "../types/VouOthChNonTaxableType";

export default class SalesHelper {

	static getSumAmtAfterItemDisc(
		salesItems: SalesItemType[],
	) {
		if (salesItems !== undefined && salesItems !== null && salesItems.length > 0) {

			let sumAmtAfterDisc = 0;
			for (let i = 0; i < salesItems.length; i++) {
				let salesItem = salesItems[i];

				sumAmtAfterDisc = sumAmtAfterDisc + salesItem.derivedAmtAfterDisc!;
			}
			return sumAmtAfterDisc;
		}
		return 0;
	}

	static getSumItemTaxableAmt(
		salesItemArr: SalesItemType[],
	) {
		if (salesItemArr !== undefined && salesItemArr !== null && salesItemArr.length > 0) {

			let sumItemTaxableAmt = 0;
			for (let i = 0; i < salesItemArr.length; i++) {
				let salesItem = salesItemArr[i];

				sumItemTaxableAmt = sumItemTaxableAmt + salesItem.derivedTaxableAmt!;
			}
			return sumItemTaxableAmt;
		}
		return 0;
	}

	public static getSumAmtAfterTax(
		salesItemArr: SalesItemType[]
	) {

		let sumAmtAfterTax = 0;

		if (salesItemArr !== undefined && salesItemArr !== null && salesItemArr.length > 0) {
			for (let i = 0; i < salesItemArr.length; i++) {
				let salesItem = salesItemArr[i];

				sumAmtAfterTax = sumAmtAfterTax + salesItem.derivedAmt!;
			}
		}

		return sumAmtAfterTax;
	}

	public static getTaxSum(
		salesItemArr: SalesItemType[]
	): TaxAmtContainerType {

		let cgstA = 0;
		let sgstA = 0;
		let igstA = 0;

		if (salesItemArr !== undefined && salesItemArr !== null && salesItemArr.length > 0) {

			for (let i = 0; i < salesItemArr.length; i++) {
				let salesItem = salesItemArr[i];

				cgstA = cgstA + salesItem.derivedCgstA!;
				sgstA = sgstA + salesItem.derivedSgstA!;
				igstA = igstA + salesItem.derivedIgstA!;
			}
		}

		return {
			cgstA: cgstA,
			sgstA: sgstA,
			igstA: igstA,
			amtWithTax: 0,
		};
	}

	public static calOthChNonTax(
		salesItemArr: SalesItemType[],
		salesOthChNonTaxableArr: VouOthChNonTaxableType[]
	) {
		let sumAmtAfterTax = this.getSumAmtAfterTax(salesItemArr);

		for (let i = 0; i < salesOthChNonTaxableArr.length; i++) {
			let salesOthChNonTaxable = salesOthChNonTaxableArr[i];

			if (salesOthChNonTaxable.rate == null) {
				salesOthChNonTaxable.derivedAmt = 0;
			}
			else {
				if (salesOthChNonTaxable.rateTypeId === RateTypeEnum.FLAT.id) {
					salesOthChNonTaxable.derivedAmt = salesOthChNonTaxable.rate;
				}
				else {
					if (salesOthChNonTaxable.rateTypeId === RateTypeEnum.PERCENT.id) {
						salesOthChNonTaxable.derivedAmt = sumAmtAfterTax * salesOthChNonTaxable.rate / 100;
					}
				}
			}
		}
	}

	public static getTotalAmt(
		salesItemArr: SalesItemType[],
		salesOthChNonTaxableArr: VouOthChNonTaxableType[]
	): TotalAmtContainerType {

		let totalAmt = 0;
		let roundOff = 0;
		let amtPayable = 0;


		totalAmt = 0;
		//
		for (let i = 0; i < salesItemArr.length; i++) {
			let salesItem = salesItemArr[i];
			{
				totalAmt = totalAmt + salesItem.derivedAmt!;
			}
		}

		for (let i = 0; i < salesOthChNonTaxableArr.length; i++) {
			let salesOthChNonTaxable = salesOthChNonTaxableArr[i];
			{
				totalAmt = totalAmt + salesOthChNonTaxable.derivedAmt!;
			}
		}

		amtPayable = totalAmt;
		roundOff = amtPayable - totalAmt;


		return {
			totalAmt: totalAmt,
			roundOff: roundOff,
			amtPayable: amtPayable,
		};
	}

}