import { useState } from "react";
import MessageDialog from "../dialogs/MessageDialog";
import PriceListTypeEnum from "../../enums/PriceListTypeEnum";
import EstimateItemType from "../../types/EstimateItemType";
import UnitLdto from "../../types/res/list/UnitLdto";
import ProdForEsimateLdto from "../../types/res/list/ProdForEsimateLdto";
import TaxCatLdto from "../../types/res/list/TaxCatLdto";
import TaxLdto from "../../types/res/list/TaxLdto";
import useConfirm from "../dialogs/ConfirmDialog";

interface AddEstimateItemsTableProps {
	taxMap: Map<number, TaxLdto>;
	taxCatMap: Map<number, TaxCatLdto>;
	productsMap: Map<number, ProdForEsimateLdto>;
	unitsMap: Map<number, UnitLdto>;
	addEstimateItems: Array<EstimateItemType>;
	handleClickEditParent: (index: number) => void;
	handleClickDeleteParent: (index: number) => void;
}

function AddEstimateItemsTable({
	taxMap,
	taxCatMap,
	productsMap,
	unitsMap,
	addEstimateItems,
	handleClickEditParent,
	handleClickDeleteParent
}: AddEstimateItemsTableProps) {

	const [selectedIndex, setSelectedIndex] = useState<number>(-1);
	//
	const [messageDialogMessage, setMessageDialogMessage] = useState("");
	const [showMessageDialog, setShowMessageDialog] = useState(false);

	const confirm = useConfirm();

	const handleClickEdit = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {

		event.preventDefault();

		if (
			selectedIndex == null
			|| selectedIndex < 0
			|| selectedIndex >= addEstimateItems.length
		) {
			setMessageDialogMessage("Select a row to edit");
			setShowMessageDialog(true);
			return false;
		}

		handleClickEditParent(selectedIndex);

	}

	const handleClickDelete = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {

		event.preventDefault();

		if (
			selectedIndex == null
			|| selectedIndex < 0
			|| selectedIndex >= addEstimateItems.length
		) {
			setMessageDialogMessage("Select a row to delete");
			setShowMessageDialog(true);
			return false;
		}

		let choice = await confirm!({
			message: "Are you sure you want to delete?",
			title: "Delete confirmation"
		});

		if (choice === undefined || choice === null || choice === false)
			return;

		handleClickDeleteParent(selectedIndex);

	}

	return (
		<>
			<div className="flex-grow-1 mb-2">
				<button
					type="button"
					className="btn btn-primary me-2"
					onClick={handleClickEdit}
				>
					Edit
				</button>
				<button
					type="button"
					className="btn btn-primary me-2"
					onClick={handleClickDelete}
				>
					Delete
				</button>
				<button type="button" className="btn btn-primary me-2">Up</button>
				<button type="button" className="btn btn-primary me-2">Down</button>
			</div>

			<div className="flex-grow-1 mb-2 table-responsive text-nowrap"
				style={{
					maxHeight: "500px"
				}}
			>
				<table
					id="navigate"
					className="table table-hover table-bordered table-sm overflow-x-auto m-0 fixTableHead"
				>
					<thead className="bg-body-tertiary">
						<tr>
							<th scope="col">
								<input
									className="form-check-input"
									type="checkbox"
									id="chkCheckAll"
								/>
							</th>
							<th scope="col">Sr. No.</th>
							<th scope="col">Product</th>
							<th scope="col">Desc</th>
							<th scope="col">Order Qty</th>
							<th scope="col">Order Unit</th>
							<th scope="col">Qty</th>
							<th scope="col">Unit</th>
							<th scope="col">Is Pending?</th>
							<th scope="col">Price List Type</th>
							<th scope="col">Rate</th>
							<th scope="col">Disc.</th>
							<th scope="col">Amt</th>
							<th scope="col">Tax</th>
							<th scope="col">Tax Amt</th>
							<th scope="col">Total</th>
						</tr>
					</thead>
					<tbody className="">
						{addEstimateItems.map((addEstimateItem, i) => (
							<tr key={i} onClick={(e) => setSelectedIndex(i)}>
								<td>
									<div>
										<input
											className="form-check-input"
											type="checkbox"
											value=""
										/>
									</div>
								</td>
								<td tabIndex={0}>
									<div>{i + 1}</div>
								</td>
								<td tabIndex={0}><div>{productsMap.get(addEstimateItem.productId)?.name}</div></td>
								<td tabIndex={0}><div>{addEstimateItem.desc}</div></td>
								<td tabIndex={0}><div>{addEstimateItem.orderQty.toLocaleString('en-IN')}</div></td>
								<td tabIndex={0}><div>{unitsMap.get(addEstimateItem.orderUnitId)?.name}</div></td>
								<td tabIndex={0}><div>{addEstimateItem.derivedQty!.toLocaleString('en-IN')}</div></td>
								<td tabIndex={0}><div>{unitsMap.get(productsMap.get(addEstimateItem.productId)?.unitId!)?.name}</div></td>
								<td tabIndex={0}><div>{addEstimateItem.isPending ? "PENDING" : null}</div></td>
								<td tabIndex={0}><div>{PriceListTypeEnum.getById(addEstimateItem.priceListTypeId)?.name}</div></td>
								<td tabIndex={0}><div>{addEstimateItem.derivedRate}</div></td>
								<td tabIndex={0}><div>{
									addEstimateItem.discRate !== undefined
										&& addEstimateItem.discRate !== null ?
										addEstimateItem.discRate.toLocaleString('en-IN') + " %"
										:
										""
								}</div></td>
								<td tabIndex={0}><div>{addEstimateItem.derivedAmtAfterDisc?.toLocaleString('en-IN')}</div></td>
								<td tabIndex={0}><div>{addEstimateItem.derivedTaxId !== undefined && addEstimateItem.derivedTaxId !== null ? taxMap.get(addEstimateItem.derivedTaxId)?.name : ""}</div></td>
								<td tabIndex={0}><div>{addEstimateItem.derivedTaxAmt?.toLocaleString('en-IN')}</div></td>
								<td tabIndex={0}><div>{addEstimateItem.derivedAmt?.toLocaleString('en-IN')}</div></td>
							</tr>
						))}
					</tbody>
					<tfoot className="bg-body-tertiary">
						<tr>
							<th scope="row">Total</th>
							<th>{addEstimateItems.length}</th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
						</tr>
					</tfoot>
				</table>
			</div >

			<MessageDialog
				showDialog={showMessageDialog}
				modalMessage={messageDialogMessage}
				setShowDialog={setShowMessageDialog} />
		</>
	);
}

export default AddEstimateItemsTable;
