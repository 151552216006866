import { useEffect, useState } from "react";
import { useGvContext } from "../../GvContext";
import SalesApi from "../../api/SalesApi";
import RowType from "../../types/RowType";
import RptSalesProfitByProdSm from "../../types/sm/RptSalesProfitByProdSm";
import ListViewButtonBar from "../ListViewButtonBar";
import ListViewTable from "../ListViewTable";
import useConfirm from "../dialogs/ConfirmDialog";
import MessageDialog from "../dialogs/MessageDialog";
import RptSalesProfitByProdSd from "../sd/RptSalesProfitByProdSd";

function RptSalesProfitByProd(): JSX.Element {

	const [rows, setRows] = useState<RowType[]>([]);
	const [selectedId, setSelectedId] = useState<number | null>(null);

	const [searchModel, setSearchModel] = useState(new RptSalesProfitByProdSm());
	//
	const [showSd, setShowSd] = useState<boolean>(false);
	
	const [messageDialogMessage, setMessageDialogMessage] = useState("");
	const [showMessageDialog, setShowMessageDialog] = useState(false);

	const confirm = useConfirm();
	const gv = useGvContext();

	const colNames = [
		"Product",
		"Sales Count",
		"Customer Count",
		"Profit Amount",
		"Profit Percent",
	];

	useEffect(() => {
		async function process() {
			loadData();
		}
		process();
	}, [searchModel]);

	const handleClickExport = () => {
		console.log("handleClickExport");
	};

	const handleClickOpen = () => {
		console.log("handleClickOpen");
	};

	const handleClickFilter = () => {
		setShowSd(true);
	};

	const handleClickClearFilter = () => {
		let searchModelLoc = new RptSalesProfitByProdSm();
		searchModelLoc.reset();
		setSearchModel(searchModelLoc);
	};

	const loadData = async () => {

		let res = await SalesApi.getRptSalesProfitByProdHdtos(searchModel);

		if (res.isSuccess) {

			let dataToReturn: RowType[] = [];

			for (let i = 0; i < res.items?.length!; i++) {
				let row = res.items![i];

				let oneRowArray: string[] = [];

				oneRowArray.push(row.prodName == null ? "" : row.prodName.toString());
				oneRowArray.push(row.salesCnt == null ? "" : row.salesCnt.toString());
				oneRowArray.push(row.customerCnt == null ? "" : row.customerCnt.toString());
				oneRowArray.push(row.profitAmt == null ? "" : row.profitAmt.toString());
				oneRowArray.push(row.profitPercent == null ? "" : row.profitPercent.toString());

				dataToReturn.push({
					id: row.prodId,
					data: oneRowArray
				});
			};

			setRows(dataToReturn);
		}
		else {
			setShowMessageDialog(true);
			setMessageDialogMessage(res.errorRes?.message!);
		}

	}

	return (
		<>
			<ListViewButtonBar
				handleClickExport={handleClickExport}
				handleClickOpen={handleClickOpen}
				handleClickFilter={handleClickFilter}
				handleClickClearFilter={handleClickClearFilter}
			/>

			<ListViewTable
				colNames={colNames}
				rows={rows}
				setSelectedId={setSelectedId}
			/>

			<RptSalesProfitByProdSd
				showModal={showSd}
				setShowModal={setShowSd}
				searchModel={searchModel}
				processOnSuccess={(searchModel) => setSearchModel(searchModel)}
			/>

			<MessageDialog
				showDialog={showMessageDialog}
				modalMessage={messageDialogMessage}
				setShowDialog={setShowMessageDialog} />
		</>
	);
}

export default RptSalesProfitByProd;
