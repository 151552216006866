import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import ProdApi from "../../api/ProdApi";
import ProductCatApi from "../../api/ProductCatApi";
import RptCurrentStockValReportByEnum from "../../enums/RptCurrentStockValReportByEnum";
import ProdCatHelper from "../../helpers/ProdCatHelper";
import ProdHelper from "../../helpers/ProdHelper";
import RptCurrentStockValReportByHelper from "../../helpers/RptCurrentStockValReportByHelper";
import DropDownItemType from "../../types/DropDownItemType";
import ProdCatLdto from "../../types/res/list/ProdCatLdto";
import ProdLdto from "../../types/res/list/ProdLdto";
import RptCurrentStockValSm from "../../types/sm/RptCurrentStockValSm";
import DownshiftSelect from "../DownshiftSelectV2";

interface RptCurrentStockValSdPropsType {
	showModal: boolean;
	setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
	searchModel: RptCurrentStockValSm;
	processOnSuccess: (searchModel: RptCurrentStockValSm) => void;
}

function RptCurrentStockValSd({
	showModal,
	setShowModal,
	searchModel,
	processOnSuccess
}: RptCurrentStockValSdPropsType) {

	const [reportBy, setReportBy] = useState(searchModel.reportBy == null ? null : RptCurrentStockValReportByEnum.getByEnumName(searchModel.reportBy));
	const [prodCatId, setProdCatId] = useState(searchModel.catId ?? null);
	const [prodCat, setProdCat] = useState(searchModel.cat ?? null);
	const [prodId, setProdId] = useState(searchModel.prodId ?? null);
	const [prod, setProd] = useState(searchModel.prod ?? null);
	const [dateFrom, setDateFrom] = useState(searchModel.dateFrom ?? null);
	const [dateTo, setDateTo] = useState(searchModel.dateTo ?? null);

	//
	const [prodCatLdtos, setProdCatLdtos] = useState<ProdCatLdto[] | null>(null);
	const [prodCatLdtoMap, setProdCatLdtoMap] = useState<Map<number, ProdCatLdto> | null>(null);
	const [prodCatDdis, setProdCatDdis] = useState<DropDownItemType[] | null>(null);

	const [prodLdtos, setProdLdtos] = useState<ProdLdto[] | null>(null);
	const [prodLdtoMap, setProdLdtoMap] = useState<Map<number, ProdLdto> | null>(null);
	const [prodDdis, setProdDdis] = useState<DropDownItemType[] | null>(null);

	useEffect(() => {
		async function process() {
			ProductCatApi.getProdCatLdtos().then((prodCatLdtos) => {
				if (prodCatLdtos.isSuccess) {
					setProdCatLdtos(prodCatLdtos.items!);
					setProdCatLdtoMap(ProdCatHelper.getProdCatLdtoMap(prodCatLdtos.items!));
					setProdCatDdis(ProdCatHelper.getProdCatDdis(prodCatLdtos.items!));
				}
			});

			ProdApi.getProdLdtos().then((prodLdtos) => {
				if (prodLdtos.isSuccess) {
					setProdLdtos(prodLdtos.items!);
					setProdLdtoMap(ProdHelper.getProdLdtoMap(prodLdtos.items!));
					setProdDdis(ProdHelper.getProdDdis(prodLdtos.items!));
				}
			});
		}
		process();
	}, []);


	const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		event.stopPropagation();
		doSearch();
	}

	const handleClickClose = () => {
		doCancel();
	};

	const handleEscapeKeyPress = (event: KeyboardEvent) => {
		if (!event.altKey
			&& !event.ctrlKey
			&& !event.metaKey
			&& !event.shiftKey
			&& event.key === "Escape"
		) {
			event.preventDefault();
			event.stopPropagation();
			doCancel();
		}
	};

	const isValid = (): boolean => {
		return true;
	}

	const doSearch = () => {

		if (!isValid) {
			return;
		}

		let searchModelNew = new RptCurrentStockValSm();

		searchModelNew.reportBy = reportBy == null ? undefined : reportBy.enumName;
		searchModelNew.catId = prodCatId;
		searchModelNew.cat = prodCat;
		searchModelNew.prodId = prodId;
		searchModelNew.prod = prod;
		searchModelNew.dateFrom = dateFrom;
		searchModelNew.dateTo = dateTo;

		setShowModal(false);

		processOnSuccess(searchModelNew);
	}

	const doCancel = () => {
		setShowModal(false);

		setReportBy(searchModel.reportBy == null ? null : RptCurrentStockValReportByEnum.getByEnumName(searchModel.reportBy));
		setProdCatId(searchModel.catId ?? null);
		setProdCat(searchModel.cat ?? null);
		setProdId(searchModel.prodId ?? null);
		setProd(searchModel.prod ?? null);
		setDateFrom(searchModel.dateFrom ?? null);
		setDateTo(searchModel.dateTo ?? null);
	}

	return (
		<Modal
			show={showModal}
			onHide={handleClickClose}
			onEscapeKeyDown={handleEscapeKeyPress}
			size="lg"
		>
			<Modal.Header closeButton>
				<Modal.Title>Message</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={handleFormSubmit} id="formSearch">

					<Form.Group as={Row} className="mb-3">
						<Form.Label column sm="3">
							Report By
						</Form.Label>
						<Col sm="9">
							<DownshiftSelect
								inputId={"cmbReportBy"}
								value={reportBy == null ? null : RptCurrentStockValReportByHelper.getRptCurrentStockValReportByDdi(reportBy)}
								onChange={(e) => setReportBy(e == null ? null : RptCurrentStockValReportByEnum.getById(e))}
								options={RptCurrentStockValReportByHelper.getRptCurrentStockValReportByDdis()}
								isLoading={false}
								isDisabled={false}
								placeholder=""
							/>
						</Col>
					</Form.Group>

					<Form.Group as={Row} className="mb-3">
						<Form.Label column sm="3">
							Category
						</Form.Label>
						<Col sm="9">
							<DownshiftSelect
								inputId={"cmbProdCat"}
								value={prodCatId == null || prodCatLdtoMap?.get(prodCatId) == null ? null : ProdCatHelper.getProdCatDdi(prodCatLdtoMap.get(prodCatId)!)}
								onChange={setProdCatId}
								options={prodCatDdis ?? []}
								isLoading={false}
								isDisabled={false}
								placeholder=""
							/>
						</Col>
					</Form.Group>

					<Form.Group as={Row} className="mb-3">
						<Form.Label column sm="3">
							Category
						</Form.Label>
						<Col sm="9">
							<Form.Control
								type="text"
								value={prodCat == null ? "" : prodCat}
								onChange={(e) => setProdCat(e.target.value)} />
						</Col>
					</Form.Group>

					<Form.Group as={Row} className="mb-3">
						<Form.Label column sm="3">
							Product
						</Form.Label>
						<Col sm="9">
							<DownshiftSelect
								inputId={"cmbProd"}
								value={prodId == null || prodLdtoMap?.get(prodId) == null ? null : ProdHelper.getProdDdi(prodLdtoMap.get(prodId)!)}
								onChange={setProdId}
								options={prodDdis ?? []}
								isLoading={false}
								isDisabled={false}
								placeholder=""
							/>
						</Col>
					</Form.Group>

					<Form.Group as={Row} className="mb-3">
						<Form.Label column sm="3">
							Product
						</Form.Label>
						<Col sm="9">
							<Form.Control
								type="text"
								value={prod == null ? "" : prod}
								onChange={(e) => setProd(e.target.value)} />
						</Col>
					</Form.Group>

					<Form.Group as={Row} className="mb-3">
						<Form.Label column sm="3">
							Date From
						</Form.Label>
						<Col sm="9">
							<ReactDatePicker
								id="dpDate"
								dateFormat="dd-MM-yyyy"
								selected={dateFrom}
								onChange={(e) => setDateFrom(e)}
								className="form-control"
							/>
						</Col>
					</Form.Group>

					<Form.Group as={Row} className="mb-3">
						<Form.Label column sm="3">
							Date To
						</Form.Label>
						<Col sm="9">
							<ReactDatePicker
								id="dpDate"
								dateFormat="dd-MM-yyyy"
								selected={dateTo}
								onChange={(e) => setDateTo(e)}
								className="form-control"
							/>
						</Col>
					</Form.Group>

				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={handleClickClose}>
					Cancel
				</Button>
				<Button variant="primary" type="submit" form="formSearch">
					SAVE
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default RptCurrentStockValSd;

