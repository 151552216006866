import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, FormControl, Row } from "react-bootstrap";
import MessageDialog from "../dialogs/MessageDialog";
import PriceListTypeEnum from "../../enums/PriceListTypeEnum";
import RateTypeEnum from "../../enums/RateTypeEnum";
import PriceListTypeHelper from "../../helpers/PriceListTypeHelper";
import ProdHelper from "../../helpers/ProdHelper";
import StringHelper2 from "../../helpers/StringHelper2";
import UnitHelper from "../../helpers/UnitHelper";
import YesNoHelper from "../../helpers/YesNoHelper";
import DropDownItemType from "../../types/DropDownItemType";
import PurchaseOrderItemType from "../../types/PurchaseOrderItemType";
import { PriceListRateResType } from "../../types/res/PriceListRateResType";
import UnitLdto from "../../types/res/list/UnitLdto";
import ProdForEsimateLdto from "../../types/res/list/ProdForEsimateLdto";
import TaxCatLdto from "../../types/res/list/TaxCatLdto";
import TaxLdto from "../../types/res/list/TaxLdto";
import DownshiftSelect from "../DownshiftSelectV2";

interface AddPurchaseOrderItemFormSvProps {
	products: ProdForEsimateLdto[];
	productsMap: Map<number, ProdForEsimateLdto>;
	productsForDd: DropDownItemType[];
	units: UnitLdto[];
	unitsMap: Map<number, UnitLdto>;
	handleItemChange: (addPurchaseOrderItem: PurchaseOrderItemType) => void;
	purchaseOrderItemToEdit: PurchaseOrderItemType | null;
	id?: number
}

function AddPurchaseOrderItemFormSv({
	products,
	productsMap,
	productsForDd,
	units,
	unitsMap,
	handleItemChange,
	purchaseOrderItemToEdit,
	id
}: AddPurchaseOrderItemFormSvProps): JSX.Element {

	const [qtyCurrent, setQtyCurrent] = useState<number | null>(null);

	const [productId, setProductId] = useState<number | null>(null);
	const [qtyStr, setQtyStr] = useState<string>("");
	const [desc, setDesc] = useState<string>("");
	//
	const cmbProductRef = useRef<HTMLInputElement | null>(null);

	const [messageDialogMessage, setMessageDialogMessage] = useState("");
	const [showMessageDialog, setShowMessageDialog] = useState(false);

	useEffect(() => {
		if (purchaseOrderItemToEdit != null) {
			setProductId(purchaseOrderItemToEdit.productId);
			handleProdChange(purchaseOrderItemToEdit.productId);
			setQtyStr(purchaseOrderItemToEdit.qty.toString());
			setDesc(purchaseOrderItemToEdit.desc);
		}
	}, [purchaseOrderItemToEdit]);

	const handleProdChange = (prodIdLoc: number | null) => {

		let qtyCurrentLoc = null;
		let qtyPendingPurchaseLoc = null;
		let qtyPendingPurchaseOrderLoc = null;
		let qtyFinalLoc = null;
		let unitsForDdLoc: UnitLdto[] = [];

		if (
			prodIdLoc != null &&
			prodIdLoc > 0 &&
			products !== undefined &&
			products !== null &&
			productsMap !== undefined &&
			productsMap !== null &&
			products.length > 0) {

			let product = productsMap.get(prodIdLoc);

			qtyCurrentLoc = product?.qtyCurrent!;
			if (qtyCurrentLoc === undefined || qtyCurrentLoc === null) {
				qtyCurrentLoc = 0;
			}
		}

		setQtyCurrent(qtyCurrentLoc);

		setProductId(prodIdLoc);

	};


	const _handleClickSave = async (event: React.FormEvent<HTMLFormElement>) => {

		event.preventDefault();

		if (!isValid()) {
			return;
		}

	}

	const fillInUi = (/*productResType: PurchaseOrderResType*/) => {

	};

	const handleClose = () => setShowMessageDialog(false);
	const handleShow = () => setShowMessageDialog(true);

	const clearBoxes = () => {
		setProductId(-1);
		setQtyStr("");
		setDesc("");
	};

	const handleSave = (e: React.MouseEvent) => {
		e.preventDefault();

		if (!isValid()) {
			return;
		}

		let productIdLoc: number = productId!;
		let qtyLoc: number = parseFloat(qtyStr);
		let descLoc: string = desc;

		let addPurchaseOrderItemLoc: PurchaseOrderItemType = {
			productId: productIdLoc,
			desc: descLoc,
			qty: qtyLoc,
		};

		handleItemChange(addPurchaseOrderItemLoc);

		clearBoxes();

		cmbProductRef.current?.focus();

	};

	const isValid = (): boolean => {

		if (
			productId === undefined
			|| productId === null
			|| productsMap?.get(productId) === undefined
			|| productsMap?.get(productId) === null
		) {
			setMessageDialogMessage("Select product");
			setShowMessageDialog(true);
			return false;
		}

		if (qtyStr === undefined
			|| qtyStr === null
			|| !StringHelper2.isNum(qtyStr)
		) {
			setMessageDialogMessage("Invalid quantity");
			setShowMessageDialog(true);
			return false;
		}

		return true;
	}



	return (
		<>


			<Row className="mb-3" xs="auto">
				<Form.Group as={Col}>
					<Form.Label>Product</Form.Label>
					<DownshiftSelect
						inputId={"product"}
						value={productId != null && productId > 0 && productsMap?.get(productId) ? ProdHelper.getProdDdi(productsMap?.get(productId)!) : null}
						onChange={handleProdChange}
						options={productsForDd}
						isLoading={false}
						isDisabled={false}
						placeholder=""
						forwardedRef={cmbProductRef}
					/>
				</Form.Group>

				<Form.Group as={Col}>
					<Form.Label>&nbsp;</Form.Label>
					<FormControl
						size="sm" as={Button}
					>...</FormControl>
				</Form.Group>

				<Form.Group as={Col}>
					<Form.Label>Quantity in stock</Form.Label>
					<FormControl
						size="sm"
						type="text"
						value={qtyCurrent ? qtyCurrent.toLocaleString('en-IN') : ""}
						readOnly={true}
					/>
				</Form.Group>

				<Form.Group as={Col}>
					<Form.Label>Quantity</Form.Label>
					<FormControl
						size="sm"
						type="text"
						value={qtyStr}
						onChange={(e) => setQtyStr(e.target.value)}
					/>
				</Form.Group>



				<Form.Group as={Col}>
					<Form.Label>&nbsp;</Form.Label>
					<FormControl
						size="sm"
						as={Button}
						onClick={handleSave}
					>
						Save
					</FormControl>
				</Form.Group>

				<Form.Group as={Col}>
					<Form.Label>&nbsp;</Form.Label>
					<FormControl
						size="sm"
						as={Button}>Cancel</FormControl>
				</Form.Group>

				<Form.Group as={Col}>
					<Form.Label>Description</Form.Label>
					<FormControl
						size="sm"
						type="text"
						onChange={(e) => setDesc(e.target.value)}
					/>
				</Form.Group>
			</Row>

			<MessageDialog
				modalMessage={messageDialogMessage}
				showDialog={showMessageDialog}
				setShowDialog={setShowMessageDialog}
			/>

		</>
	);
}

export default AddPurchaseOrderItemFormSv;
