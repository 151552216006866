export default class RptSalesOrderLedgerEnumCol {

	public static readonly NO = new RptSalesOrderLedgerEnumCol("no");
	public static readonly DATE = new RptSalesOrderLedgerEnumCol("date");
	public static readonly VOUCHER_TYPE_ID = new RptSalesOrderLedgerEnumCol("voucher_type_id");
	public static readonly CUSTOMER_NAME = new RptSalesOrderLedgerEnumCol("customer_name");
	public static readonly PRODUCT_NAME = new RptSalesOrderLedgerEnumCol("product_name");
	public static readonly QTY_ORDER = new RptSalesOrderLedgerEnumCol("qty_order");
	public static readonly QTY_SOLD = new RptSalesOrderLedgerEnumCol("qty_sold");
	public static readonly QTY_PENDING = new RptSalesOrderLedgerEnumCol("qty_pending");

	private constructor(
		public readonly key: string) {
	}

	static get values(): RptSalesOrderLedgerEnumCol[] {
		return [
			this.NO,
			this.DATE,
			this.VOUCHER_TYPE_ID,
			this.CUSTOMER_NAME,
			this.PRODUCT_NAME,
			this.QTY_ORDER,
			this.QTY_SOLD,
			this.QTY_PENDING,
		];
	}

	static getByKey(key: string): RptSalesOrderLedgerEnumCol | null {
		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.key === key) {
				return element;
			}
		}
		return null;
	}
}
