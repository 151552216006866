import { useEffect, useState } from "react";
import { useGvContext } from "../../GvContext";
import MenuApi from "../../api/MenuApi";
import SalesOrderApi from "../../api/SalesOrderApi";
import { BASE_URL, TOKEN } from "../../config";
import DateFormatEnum from "../../enums/DateFormatEnum";
import ListMenuEnum from "../../enums/ListMenuEnum";
import ResourceEnum from "../../enums/ResourceEnum";
import SalesOrderPrintTypeEnum from "../../enums/SalesOrderPrintTypeEnum";
import SalesPrintTypeEnum from "../../enums/SalesPrintTypeEnum";
import LstSalesOrderEnumCol from "../../enums/col/LstSalesOrderEnumCol";
import dateToString from "../../helpers/DateHelper";
import strFormat from "../../helpers/StringHelper";
import RowType from "../../types/RowType";
import ColSettingResType from "../../types/res/ColSettingResType";
import ListSalesOrderSm from "../../types/sm/ListSalesOrderSm";
import ListViewButtonBar from "../ListViewButtonBar";
import ListViewTable from "../ListViewTable";
import useConfirm from "../dialogs/ConfirmDialog";
import MessageDialog from "../dialogs/MessageDialog";
import SalesOrderPrintDialog from "../dialogs/SalesOrderPrintDialog";
import ListSalesOrderSd from "../sd/ListSalesOrderSd";

function ListSalesOrder(): JSX.Element {

	const resourceName = ResourceEnum.SALES_ORDER.name;

	const [colSettings, setColSettings] = useState<ColSettingResType[]>([]);
	const [colNames, setColNames] = useState<string[]>([]);

	const [rows, setRows] = useState<RowType[]>([]);
	const [selectedId, setSelectedId] = useState<number | null>(null);

	const [listSalesOrderSm, setListSalesOrderSm] = useState<ListSalesOrderSm>(new ListSalesOrderSm(false, true));
	//
	const [showSd, setShowSd] = useState<boolean>(false);
	
	const [messageDialogMessage, setMessageDialogMessage] = useState("");
	const [showMessageDialog, setShowMessageDialog] = useState(false);

	const [showSalesOrderPrintDialog, setShowSalesOrderPrintDialog] = useState(false);

	const confirm = useConfirm();
	const gv = useGvContext();

	useEffect(() => {
		async function process() {

			let res = await MenuApi.getColSettingDtos(ListMenuEnum.SALES_ORDER);

			if (res.isSuccess) {

				setColSettings(res.items!);

				let colNamesLocal: string[] = [];
				res.items!.forEach((item) => {
					colNamesLocal.push(item.colName);
				});
				setColNames(colNamesLocal);

			}
			else {
				setShowMessageDialog(true);
				setMessageDialogMessage(res.errorRes?.message!);
			}

		}
		process();
	}, []);

	useEffect(() => {
		if (colSettings != null && colSettings.length > 0) {
			loadData();
		}
	}, [listSalesOrderSm, colSettings]);

	const getListViewOptions = () => {
		return [
			{
				name: "Create Sales",
				myCallbackFunction: handleClickBtnConvertToSales
			},
			{
				name: "Print picklist",
				myCallbackFunction: handleClickBtnPrintPicklist
			},
			{
				name: "Send text Message on Whatsapp",
				myCallbackFunction: handleClickMiSendWhatsapp
			},
			{
				name: "Send invoice on Whatsapp",
				myCallbackFunction: handleClickMiWhatsappBill
			},
			{
				name: "Duplicate",
				myCallbackFunction: handleClickDuplicateVoucher
			},
			{
				name: "Print Pending Items",
				myCallbackFunction: handleClickBtnPrintPendingItems
			},
		];
	}

	const handleClickEdit = () => {

		if (selectedId === null) {
			setMessageDialogMessage("Select atleast one row");
			setShowMessageDialog(true);
			return;
		}

		gv.menuHelper.openAddSalesOrder(selectedId);
	};

	const handleClickDelete = async () => {
		if (selectedId === null) {
			setMessageDialogMessage("Select atleast one row");
			setShowMessageDialog(true);
			return;
		}

		let choice = await confirm!({
			message: "Are you sure you want to delete?",
			title: "Delete confirmation"
		});

		if (choice === undefined || choice === null || choice === false)
			return;

		const requestOptions = {
			method: "DELETE",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			}
		}

		const res = await fetch(strFormat("%s/%s/%s", BASE_URL, resourceName, selectedId), requestOptions);

		if (res.status !== 204) {
			const data = await res.json();
			setMessageDialogMessage(data.message);
			setShowMessageDialog(true);
		}
		else {
			loadData();
		}
	};

	const handleClickExport = () => {
		console.log("handleClickExport");
	};

	const handleClickPrint = () => {

		if (selectedId === null) {
			setMessageDialogMessage("Select atleast one row");
			setShowMessageDialog(true);
			return;
		}

		setShowSalesOrderPrintDialog(true);
	};

	const handleClickBtnConvertToSales = () => {
		if (selectedId === null) {
			setMessageDialogMessage("Select atleast one row");
			setShowMessageDialog(true);
			return;
		}
		gv.menuHelper.openAddSalesBySalesOrderId(selectedId);
	};

	const handleClickBtnPrintPicklist = async () => {
		if (selectedId === null) {
			setMessageDialogMessage("Select atleast one row");
			setShowMessageDialog(true);
			return;
		}

		const fileURL = window.URL.createObjectURL(await SalesOrderApi.print(SalesOrderPrintTypeEnum.PICK_LIST, [selectedId], null));
		//Open the URL on new Window
		const pdfWindow = window.open();
		pdfWindow!.location.href = fileURL;
	};
	const handleClickMiSendWhatsapp = () => {

	};
	const handleClickMiWhatsappBill = () => {

	};
	const handleClickDuplicateVoucher = () => {

	};
	const handleClickBtnPrintPendingItems = () => {

	};

	const printSalesOrder = async (salesPrintType: SalesPrintTypeEnum) => {

		if (selectedId === null) {
			setMessageDialogMessage("Select atleast one row");
			setShowMessageDialog(true);
			return;
		}

		setShowSalesOrderPrintDialog(true);

		const fileURL = window.URL.createObjectURL(await SalesOrderApi.print(SalesOrderPrintTypeEnum.SALES_ORDER, [selectedId], salesPrintType));
		//Open the URL on new Window
		const pdfWindow = window.open();
		pdfWindow!.location.href = fileURL;
	};

	const handleClickOpen = () => {
		console.log("handleClickOpen");
	};

	const handleClickFilter = () => {
		setShowSd(true);
	};

	const handleClickClearFilter = () => {
		let listSalesOrderSmNew = new ListSalesOrderSm();
		listSalesOrderSmNew.reset()
		setListSalesOrderSm(listSalesOrderSmNew);
	};

	const handleClickColSettings = () => {
		console.log("handleClickColSettings");
	};

	const loadData = async () => {

		let res = await SalesOrderApi.getSalesOrderLdtos(listSalesOrderSm);

		if (res.isSuccess) {

			let dataToReturn: RowType[] = [];
			let sumAmtPayable = 0;

			for (let i = 0; i < res.items?.length!; i++) {
				let salesOrderDto = res.items![i];

				let oneRowArray: string[] = [];

				for (let j = 0; j < colSettings.length; j++) {
					let colSetting = colSettings[j];
					switch (LstSalesOrderEnumCol.getByKey(colSetting.colKey)) {
						case LstSalesOrderEnumCol.CITY:
							oneRowArray.push(salesOrderDto.city == null ? "" : salesOrderDto.city);
							break;
						case LstSalesOrderEnumCol.CUSTOMER:
							oneRowArray.push(salesOrderDto.customerName);
							break;
						case LstSalesOrderEnumCol.DATE:
							oneRowArray.push(dateToString(salesOrderDto.date, DateFormatEnum.APP_DATETIME));
							break;
						case LstSalesOrderEnumCol.NO:
							oneRowArray.push(salesOrderDto.no.toString());
							break;
						case LstSalesOrderEnumCol.STATE:
							oneRowArray.push(salesOrderDto.stateName == null ? "" : salesOrderDto.stateName);
							break;
						case LstSalesOrderEnumCol.NOTE:
							oneRowArray.push(salesOrderDto.note);
							break;
						case LstSalesOrderEnumCol.QTY:
							oneRowArray.push(salesOrderDto.sumQty == null ? "" : salesOrderDto.sumQty.toString());
							break;
						case LstSalesOrderEnumCol.CREATED_BY:
							oneRowArray.push(salesOrderDto.createdByUserName);
							break;
						case LstSalesOrderEnumCol.CREATED_AT:
							oneRowArray.push(dateToString(salesOrderDto.createdAt, DateFormatEnum.APP_DATETIME));
							break;
						case LstSalesOrderEnumCol.SALES_NO:
							oneRowArray.push(salesOrderDto.salesNo == null ? "" : salesOrderDto.salesNo);
							break;
						case LstSalesOrderEnumCol.COMPLETED:
							oneRowArray.push(salesOrderDto.completed ? "COMPLETED" : "PENDING");
							break;
						case LstSalesOrderEnumCol.AMT_PAYABLE:
							oneRowArray.push(salesOrderDto.amtPayable.toLocaleString('en-IN'));
							sumAmtPayable = sumAmtPayable + salesOrderDto.amtPayable;
							break;
						case LstSalesOrderEnumCol.COMPANY_PROFILE:
							oneRowArray.push(salesOrderDto.companyProfileName);
							break;
					}
				};

				dataToReturn.push({
					id: salesOrderDto.id,
					data: oneRowArray
				});
			};

			setRows(dataToReturn);
		}
		else {
			setShowMessageDialog(true);
			setMessageDialogMessage(res.errorRes?.message!);
		}
	}

	return (
		<>
			<ListViewButtonBar
				listViewOptions={getListViewOptions()}
				handleClickEdit={handleClickEdit}
				handleClickDelete={handleClickDelete}
				handleClickExport={handleClickExport}
				handleClickPrint={handleClickPrint}
				handleClickOpen={handleClickOpen}
				handleClickFilter={handleClickFilter}
				handleClickClearFilter={handleClickClearFilter}
				handleClickColSettings={handleClickColSettings}
			/>
			<ListViewTable
					colNames={colNames}
					rows={rows}
					setSelectedId={setSelectedId}
				/>

			<ListSalesOrderSd
				showModal={showSd}
				setShowModal={setShowSd}
				listSalesOrderSm={listSalesOrderSm}
				processOnSuccess={(listSalesOrderSm) => setListSalesOrderSm(listSalesOrderSm)}
			/>

			<MessageDialog
				showDialog={showMessageDialog}
				modalMessage={messageDialogMessage}
				setShowDialog={setShowMessageDialog} />

			<SalesOrderPrintDialog
				showDialog={showSalesOrderPrintDialog}
				setShowDialog={setShowSalesOrderPrintDialog}
				print={printSalesOrder}
			/>
		</>
	);
}

export default ListSalesOrder;
