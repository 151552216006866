import React, { useEffect, useState } from "react";
import RateTypeEnum from "../../enums/RateTypeEnum";
import StringHelper2 from "../../helpers/StringHelper2";
import DropDownItemType from "../../types/DropDownItemType";
import PusaOthChOnItemRowHolder from "../../types/PusaOthChOnItemRowHolder";
import VouOthChOnItemType from "../../types/VouOthChOnItemType";
import VouOthChargeLdto from "../../types/res/list/VouOthChargeLdto";
import AddPusaOthChOnItemRowSv from "./AddPusaOthChOnItemRowSv";

interface AddPusaOthChOnItemSvProps {
	vouOthChOnItemResMap: Map<number, VouOthChargeLdto>;
	vouOthChOnItemResArrForDd: DropDownItemType[];
	vouOthChOnItemArr: VouOthChOnItemType[];
	handleOthChOnItemChange: (vouOthChOnItemArr: VouOthChOnItemType[]) => void;
}

function AddPusaOthChOnItemSv({
	vouOthChOnItemResMap,
	vouOthChOnItemResArrForDd,
	vouOthChOnItemArr,
	handleOthChOnItemChange
}: AddPusaOthChOnItemSvProps): JSX.Element {

	const [pusaOthChOnItemRowHolders, setPusaOthChOnItemRowHolders] = useState<PusaOthChOnItemRowHolder[]>([]);

	useEffect(() => {

		let vouOthChOnItemArrLoc: VouOthChOnItemType[] = [];
		for (let i = 0; i < pusaOthChOnItemRowHolders.length; i++) {
			let pusaOthChOnItemRowHolder = pusaOthChOnItemRowHolders[i];

			if (!isValid(pusaOthChOnItemRowHolder)) {
				return;
			}

			vouOthChOnItemArrLoc.push({
				othChId: pusaOthChOnItemRowHolder.othChId!,
				rate: parseFloat(pusaOthChOnItemRowHolder.rateStr!),
				rateTypeId: pusaOthChOnItemRowHolder.rateTypeId!
			});
		}

		handleOthChOnItemChange(vouOthChOnItemArrLoc);

	}, [pusaOthChOnItemRowHolders]);

	const handleClickAdd = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		event.preventDefault();

		setPusaOthChOnItemRowHolders([...pusaOthChOnItemRowHolders, {}])
	};

	const handleUpdateRow = (index: number, pusaOthChOnItemRowHolder: PusaOthChOnItemRowHolder) => {

		let newPusaOthChOnItemRowHolder = [...pusaOthChOnItemRowHolders];
		newPusaOthChOnItemRowHolder[index] = pusaOthChOnItemRowHolder;
		setPusaOthChOnItemRowHolders(newPusaOthChOnItemRowHolder);

	};

	const handleClickRemove = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => {
		event.preventDefault();

		let newPusaOthChOnItemRowHolder = [...pusaOthChOnItemRowHolders];
		newPusaOthChOnItemRowHolder.splice(index, 1);
		setPusaOthChOnItemRowHolders(newPusaOthChOnItemRowHolder);
	};

	const isValid = (pusaOthChOnItemRowHolder: PusaOthChOnItemRowHolder): boolean => {

		if (
			pusaOthChOnItemRowHolder.othChId === undefined
			|| pusaOthChOnItemRowHolder.othChId === null
			|| pusaOthChOnItemRowHolder.othChId === -1
			|| vouOthChOnItemResMap?.get(pusaOthChOnItemRowHolder.othChId) === undefined
			|| vouOthChOnItemResMap?.get(pusaOthChOnItemRowHolder.othChId) === null
		) {
			return false;
		}

		if (pusaOthChOnItemRowHolder.rateStr === undefined
			|| pusaOthChOnItemRowHolder.rateStr === null
			|| !StringHelper2.isNum(pusaOthChOnItemRowHolder.rateStr)
		) {
			return false;
		}

		if (
			pusaOthChOnItemRowHolder.rateTypeId === undefined
			|| pusaOthChOnItemRowHolder.rateTypeId === null
			|| pusaOthChOnItemRowHolder.rateTypeId === -1
			|| RateTypeEnum.getById(pusaOthChOnItemRowHolder.rateTypeId) === undefined
			|| RateTypeEnum.getById(pusaOthChOnItemRowHolder.rateTypeId) === null
		) {
			return false;
		}

		return true;

	}

	return (
		<div className="d-flex g-3 justify-content-end">
			<div className="card">
				<div className="card-header">
					Other harge (After Tax)
				</div>
				<div className="card-body">

					{pusaOthChOnItemRowHolders.map((pusaOthChOnItemRowHolder, index) => (
						<div key={index}>
							<AddPusaOthChOnItemRowSv
								vouOthChOnItemResMap={vouOthChOnItemResMap}
								vouOthChOnItemResArrForDd={vouOthChOnItemResArrForDd}
								vouOthChOnItem={index < vouOthChOnItemArr.length ? vouOthChOnItemArr[index] : null}
								pusaOthChOnItemRowHolder={pusaOthChOnItemRowHolder}
								handleClickRemove={handleClickRemove}
								handleUpdateRow={handleUpdateRow}
								myIndex={index}
								key={index}
							/>
						</div>
					))}

					<a
						onClick={(event) => handleClickAdd(event)}
						href="#"
						className="btn btn-primary">
						Add
					</a>
				</div>
			</div>
		</div>
	);

}

export default AddPusaOthChOnItemSv;
