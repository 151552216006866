export default class RptLowStockReportByEnum {

	public static readonly CATEGORY = new RptLowStockReportByEnum(1, "CATEGORY", "Category");
	public static readonly PRODUCT = new RptLowStockReportByEnum(2, "PRODUCT", "Product");

	private constructor(
		public readonly id: number,
		public readonly enumName: string,
		public readonly name: string) {
	}

	// write funtion values which return array of all above objects
	static get values(): RptLowStockReportByEnum[] {
		return [
			this.CATEGORY,
			this.PRODUCT,
		];
	}


	static getById(id: number) {
		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.id === id) {
				return element;
			}
		}
		return null;
	}

	static getByEnumName(enumName: string) {
		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.enumName === enumName) {
				return element;
			}
		}
		return null;
	}

	// add tostring code
	public toString(): string {
		return this.enumName;
	}

}