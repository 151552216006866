import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import AcctApi from "../../api/AcctApi";
import ProdApi from "../../api/ProdApi";
import OrderReportByTypeEnum from "../../enums/OrderReportByTypeEnum";
import YesNoEnum from "../../enums/YesNoEnum";
import AcctHelper from "../../helpers/AcctHelper";
import OrderReportByTypeHelper from "../../helpers/OrderReportByTypeHelper";
import ProdHelper from "../../helpers/ProdHelper";
import YesNoHelper from "../../helpers/YesNoHelper";
import DropDownItemType from "../../types/DropDownItemType";
import AcctLdto from "../../types/res/list/AcctLdto";
import ProdLdto from "../../types/res/list/ProdLdto";
import RptSalesOrderPendingSmType from "../../types/sm/RptSalesOrderPendingSm";
import DownshiftSelect from "../DownshiftSelectV2";

interface RptSalesOrderPendingSdPropsType {
	showModal: boolean;
	setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
	searchModel: RptSalesOrderPendingSmType;
	processOnSuccess: (searchModel: RptSalesOrderPendingSmType) => void;
}

function RptSalesOrderPendingSd({
	showModal,
	setShowModal,
	searchModel,
	processOnSuccess
}: RptSalesOrderPendingSdPropsType) {

	const [dateFrom, setDateFrom] = useState<Date | null>(searchModel.dateFrom ?? null);
	const [dateTo, setDateTo] = useState(searchModel.dateTo ?? null);
	const [prodId, setProdId] = useState<number | null>(searchModel.prodId ?? null);
	const [customerId, setCustomerId] = useState<number | null>(searchModel.acctId ?? null);
	const [summaryBy, setSummaryBy] = useState(searchModel.orderReportByType ?? null);
	const [isPending, setIsPending] = useState(searchModel.pending ?? null);
	const [no, setNo] = useState(searchModel.no ?? null);
	const [note, setNote] = useState(searchModel.note ?? null);

	//
	const [prodLdtos, setProdLdtos] = useState<ProdLdto[] | null>(null);
	const [prodLdtoMap, setProdLdtoMap] = useState<Map<number, ProdLdto> | null>(null);
	const [prodDdis, setProdDdis] = useState<DropDownItemType[] | null>(null);

	const [customerLdtos, setCustomerLdtos] = useState<AcctLdto[] | null>(null);
	const [customerLdtoMap, setCustomerLdtoMap] = useState<Map<number, AcctLdto> | null>(null);
	const [customerDdis, setCustomerDdis] = useState<DropDownItemType[] | null>(null);

	useEffect(() => {
		async function process() {
			ProdApi.getProdLdtos().then((productLdtos) => {
				if (productLdtos.isSuccess) {
					setProdLdtos(productLdtos.items!);
					setProdLdtoMap(ProdHelper.getProdLdtoMap(productLdtos.items!));
					setProdDdis(ProdHelper.getProdDdis(productLdtos.items!));
				}
			});

			AcctApi.getAcctLdtos().then((acctLdtos) => {
				if (acctLdtos.isSuccess) {
					setCustomerLdtos(acctLdtos.items!);
					setCustomerLdtoMap(AcctHelper.getAcctLdtoMap(acctLdtos.items!));
					setCustomerDdis(AcctHelper.getAcctDdis(acctLdtos.items!));
				}
			});
		}
		process();
	}, []);


	const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		event.stopPropagation();
		doSearch();
	}

	const handleClickClose = () => {
		doCancel();
	};

	const handleEscapeKeyPress = (event: KeyboardEvent) => {
		if (!event.altKey
			&& !event.ctrlKey
			&& !event.metaKey
			&& !event.shiftKey
			&& event.key === "Escape"
		) {
			event.preventDefault();
			event.stopPropagation();
			doCancel();
		}
	};

	const isValid = (): boolean => {
		return true;
	}

	const doSearch = () => {

		if (!isValid) {
			return;
		}

		let searchModelNew = new RptSalesOrderPendingSmType();

		searchModelNew.dateFrom = dateFrom;
		searchModelNew.dateTo = dateTo;
		searchModelNew.prodId = prodId;
		searchModelNew.acctId = customerId;
		searchModelNew.orderReportByType = summaryBy;
		searchModelNew.pending = isPending;
		searchModelNew.no = no;
		searchModelNew.note = note;

		setShowModal(false);

		processOnSuccess(searchModelNew);
	}

	const doCancel = () => {
		setShowModal(false);

		setDateFrom(searchModel.dateFrom ?? null);
		setDateTo(searchModel.dateTo ?? null);
		setProdId(searchModel.prodId ?? null);
		setCustomerId(searchModel.acctId ?? null);
		setSummaryBy(searchModel.orderReportByType ?? null);
		setIsPending(searchModel.pending ?? null);
		setNo(searchModel.no ?? null);
		setNote(searchModel.note ?? null);
	}


	return (
		<Modal
			show={showModal}
			onHide={handleClickClose}
			onEscapeKeyDown={handleEscapeKeyPress}
			size="lg"
		>
			<Modal.Header closeButton>
				<Modal.Title>Message</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={handleFormSubmit} id="formSearch">

					<Form.Group as={Row} className="mb-3">
						<Form.Label column sm="3">
							Date From
						</Form.Label>
						<Col sm="9">
							<ReactDatePicker
								id="dpDate"
								dateFormat="dd-MM-yyyy"
								selected={dateFrom}
								onChange={(e) => setDateFrom(e)}
								className="form-control"
							/>
						</Col>
					</Form.Group>

					<Form.Group as={Row} className="mb-3">
						<Form.Label column sm="3">
							Date To
						</Form.Label>
						<Col sm="9">
							<ReactDatePicker
								id="dpDate"
								dateFormat="dd-MM-yyyy"
								selected={dateTo}
								onChange={(e) => setDateTo(e)}
								className="form-control"
							/>
						</Col>
					</Form.Group>

					<Form.Group as={Row} className="mb-3">
						<Form.Label column sm="3">
							Product
						</Form.Label>
						<Col sm="9">
							<DownshiftSelect
								inputId={"cmbProd"}
								value={prodId == null || prodLdtoMap?.get(prodId) == null ? null : ProdHelper.getProdDdi(prodLdtoMap.get(prodId)!)}
								onChange={setProdId}
								options={prodDdis ?? []}
								isLoading={false}
								isDisabled={false}
								placeholder=""
							/>
						</Col>
					</Form.Group>

					<Form.Group as={Row} className="mb-3">
						<Form.Label column sm="3">
							Customer
						</Form.Label>
						<Col sm="9">
							<DownshiftSelect
								inputId={"cmbCustomer"}
								value={customerId == null || customerLdtoMap?.get(customerId) == null ? null : AcctHelper.getAcctDdi(customerLdtoMap.get(customerId)!)}
								onChange={setCustomerId}
								options={customerDdis ?? []}
								isLoading={false}
								isDisabled={false}
								placeholder=""
							/>
						</Col>
					</Form.Group>

					<Form.Group as={Row} className="mb-3">
						<Form.Label column sm="3">
							Summary By
						</Form.Label>
						<Col sm="9">
							<DownshiftSelect
								inputId={"cmbSummaryBy"}
								value={summaryBy == null ? null : OrderReportByTypeHelper.getOrderReportByTypeDdi(summaryBy)}
								onChange={(e) => setSummaryBy(e == null ? null : OrderReportByTypeEnum.getById(e))}
								options={OrderReportByTypeHelper.getOrderReportByTypeDdis()}
								isLoading={false}
								isDisabled={false}
								placeholder=""
							/>
						</Col>
					</Form.Group>

					<Form.Group as={Row} className="mb-3">
						<Form.Label column sm="3">
							Is Pending?
						</Form.Label>
						<Col sm="9">
							<DownshiftSelect
								inputId={"cmbIsConvertedToSo"}
								value={isPending == null ? null : YesNoHelper.getYesNoForDd(YesNoEnum.getByVal(isPending)!)}
								onChange={(e) => { if (e != null) setIsPending(YesNoEnum.getById(e)?.val!) }}
								options={YesNoHelper.getYesNosForDd()}
								isLoading={false}
								isDisabled={false}
								placeholder=""
							/>
						</Col>
					</Form.Group>

					<Form.Group as={Row} className="mb-3">
						<Form.Label column sm="3">
							No
						</Form.Label>
						<Col sm="9">
							<Form.Control
								type="text"
								value={no == null ? "" : no}
								onChange={(e) => setNo(e.target.value)} />
						</Col>
					</Form.Group>

					<Form.Group as={Row} className="mb-3">
						<Form.Label column sm="3">
							Note
						</Form.Label>
						<Col sm="9">
							<Form.Control
								type="text"
								value={note == null ? "" : note}
								onChange={(e) => setNote(e.target.value)} />
						</Col>
					</Form.Group>

				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={handleClickClose}>
					Cancel
				</Button>
				<Button variant="primary" type="submit" form="formSearch">
					SAVE
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default RptSalesOrderPendingSd;

