import RateTypeEnum from "../enums/RateTypeEnum";
import SalesOrderItemType from "../types/SalesOrderItemType";
import TaxAmtContainerType from "../types/TaxAmtContainerType";
import TotalAmtContainerType from "../types/TotalAmtContainerType";
import VouOthChNonTaxableType from "../types/VouOthChNonTaxableType";

export default class SalesOrderHelper {

	static getSumAmtAfterItemDisc(
		salesOrderItems: SalesOrderItemType[],
	) {
		if (salesOrderItems !== undefined && salesOrderItems !== null && salesOrderItems.length > 0) {

			let sumAmtAfterDisc = 0;
			for (let i = 0; i < salesOrderItems.length; i++) {
				let salesOrderItem = salesOrderItems[i];

				sumAmtAfterDisc = sumAmtAfterDisc + salesOrderItem.derivedAmtAfterDisc!;
			}
			return sumAmtAfterDisc;
		}
		return 0;
	}

	static getSumItemTaxableAmt(
		salesOrderItemArr: SalesOrderItemType[],
	) {
		if (salesOrderItemArr !== undefined && salesOrderItemArr !== null && salesOrderItemArr.length > 0) {

			let sumItemTaxableAmt = 0;
			for (let i = 0; i < salesOrderItemArr.length; i++) {
				let salesOrderItem = salesOrderItemArr[i];

				sumItemTaxableAmt = sumItemTaxableAmt + salesOrderItem.derivedTaxableAmt!;
			}
			return sumItemTaxableAmt;
		}
		return 0;
	}

	public static getSumAmtAfterTax(
		salesOrderItemArr: SalesOrderItemType[]
	) {

		let sumAmtAfterTax = 0;

		if (salesOrderItemArr !== undefined && salesOrderItemArr !== null && salesOrderItemArr.length > 0) {
			for (let i = 0; i < salesOrderItemArr.length; i++) {
				let salesOrderItem = salesOrderItemArr[i];

				sumAmtAfterTax = sumAmtAfterTax + salesOrderItem.derivedAmt!;
			}
		}

		return sumAmtAfterTax;
	}

	public static getTaxSum(
		salesOrderItemArr: SalesOrderItemType[]
	): TaxAmtContainerType {

		let cgstA = 0;
		let sgstA = 0;
		let igstA = 0;

		if (salesOrderItemArr !== undefined && salesOrderItemArr !== null && salesOrderItemArr.length > 0) {

			for (let i = 0; i < salesOrderItemArr.length; i++) {
				let salesOrderItem = salesOrderItemArr[i];

				cgstA = cgstA + salesOrderItem.derivedCgstA!;
				sgstA = sgstA + salesOrderItem.derivedSgstA!;
				igstA = igstA + salesOrderItem.derivedIgstA!;
			}
		}

		return {
			cgstA: cgstA,
			sgstA: sgstA,
			igstA: igstA,
			amtWithTax: 0,
		};
	}

	public static calOthChNonTax(
		salesOrderItemArr: SalesOrderItemType[],
		salesOrderOthChNonTaxableArr: VouOthChNonTaxableType[]
	) {
		let sumAmtAfterTax = this.getSumAmtAfterTax(salesOrderItemArr);

		for (let i = 0; i < salesOrderOthChNonTaxableArr.length; i++) {
			let salesOrderOthChNonTaxable = salesOrderOthChNonTaxableArr[i];

			if (salesOrderOthChNonTaxable.rate == null) {
				salesOrderOthChNonTaxable.derivedAmt = 0;
			}
			else {
				if (salesOrderOthChNonTaxable.rateTypeId === RateTypeEnum.FLAT.id) {
					salesOrderOthChNonTaxable.derivedAmt = salesOrderOthChNonTaxable.rate;
				}
				else {
					if (salesOrderOthChNonTaxable.rateTypeId === RateTypeEnum.PERCENT.id) {
						salesOrderOthChNonTaxable.derivedAmt = sumAmtAfterTax * salesOrderOthChNonTaxable.rate / 100;
					}
				}
			}
		}
	}

	public static getTotalAmt(
		salesOrderItemArr: SalesOrderItemType[],
		salesOrderOthChNonTaxableArr: VouOthChNonTaxableType[]
	): TotalAmtContainerType {

		let totalAmt = 0;
		let roundOff = 0;
		let amtPayable = 0;


		totalAmt = 0;
		//
		for (let i = 0; i < salesOrderItemArr.length; i++) {
			let salesOrderItem = salesOrderItemArr[i];
			{
				totalAmt = totalAmt + salesOrderItem.derivedAmt!;
			}
		}

		for (let i = 0; i < salesOrderOthChNonTaxableArr.length; i++) {
			let salesOrderOthChNonTaxable = salesOrderOthChNonTaxableArr[i];
			{
				totalAmt = totalAmt + salesOrderOthChNonTaxable.derivedAmt!;
			}
		}

		amtPayable = totalAmt;
		roundOff = amtPayable - totalAmt;


		return {
			totalAmt: totalAmt,
			roundOff: roundOff,
			amtPayable: amtPayable,
		};
	}

}