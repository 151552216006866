import { useEffect, useState } from "react";
import { useGvContext } from "../../GvContext";
import { BASE_URL, TOKEN } from "../../config";
import DateFormatEnum from "../../enums/DateFormatEnum";
import ListMenuEnum from "../../enums/ListMenuEnum";
import ResourceEnum from "../../enums/ResourceEnum";
import LstCommVouEnumCol from "../../enums/col/LstCommVouEnumCol";
import dateToString from "../../helpers/DateHelper";
import strFormat from "../../helpers/StringHelper";
import { getQueryString } from "../../helpers/UrlSearchParamHelper";
import RowType from "../../types/RowType";
import ColSettingResType from "../../types/res/ColSettingResType";
import CommVouLdto from "../../types/res/list/CommVouLdto";
import ListCommVouSm from "../../types/sm/ListCommVouSm";
import ListViewButtonBar from "../ListViewButtonBar";
import ListViewTable from "../ListViewTable";
import useConfirm from "../dialogs/ConfirmDialog";
import MessageDialog from "../dialogs/MessageDialog";

function ListCommVou(): JSX.Element {

	const resourceName = ResourceEnum.COMM_VOU.name;

	const [colSettings, setColSettings] = useState<ColSettingResType[]>([]);
	const [colNames, setColNames] = useState<string[]>([]);

	const [rows, setRows] = useState<RowType[]>([]);
	const [selectedId, setSelectedId] = useState<number | null>(null);

	const [listCommVouSm, setListCommVouSm] = useState<ListCommVouSm>(new ListCommVouSm());

	const [showSd, setShowSd] = useState<boolean>(false);
	
	const [messageDialogMessage, setMessageDialogMessage] = useState("");
	const [showMessageDialog, setShowMessageDialog] = useState(false);

	const confirm = useConfirm();
	const gv = useGvContext();



	useEffect(() => {
		async function process() {

			const requestOptions = {
				method: "GET",
				headers: {
					"Authorization": "Bearer " + TOKEN.val,
					"Content-Type": "application/json"
				}
			}

			const res = await fetch(strFormat("%s/%s/%s/%s",
				BASE_URL,
				ResourceEnum.MENU.name,
				ListMenuEnum.COMM_VOU.id,
				ResourceEnum.COL_SETTING.name,
			), requestOptions);

			if (res.status !== 200) {
				setMessageDialogMessage("Server error");
				setShowMessageDialog(true);
			}
			else {
				const data: ColSettingResType[] = await res.json();
				setColSettings(data);

				let colNamesLocal: string[] = [];
				data.forEach((item) => {
					colNamesLocal.push(item.colName);
				});
				setColNames(colNamesLocal);
				loadData(listCommVouSm, data);
			}

		}
		process();
	}, []);

	const handleClickAdd = () => {
		gv.menuHelper.openAddCommVou();
	};

	const handleClickEdit = () => {
		if (selectedId === null) {
			setMessageDialogMessage("Select atleast one row");
			setShowMessageDialog(true);
			return;
		}
	};

	const handleClickDelete = async () => {
		if (selectedId === null) {
			setMessageDialogMessage("Select atleast one row");
			setShowMessageDialog(true);
			return;
		}

		let choice = await confirm!({
			message: "Are you sure you want to delete?",
			title: "Delete confirmation"
		});

		if (choice === undefined || choice === null || choice === false)
			return;

		const requestOptions = {
			method: "DELETE",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			}
		}

		const res = await fetch(strFormat("%s/%s/%s", BASE_URL, resourceName, selectedId), requestOptions);

		if (res.status !== 204) {
			const data = await res.json();
			setMessageDialogMessage(data.message);
			setShowMessageDialog(true);
		}
		else {
			loadData(listCommVouSm);
		}
	};

	const handleClickExport = () => {
		console.log("handleClickExport");
	};

	const handleClickPrint = () => {
		console.log("handleClickPrint");
	};

	const handleClickOpen = () => {
		console.log("handleClickOpen");
	};

	const handleClickFilter = () => {
		setShowSd(true);
	};

	const handleClickClearFilter = () => {
		console.log("handleClickClearFilter");
	};

	const handleClickColSettings = () => {
		console.log("handleClickColSettings");
	};

	const loadData = async (
		listCommSm: ListCommVouSm,
		colSettingsLocal?: ColSettingResType[]
	) => {

		let colSettingsToUse: ColSettingResType[];

		if (colSettingsLocal === undefined) {
			colSettingsToUse = colSettings;
		}
		else {
			colSettingsToUse = colSettingsLocal;
		}

		setListCommVouSm(listCommSm);

		const requestOptions = {
			method: "GET",
			headers: {
				Authorization: "Bearer " + TOKEN.val,
				"Content-Type": "application/json",
			},
		};
		const res = await fetch(strFormat("%s/%s", BASE_URL, resourceName)
			//+ new URLSearchParams(listVouOthChargeSm.toUrlSearchParams())
			+ getQueryString(listCommSm)
			, requestOptions);
		const results: CommVouLdto[] = await res.json();

		let dataToReturn: RowType[] = [];

		let sumDueComm = 0;
		let sumEstimatedComm = 0;
		let sumPayableComm = 0;
		let sumSalesAmt = 0;

		results.forEach((result) => {
			let oneRowArray: string[] = [];

			colSettingsToUse.forEach(colSetting => {
				switch (LstCommVouEnumCol.getByKey(colSetting.colKey)) {
					case LstCommVouEnumCol.AGENT_NAME:
						oneRowArray.push(result.agentName);
						break;
					case LstCommVouEnumCol.CUSTOMER_NAME:
						oneRowArray.push(result.customerName);
						break;
					case LstCommVouEnumCol.DATE:
						oneRowArray.push(dateToString(result.date, DateFormatEnum.APP_DATETIME));
						break;
					case LstCommVouEnumCol.DUE_COMM:
						oneRowArray.push(result.dueComm.toString());
						sumDueComm = sumDueComm + result.dueComm;
						break;
					case LstCommVouEnumCol.ESTIMATED_COMM:
						oneRowArray.push(result.estimatedComm.toString());
						sumEstimatedComm = sumEstimatedComm + result.estimatedComm;
						break;
					case LstCommVouEnumCol.NO:
						oneRowArray.push(result.no.toString());
						break;
					case LstCommVouEnumCol.PAYABLE_COMM:
						oneRowArray.push(result.payableComm.toString());
						sumPayableComm = sumPayableComm + result.payableComm;
						break;
					case LstCommVouEnumCol.SALES_DATE:
						oneRowArray.push(dateToString(result.salesDate, DateFormatEnum.APP_DATE));
						break;
					case LstCommVouEnumCol.SALES_NO:
						oneRowArray.push(result.salesNo.toString());
						break;
					case LstCommVouEnumCol.SALES_AMT:
						oneRowArray.push(result.salesAmt.toLocaleString("en-IN", { minimumFractionDigits: 2 }));
						sumSalesAmt = sumSalesAmt + result.salesAmt;
						break;
					case LstCommVouEnumCol.CREATED_BY:
						oneRowArray.push(result.createdByUserName);
						break;
					case LstCommVouEnumCol.CREATED_AT:
						oneRowArray.push(dateToString(result.createdAt, DateFormatEnum.APP_DATETIME));
						break;
				}
			});

			dataToReturn.push({
				id: result.id,
				data: oneRowArray
			});
		});

		setRows(dataToReturn);
	}

	return (
		<>
			<ListViewButtonBar
				handleClickEdit={handleClickEdit}
				handleClickDelete={handleClickDelete}
				handleClickExport={handleClickExport}
				handleClickPrint={handleClickPrint}
				handleClickOpen={handleClickOpen}
				handleClickFilter={handleClickFilter}
				handleClickClearFilter={handleClickClearFilter}
				handleClickColSettings={handleClickColSettings}
			/>
			<ListViewTable
				colNames={colNames}
				rows={rows}
				setSelectedId={setSelectedId}
			/>

			<MessageDialog
				showDialog={showMessageDialog}
				modalMessage={messageDialogMessage}
				setShowDialog={setShowMessageDialog} />
		</>
	);
}

export default ListCommVou;
