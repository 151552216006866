import { BASE_URL, TOKEN } from "../config";
import ServerNotReachableErrorCommonCudObj from "../default_objects/ServerNotReachableErrorCommonCudObj";
import ServerSideErrorCommonCudObj from "../default_objects/ServerSideErrorCommonCudObj";
import strFormat from "../helpers/StringHelper";
import { getQueryString } from "../helpers/UrlSearchParamHelper";
import CommonGetListType from "../types/CommonGetListType";
import ErrorResType from "../types/res/ErrorResType";
import RptIoDetailedHdto from "../types/res/list/RptIoDetailedHdto";
import RptIoSummByMonthHdto from "../types/res/list/RptIoSummByMonthHdto";
import RptIoSummByProdHdto from "../types/res/list/RptIoSummByProdHdto";
import RptIoSummByProdCatHdto from "../types/res/list/RptIoSummByProductCategoryHdto";
import RptLedgerHdto from "../types/res/list/RptLedgerHdto";
import RptInvIoDetailedSm from "../types/sm/RptInvIoDetailedSm";
import RptInvIoSummByMonthSm from "../types/sm/RptInvIoSummByMonthSm";
import RptInvIoSummByProdCatSm from "../types/sm/RptInvIoSummByProdCatSm";
import RptInvIoSummByProdSm from "../types/sm/RptInvIoSummByProdSm";
import RptLedgerSm from "../types/sm/RptLedgerSm";

export default class ReportApi {


	// report
	static async getRptInvIoSummByProdCatHdtos(searchModel: RptInvIoSummByProdCatSm): Promise<CommonGetListType<RptIoSummByProdCatHdto>> {
		try {
			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s/%s",
					BASE_URL,
					"reports",
					"io",
					"summary",
					"by-product-category"
				)
				+ getQueryString(searchModel)
				, requestOptions);
			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptIoSummByProdCatHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async getRptInvIoSummByProdHdtos(searchModel: RptInvIoSummByProdSm): Promise<CommonGetListType<RptIoSummByProdHdto>> {
		try {
			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s/%s",
					BASE_URL,
					"reports",
					"io",
					"summary",
					"by-product"
				)
				+ getQueryString(searchModel)
				, requestOptions);
			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptIoSummByProdHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async getRptInvIoSummByMonthHdtos(searchModel: RptInvIoSummByMonthSm): Promise<CommonGetListType<RptIoSummByMonthHdto>> {
		try {
			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s/%s",
					BASE_URL,
					"reports",
					"io",
					"summary",
					"by-month"
				)
				+ getQueryString(searchModel)
				, requestOptions);
			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptIoSummByMonthHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async getRptInvIoDetailedHdtos(searchModel: RptInvIoDetailedSm): Promise<CommonGetListType<RptIoDetailedHdto>> {
		try {
			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s",
					BASE_URL,
					"reports",
					"io",
					"detailed",
				)
				+ getQueryString(searchModel)
				, requestOptions);
			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptIoDetailedHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async getRptLedger(searchModel: RptLedgerSm): Promise<CommonGetListType<RptLedgerHdto>> {
		try {
			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s",
					BASE_URL,
					"reports",
					"ledger",
				)
				+ getQueryString(searchModel)
				, requestOptions);
			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptLedgerHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

}