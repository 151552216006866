import { useEffect, useState } from "react";
import { useGvContext } from "../../GvContext";
import MenuApi from "../../api/MenuApi";
import VouOthChApi from "../../api/VouOthChApi";
import { BASE_URL, TOKEN } from "../../config";
import CalcOnTypeEnum from "../../enums/CalcOnTypeEnum";
import CalcTypeEnum from "../../enums/CalcTypeEnum";
import DateFormatEnum from "../../enums/DateFormatEnum";
import KbShortcutEnum from "../../enums/KbShortcutEnum";
import ListMenuEnum from "../../enums/ListMenuEnum";
import RateTypeEnum from "../../enums/RateTypeEnum";
import ResourceEnum from "../../enums/ResourceEnum";
import LstVouOthChargeEnumCol from "../../enums/col/LstVouOthChargeEnumCol";
import dateToString from "../../helpers/DateHelper";
import KbscHelper from "../../helpers/KbscHelper";
import strFormat from "../../helpers/StringHelper";
import RowType from "../../types/RowType";
import ColSettingResType from "../../types/res/ColSettingResType";
import ListVouOthChargeSm from "../../types/sm/ListVouOthChargeSm";
import ListViewButtonBar from "../ListViewButtonBar";
import ListViewTable from "../ListViewTable";
import useConfirm from "../dialogs/ConfirmDialog";
import MessageDialog from "../dialogs/MessageDialog";
import ListVouOthChargeSd from "../sd/ListVouOthChargeSd";

function ListVoucherOtherCharge(): JSX.Element {

	const resourceName = ResourceEnum.VOU_OTH_CHARGE.name;

	const [colSettings, setColSettings] = useState<ColSettingResType[]>([]);
	const [colNames, setColNames] = useState<string[]>([]);

	const [rows, setRows] = useState<RowType[]>([]);
	const [selectedId, setSelectedId] = useState<number | null>(null);

	const [searchModel, setSearchModel] = useState<ListVouOthChargeSm>(new ListVouOthChargeSm());

	const [showSd, setShowSd] = useState<boolean>(false);

	const [messageDialogMessage, setMessageDialogMessage] = useState("");
	const [showMessageDialog, setShowMessageDialog] = useState(false);

	const confirm = useConfirm();
	const gv = useGvContext();

	useEffect(() => {
		async function process() {

			let res = await MenuApi.getColSettingDtos(ListMenuEnum.VOU_OTH_CHARGE);

			if (res.isSuccess) {

				setColSettings(res.items!);

				let colNamesLocal: string[] = [];
				res.items!.forEach((item) => {
					colNamesLocal.push(item.colName);
				});
				setColNames(colNamesLocal);

			}
			else {
				setShowMessageDialog(true);
				setMessageDialogMessage(res.errorRes?.message!);
			}
		}
		process();
	}, []);

	useEffect(() => {

		const handleKeyDown = (event: React.KeyboardEvent<HTMLTableElement> | KeyboardEvent) => {

			if (showSd || showMessageDialog) {
				return;
			}

			let kbShortcutModel = KbscHelper.findKbShortcut(event, gv.kbKbShortcutModel);
			if (kbShortcutModel == null) {
				return;
			}

			let kbShortcutEnum = KbShortcutEnum.getById(kbShortcutModel.id);
			if (kbShortcutEnum == null) {
				return;
			}

			switch (kbShortcutEnum) {
				case KbShortcutEnum.ADD:
					handleClickAdd();
					event.preventDefault();
					event.stopPropagation();
					break;
				case KbShortcutEnum.EDIT:
					handleClickEdit();
					event.preventDefault();
					event.stopPropagation();
					break;
				case KbShortcutEnum.DELETE:
					handleClickDelete();
					event.preventDefault();
					event.stopPropagation();
					break;
				case KbShortcutEnum.EXPORT:
					handleClickExport();
					event.preventDefault();
					event.stopPropagation();
					break;
				case KbShortcutEnum.FIND:
					handleClickFilter();
					event.preventDefault();
					event.stopPropagation();
					break;
				case KbShortcutEnum.CLEAR_FILTER:
					handleClickClearFilter();
					event.preventDefault();
					event.stopPropagation();
					break;
			}
		};

		window.addEventListener('keydown', handleKeyDown);

		// Clean up event listener on component unmount
		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	}, [gv.kbKbShortcutModel, showSd, showMessageDialog]);

	useEffect(() => {
		if (colSettings != null && colSettings.length > 0) {
			loadData();
		}
	}, [searchModel, colSettings]);

	const handleClickAdd = () => {
		gv.menuHelper.openAddVouOthCh();
	};

	const handleClickEdit = () => {

		if (selectedId === null) {
			setMessageDialogMessage("Select atleast one row");
			setShowMessageDialog(true);
			return;
		}

		gv.menuHelper.openAddVouOthCh(selectedId);
	};

	const handleClickDelete = async () => {
		if (selectedId === null) {
			setMessageDialogMessage("Select atleast one row");
			setShowMessageDialog(true);
			return;
		}

		let choice = await confirm!({
			message: "Are you sure you want to delete?",
			title: "Delete confirmation"
		});

		if (choice === undefined || choice === null || choice === false)
			return;

		const requestOptions = {
			method: "DELETE",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			}
		}

		const res = await fetch(strFormat("%s/%s/%s", BASE_URL, resourceName, selectedId), requestOptions);

		if (res.status !== 204) {
			const data = await res.json();
			setMessageDialogMessage(data.message);
			setShowMessageDialog(true);
		}
		else {
			loadData();
		}
	};

	const handleClickExport = () => {
		console.log("handleClickExport");
	};

	const handleClickFilter = () => {
		setShowSd(true);
	};

	const handleClickClearFilter = () => {
		let searchModelLoc = new ListVouOthChargeSm();
		searchModelLoc.reset();
		setSearchModel(searchModelLoc);
	};

	const handleClickColSettings = () => {
		console.log("handleClickColSettings");
	};

	const loadData = async () => {

		let res = await VouOthChApi.getVouOthChargeLdtos(searchModel);

		if (res.isSuccess) {

			let dataToReturn: RowType[] = [];

			for (let i = 0; i < res.items?.length!; i++) {
				let row = res.items![i];

				let oneRowArray: string[] = [];

				for (let j = 0; j < colSettings.length; j++) {
					let colSetting = colSettings[j];

					switch (LstVouOthChargeEnumCol.getByKey(colSetting.colKey)) {
						case LstVouOthChargeEnumCol.AUTO_ADD_PURCHASE:
							oneRowArray.push(row.isAddAutoP ? "YES" : "NO");
							break;
						case LstVouOthChargeEnumCol.AUTO_ADD_PURCHASE_RET:
							oneRowArray.push(row.isAddAutoPR ? "YES" : "NO");
							break;
						case LstVouOthChargeEnumCol.AUTO_ADD_SALES:
							oneRowArray.push(row.isAddAutoS ? "YES" : "NO");
							break;
						case LstVouOthChargeEnumCol.AUTO_ADD_SALES_RET:
							oneRowArray.push(row.isAddAutoSR ? "YES" : "NO");
							break;
						case LstVouOthChargeEnumCol.CALC_ON:
							oneRowArray.push(CalcOnTypeEnum.getById(row.calcOnTypeId)?.name!);
							break;
						case LstVouOthChargeEnumCol.CALC_TYPE:
							oneRowArray.push(CalcTypeEnum.getByValue(row.calcTypeVal)?.name!);
							break;
						case LstVouOthChargeEnumCol.CREATED_AT:
							oneRowArray.push(dateToString(row.createdAt, DateFormatEnum.APP_DATETIME));
							break;
						case LstVouOthChargeEnumCol.CREATED_BY:
							oneRowArray.push(row.createdByUserName);
							break;
						case LstVouOthChargeEnumCol.IS_TAXABLE:
							oneRowArray.push(row.isTaxable ? "YES" : "NO");
							break;
						case LstVouOthChargeEnumCol.NAME:
							oneRowArray.push(row.name);
							break;
						case LstVouOthChargeEnumCol.PURCHASE_ACCT:
							oneRowArray.push(row.purchaseAcctName);
							break;
						case LstVouOthChargeEnumCol.PURCHASE_RET_ACCT:
							oneRowArray.push(row.purchaseRetAcctName);
							break;
						case LstVouOthChargeEnumCol.RATE:
							oneRowArray.push(row.rate === null ? "" : row.rate.toString());
							break;
						case LstVouOthChargeEnumCol.RATE_TYPE:
							oneRowArray.push(row.rateTypeId === null ? "" : RateTypeEnum.getById(row.rateTypeId)?.name!);
							break;
						case LstVouOthChargeEnumCol.SALES_ACCT:
							oneRowArray.push(row.salesAcctName);
							break;
						case LstVouOthChargeEnumCol.SALES_RET_ACCT:
							oneRowArray.push(row.salesRetAcctName);
							break;
						case LstVouOthChargeEnumCol.SEQ_NO:
							oneRowArray.push(row.seqNo.toString());
							break;
						case LstVouOthChargeEnumCol.TAX:
							oneRowArray.push(row.taxName);
							break;
					}
				};

				dataToReturn.push({
					id: row.id,
					data: oneRowArray
				});
			};

			setRows(dataToReturn);
		}
		else {
			setShowMessageDialog(true);
			setMessageDialogMessage(res.errorRes?.message!);
		}

	}

	return (
		<>
			<ListViewButtonBar
				handleClickAdd={handleClickAdd}
				handleClickEdit={handleClickEdit}
				handleClickDelete={handleClickDelete}
				handleClickExport={handleClickExport}
				handleClickFilter={handleClickFilter}
				handleClickClearFilter={handleClickClearFilter}
				handleClickColSettings={handleClickColSettings}
			/>

			<ListViewTable
				colNames={colNames}
				rows={rows}
				setSelectedId={setSelectedId}
			/>

			<ListVouOthChargeSd
				showModal={showSd}
				setShowModal={setShowSd}
				searchModel={searchModel}
				processOnSuccess={(searchModel) => setSearchModel(searchModel)}
			/>

			<MessageDialog
				showDialog={showMessageDialog}
				modalMessage={messageDialogMessage}
				setShowDialog={setShowMessageDialog} />
		</>
	);
}

export default ListVoucherOtherCharge;
