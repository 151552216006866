export default class KbShortcutEnum {

	public static readonly ADD = new KbShortcutEnum(1, "add");
	public static readonly EDIT = new KbShortcutEnum(2, "edit");
	public static readonly DELETE = new KbShortcutEnum(3, "delete");
	public static readonly OPEN = new KbShortcutEnum(4, "open");
	public static readonly VIEW = new KbShortcutEnum(5, "view");
	public static readonly PRINT = new KbShortcutEnum(6, "print");
	public static readonly EXPORT = new KbShortcutEnum(7, "export");
	public static readonly FIND = new KbShortcutEnum(8, "find");
	public static readonly CLEAR_FILTER = new KbShortcutEnum(9, "clear_filter");
	public static readonly OPEN_COL_SETTING = new KbShortcutEnum(10, "open_col_setting");
	public static readonly HIDE_ROW = new KbShortcutEnum(11, "hide_row");
	public static readonly UNHIDE_ROW = new KbShortcutEnum(12, "unhide_row");
	public static readonly FOCUS_SEARCH = new KbShortcutEnum(13, "focus_search");
	public static readonly FOCUS_TABLE = new KbShortcutEnum(14, "focus_table");
	public static readonly GO_TO_DATE = new KbShortcutEnum(15, "go_to_date");
	public static readonly SEARCH = new KbShortcutEnum(16, "search");
	public static readonly CLEAR_SEARCH = new KbShortcutEnum(17, "clear_search");
	public static readonly BACK = new KbShortcutEnum(18, "back");
	public static readonly COMPANY_YEAR = new KbShortcutEnum(19, "company_year");
	public static readonly SYSTEM_SETTINGS = new KbShortcutEnum(20, "system_settings");
	public static readonly NEXT_ENTRY = new KbShortcutEnum(21, "next_entry");
	public static readonly PREVIOUS_ENTRY = new KbShortcutEnum(22, "previous_entry");
	public static readonly SAVE = new KbShortcutEnum(23, "save");
	public static readonly CANCEL = new KbShortcutEnum(24, "cancel");
	public static readonly ADD_DROPDOWN_ITEM = new KbShortcutEnum(25, "add_dropdown_item");
	public static readonly EDIT_DROPDOWN_ITEM = new KbShortcutEnum(26, "edit_dropdown_item");
	public static readonly ADD_EDIT_DROPDOWN_ITEM = new KbShortcutEnum(27, "add_edit_dropdown_item");
	public static readonly JUMP_TO_NEXT_SECTION = new KbShortcutEnum(28, "jump_to_next_section");
	public static readonly EDIT_ITEM = new KbShortcutEnum(29, "edit_item");
	public static readonly DELETE_ITEM = new KbShortcutEnum(30, "delete_item");
	public static readonly MOVE_ITEM_UP = new KbShortcutEnum(31, "move_item_up");
	public static readonly MOVE_ITEM_DOWN = new KbShortcutEnum(32, "move_item_down");
	public static readonly SAVE_ITEM = new KbShortcutEnum(33, "save_item");
	public static readonly SEND_MSG_WA = new KbShortcutEnum(34, "send_msg_wa");
	public static readonly OPEN_RELATED_REPORTS = new KbShortcutEnum(35, "open_related_reports");
	public static readonly USER = new KbShortcutEnum(36, "user");
	public static readonly USER_ADD = new KbShortcutEnum(37, "user_add");
	public static readonly ACCESS_ROLE = new KbShortcutEnum(38, "access_role");
	public static readonly ACCESS_ROLE_ADD = new KbShortcutEnum(39, "access_role_add");
	public static readonly TASK = new KbShortcutEnum(40, "task");
	public static readonly TASK_ADD = new KbShortcutEnum(41, "task_add");
	public static readonly COMMNC_BOOK = new KbShortcutEnum(42, "commnc_book");
	public static readonly OPEN_COMMNC_BOOK_OPT = new KbShortcutEnum(43, "open_commnc_book_opt");
	public static readonly REMINDER = new KbShortcutEnum(44, "reminder");
	public static readonly REMINDER_ADD = new KbShortcutEnum(45, "reminder_add");
	public static readonly REMINDER_SNOOZE = new KbShortcutEnum(46, "reminder_snooze");
	public static readonly REMINDER_TURN_OFF = new KbShortcutEnum(47, "reminder_turn_off");
	public static readonly REMINDER_RESCHED = new KbShortcutEnum(48, "reminder_resched");
	public static readonly COMMNC = new KbShortcutEnum(49, "commnc");
	public static readonly COMMNC_ADD = new KbShortcutEnum(50, "commnc_add");
	public static readonly COMMNC_ASSIGN_TO = new KbShortcutEnum(51, "commnc_assign_to");
	public static readonly LEAD = new KbShortcutEnum(52, "lead");
	public static readonly LEAD_ADD = new KbShortcutEnum(53, "lead_add");
	public static readonly ADD_REMARK_OPT = new KbShortcutEnum(54, "add_remark_opt");
	public static readonly ADD_REMINDER_OPT = new KbShortcutEnum(55, "add_reminder_opt");
	public static readonly ACCT_BOOK = new KbShortcutEnum(56, "acct_book");
	public static readonly DAY_BOOK = new KbShortcutEnum(57, "day_book");
	public static readonly CDP = new KbShortcutEnum(58, "cdp");
	public static readonly CDP_ADD = new KbShortcutEnum(59, "cdp_add");
	public static readonly JOURNAL = new KbShortcutEnum(60, "journal");
	public static readonly JOURNAL_ADD = new KbShortcutEnum(61, "journal_add");
	public static readonly CONTRA = new KbShortcutEnum(62, "contra");
	public static readonly CONTRA_ADD = new KbShortcutEnum(63, "contra_add");
	public static readonly RECEIPT = new KbShortcutEnum(64, "receipt");
	public static readonly RECEIPT_ADD = new KbShortcutEnum(65, "receipt_add");
	public static readonly PAYMENT = new KbShortcutEnum(66, "payment");
	public static readonly PAYMENT_ADD = new KbShortcutEnum(67, "payment_add");
	public static readonly SALES = new KbShortcutEnum(68, "sales");
	public static readonly SALES_ADD = new KbShortcutEnum(69, "sales_add");
	public static readonly SALES_RETURN = new KbShortcutEnum(70, "sales_return");
	public static readonly SALES_RETURN_ADD = new KbShortcutEnum(71, "sales_return_add");
	public static readonly PI = new KbShortcutEnum(72, "pi");
	public static readonly PI_ADD = new KbShortcutEnum(73, "pi_add");
	public static readonly PI_TO_SO = new KbShortcutEnum(74, "pi_to_so");
	public static readonly PI_TO_ESTIMATE = new KbShortcutEnum(75, "pi_to_estimate");
	public static readonly PI_TO_SALES = new KbShortcutEnum(76, "pi_to_sales");
	public static readonly SALES_ORDER = new KbShortcutEnum(77, "sales_order");
	public static readonly SALES_ORDER_ADD = new KbShortcutEnum(78, "sales_order_add");
	public static readonly SO_TO_ESTIMATE = new KbShortcutEnum(79, "so_to_estimate");
	public static readonly SO_TO_PI = new KbShortcutEnum(80, "so_to_pi");
	public static readonly SALES_ORDER_TO_SALES = new KbShortcutEnum(81, "sales_order_to_sales");
	public static readonly ESTIMATE = new KbShortcutEnum(82, "estimate");
	public static readonly ESTIMATE_ADD = new KbShortcutEnum(83, "estimate_add");
	public static readonly ESTIMATE_TO_SO = new KbShortcutEnum(84, "estimate_to_so");
	public static readonly ESITMATE_TO_PI = new KbShortcutEnum(85, "esitmate_to_pi");
	public static readonly ESTIMATE_TO_SALES = new KbShortcutEnum(86, "estimate_to_sales");
	public static readonly PURCHASE = new KbShortcutEnum(87, "purchase");
	public static readonly PURCHASE_ADD = new KbShortcutEnum(88, "purchase_add");
	public static readonly PURCHASE_RETURN = new KbShortcutEnum(89, "purchase_return");
	public static readonly PURCHASE_RETURN_ADD = new KbShortcutEnum(90, "purchase_return_add");
	public static readonly PURCHASE_ORDER = new KbShortcutEnum(91, "purchase_order");
	public static readonly PURCHASE_ORDER_ADD = new KbShortcutEnum(92, "purchase_order_add");
	public static readonly COMM_VOU = new KbShortcutEnum(93, "comm_vou");
	public static readonly COMM_VOU_ADD = new KbShortcutEnum(94, "comm_vou_add");
	public static readonly CASH_DISC_VOU = new KbShortcutEnum(95, "cash_disc_vou");
	public static readonly CASH_DISC_VOU_ADD = new KbShortcutEnum(96, "cash_disc_vou_add");
	public static readonly BANK = new KbShortcutEnum(97, "bank");
	public static readonly BANK_ADD = new KbShortcutEnum(98, "bank_add");
	public static readonly CUSTOMER = new KbShortcutEnum(99, "customer");
	public static readonly CUSTOMER_ADD = new KbShortcutEnum(100, "customer_add");
	public static readonly SUPPLIER = new KbShortcutEnum(101, "supplier");
	public static readonly SUPPLIER_ADD = new KbShortcutEnum(102, "supplier_add");
	public static readonly AGENT = new KbShortcutEnum(103, "agent");
	public static readonly AGENT_ADD = new KbShortcutEnum(104, "agent_add");
	public static readonly ACCT = new KbShortcutEnum(105, "acct");
	public static readonly ACCT_ADD = new KbShortcutEnum(106, "acct_add");
	public static readonly PRINT_ENVELOPE = new KbShortcutEnum(107, "print_envelope");
	public static readonly ACCT_TYPE = new KbShortcutEnum(108, "acct_type");
	public static readonly ACCT_TYPE_ADD = new KbShortcutEnum(109, "acct_type_add");
	public static readonly INV_ADJUST = new KbShortcutEnum(110, "inv_adjust");
	public static readonly INV_ADJUST_ADD = new KbShortcutEnum(111, "inv_adjust_add");
	public static readonly COMM_LIST = new KbShortcutEnum(112, "comm_list");
	public static readonly COMM_LIST_ADD = new KbShortcutEnum(113, "comm_list_add");
	public static readonly SALES_PRICE_LIST = new KbShortcutEnum(114, "price_list");
	public static readonly SALES_PRICE_LIST_ADD = new KbShortcutEnum(115, "price_list_add");
	public static readonly SERVICE = new KbShortcutEnum(116, "service");
	public static readonly SERVICE_ADD = new KbShortcutEnum(117, "service_add");
	public static readonly PRODUCT = new KbShortcutEnum(118, "product");
	public static readonly PRODUCT_ADD = new KbShortcutEnum(119, "product_add");
	public static readonly PROD_OTH_CHARGE = new KbShortcutEnum(120, "prod_oth_charge");
	public static readonly PROD_OTH_CHARGE_ADD = new KbShortcutEnum(121, "prod_oth_charge_add");
	public static readonly TAX_CAT = new KbShortcutEnum(122, "tax_cat");
	public static readonly TAX_CAT_ADD = new KbShortcutEnum(123, "tax_cat_add");
	public static readonly PROD_CAT = new KbShortcutEnum(124, "prod_cat");
	public static readonly PROD_CAT_ADD = new KbShortcutEnum(125, "prod_cat_add");
	public static readonly UNIT = new KbShortcutEnum(126, "unit");
	public static readonly UNIT_ADD = new KbShortcutEnum(127, "unit_add");
	public static readonly RPT_SALES_RETURN_BY_MONTH = new KbShortcutEnum(128, "rpt_sales_return_by_month");
	public static readonly RPT_SALES_RETURN_BY_DAY = new KbShortcutEnum(129, "rpt_sales_return_by_day");
	public static readonly RPT_SALES_RETURN_BY_CUSTOMER = new KbShortcutEnum(130, "rpt_sales_return_by_customer");
	public static readonly RPT_SALES_RETURN_BY_CATEGORY = new KbShortcutEnum(131, "rpt_sales_return_by_category");
	public static readonly RPT_SALES_RETURN_BY_PRODUCT = new KbShortcutEnum(132, "rpt_sales_return_by_product");
	public static readonly RPT_SALES_RETURN_BY_CITY = new KbShortcutEnum(133, "rpt_sales_return_by_city");
	public static readonly RPT_SALES_RETURN_BY_STATE = new KbShortcutEnum(134, "rpt_sales_return_by_state");
	public static readonly RPT_SALES_RETURN_BY_AGENT = new KbShortcutEnum(135, "rpt_sales_return_by_agent");
	public static readonly RPT_SALES_BY_MONTH = new KbShortcutEnum(136, "rpt_sales_by_month");
	public static readonly RPT_SALES_BY_DAY = new KbShortcutEnum(137, "rpt_sales_by_day");
	public static readonly RPT_SALES_BY_CUSTOMER = new KbShortcutEnum(138, "rpt_sales_by_customer");
	public static readonly RPT_SALES_BY_CATEGORY = new KbShortcutEnum(139, "rpt_sales_by_category");
	public static readonly RPT_SALES_BY_PRODUCT = new KbShortcutEnum(140, "rpt_sales_by_product");
	public static readonly RPT_SALES_BY_CITY = new KbShortcutEnum(141, "rpt_sales_by_city");
	public static readonly RPT_SALES_BY_STATE = new KbShortcutEnum(142, "rpt_sales_by_state");
	public static readonly RPT_SALES_BY_AGENT = new KbShortcutEnum(143, "rpt_sales_by_agent");
	public static readonly RPT_PURCHASE_RETURN_BY_MONTH = new KbShortcutEnum(144, "rpt_purchase_return_by_month");
	public static readonly RPT_PURCHASE_RETURN_BY_DAY = new KbShortcutEnum(145, "rpt_purchase_return_by_day");
	public static readonly RPT_PURCHASE_RETURN_BY_SUPPLIER = new KbShortcutEnum(146, "rpt_purchase_return_by_supplier");
	public static readonly RPT_PURCHASE_RETURN_BY_CATEGORY = new KbShortcutEnum(147, "rpt_purchase_return_by_category");
	public static readonly RPT_PURCHASE_RETURN_BY_PRODUCT = new KbShortcutEnum(148, "rpt_purchase_return_by_product");
	public static readonly RPT_PURCHASE_RETURN_BY_CITY = new KbShortcutEnum(149, "rpt_purchase_return_by_city");
	public static readonly RPT_PURCHASE_RETURN_BY_STATE = new KbShortcutEnum(150, "rpt_purchase_return_by_state");
	public static readonly RPT_PURCHASE_BY_MONTH = new KbShortcutEnum(151, "rpt_purchase_by_month");
	public static readonly RPT_PURCHASE_BY_DAY = new KbShortcutEnum(152, "rpt_purchase_by_day");
	public static readonly RPT_PURCHASE_BY_SUPPLIER = new KbShortcutEnum(153, "rpt_purchase_by_supplier");
	public static readonly RPT_PURCHASE_BY_CATEGORY = new KbShortcutEnum(154, "rpt_purchase_by_category");
	public static readonly RPT_PURCHASE_BY_PRODUCT = new KbShortcutEnum(155, "rpt_purchase_by_product");
	public static readonly RPT_PURCHASE_BY_CITY = new KbShortcutEnum(156, "rpt_purchase_by_city");
	public static readonly RPT_PURCHASE_BY_STATE = new KbShortcutEnum(157, "rpt_purchase_by_state");
	public static readonly RPT_ESTIMATE_PENDING_PRODUCTS = new KbShortcutEnum(158, "rpt_estimate_pending_products");
	public static readonly DUE_RCVABLE_BY_CUSTOMER = new KbShortcutEnum(159, "due_rcvable_by_customer");
	public static readonly DUE_RCVABLE_BY_SALES = new KbShortcutEnum(160, "due_rcvable_by_sales");
	public static readonly RPT_IO_SUMM_BY_PROD_CAT = new KbShortcutEnum(161, "rpt_io_summ_by_prod_cat");
	public static readonly RPT_IO_SUMM_BY_MONTH = new KbShortcutEnum(162, "rpt_io_summ_by_month");
	public static readonly RPT_IO_SUMM_BY_PROD = new KbShortcutEnum(163, "rpt_io_summ_by_prod");
	public static readonly RPT_IO_DET = new KbShortcutEnum(164, "rpt_io_det");
	public static readonly LEDGER = new KbShortcutEnum(165, "ledger");
	public static readonly TRIAL_BALANCE = new KbShortcutEnum(166, "trial_balance");
	public static readonly TRADING_ACCT = new KbShortcutEnum(167, "trading_acct");
	public static readonly PL_ACCT = new KbShortcutEnum(168, "pl_acct");
	public static readonly BALANCE_SHEET = new KbShortcutEnum(169, "balance_sheet");
	public static readonly GST_LEDGER = new KbShortcutEnum(170, "gst_ledger");
	public static readonly INVENTORY_LEDGER = new KbShortcutEnum(171, "inventory_ledger");
	public static readonly CURRENT_STOCK = new KbShortcutEnum(172, "current_stock");
	public static readonly CURRENT_STOCK_VAL = new KbShortcutEnum(173, "current_stock_val");
	public static readonly LOW_STOCK = new KbShortcutEnum(174, "low_stock");
	public static readonly SALES_ANALYSIS = new KbShortcutEnum(175, "sales_analysis");
	public static readonly CASH_DISC_VOU_LEDGER = new KbShortcutEnum(176, "cash_disc_vou_ledger");
	public static readonly CASH_DISC_VOU_LEDGER_2 = new KbShortcutEnum(177, "cash_disc_vou_ledger_2");
	public static readonly COMM_VOU_LEDGER = new KbShortcutEnum(178, "comm_vou_ledger");
	public static readonly SALES_ORDER_PENDING = new KbShortcutEnum(179, "sales_order_pending");
	public static readonly PURCHASE_ORDER_PENDING = new KbShortcutEnum(180, "purchase_order_pending");
	public static readonly SCHEME = new KbShortcutEnum(181, "scheme");
	public static readonly SCHEME_ADD = new KbShortcutEnum(182, "scheme_add");
	public static readonly TRANSPORTER = new KbShortcutEnum(183, "transporter");
	public static readonly TRANSPORTER_ADD = new KbShortcutEnum(184, "transporter_add");
	public static readonly TAX_CAL_RATE = new KbShortcutEnum(185, "tax_cal_rate");
	public static readonly TAX_CAL_RATE_ADD = new KbShortcutEnum(186, "tax_cal_rate_add");
	public static readonly TAX = new KbShortcutEnum(187, "tax");
	public static readonly TAX_ADD = new KbShortcutEnum(188, "tax_add");
	public static readonly MSG_TEMPLATE = new KbShortcutEnum(189, "msg_template");
	public static readonly MSG_TEMPLATE_ADD = new KbShortcutEnum(190, "msg_template_add");
	public static readonly COMPANY_INFO = new KbShortcutEnum(191, "company_info");
	public static readonly COMPANY_PROFILE = new KbShortcutEnum(192, "company_profile");
	public static readonly COMPANY_PROFILE_ADD = new KbShortcutEnum(193, "company_profile_add");
	public static readonly VOU_OTH_CH = new KbShortcutEnum(194, "vou_oth_ch");
	public static readonly VOU_OTH_CH_ADD = new KbShortcutEnum(195, "vou_oth_ch_add");
	public static readonly IMPORT_DATA = new KbShortcutEnum(196, "import_data");
	public static readonly MENU_CONFIG = new KbShortcutEnum(197, "menu_config");

	private constructor(
		public readonly id: number,
		public readonly key: string
	) {

	}

	static get values() {
		return [
			this.ADD,
			this.EDIT,
			this.DELETE,
			this.OPEN,
			this.VIEW,
			this.PRINT,
			this.EXPORT,
			this.FIND,
			this.CLEAR_FILTER,
			this.OPEN_COL_SETTING,
			this.HIDE_ROW,
			this.UNHIDE_ROW,
			this.FOCUS_SEARCH,
			this.FOCUS_TABLE,
			this.GO_TO_DATE,
			this.SEARCH,
			this.CLEAR_SEARCH,
			this.BACK,
			this.COMPANY_YEAR,
			this.SYSTEM_SETTINGS,
			this.NEXT_ENTRY,
			this.PREVIOUS_ENTRY,
			this.SAVE,
			this.CANCEL,
			this.ADD_DROPDOWN_ITEM,
			this.EDIT_DROPDOWN_ITEM,
			this.ADD_EDIT_DROPDOWN_ITEM,
			this.JUMP_TO_NEXT_SECTION,
			this.EDIT_ITEM,
			this.DELETE_ITEM,
			this.MOVE_ITEM_UP,
			this.MOVE_ITEM_DOWN,
			this.SAVE_ITEM,
			this.SEND_MSG_WA,
			this.OPEN_RELATED_REPORTS,
			this.USER,
			this.USER_ADD,
			this.ACCESS_ROLE,
			this.ACCESS_ROLE_ADD,
			this.TASK,
			this.TASK_ADD,
			this.COMMNC_BOOK,
			this.OPEN_COMMNC_BOOK_OPT,
			this.REMINDER,
			this.REMINDER_ADD,
			this.REMINDER_SNOOZE,
			this.REMINDER_TURN_OFF,
			this.REMINDER_RESCHED,
			this.COMMNC,
			this.COMMNC_ADD,
			this.COMMNC_ASSIGN_TO,
			this.LEAD,
			this.LEAD_ADD,
			this.ADD_REMARK_OPT,
			this.ADD_REMINDER_OPT,
			this.ACCT_BOOK,
			this.DAY_BOOK,
			this.CDP,
			this.CDP_ADD,
			this.JOURNAL,
			this.JOURNAL_ADD,
			this.CONTRA,
			this.CONTRA_ADD,
			this.RECEIPT,
			this.RECEIPT_ADD,
			this.PAYMENT,
			this.PAYMENT_ADD,
			this.SALES,
			this.SALES_ADD,
			this.SALES_RETURN,
			this.SALES_RETURN_ADD,
			this.PI,
			this.PI_ADD,
			this.PI_TO_SO,
			this.PI_TO_ESTIMATE,
			this.PI_TO_SALES,
			this.SALES_ORDER,
			this.SALES_ORDER_ADD,
			this.SO_TO_ESTIMATE,
			this.SO_TO_PI,
			this.SALES_ORDER_TO_SALES,
			this.ESTIMATE,
			this.ESTIMATE_ADD,
			this.ESTIMATE_TO_SO,
			this.ESITMATE_TO_PI,
			this.ESTIMATE_TO_SALES,
			this.PURCHASE,
			this.PURCHASE_ADD,
			this.PURCHASE_RETURN,
			this.PURCHASE_RETURN_ADD,
			this.PURCHASE_ORDER,
			this.PURCHASE_ORDER_ADD,
			this.COMM_VOU,
			this.COMM_VOU_ADD,
			this.CASH_DISC_VOU,
			this.CASH_DISC_VOU_ADD,
			this.BANK,
			this.BANK_ADD,
			this.CUSTOMER,
			this.CUSTOMER_ADD,
			this.SUPPLIER,
			this.SUPPLIER_ADD,
			this.AGENT,
			this.AGENT_ADD,
			this.ACCT,
			this.ACCT_ADD,
			this.PRINT_ENVELOPE,
			this.ACCT_TYPE,
			this.ACCT_TYPE_ADD,
			this.INV_ADJUST,
			this.INV_ADJUST_ADD,
			this.COMM_LIST,
			this.COMM_LIST_ADD,
			this.SALES_PRICE_LIST,
			this.SALES_PRICE_LIST_ADD,
			this.SERVICE,
			this.SERVICE_ADD,
			this.PRODUCT,
			this.PRODUCT_ADD,
			this.PROD_OTH_CHARGE,
			this.PROD_OTH_CHARGE_ADD,
			this.TAX_CAT,
			this.TAX_CAT_ADD,
			this.PROD_CAT,
			this.PROD_CAT_ADD,
			this.UNIT,
			this.UNIT_ADD,
			this.RPT_SALES_RETURN_BY_MONTH,
			this.RPT_SALES_RETURN_BY_DAY,
			this.RPT_SALES_RETURN_BY_CUSTOMER,
			this.RPT_SALES_RETURN_BY_CATEGORY,
			this.RPT_SALES_RETURN_BY_PRODUCT,
			this.RPT_SALES_RETURN_BY_CITY,
			this.RPT_SALES_RETURN_BY_STATE,
			this.RPT_SALES_RETURN_BY_AGENT,
			this.RPT_SALES_BY_MONTH,
			this.RPT_SALES_BY_DAY,
			this.RPT_SALES_BY_CUSTOMER,
			this.RPT_SALES_BY_CATEGORY,
			this.RPT_SALES_BY_PRODUCT,
			this.RPT_SALES_BY_CITY,
			this.RPT_SALES_BY_STATE,
			this.RPT_SALES_BY_AGENT,
			this.RPT_PURCHASE_RETURN_BY_MONTH,
			this.RPT_PURCHASE_RETURN_BY_DAY,
			this.RPT_PURCHASE_RETURN_BY_SUPPLIER,
			this.RPT_PURCHASE_RETURN_BY_CATEGORY,
			this.RPT_PURCHASE_RETURN_BY_PRODUCT,
			this.RPT_PURCHASE_RETURN_BY_CITY,
			this.RPT_PURCHASE_RETURN_BY_STATE,
			this.RPT_PURCHASE_BY_MONTH,
			this.RPT_PURCHASE_BY_DAY,
			this.RPT_PURCHASE_BY_SUPPLIER,
			this.RPT_PURCHASE_BY_CATEGORY,
			this.RPT_PURCHASE_BY_PRODUCT,
			this.RPT_PURCHASE_BY_CITY,
			this.RPT_PURCHASE_BY_STATE,
			this.RPT_ESTIMATE_PENDING_PRODUCTS,
			this.DUE_RCVABLE_BY_CUSTOMER,
			this.DUE_RCVABLE_BY_SALES,
			this.RPT_IO_SUMM_BY_PROD_CAT,
			this.RPT_IO_SUMM_BY_MONTH,
			this.RPT_IO_SUMM_BY_PROD,
			this.RPT_IO_DET,
			this.LEDGER,
			this.TRIAL_BALANCE,
			this.TRADING_ACCT,
			this.PL_ACCT,
			this.BALANCE_SHEET,
			this.GST_LEDGER,
			this.INVENTORY_LEDGER,
			this.CURRENT_STOCK,
			this.CURRENT_STOCK_VAL,
			this.LOW_STOCK,
			this.SALES_ANALYSIS,
			this.CASH_DISC_VOU_LEDGER,
			this.CASH_DISC_VOU_LEDGER_2,
			this.COMM_VOU_LEDGER,
			this.SALES_ORDER_PENDING,
			this.PURCHASE_ORDER_PENDING,
			this.SCHEME,
			this.SCHEME_ADD,
			this.TRANSPORTER,
			this.TRANSPORTER_ADD,
			this.TAX_CAL_RATE,
			this.TAX_CAL_RATE_ADD,
			this.TAX,
			this.TAX_ADD,
			this.MSG_TEMPLATE,
			this.MSG_TEMPLATE_ADD,
			this.COMPANY_INFO,
			this.COMPANY_PROFILE,
			this.COMPANY_PROFILE_ADD,
			this.VOU_OTH_CH,
			this.VOU_OTH_CH_ADD,
			this.IMPORT_DATA,
			this.MENU_CONFIG,

		];
	}

	static getById(id: number) {
		let elements = this.values;
		for (let i = 0; i < elements.length; i++) {
			let element = elements[i];

			if (id === element.id)
				return element;
		}
		return null;
	}

	static getByKey(key: string) {
		let elements = this.values;
		for (let i = 0; i < elements.length; i++) {
			let element = elements[i];

			if (key === element.key)
				return element;
		}
		return null;
	}

}
