import OrderReportByTypeEnum from "../../enums/OrderReportByTypeEnum";

export default class RptSalesOrderPendingSm {

	dateFrom: Date | undefined | null;
	dateTo: Date | undefined | null;

	orderReportByType: OrderReportByTypeEnum | undefined | null;

	acctId: number | undefined | null;
	prodId: number | undefined | null;

	pending: boolean | undefined | null;

	no: string | undefined | null;
	note: string | undefined | null;

	doSet(searchModel: RptSalesOrderPendingSm): void {
		this.dateFrom = searchModel.dateFrom;
		this.dateTo = searchModel.dateTo;

		this.orderReportByType = searchModel.orderReportByType;

		this.acctId = searchModel.acctId;
		this.prodId = searchModel.prodId;

		this.pending = searchModel.pending;

		this.no = searchModel.no;
		this.note = searchModel.note;
	}

	reset(): void {
		this.dateFrom = undefined;
		this.dateTo = undefined;

		this.orderReportByType = undefined;

		this.acctId = undefined;
		this.prodId = undefined;

		this.pending = undefined;

		this.no = undefined;
		this.note = undefined;
	}

	isBlank(): boolean {
		return this.dateFrom === undefined
			&& this.dateTo === undefined
			&& this.orderReportByType === undefined
			&& this.acctId === undefined
			&& this.prodId === undefined
			&& this.pending === undefined
			&& this.no === undefined
			&& this.note === undefined;
	}

}
