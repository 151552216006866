export default class OrderReportByTypeEnum {
	public static readonly ACCT = new OrderReportByTypeEnum(1, "Customer / Supplier");
	public static readonly PRODUCT = new OrderReportByTypeEnum(2, "Product");

	private constructor(
		public readonly id: number,
		public readonly name: string) {
	}

	static get values(): OrderReportByTypeEnum[] {
		return [
			this.ACCT,
			this.PRODUCT,
		];
	}

	static getById(id: number): OrderReportByTypeEnum | null {
		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.id === id) {
				return element;
			}
		}
		return null;
	}

	static getByName(name: string): OrderReportByTypeEnum | null {
		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.name === name) {
				return element;
			}
		}
		return null;
	}
}
