import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import AcctForCustomerHdto from "../../types/res/list/AcctForCustomerHdto";

interface CustomerSelectDialogProps {
	showDialog: boolean;
	setShowDialog: React.Dispatch<React.SetStateAction<boolean>>;
	accounts: AcctForCustomerHdto[];
	setAcctForCustomer: (acctForCustomerSelected: AcctForCustomerHdto | null) => void;
}

function CustomerSelectDialog({
	showDialog,
	setShowDialog,
	accounts,
	setAcctForCustomer
}: CustomerSelectDialogProps): JSX.Element {

	const [textToSearch, setTextToSearch] = useState<string>("");

	// const [selectedAcctForCustomer, setSelectedAcctForCustomer] = useState<AcctForCustomerHdto | null>(null);
	const [selectedIndex, setSelectedIndex] = useState<number>(0);
	const textboxRef = useRef<HTMLInputElement | null>(null);
	const selectedItemRef = useRef<HTMLTableRowElement | null>(null);

	const [filteredAccounts, setFilteredAccounts] = useState<AcctForCustomerHdto[]>([]);

	useEffect(() => {
		if (showDialog && textboxRef.current != null) {
			textboxRef.current.focus();
		}
	}, [showDialog]);

	useEffect(() => {
		setFilteredAccounts(accounts);
	}, [accounts]);

	useEffect(() => {
		if (selectedItemRef != null && selectedItemRef.current) {
			selectedItemRef.current.scrollIntoView({
				block: "nearest",
				behavior: "smooth",
			});
		}
	}, [selectedIndex]);

	const handleClose = () => setShowDialog(false);

	const handleOk = (): void => {
		//setAcctForCustomer(selectedAcctForCustomer);
		setAcctForCustomer(filteredAccounts[selectedIndex]);
		setShowDialog(false);
	};

	const getFilteredAccts = (textToSearch: string): AcctForCustomerHdto[] => {
		let res: AcctForCustomerHdto[] = [];
		for (let i = 0; i < accounts.length; i++) {
			let acct = accounts[i];
			let tempStr = "";
			if (acct.name !== null && acct.name !== undefined) {
				tempStr = tempStr + acct.name;
			}
			if (acct.address !== null && acct.address !== undefined) {
				tempStr = tempStr + acct.address;
			}
			if (acct.city !== null && acct.city !== undefined) {
				tempStr = tempStr + acct.city;
			}
			if (acct.stateName !== null && acct.stateName !== undefined) {
				tempStr = tempStr + acct.stateName;
			}
			if (acct.pin !== null && acct.pin !== undefined) {
				tempStr = tempStr + acct.pin;
			}
			if (acct.gstNo !== null && acct.gstNo !== undefined) {
				tempStr = tempStr + acct.gstNo;
			}
			if (tempStr.toLowerCase().includes(textToSearch.toLowerCase())
			) {
				res.push(acct);
			}
		}
		return res;
	};

	const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {

		if (!event.altKey
			&& !event.ctrlKey
			&& !event.metaKey
			&& !event.shiftKey
			&& !event.defaultPrevented
			&& event.key === "Enter"
		) {
			event.preventDefault();
			event.stopPropagation();
			handleOk();
		}
	};

	const handleTxtKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {

		if (!event.altKey
			&& !event.ctrlKey
			&& !event.metaKey
			&& !event.shiftKey
			&& !event.defaultPrevented
			&& event.key === "ArrowUp"
		) {
			event.preventDefault();
			event.stopPropagation();

			if (selectedIndex > 0) {
				setSelectedIndex(selectedIndex - 1);
			}

		}
		else if (!event.altKey
			&& !event.ctrlKey
			&& !event.metaKey
			&& !event.shiftKey
			&& !event.defaultPrevented
			&& event.key === "ArrowDown"
		) {
			event.preventDefault();
			event.stopPropagation();

			if (selectedIndex < filteredAccounts.length - 1) {
				setSelectedIndex(selectedIndex + 1);
			}
		}
	};

	return (
		<Modal
			show={showDialog}
			onHide={handleClose}
			className="modal-xl"
			scrollable={true}
		>
			<Modal.Header closeButton>
				<Modal.Title>Customers</Modal.Title>
			</Modal.Header>
			<Modal.Body
				onKeyDown={handleKeyDown}
			>

				<div className="mb-3">
					<input
						type="text"
						className="form-control"
						value={textToSearch}
						onChange={((e) => {
							setTextToSearch(e.target.value);
							let tempVal = getFilteredAccts(e.target.value);
							setFilteredAccounts(tempVal);
							//setSelectedAcctForCustomer(tempVal[0]);
							setSelectedIndex(0);
						})}
						onKeyDown={handleTxtKeyDown}
						ref={textboxRef}
					/>
				</div>

				<div className="flex-grow-1 mb-2 table-responsive text-nowrap">
					<table
						id="navigate"
						className="table table-hover table-bordered table-sm overflow-x-auto m-0 fixTableHead"
					>
						<thead className="bg-body-tertiary">
							<tr>
								<th scope="col"></th>
								<th scope="col">Sr. No.</th>
								<th scope="col">Name</th>
								<th scope="col">Alias</th>
								<th scope="col">Address</th>
								<th scope="col">City</th>
								<th scope="col">State</th>
								<th scope="col">PIN</th>
								<th scope="col">GST No.</th>
							</tr>
						</thead>
						<tbody className="">
							{filteredAccounts.map((account, i) => (
								<tr
									key={i}
									onClick={(e) => setSelectedIndex(i)}
									ref={selectedIndex === i ? selectedItemRef : null}
								>
									<td tabIndex={0}>
										<div>
											<div className="form-check">
												<input
													className="form-check-input"
													type="radio"
													name="rdoCustomer"
													checked={selectedIndex === i}
													onChange={(e) => setSelectedIndex(i)}
												/>
											</div>
										</div>
									</td>
									<td tabIndex={0}>
										<div>{i + 1}</div>
									</td>
									<td tabIndex={0}><div>{account.name}</div></td>
									<td tabIndex={0}><div>{account.nameToPrint}</div></td>
									<td tabIndex={0}><div>{account.address}</div></td>
									<td tabIndex={0}><div>{account.city}</div></td>
									<td tabIndex={0}><div>{account.stateName}</div></td>
									<td tabIndex={0}><div>{account.pin}</div></td>
									<td tabIndex={0}><div>{account.gstNo}</div></td>
								</tr>
							))}
						</tbody>
						<tfoot className="bg-body-tertiary">
							<tr>
								<th scope="row">Total</th>
								<th>{accounts.length}</th>
								{[...Array(7)].map((x, i) => (
									<th key={i}></th>
								))}
							</tr>
						</tfoot>
					</table>
				</div>

			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={handleClose}>
					Cancel
				</Button>
				<Button variant="primary" onClick={handleOk}>
					Ok
				</Button>
			</Modal.Footer>
		</Modal >
	);
}

export default CustomerSelectDialog;
