import { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import ListCompanyProfileSmType from "../../types/sm/ListCompanyProfileSm";

interface ListVouOthChageSdPropsType {
	showModal: boolean;
	setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
	searchModel: ListCompanyProfileSmType;
	processOnSuccess: (listCompanyProfileSm: ListCompanyProfileSmType) => void;
}

function ListCompanyProfileSd({
	showModal,
	setShowModal,
	searchModel,
	processOnSuccess
}: ListVouOthChageSdPropsType) {

	const [name, setName] = useState(searchModel.name);

	const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		event.stopPropagation();
		doSearch();
	}

	const handleClickClose = () => {
		doCancel();
	};

	const handleEscapeKeyPress = (event: KeyboardEvent) => {
		if (!event.altKey
			&& !event.ctrlKey
			&& !event.metaKey
			&& !event.shiftKey
			&& event.key === "Escape"
		) {
			event.preventDefault();
			event.stopPropagation();
			doCancel();
		}
	};

	const isValid = (): boolean => {
		return true;
	}

	const doSearch = () => {

		if (!isValid) {
			return;
		}

		let searchModelNew = new ListCompanyProfileSmType();

		if (name !== undefined && name !== null && name !== "") {
			searchModelNew.name = name;
		}

		setShowModal(false);

		processOnSuccess(searchModelNew);
	}

	const doCancel = () => {
		setShowModal(false);

		setName(searchModel.name);
	}


	return (
		<Modal
			show={showModal}
			onHide={handleClickClose}
			onEscapeKeyDown={handleEscapeKeyPress}
			size="lg"
		>
			<Modal.Header closeButton>
				<Modal.Title>Message</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={handleFormSubmit} id="formSearch">
					<Form.Group as={Row} className="mb-3">
						<Form.Label column sm="2">
							Name
						</Form.Label>
						<Col sm="10">
							<Form.Control
								type="text"
								value={name === null ? "" : name}
								onChange={(e) => setName(e.target.value)}
							/>
						</Col>
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={handleClickClose}>
					Cancel
				</Button>
				<Button variant="primary" type="submit" form="formSearch"				>
					SAVE
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default ListCompanyProfileSd;

