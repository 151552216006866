import { BASE_URL, TOKEN } from "../config";
import ServerNotReachableErrorCommonCudObj from "../default_objects/ServerNotReachableErrorCommonCudObj";
import ServerSideErrorCommonCudObj from "../default_objects/ServerSideErrorCommonCudObj";
import ResourceEnum from "../enums/ResourceEnum";
import strFormat from "../helpers/StringHelper";
import { getQueryString } from "../helpers/UrlSearchParamHelper";
import CommonCudType from "../types/CommonCudType";
import CommonGetListType from "../types/CommonGetListType";
import ErrorResType from "../types/res/ErrorResType";
import CommVouLdto from "../types/res/list/CommVouLdto";
import RptCommVouLedger2Hdto from "../types/res/list/RptCommVouLedger2Hdto";
import ListCommVouSm from "../types/sm/ListCommVouSm";
import RptCommVouLedgerSm from "../types/sm/RptCommVouLedgerSm";

export default class CommVouApi {

	static async getCommVouLdtos(listCommVouSm: ListCommVouSm): Promise<CommonGetListType<CommVouLdto>> {
		try {

			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.COMM_VOU.name)
				+ getQueryString(listCommVouSm)
				, requestOptions);

			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as CommVouLdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async delete(id: number): Promise<CommonCudType> {

		try {
			const requestOptions = {
				method: "DELETE",
				headers: {
					"Authorization": "Bearer " + TOKEN.val,
					"Content-Type": "application/json"
				}
			}

			const response = await fetch(strFormat("%s/%s/%s", BASE_URL, ResourceEnum.COMM_VOU.name, id), requestOptions);

			if (response.status === 200) {
				return {
					isSuccess: true
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}


	}

	// report
	static async getRptCommVouLedgerHdtos(searchModel: RptCommVouLedgerSm): Promise<CommonGetListType<RptCommVouLedger2Hdto>> {
		try {
			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s",
					BASE_URL,
					ResourceEnum.COMM_VOU.name,
					"reports",
					"ledger"
				)
				+ getQueryString(searchModel)
				, requestOptions);
			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptCommVouLedger2Hdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}
}