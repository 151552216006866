import { useGvContext } from "../GvContext";
import MenuEnum from "../enums/MenuEnum";
import MenuResType from "../types/res/MenuResType";

interface MenuButtonOnlyProps {
	index: number;
	menu: MenuResType;
}

function MenuButtonOnly({ index, menu }: MenuButtonOnlyProps) {

	const gv = useGvContext();

	return (
		<div className="col-md-4 mb-4 menu-btn-wrapper">
			<div className="menu-btn-container">
				<button
					className="btn btn-secondary menu-btn justify-content-between"
					type="button"
					onClick={() => gv.menuHelper.openListView(MenuEnum.getByKey(menu.name)!)}
				>
					<span className="d-flex">
						<span className="flex-grow-1">
							<span className="fw-bold">
								{index + 1}.{" "}
								{menu.displayName === null || menu.displayName === ""
									? menu.name
									: menu.displayName}
							</span>
							<span className="d-block kbs">CS7</span>
						</span>
					</span>
				</button>
			</div>
		</div>
	);
}

export default MenuButtonOnly;
