import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Modal, Tab, Tabs } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import AcctApi from "../../api/AcctApi";
import ProdApi from "../../api/ProdApi";
import PurchaseOrderApi from "../../api/PurchaseOrderApi";
import UnitApi from "../../api/UnitApi";
import VoucherApi from "../../api/VoucherApi";
import VoucherTypeEnum from "../../enums/VoucherTypeEnum";
import AcctHelper from "../../helpers/AcctHelper";
import AcctJournalSumHelper from "../../helpers/AcctJournalSumHelper";
import ProdHelper from "../../helpers/ProdHelper";
import UnitHelper from "../../helpers/UnitHelper";
import CommonCudType from "../../types/CommonCudType";
import DropDownItemType from "../../types/DropDownItemType";
import PurchaseOrderItemType from "../../types/PurchaseOrderItemType";
import AcctForCustomerHdto from "../../types/res/list/AcctForCustomerHdto";
import ProdForEsimateLdto from "../../types/res/list/ProdForEsimateLdto";
import UnitLdto from "../../types/res/list/UnitLdto";
import PurchaseOrderRrHoDto from "../../types/rr/PurchaseOrderRrHoDto";
import CustomerSelectDialog from "../dialogs/CustomerSelectDialog";
import AddPurchaseOrderItemsSv from "./AddPurchaseOrderItemsSv";

interface AddPurchaseOrderProps {
	id?: number
}

function AddPurchaseOrder({
	id,
}: AddPurchaseOrderProps): JSX.Element {

	const [acctForSupplier, setAcctForSupplier] = useState<AcctForCustomerHdto | null>(null);

	const [noStr, setNoStr] = useState("");
	const [date, setDate] = useState<Date | null>(new Date());
	const [note, setNote] = useState("");

	const [purchaseOrderItems, setPurchaseOrderItems] = useState<PurchaseOrderItemType[]>([]);

	//
	const [accounts, setAccounts] = useState<AcctForCustomerHdto[] | null>(null);
	const [accountsMap, setAccountsMap] = useState<Map<number, AcctForCustomerHdto> | null>(null);

	const [productsMap, setProductsMap] = useState<Map<number, ProdForEsimateLdto> | null>(null);
	const [products, setProducts] = useState<ProdForEsimateLdto[] | null>(null);
	const [productsForDd, setProductsForDd] = useState<DropDownItemType[] | null>(null);

	const [unitsMap, setUnitsMap] = useState<Map<number, UnitLdto> | null>(null);
	const [units, setUnits] = useState<UnitLdto[] | null>(null);
	//
	const [showSupplierDialog, setShowSupplierDialog] = useState(false);

	const [messageDialogMessage, setMessageDialogMessage] = useState("");
	const [showMessageDialog, setShowMessageDialog] = useState(false);

	useEffect(() => {
		async function process() {

			AcctApi.getAcctForCustomerHdtos().then((accounts) => {
				if (accounts.isSuccess) {
					setAccounts(accounts.items!);
					setAccountsMap(AcctHelper.getMap(accounts.items!));
				}
			});


			ProdApi.getProdForEstimate().then((listProducts) => {
				setProducts(listProducts);
				setProductsForDd(ProdHelper.getProdDdis(listProducts));
				setProductsMap(ProdHelper.getProductMap2(listProducts));

			});

			UnitApi.getUnitLdtos().then((listUnits) => {
				if (listUnits.isSuccess) {
					setUnits(listUnits.items!);
					setUnitsMap(UnitHelper.getUnitMap(listUnits.items!));
				}
			});

			if (id == null) {
				let nextNoRes = await VoucherApi.getNextNo(VoucherTypeEnum.PURCHASE_ORDER);
				if (nextNoRes.isSuccess) {
					setNoStr(nextNoRes.item!.no);
				}
			}
		}
		process();

		window.addEventListener('keydown', handleKeyDown);

		// Clean up event listener on component unmount
		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	}, []);

	// Function to handle keyboard events
	const handleKeyDown = (event: KeyboardEvent) => {
		if (
			event.key === 's'
			&& event.ctrlKey
			&& !event.altKey
			&& !event.metaKey
			&& !event.shiftKey) {
			handleClickSave(event);
		}
		// Add more conditions for other shortcuts if needed
	};

	useEffect(() => {
		async function process() {
			if (
				accounts != null &&
				accountsMap != null &&
				productsMap != null &&
				products != null &&
				productsForDd != null &&
				unitsMap != null &&
				units != null
			) {

				if (id != null) {
					PurchaseOrderApi.getById(id).then((result) => fillInUi(result));
				}
			}
		}

		process();
	}, [accounts,
		accountsMap,
		productsMap,
		products,
		productsForDd,
		unitsMap,
		units,
	]);

	const handleAddItem = (addPurchaseOrderItem: PurchaseOrderItemType) => {
		setPurchaseOrderItems([...purchaseOrderItems, addPurchaseOrderItem]);
	}

	const handleUpdateItem = (index: number, purchaseOrderItem: PurchaseOrderItemType) => {
		purchaseOrderItems[index] = purchaseOrderItem;
		setPurchaseOrderItems(purchaseOrderItems);
	}

	const handleDeleteItem = (index: number) => {
		let purchaseOrderItemsLoc = [...purchaseOrderItems];
		purchaseOrderItemsLoc.splice(index, 1);
		setPurchaseOrderItems(purchaseOrderItemsLoc);
	}

	const handleClickSave = async (event: React.FormEvent<HTMLFormElement> | KeyboardEvent) => {

		event.preventDefault();

		if (!isValid()) {
			return;
		}

		let purchaseOrderReqHo: PurchaseOrderRrHoDto = {
			purchaseOrderRrDto: {
				date: date!,
				no: noStr,
				supplierId: acctForSupplier?.id!,
				note: note,
			},
			purchaseOrderItemRrDtos: purchaseOrderItems,
		}

		let res: CommonCudType;

		if (id === undefined || id === null) {
			res = await PurchaseOrderApi.createPurchaseOrder(purchaseOrderReqHo);
		}
		else {
			res = await PurchaseOrderApi.updatePurchaseOrder(id, purchaseOrderReqHo);
		}

		if (!res!.isSuccess) {
			setMessageDialogMessage(res!.errorRes?.message!);
			setShowMessageDialog(true);
		}
		else {
			setMessageDialogMessage("Saved");
			setShowMessageDialog(true);
			clearBoxes();
		}

	}

	const fillInUi = (purchaseOrderRrHo: PurchaseOrderRrHoDto) => {
		setAcctForSupplier(accountsMap?.get(purchaseOrderRrHo.purchaseOrderRrDto.supplierId)!);

		setNoStr(purchaseOrderRrHo.purchaseOrderRrDto.no.toString())
		setDate(moment(purchaseOrderRrHo.purchaseOrderRrDto.date).toDate());
		setNote(purchaseOrderRrHo.purchaseOrderRrDto.note);

		setPurchaseOrderItems(purchaseOrderRrHo.purchaseOrderItemRrDtos);
	};

	const handleClose = () => setShowMessageDialog(false);

	const handleDateChange = (date: Date | null) => {
		setDate(date);
	}

	const clearBoxes = () => {

		setNoStr("");
		setDate(new Date());
		setAcctForSupplier(null);
		setNote("");

		setPurchaseOrderItems([]);
	};


	const mySetAcctForSupplier = (acctForSupplierSelected: AcctForCustomerHdto | null) => {

		let creditDaysLimitLoc = "";
		let creditDaysCurrentStatusLoc = "";
		let overdueBalLoc = "";
		let isCreditDaysRightLoc = false;

		let creditAmtLoc = "";
		let currentBalLoc = "";
		let currenBalDiffLoc = "";
		let isCreditAmtRightLoc = false;

		if (acctForSupplierSelected !== null) {


			if (acctForSupplierSelected.creditDays !== undefined
				&& acctForSupplierSelected.creditDays !== null) {
				creditDaysLimitLoc = acctForSupplierSelected.creditDays.toLocaleString('en-IN');
			}

			if (acctForSupplierSelected.overdueDays !== undefined
				&& acctForSupplierSelected.overdueDays !== null
			) {
				creditDaysCurrentStatusLoc = acctForSupplierSelected.overdueDays.toLocaleString('en-IN');
			}

			if (acctForSupplierSelected.overdueAmt !== undefined
				&& acctForSupplierSelected.overdueAmt !== null
				&& acctForSupplierSelected.overdueAmt !== 0
			) {
				overdueBalLoc = acctForSupplierSelected.overdueAmt.toLocaleString('en-IN');
			}

			if (acctForSupplierSelected.overdueAmt !== undefined
				&& acctForSupplierSelected.overdueAmt !== null
				&& acctForSupplierSelected.overdueAmt <= 0
			) {
				isCreditDaysRightLoc = true;
			}

			//

			if (acctForSupplierSelected.creditAmtLimit !== undefined
				&& acctForSupplierSelected.creditAmtLimit !== null

			) {
				creditAmtLoc = acctForSupplierSelected.creditAmtLimit.toLocaleString('en-IN');
			}

			let acctJournalSumHelper = new AcctJournalSumHelper(acctForSupplierSelected.sumDebit,
				acctForSupplierSelected.sumCredit);

			if (acctJournalSumHelper.isCrDrSame()) {
				currentBalLoc = "0";
			}
			else if (acctJournalSumHelper.isCrBig()) {
				currentBalLoc = " - " + acctJournalSumHelper.getCrBal().toLocaleString('en-IN');
			}
			else if (acctJournalSumHelper.isDrBig()) {
				currentBalLoc = acctJournalSumHelper.getDrBal().toLocaleString('en-IN');
			}

			if (acctForSupplierSelected.creditAmtLimit !== null) {
				acctJournalSumHelper.credit = acctJournalSumHelper.credit + acctForSupplierSelected.creditAmtLimit;
			}

			let acctJournalSumHelperTemp = new AcctJournalSumHelper(
				acctJournalSumHelper.debit,
				acctJournalSumHelper.credit
			);

			currenBalDiffLoc = acctJournalSumHelperTemp.getCrBal().toLocaleString('en-IN');

			if (acctJournalSumHelperTemp.getCrBal() >= 0) {
				isCreditAmtRightLoc = true;
			}

		}

		setAcctForSupplier(acctForSupplierSelected)
	}

	const isValid = (): boolean => {

		if (date == null) {
			setMessageDialogMessage("Select date");
			setShowMessageDialog(true);
			return false;
		}

		if (acctForSupplier == null) {
			setMessageDialogMessage("Select supplier");
			setShowMessageDialog(true);
			return false;
		}

		if (purchaseOrderItems == null || purchaseOrderItems.length == 0) {
			setMessageDialogMessage("Add items");
			setShowMessageDialog(true);
			return false;
		}

		return true;
	}

	return (
		<>
			<div className="container-fluid">
				<form onSubmit={(event) => handleClickSave(event)}>

					<div className="row col-sm-6">
						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="txtNo" className="col-form-label">No.</label>
							</div>
							<div className="col-sm-10">
								<input
									id="txtNo"
									type="text"
									className="form-control"
									value={noStr}
									onChange={(e) => setNoStr(e.target.value)}
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="dpDate" className="col-form-label">Date</label>
							</div>
							<div className="col-sm-10">
								<DatePicker
									id="dpDate"
									dateFormat="dd-MM-yyyy"
									selected={date}
									onChange={(e) => handleDateChange(e)}
									className="form-control"
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="txtSupplier" className="col-form-label">Supplier</label>
							</div>
							<div className="col-sm-10">
								<div className="input-group">
									<input
										id="txtSupplier"
										type="text"
										className="form-control"
										value={acctForSupplier?.name ?? ""}
										readOnly={true}
									/>
									<button
										className="btn btn-outline-secondary" type="button"
										onClick={(e) => setShowSupplierDialog(true)}
									>...</button>
								</div>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="txtAddress" className="col-form-label">Address</label>
							</div>
							<div className="col-sm-10">
								<textarea
									id="txtAddress"
									className="form-control"
									rows={3}
									value={acctForSupplier?.address ?? ""}
									readOnly={true}
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="txtCity" className="col-form-label">City</label>
							</div>
							<div className="col-sm-10">
								<input
									id="txtCity"
									type="text"
									className="form-control"
									value={acctForSupplier?.city ?? ""}
									readOnly={true}
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label className="col-form-label">State</label>
							</div>
							<div className="col-sm-10">
								<input
									type="text"
									className="form-control"
									value={acctForSupplier?.stateName}
									readOnly={true}
								/>
							</div>
						</div>

					</div>
					<Tabs
						defaultActiveKey="tab-item-details"
						id="uncontrolled-tab-example"
						className="mb-3"
					>
						<Tab eventKey="tab-item-details" title="Item Details">

							<AddPurchaseOrderItemsSv
								products={products ?? []}
								productsMap={productsMap ?? new Map()}
								productsForDd={productsForDd ?? []}
								units={units ?? []}
								unitsMap={unitsMap ?? new Map()}
								handleAddItem={handleAddItem}
								handleUpdateItem={handleUpdateItem}
								handleDeleteItem={handleDeleteItem}
								addPurchaseOrderItems={purchaseOrderItems}
							/>
						</Tab>
						<Tab eventKey="tab-pending-items" title="Pending Items">
							Tab content for Profile
						</Tab>
					</Tabs>

					<button type="submit" className="btn btn-primary">Submit</button>
				</form>

			</div >

			<Modal show={showMessageDialog} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Message</Modal.Title>
				</Modal.Header>
				<Modal.Body>{messageDialogMessage}</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						OK
					</Button>
				</Modal.Footer>
			</Modal>


			<CustomerSelectDialog
				showDialog={showSupplierDialog}
				setShowDialog={setShowSupplierDialog}
				accounts={accounts ?? []}
				setAcctForCustomer={mySetAcctForSupplier}
			/>

		</>
	);
}

export default AddPurchaseOrder;
