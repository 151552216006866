import { useCallback, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";

function useYesNoDialog() {

	const [isOpen, setIsOpen] = useState(false);
	const [title, setTitle] = useState("Confirm");
	const [message, setMessage] = useState("Are you sure?");

	const fn = useRef<(choice: boolean) => void>();

	const getConfirmation = useCallback((
		title?: string,
		message?: string,
	) => {
		if (title != null) {
			setTitle(title);
		}
		if (message != null) {
			setMessage(message);
		}

		setIsOpen(true);

		return new Promise<boolean>((resolve) => {
			fn.current = (choice) => {
				setIsOpen(false);
				resolve(choice);
			};
		});
	}, []);


	const YesNoDialog = () => (
		<Modal show={isOpen} onHide={() => fn.current!(false)}>
			<Modal.Header closeButton>
				<Modal.Title>{title == null ? "Confirm" : title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>{message}</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={() => fn.current!(false)}>
					NO
				</Button>
				<Button variant="primary" onClick={() => fn.current!(true)}>
					YES
				</Button>
			</Modal.Footer>
		</Modal>
	);

	return [getConfirmation, YesNoDialog];
}

export default useYesNoDialog;
