import OrderTypeEnum from "../../enums/OrderTypeEnum";

export default class RptSalesOrderLedgerSm {

	orderType: OrderTypeEnum | undefined | null;

	acctId: number | undefined | null;
	prodId: number | undefined | null;

	pending: boolean | undefined | null;

	doSet(searchModel: RptSalesOrderLedgerSm): void {

		this.orderType = searchModel.orderType;

		this.acctId = searchModel.acctId;
		this.prodId = searchModel.prodId;

		this.pending = searchModel.pending;

	}

	reset(): void {

		this.orderType = undefined;

		this.acctId = undefined;
		this.prodId = undefined;

		this.pending = undefined;

	}

	isBlank(): boolean {
		return this.orderType === undefined
			&& this.acctId === undefined
			&& this.prodId === undefined
			&& this.pending === undefined;
	}

}
