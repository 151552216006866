import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import TransporterApi from "../../api/TransporterApi";
import { BASE_URL, TOKEN } from "../../config";
import TransporterResType from "../../types/res/TransporterResType";


interface AddTransporterProps {
	id?: number
}

function AddTransporter({
	id
}: AddTransporterProps): JSX.Element {

	const [name, setName] = useState("");
	const [transporterId, setTransporterId] = useState("");
	const [modalMessage, setModalMessage] = useState("");
	const [showModal, setshowModal] = useState(false);

	useEffect(() => {
		async function process() {

			if (id !== undefined && id !== null) {
				const result = await TransporterApi.getTransporter(id);
				fillInUi(result);
			}

		}
		process();
	}, []);

	const handleClickSave = async (event: React.FormEvent<HTMLFormElement>) => {

		event.preventDefault();

		if (id === undefined || id === null) {

			const requestOptions = {
				method: "POST",
				headers: {
					"Authorization": "Bearer " + TOKEN.val,
					"Content-Type": "application/json"
				},
				body: JSON.stringify({ "name": name, "transporterId": transporterId })
			}

			const res = await fetch(BASE_URL + "transporters", requestOptions);

			if (res.status !== 201) {
				const data = await res.json();
				setModalMessage(data.message);
				setshowModal(true);
			}
			else {
				setModalMessage("Saved");
				setshowModal(true);
				clearBoxes();
			}
		}
		else {

			const requestOptions = {
				method: "PUT",
				headers: {
					"Authorization": "Bearer " + TOKEN.val,
					"Content-Type": "application/json"
				},
				body: JSON.stringify({ "name": name, "transporterId": transporterId })
			}

			const res = await fetch(BASE_URL + "transporters/" + id, requestOptions);

			if (res.status !== 204) {
				const data = await res.json();
				setModalMessage(data.message);
				setshowModal(true);
			}
			else {
				setModalMessage("Saved");
				setshowModal(true);
				clearBoxes();
			}

		}

	}

	const fillInUi = (transporterResType: TransporterResType) => {
		setName(transporterResType.name);
		setTransporterId(transporterResType.transporterId);
	};

	const handleClose = () => setshowModal(false);
	const handleShow = () => setshowModal(true);

	const clearBoxes = () => {
		setName("");
		setTransporterId("");
	};

	return (
		<>
			<div className="container-fluid">
				<form onSubmit={(event) => handleClickSave(event)}>
					<div className="row col-sm-6">
						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label className="col-form-label">Name</label>
							</div>
							<div className="col-sm-10">
								<input
									type="text"
									className="form-control"
									value={name}
									onChange={(e) => setName(e.target.value)}
								/>
							</div>
						</div>
						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label className="col-form-label"
								>Transporter Id</label
								>
							</div>
							<div className="col-sm-10">
								<input
									type="text"
									className="form-control"
									value={transporterId}
									onChange={(e) => setTransporterId(e.target.value)}
								/>
							</div>
						</div>
					</div>

					<button type="submit" className="btn btn-primary">Submit</button>
				</form>
			</div>

			<Modal show={showModal} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Message</Modal.Title>
				</Modal.Header>
				<Modal.Body>{modalMessage}</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						OK
					</Button>
				</Modal.Footer>
			</Modal>

		</>
	);
}

export default AddTransporter;
