export default class LstSalesEnumCol {

	public static readonly NO = new LstSalesEnumCol("no", "Number");
	public static readonly SALES_LEDGER = new LstSalesEnumCol("sales_ledger", "Sales Ledger");
	public static readonly DATE = new LstSalesEnumCol("date", "Date");
	public static readonly CUSTOMER_NAME = new LstSalesEnumCol("customer_name", "Customer Name");
	public static readonly CITY = new LstSalesEnumCol("city", "City");
	public static readonly STATE_NAME = new LstSalesEnumCol("state_name", "State Name");
	public static readonly ORDER_NO = new LstSalesEnumCol("order_no", "Order No");
	public static readonly ORDER_DATE = new LstSalesEnumCol("order_date", "Order Date");
	public static readonly AGENT_NAME = new LstSalesEnumCol("agent_name", "Agent");
	public static readonly LR_NO = new LstSalesEnumCol("lr_no", "LR No");
	public static readonly LR_DATE = new LstSalesEnumCol("lr_date", "LR Date");
	public static readonly TRANSPORTER_NAME = new LstSalesEnumCol("transporter_name", "Transport");
	public static readonly AMT_PAYABLE = new LstSalesEnumCol("amt_payable", "Amount Payable");
	public static readonly AMT_CLRD = new LstSalesEnumCol("amt_clrd", "Amount Cleared");
	public static readonly AMT_UNCLRD = new LstSalesEnumCol("amt_unclrd", "Amount Uncleared");
	public static readonly DAYS_CNT = new LstSalesEnumCol("days_cnt", "Days Count");
	public static readonly NOTE = new LstSalesEnumCol("note", "Note");
	public static readonly COMPANY_PROFILE = new LstSalesEnumCol("company_profile", "Company Profile");
	public static readonly FINAL_INVOICE_NO = new LstSalesEnumCol("final_invoice_no", "Final Invoice No");
	public static readonly NO_OF_CARTON = new LstSalesEnumCol("no_of_carton", "No. Of Carton");
	public static readonly DELIVERY_BY = new LstSalesEnumCol("delivery_by", "Delivery By");
	public static readonly CREATED_BY = new LstSalesEnumCol("created_by", "Created By");
	public static readonly CREATED_AT = new LstSalesEnumCol("created_at", "Created At");

	private constructor(public readonly key: string, public readonly name: string) {
		
	}

	static get values(): LstSalesEnumCol[] {
		return [
			this.NO,
			this.SALES_LEDGER,
			this.DATE,
			this.CUSTOMER_NAME,
			this.CITY,
			this.STATE_NAME,
			this.ORDER_NO,
			this.ORDER_DATE,
			this.AGENT_NAME,
			this.LR_NO,
			this.LR_DATE,
			this.TRANSPORTER_NAME,
			this.AMT_PAYABLE,
			this.AMT_CLRD,
			this.AMT_UNCLRD,
			this.DAYS_CNT,
			this.NOTE,
			this.COMPANY_PROFILE,
			this.FINAL_INVOICE_NO,
			this.NO_OF_CARTON,
			this.DELIVERY_BY,
			this.CREATED_BY,
			this.CREATED_AT,
		];
	}

	static getByKey(key: string): LstSalesEnumCol | null {
		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.key === key) {
				return element;
			}
		}
		return null;
	}
}
