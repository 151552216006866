export default class LstReminderEnumCol {

	public static readonly PARENT_TYPE_ID = new LstReminderEnumCol("parent_type_id", "Parent");
	public static readonly NAME = new LstReminderEnumCol("name", "Name");
	public static readonly DATE_TIME = new LstReminderEnumCol("date_time", "Date Time");
	public static readonly SUBJECT = new LstReminderEnumCol("subject", "Subject");
	public static readonly IS_ON = new LstReminderEnumCol("is_on", "On/Off");
	public static readonly CREATED_BY_USER_NAME = new LstReminderEnumCol("created_by_user_name", "Created By");
	public static readonly CREATED_AT = new LstReminderEnumCol("created_at", "Created At");

	private constructor(public readonly key: string, public readonly name: string) {
		
	}

	static get values(): LstReminderEnumCol[] {
		return [
			this.PARENT_TYPE_ID,
			this.NAME,
			this.DATE_TIME,
			this.SUBJECT,
			this.IS_ON,
			this.CREATED_BY_USER_NAME,
			this.CREATED_AT,
		];
	}

	static getByKey(key: string): LstReminderEnumCol | null {
		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.key === key) {
				return element;
			}
		}
		return null;
	}
}
