import DropDownItemType from "../types/DropDownItemType";
import AcctForCustomerHdto from "../types/res/list/AcctForCustomerHdto";
import AcctLdto from "../types/res/list/AcctLdto";

export default class AcctHelper {

	static getAcctLdtoMap(items: AcctLdto[]) {
		const map = new Map<number, AcctLdto>();
		for (const item of items) {
			map.set(item.id, item);
		}
		return map;
	}


	static getAcctDdis(listAccts: AcctLdto[]): DropDownItemType[] {
		const finalArr: DropDownItemType[] = [];
		for (const item of listAccts) {
			finalArr.push({
				id: item.id,
				name: item.name
			});
		}
		return finalArr;
	}

	static getAcctDdi(acctLdto: AcctLdto): DropDownItemType {
		return {
			id: acctLdto.id,
			name: acctLdto.name
		};
	}

	static getArrForDd(items: AcctForCustomerHdto[]) {

		let finalArr: DropDownItemType[] = [];
		items.forEach(item => {
			finalArr.push({
				id: item.id,
				name: item.name
			});
		});
		return finalArr;

	}

	static getOneForDd(item: AcctForCustomerHdto): DropDownItemType {
		return {
			id: item.id,
			name: item.name
		};
	}

	static getMap(itemArr: AcctForCustomerHdto[]) {
		let map = new Map<number, AcctForCustomerHdto>([]);
		for (let index = 0; index < itemArr.length; index++) {
			const item = itemArr[index];
			map.set(item.id, item);
		}
		return map;
	}

}