import { BASE_URL, TOKEN } from "../config";
import ServerNotReachableErrorCommonCudObj from "../default_objects/ServerNotReachableErrorCommonCudObj";
import ServerSideErrorCommonCudObj from "../default_objects/ServerSideErrorCommonCudObj";
import ResourceEnum from "../enums/ResourceEnum";
import strFormat from "../helpers/StringHelper";
import { getQueryString } from "../helpers/UrlSearchParamHelper";
import CommonCudType from "../types/CommonCudType";
import CommonGetListType from "../types/CommonGetListType";
import ErrorResType from "../types/res/ErrorResType";
import RptSalesReturnByAgentHdto from "../types/res/list/RptSalesReturnByAgentHdto";
import RptSalesReturnByCityHdto from "../types/res/list/RptSalesReturnByCityHdto";
import RptSalesReturnByCustomerHdto from "../types/res/list/RptSalesReturnByCustomerHdto";
import RptSalesReturnByDayHdto from "../types/res/list/RptSalesReturnByDayHdto";
import RptSalesReturnByMonthHdto from "../types/res/list/RptSalesReturnByMonthHdto";
import RptSalesReturnByProductCategoryHdto from "../types/res/list/RptSalesReturnByProductCategoryHdto";
import RptSalesReturnByProductHdto from "../types/res/list/RptSalesReturnByProductHdto";
import RptSalesReturnByStateHdto from "../types/res/list/RptSalesReturnByStateHdto";
import RptSalesReturnDetailedByProductHdto from "../types/res/list/RptSalesReturnDetailedByProductHdto";
import RptSalesReturnDetailedByVoucherHdto from "../types/res/list/RptSalesReturnDetailedByVoucherHdto";
import SalesReturnLdto from "../types/res/list/SalesReturnLdto";
import SalesReturnRrHoDto from "../types/rr/SalesReturnRrHoDto";
import ListSalesReturnSm from "../types/sm/ListSalesReturnSm";
import RptSalesReturnByAgentSm from "../types/sm/RptSalesReturnByAgentSm";
import RptSalesReturnByCitySm from "../types/sm/RptSalesReturnByCitySm";
import RptSalesReturnByCustomerSm from "../types/sm/RptSalesReturnByCustomerSm";
import RptSalesReturnByDaySm from "../types/sm/RptSalesReturnByDaySm";
import RptSalesReturnByMonthSm from "../types/sm/RptSalesReturnByMonthSm";
import RptSalesReturnByProductCategorySm from "../types/sm/RptSalesReturnByProdCatSm";
import RptSalesReturnByProductSm from "../types/sm/RptSalesReturnByProdSm";
import RptSalesReturnByStateSm from "../types/sm/RptSalesReturnByStateSm";
import RptSalesReturnDetailedByProductSm from "../types/sm/RptSalesReturnDetailedByProdSm";
import RptSalesReturnDetailedByVoucherSm from "../types/sm/RptSalesReturnDetailedByVoucherSm";

export default class SalesReturnApi {

	static async getSalesReturnLdtos(listSalesReturnSm: ListSalesReturnSm): Promise<CommonGetListType<SalesReturnLdto>> {
		try {

			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.SALES_RETURN.name)
				+ getQueryString(listSalesReturnSm)
				, requestOptions);

			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as SalesReturnLdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async createSalesReturn(salesReturnRrHoDto: SalesReturnRrHoDto): Promise<CommonCudType> {

		const requestOptions = {
			method: "POST",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(salesReturnRrHoDto)
		}

		const res = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.SALES_RETURN.name), requestOptions);

		if (res.status !== 201) {
			const data: ErrorResType = await res.json();
			return {
				isSuccess: false,
				errorRes: data
			}
		}

		return {
			isSuccess: true,
		}

	}

	static async updateSalesReturn(id: number, salesReturnRrHoDto: SalesReturnRrHoDto): Promise<CommonCudType> {

		const requestOptions = {
			method: "PUT",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(salesReturnRrHoDto)
		}

		const res = await fetch(strFormat("%s/%s/%s", BASE_URL, ResourceEnum.SALES_RETURN.name, id), requestOptions);

		if (res.status !== 201) {
			const data: ErrorResType = await res.json();
			return {
				isSuccess: false,
				errorRes: data
			}
		}

		return {
			isSuccess: true,
		}

	}

	static async getById(id: number) {

		const requestOptions = {
			method: "GET",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			}
		}

		const res = await fetch(strFormat("%s/%s/%s", BASE_URL, ResourceEnum.SALES_RETURN.name, id), requestOptions);
		const data: SalesReturnRrHoDto = await res.json();
		return data;

	}

	static async delete(id: number): Promise<CommonCudType> {

		try {
			const requestOptions = {
				method: "DELETE",
				headers: {
					"Authorization": "Bearer " + TOKEN.val,
					"Content-Type": "application/json"
				}
			}

			const response = await fetch(strFormat("%s/%s/%s", BASE_URL, ResourceEnum.SALES_RETURN.name, id), requestOptions);

			if (response.status === 200) {
				return {
					isSuccess: true
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}


	}

	static async print(ids: number[]) {

		const requestOptions = {
			method: "GET",
			headers: {
				Authorization: "Bearer " + TOKEN.val,
				"Content-Type": "application/json",
			},
		};
		const res = await fetch(strFormat("%s/%s/print", BASE_URL, ResourceEnum.SALES_RETURN.name)
			+ getQueryString({
				ids: ids,
			})
			, requestOptions);

		var blob = await res.blob();
		return new Blob([blob], { type: 'application/pdf' });
	}

	// report
	static async getRptSalesReturnByMonthHdtos(searchModel: RptSalesReturnByMonthSm): Promise<CommonGetListType<RptSalesReturnByMonthHdto>> {
		try {

			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s/%s",
					BASE_URL,
					ResourceEnum.SALES_RETURN.name,
					"reports",
					"summary",
					"by-month"
				)
				+ getQueryString(searchModel)
				, requestOptions);

			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptSalesReturnByMonthHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async getRptSalesReturnByDayHdtos(searchModel: RptSalesReturnByDaySm): Promise<CommonGetListType<RptSalesReturnByDayHdto>> {
		try {

			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s/%s",
					BASE_URL,
					ResourceEnum.SALES_RETURN.name,
					"reports",
					"summary",
					"by-day"
				)
				+ getQueryString(searchModel)
				, requestOptions);

			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptSalesReturnByDayHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async getRptSalesReturnByAgentHdtos(searchModel: RptSalesReturnByAgentSm): Promise<CommonGetListType<RptSalesReturnByAgentHdto>> {
		try {

			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s/%s",
					BASE_URL,
					ResourceEnum.SALES_RETURN.name,
					"reports",
					"summary",
					"by-agent"
				)
				+ getQueryString(searchModel)
				, requestOptions);

			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptSalesReturnByAgentHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async getRptSalesReturnByCityHdtos(searchModel: RptSalesReturnByCitySm): Promise<CommonGetListType<RptSalesReturnByCityHdto>> {
		try {

			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s/%s",
					BASE_URL,
					ResourceEnum.SALES_RETURN.name,
					"reports",
					"summary",
					"by-city"
				)
				+ getQueryString(searchModel)
				, requestOptions);

			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptSalesReturnByCityHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async getRptSalesReturnByCustomerHdtos(searchModel: RptSalesReturnByCustomerSm): Promise<CommonGetListType<RptSalesReturnByCustomerHdto>> {
		try {

			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s/%s",
					BASE_URL,
					ResourceEnum.SALES_RETURN.name,
					"reports",
					"summary",
					"by-customer"
				)
				+ getQueryString(searchModel)
				, requestOptions);

			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptSalesReturnByCustomerHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async getRptSalesReturnByProdCatHdtos(searchModel: RptSalesReturnByProductCategorySm): Promise<CommonGetListType<RptSalesReturnByProductCategoryHdto>> {
		try {

			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s/%s",
					BASE_URL,
					ResourceEnum.SALES_RETURN.name,
					"reports",
					"summary",
					"by-product-category"
				)
				+ getQueryString(searchModel)
				, requestOptions);

			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptSalesReturnByProductCategoryHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async getRptSalesReturnByProdHdtos(searchModel: RptSalesReturnByProductSm): Promise<CommonGetListType<RptSalesReturnByProductHdto>> {
		try {

			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s/%s",
					BASE_URL,
					ResourceEnum.SALES_RETURN.name,
					"reports",
					"summary",
					"by-product"
				)
				+ getQueryString(searchModel)
				, requestOptions);

			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptSalesReturnByProductHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async getRptSalesReturnByStateHdtos(searchModel: RptSalesReturnByStateSm): Promise<CommonGetListType<RptSalesReturnByStateHdto>> {
		try {

			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s/%s",
					BASE_URL,
					ResourceEnum.SALES_RETURN.name,
					"reports",
					"summary",
					"by-state"
				)
				+ getQueryString(searchModel)
				, requestOptions);

			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptSalesReturnByStateHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async getRptSalesReturnDetailedByVoucherHdtos(searchModel: RptSalesReturnDetailedByVoucherSm): Promise<CommonGetListType<RptSalesReturnDetailedByVoucherHdto>> {
		try {

			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s/%s",
					BASE_URL,
					ResourceEnum.SALES_RETURN.name,
					"reports",
					"detailed",
					"by-voucher"
				)
				+ getQueryString(searchModel)
				, requestOptions);

			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptSalesReturnDetailedByVoucherHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async getRptSalesReturnDetailedByProdHdtos(searchModel: RptSalesReturnDetailedByProductSm): Promise<CommonGetListType<RptSalesReturnDetailedByProductHdto>> {
		try {

			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s/%s",
					BASE_URL,
					ResourceEnum.SALES_RETURN.name,
					"reports",
					"detailed",
					"by-product"
				)
				+ getQueryString(searchModel)
				, requestOptions);

			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptSalesReturnDetailedByProductHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}
}