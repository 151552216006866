import { useEffect, useState } from "react";
import { useGvContext } from "../../GvContext";
import SalesReturnApi from "../../api/SalesReturnApi";
import { BASE_URL, TOKEN } from "../../config";
import DateFormatEnum from "../../enums/DateFormatEnum";
import ListMenuEnum from "../../enums/ListMenuEnum";
import ResourceEnum from "../../enums/ResourceEnum";
import LstSalesReturnEnumCol from "../../enums/col/LstSalesReturnEnumCol";
import dateToString from "../../helpers/DateHelper";
import DateHelper2 from "../../helpers/DateHelper2";
import strFormat from "../../helpers/StringHelper";
import { getQueryString } from "../../helpers/UrlSearchParamHelper";
import RowType from "../../types/RowType";
import ColSettingResType from "../../types/res/ColSettingResType";
import SalesReturnLdto from "../../types/res/list/SalesReturnLdto";
import ListSalesReturnSm from "../../types/sm/ListSalesReturnSm";
import ListViewButtonBar from "../ListViewButtonBar";
import ListViewTable from "../ListViewTable";
import useConfirm from "../dialogs/ConfirmDialog";
import MessageDialog from "../dialogs/MessageDialog";

function ListSalesReturn(): JSX.Element {

	const resourceName = ResourceEnum.SALES_RETURN.name;

	const [colSettings, setColSettings] = useState<ColSettingResType[]>([]);
	const [colNames, setColNames] = useState<string[]>([]);

	const [rows, setRows] = useState<RowType[]>([]);
	const [selectedId, setSelectedId] = useState<number | null>(null);

	const [listSalesReturnSm, setListSalesReturnSm] = useState<ListSalesReturnSm>(new ListSalesReturnSm(DateHelper2.getFirstDateOfCurrentMonth(), DateHelper2.getLastDateOfCurrentMonth()));

	const [showSd, setShowSd] = useState<boolean>(false);
	
	const [messageDialogMessage, setMessageDialogMessage] = useState("");
	const [showMessageDialog, setShowMessageDialog] = useState(false);

	const confirm = useConfirm();
	const gv = useGvContext();

	useEffect(() => {
		async function process() {

			const requestOptions = {
				method: "GET",
				headers: {
					"Authorization": "Bearer " + TOKEN.val,
					"Content-Type": "application/json"
				}
			}

			const res = await fetch(strFormat("%s/%s/%s/%s",
				BASE_URL,
				ResourceEnum.MENU.name,
				ListMenuEnum.SALES_RETURN.id,
				ResourceEnum.COL_SETTING.name,
			), requestOptions);

			if (res.status !== 200) {
				setMessageDialogMessage("Server error");
				setShowMessageDialog(true);
			}
			else {
				const data: ColSettingResType[] = await res.json();
				setColSettings(data);

				let colNamesLocal: string[] = [];
				data.forEach((item) => {
					colNamesLocal.push(item.colName);
				});
				setColNames(colNamesLocal);
				loadData(listSalesReturnSm, data);
			}

		}
		process();
	}, []);

	const handleClickAdd = () => {
		gv.menuHelper.openAddSalesReturn();
	};

	const handleClickEdit = () => {
		if (selectedId === null) {
			setMessageDialogMessage("Select atleast one row");
			setShowMessageDialog(true);
			return;
		}
		gv.menuHelper.openAddSalesReturn(selectedId);
	};

	const handleClickDelete = async () => {
		if (selectedId === null) {
			setMessageDialogMessage("Select atleast one row");
			setShowMessageDialog(true);
			return;
		}

		let choice = await confirm!({
			message: "Are you sure you want to delete?",
			title: "Delete confirmation"
		});

		if (choice === undefined || choice === null || choice === false)
			return;

		const requestOptions = {
			method: "DELETE",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			}
		}

		const res = await fetch(strFormat("%s/%s/%s", BASE_URL, resourceName, selectedId), requestOptions);

		if (res.status !== 204) {
			const data = await res.json();
			setMessageDialogMessage(data.message);
			setShowMessageDialog(true);
		}
		else {
			loadData(listSalesReturnSm);
		}
	};

	const handleClickExport = () => {
		console.log("handleClickExport");
	};

	const handleClickPrint = async () => {

		if (selectedId === null) {
			setMessageDialogMessage("Select atleast one row");
			setShowMessageDialog(true);
			return;
		}

		const fileURL = window.URL.createObjectURL(await SalesReturnApi.print([selectedId]));
		//Open the URL on new Window
		const pdfWindow = window.open();
		pdfWindow!.location.href = fileURL;

	};

	const handleClickOpen = () => {
		console.log("handleClickOpen");
	};

	const handleClickFilter = () => {
		setShowSd(true);
	};

	const handleClickClearFilter = () => {
		console.log("handleClickClearFilter");
	};

	const handleClickColSettings = () => {
		console.log("handleClickColSettings");
	};

	const loadData = async (
		listSalesReturnSm: ListSalesReturnSm,
		colSettingsLocal?: ColSettingResType[]
	) => {

		let colSettingsToUse: ColSettingResType[];

		if (colSettingsLocal === undefined) {
			colSettingsToUse = colSettings;
		}
		else {
			colSettingsToUse = colSettingsLocal;
		}

		setListSalesReturnSm(listSalesReturnSm);

		const requestOptions = {
			method: "GET",
			headers: {
				Authorization: "Bearer " + TOKEN.val,
				"Content-Type": "application/json",
			},
		};
		const res = await fetch(strFormat("%s/%s", BASE_URL, resourceName)
			//+ new URLSearchParams(listVouOthChargeSm.toUrlSearchParams())
			+ getQueryString(listSalesReturnSm)
			, requestOptions);
		const results: SalesReturnLdto[] = await res.json();

		let dataToReturn: RowType[] = [];

		let sumAmt = 0;

		results.forEach((result) => {
			let oneRowArray: string[] = [];

			colSettingsToUse.forEach(colSetting => {
				switch (LstSalesReturnEnumCol.getByKey(colSetting.colKey)) {
					case LstSalesReturnEnumCol.AGENT_NAME:
						oneRowArray.push(result.agentName == null ? "" : result.agentName);
						break;
					case LstSalesReturnEnumCol.AMT_CLRD:
						oneRowArray.push("");
						break;
					case LstSalesReturnEnumCol.AMT_PAYABLE:
						oneRowArray.push(result.amtPayable.toLocaleString('en-IN'));
						sumAmt = sumAmt + result.amtPayable;
						break;
					case LstSalesReturnEnumCol.AMT_UNCLRD:
						oneRowArray.push("");
						break;
					case LstSalesReturnEnumCol.CITY:
						oneRowArray.push(result.city == null ? "" : result.city);
						break;
					case LstSalesReturnEnumCol.CUSTOMER_NAME:
						oneRowArray.push(result.customerName);
						break;
					case LstSalesReturnEnumCol.DATE:
						oneRowArray.push(dateToString(result.date, DateFormatEnum.APP_DATETIME));
						break;
					case LstSalesReturnEnumCol.NO:
						oneRowArray.push(result.no.toString());
						break;
					case LstSalesReturnEnumCol.NOTE:
						oneRowArray.push(result.note);
						break;
					case LstSalesReturnEnumCol.STATE_NAME:
						oneRowArray.push(result.stateName == null ? "" : result.stateName);
						break;
					case LstSalesReturnEnumCol.CREATED_BY:
						oneRowArray.push(result.createdByUserName);
						break;
					case LstSalesReturnEnumCol.CREATED_AT:
						oneRowArray.push(dateToString(result.createdAt, DateFormatEnum.APP_DATETIME));
						break;
					case LstSalesReturnEnumCol.SALES_RETURN_LEDGER:
						oneRowArray.push(result.salesReturnLedger == null ? "" : result.salesReturnLedger);
						break;
				}
			});

			dataToReturn.push({
				id: result.id,
				data: oneRowArray
			});
		});

		setRows(dataToReturn);
	}

	return (
		<>
			<ListViewButtonBar
				handleClickEdit={handleClickEdit}
				handleClickDelete={handleClickDelete}
				handleClickExport={handleClickExport}
				handleClickPrint={handleClickPrint}
				handleClickOpen={handleClickOpen}
				handleClickFilter={handleClickFilter}
				handleClickClearFilter={handleClickClearFilter}
				handleClickColSettings={handleClickColSettings}
			/>
			<ListViewTable
				colNames={colNames}
				rows={rows}
				setSelectedId={setSelectedId}
			/>

			<MessageDialog
				showDialog={showMessageDialog}
				modalMessage={messageDialogMessage}
				setShowDialog={setShowMessageDialog} />
		</>
	);
}

export default ListSalesReturn;
