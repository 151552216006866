export default class DueRcvableByCustomerEnumCol {

	public static readonly CUSTOMER_NAME = new DueRcvableByCustomerEnumCol("customer_name", "Customer Name");
	public static readonly CITY = new DueRcvableByCustomerEnumCol("city", "City");
	public static readonly STATE_NAME = new DueRcvableByCustomerEnumCol("state_name", "State Name");
	public static readonly CREDIT_DAYS = new DueRcvableByCustomerEnumCol("credit_days", "Credit Days");
	public static readonly SUM_AMT = new DueRcvableByCustomerEnumCol("sum_amt", "Amount");

	private constructor(
		public readonly key: string,
		public readonly name: string) {

	}

	static get values(): DueRcvableByCustomerEnumCol[] {
		return [
			this.CUSTOMER_NAME,
			this.CITY,
			this.STATE_NAME,
			this.CREDIT_DAYS,
			this.SUM_AMT,
		];
	}


	static getByKey(key: string): DueRcvableByCustomerEnumCol | null {
		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.key === key) {
				return element;
			}
		}
		return null;
	}
}
