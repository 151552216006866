export default class RptPurchaseOrderPendingSummByProdEnumCol {

	public static readonly PROD = new RptPurchaseOrderPendingSummByProdEnumCol("prod");
	public static readonly SUPPLIER_CNT = new RptPurchaseOrderPendingSummByProdEnumCol("supplier_cnt");
	public static readonly SUM_QTY = new RptPurchaseOrderPendingSummByProdEnumCol("sum_qty");

	private constructor(
		public readonly key: string) {
	}

	static get values(): RptPurchaseOrderPendingSummByProdEnumCol[] {
		return [
			this.PROD,
			this.SUPPLIER_CNT,
			this.SUM_QTY,
		];
	}

	static getByKey(key: string): RptPurchaseOrderPendingSummByProdEnumCol | null {
		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.key === key) {
				return element;
			}
		}
		return null;
	}
}
