import { BASE_URL, TOKEN } from "../config";
import ServerNotReachableErrorCommonCudObj from "../default_objects/ServerNotReachableErrorCommonCudObj";
import ServerSideErrorCommonCudObj from "../default_objects/ServerSideErrorCommonCudObj";
import ResourceEnum from "../enums/ResourceEnum";
import SalesPrintTypeEnum from "../enums/SalesPrintTypeEnum";
import strFormat from "../helpers/StringHelper";
import { getQueryString } from "../helpers/UrlSearchParamHelper";
import CommonCudType from "../types/CommonCudType";
import CommonGetListType from "../types/CommonGetListType";
import ErrorResType from "../types/res/ErrorResType";
import RptDueRcvableByCustomerHdto from "../types/res/list/RptDueRcvableByCustomerHdto";
import RptDueRcvableBySalesHdto from "../types/res/list/RptDueRcvableBySalesHdto";
import RptSalesByAgentHdto from "../types/res/list/RptSalesByAgentHdto";
import RptSalesByCityHdto from "../types/res/list/RptSalesByCityHdto";
import RptSalesByCustomerHdto from "../types/res/list/RptSalesByCustomerHdto";
import RptSalesByDayHdto from "../types/res/list/RptSalesByDayHdto";
import RptSalesByMonthHdto from "../types/res/list/RptSalesByMonthHdto";
import RptSalesByProductCategoryHdto from "../types/res/list/RptSalesByProductCategoryHdto";
import RptSalesByProductHdto from "../types/res/list/RptSalesByProductHdto";
import RptSalesByStateHdto from "../types/res/list/RptSalesByStateHdto";
import RptSalesDetailedByProductHdto from "../types/res/list/RptSalesDetailedByProductHdto";
import RptSalesDetailedByVoucherHdto from "../types/res/list/RptSalesDetailedByVoucherHdto";
import RptSalesProfitByCustomerHdto from "../types/res/list/RptSalesProfitByCustomerHdto";
import RptSalesProfitByProdCatHdto from "../types/res/list/RptSalesProfitByProdCatHdto";
import RptSalesProfitByProdHdto from "../types/res/list/RptSalesProfitByProdHdto";
import RptSalesProfitBySalesItemHdto from "../types/res/list/RptSalesProfitBySalesItemHdto";
import RptSalesProfitBySalesVoucherHdto from "../types/res/list/RptSalesProfitBySalesVoucherHdto";
import SalesLdto from "../types/res/list/SalesLdto";
import SalesRrHoDto from "../types/rr/SalesRrHoDto";
import ListSalesSm from "../types/sm/ListSalesSm";
import RptDueRcvableByCustomerSm from "../types/sm/RptDueRcvableByCustomerSm";
import RptDueRcvableBySalesSm from "../types/sm/RptDueRcvableBySalesSm";
import RptSalesByAgentSm from "../types/sm/RptSalesByAgentSm";
import RptSalesByCitySm from "../types/sm/RptSalesByCitySm";
import RptSalesByCustomerSm from "../types/sm/RptSalesByCustomerSm";
import RptSalesByDaySm from "../types/sm/RptSalesByDaySm";
import RptSalesByMonthSm from "../types/sm/RptSalesByMonthSm";
import RptSalesByProductCategorySm from "../types/sm/RptSalesByProdCatSm";
import RptSalesByProductSm from "../types/sm/RptSalesByProdSm";
import RptSalesByStateSm from "../types/sm/RptSalesByStateSm";
import RptSalesDetailedByProductSm from "../types/sm/RptSalesDetailedByProdSm";
import RptSalesDetailedByVoucherSm from "../types/sm/RptSalesDetailedByVoucherSm";
import RptSalesProfitByCustomerSm from "../types/sm/RptSalesProfitByCustomerSm";
import RptSalesProfitByProdCatSm from "../types/sm/RptSalesProfitByProdCatSm";
import RptSalesProfitByProdSm from "../types/sm/RptSalesProfitByProdSm";
import RptSalesProfitBySalesItemSm from "../types/sm/RptSalesProfitBySalesItemSm";
import RptSalesProfitBySalesVoucherSm from "../types/sm/RptSalesProfitBySalesVoucherSm";

export default class SalesApi {

	static async getSalesLdtos(listSalesSm: ListSalesSm): Promise<CommonGetListType<SalesLdto>> {
		try {
			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.SALES.name)
				+ getQueryString(listSalesSm)
				, requestOptions);
			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as SalesLdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async createSales(salesRrHoDto: SalesRrHoDto): Promise<CommonCudType> {
		const requestOptions = {
			method: "POST",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(salesRrHoDto)
		}
		const res = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.SALES.name), requestOptions);
		if (res.status !== 201) {
			const data: ErrorResType = await res.json();
			return {
				isSuccess: false,
				errorRes: data
			}
		}
		return {
			isSuccess: true,
		}
	}

	static async updateSales(id: number, salesRrHoDto: SalesRrHoDto): Promise<CommonCudType> {
		const requestOptions = {
			method: "PUT",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(salesRrHoDto)
		}
		const res = await fetch(strFormat("%s/%s/%s", BASE_URL, ResourceEnum.SALES.name, id), requestOptions);
		if (res.status !== 201) {
			const data: ErrorResType = await res.json();
			return {
				isSuccess: false,
				errorRes: data
			}
		}
		return {
			isSuccess: true,
		}
	}

	static async getById(id: number) {
		const requestOptions = {
			method: "GET",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			}
		}
		const res = await fetch(strFormat("%s/%s/%s", BASE_URL, ResourceEnum.SALES.name, id), requestOptions);
		const data: SalesRrHoDto = await res.json();
		return data;
	}

	static async delete(id: number): Promise<CommonCudType> {
		try {
			const requestOptions = {
				method: "DELETE",
				headers: {
					"Authorization": "Bearer " + TOKEN.val,
					"Content-Type": "application/json"
				}
			}
			const response = await fetch(strFormat("%s/%s/%s", BASE_URL, ResourceEnum.SALES.name, id), requestOptions);
			if (response.status === 200) {
				return {
					isSuccess: true
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}

	}

	static async print(ids: number[], salesPrintType: SalesPrintTypeEnum) {
		const requestOptions = {
			method: "GET",
			headers: {
				Authorization: "Bearer " + TOKEN.val,
				"Content-Type": "application/json",
			},
		};
		const res = await fetch(strFormat("%s/%s/print", BASE_URL, ResourceEnum.SALES.name)
			+ getQueryString({
				ids: ids,
				salesPrintType: salesPrintType.enumName,
			})
			, requestOptions);
		var blob = await res.blob();
		return new Blob([blob], { type: 'application/pdf' });
	}
	// report
	static async getRptSalesByMonthHdtos(searchModel: RptSalesByMonthSm): Promise<CommonGetListType<RptSalesByMonthHdto>> {
		try {
			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s/%s",
					BASE_URL,
					ResourceEnum.SALES.name,
					"reports",
					"summary",
					"by-month"
				)
				+ getQueryString(searchModel)
				, requestOptions);
			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptSalesByMonthHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async getRptSalesByDayHdtos(searchModel: RptSalesByDaySm): Promise<CommonGetListType<RptSalesByDayHdto>> {
		try {
			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s/%s",
					BASE_URL,
					ResourceEnum.SALES.name,
					"reports",
					"summary",
					"by-day"
				)
				+ getQueryString(searchModel)
				, requestOptions);
			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptSalesByDayHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async getRptSalesByAgentHdtos(searchModel: RptSalesByAgentSm): Promise<CommonGetListType<RptSalesByAgentHdto>> {
		try {
			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s/%s",
					BASE_URL,
					ResourceEnum.SALES.name,
					"reports",
					"summary",
					"by-agent"
				)
				+ getQueryString(searchModel)
				, requestOptions);
			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptSalesByAgentHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async getRptSalesByCityHdtos(searchModel: RptSalesByCitySm): Promise<CommonGetListType<RptSalesByCityHdto>> {
		try {
			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s/%s",
					BASE_URL,
					ResourceEnum.SALES.name,
					"reports",
					"summary",
					"by-city"
				)
				+ getQueryString(searchModel)
				, requestOptions);
			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptSalesByCityHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async getRptSalesByCustomerHdtos(searchModel: RptSalesByCustomerSm): Promise<CommonGetListType<RptSalesByCustomerHdto>> {
		try {
			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s/%s",
					BASE_URL,
					ResourceEnum.SALES.name,
					"reports",
					"summary",
					"by-customer"
				)
				+ getQueryString(searchModel)
				, requestOptions);
			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptSalesByCustomerHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async getRptSalesByProdCatHdtos(searchModel: RptSalesByProductCategorySm): Promise<CommonGetListType<RptSalesByProductCategoryHdto>> {
		try {
			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s/%s",
					BASE_URL,
					ResourceEnum.SALES.name,
					"reports",
					"summary",
					"by-product-category"
				)
				+ getQueryString(searchModel)
				, requestOptions);
			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptSalesByProductCategoryHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async getRptSalesByProdHdtos(searchModel: RptSalesByProductSm): Promise<CommonGetListType<RptSalesByProductHdto>> {
		try {
			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s/%s",
					BASE_URL,
					ResourceEnum.SALES.name,
					"reports",
					"summary",
					"by-product"
				)
				+ getQueryString(searchModel)
				, requestOptions);
			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptSalesByProductHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async getRptSalesByStateHdtos(searchModel: RptSalesByStateSm): Promise<CommonGetListType<RptSalesByStateHdto>> {
		try {
			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s/%s",
					BASE_URL,
					ResourceEnum.SALES.name,
					"reports",
					"summary",
					"by-state"
				)
				+ getQueryString(searchModel)
				, requestOptions);
			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptSalesByStateHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async getRptSalesDetailedByVoucherHdtos(searchModel: RptSalesDetailedByVoucherSm): Promise<CommonGetListType<RptSalesDetailedByVoucherHdto>> {
		try {
			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s/%s",
					BASE_URL,
					ResourceEnum.SALES.name,
					"reports",
					"detailed",
					"by-voucher"
				)
				+ getQueryString(searchModel)
				, requestOptions);
			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptSalesDetailedByVoucherHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async getRptSalesDetailedByProdHdtos(searchModel: RptSalesDetailedByProductSm): Promise<CommonGetListType<RptSalesDetailedByProductHdto>> {
		try {
			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s/%s",
					BASE_URL,
					ResourceEnum.SALES.name,
					"reports",
					"detailed",
					"by-product"
				)
				+ getQueryString(searchModel)
				, requestOptions);
			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptSalesDetailedByProductHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async getRptSalesProfitByCustomerHdtos(searchModel: RptSalesProfitByCustomerSm): Promise<CommonGetListType<RptSalesProfitByCustomerHdto>> {
		try {
			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s/%s",
					BASE_URL,
					ResourceEnum.SALES.name,
					"reports",
					"profit",
					"by-customer"
				)
				+ getQueryString(searchModel)
				, requestOptions);
			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptSalesProfitByCustomerHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async getRptSalesProfitByProdHdtos(searchModel: RptSalesProfitByProdSm): Promise<CommonGetListType<RptSalesProfitByProdHdto>> {
		try {
			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s/%s",
					BASE_URL,
					ResourceEnum.SALES.name,
					"reports",
					"profit",
					"by-product"
				)
				+ getQueryString(searchModel)
				, requestOptions);
			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptSalesProfitByProdHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async getRptSalesProfitByProdCatHdtos(searchModel: RptSalesProfitByProdCatSm): Promise<CommonGetListType<RptSalesProfitByProdCatHdto>> {
		try {
			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s/%s",
					BASE_URL,
					ResourceEnum.SALES.name,
					"reports",
					"profit",
					"by-product-category"
				)
				+ getQueryString(searchModel)
				, requestOptions);
			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptSalesProfitByProdCatHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async getRptSalesProfitBySalesItemHdtos(searchModel: RptSalesProfitBySalesItemSm): Promise<CommonGetListType<RptSalesProfitBySalesItemHdto>> {
		try {
			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s/%s",
					BASE_URL,
					ResourceEnum.SALES.name,
					"reports",
					"profit",
					"by-sales-item"
				)
				+ getQueryString(searchModel)
				, requestOptions);
			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptSalesProfitBySalesItemHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async getRptSalesProfitBySalesVoucherHdtos(searchModel: RptSalesProfitBySalesVoucherSm): Promise<CommonGetListType<RptSalesProfitBySalesVoucherHdto>> {
		try {
			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s/%s",
					BASE_URL,
					ResourceEnum.SALES.name,
					"reports",
					"profit",
					"by-sales-voucher"
				)
				+ getQueryString(searchModel)
				, requestOptions);
			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptSalesProfitBySalesVoucherHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async getRptDueRcvableByCustomerHdtos(searchModel: RptDueRcvableByCustomerSm): Promise<CommonGetListType<RptDueRcvableByCustomerHdto>> {
		try {
			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s/%s",
					BASE_URL,
					ResourceEnum.SALES.name,
					"reports",
					"due-receivables",
					"by-customer"
				)
				+ getQueryString(searchModel)
				, requestOptions);
			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptDueRcvableByCustomerHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async getRptDueRcvableBySalesHdtos(searchModel: RptDueRcvableBySalesSm): Promise<CommonGetListType<RptDueRcvableBySalesHdto>> {
		try {
			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s/%s",
					BASE_URL,
					ResourceEnum.SALES.name,
					"reports",
					"due-receivables",
					"by-sales"
				)
				+ getQueryString(searchModel)
				, requestOptions);
			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptDueRcvableBySalesHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}
}