import RateTypeEnum from "../../enums/RateTypeEnum";
import RateTypeHelper from "../../helpers/RateTypeHelper";
import VouOthChHelper from "../../helpers/VouOthChHelper";
import DropDownItemType from "../../types/DropDownItemType";
import PusaOthChNonTaxableRowHolder from "../../types/PusaOthChNonTaxableRowHolder";
import VouOthChargeLdto from "../../types/res/list/VouOthChargeLdto";
import VouOthChNonTaxableType from "../../types/VouOthChNonTaxableType";
import DownshiftSelect from "../DownshiftSelectV2";

interface AddPusaOthChNonTaxableRowSvProps {
	vouOthChNonTaxableResMap: Map<number, VouOthChargeLdto>;
	vouOthChNonTaxableResArrForDd: DropDownItemType[];
	vouOthChNonTaxable: VouOthChNonTaxableType | null;
	pusaOthChNonTaxableRowHolder: PusaOthChNonTaxableRowHolder;
	handleClickRemove: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => void;
	handleUpdateRow: (key: number, pusaOthChNonTaxableRowHolder: PusaOthChNonTaxableRowHolder) => void;
	myIndex: number;
}

function AddPusaOthChNonTaxableRowSv({
	vouOthChNonTaxableResMap,
	vouOthChNonTaxableResArrForDd,
	vouOthChNonTaxable,
	pusaOthChNonTaxableRowHolder,
	handleClickRemove,
	handleUpdateRow,
	myIndex,
}: AddPusaOthChNonTaxableRowSvProps): JSX.Element {

	return (
		<div className="row g-3 align-items-center">
			<div className="col-auto">
				<button
					onClick={(e) => handleClickRemove(e, myIndex)}
					type="submit"
					className="btn btn-primary mb-3"
				>
					Remove
				</button>
			</div>
			<div className="col-auto">
				<DownshiftSelect
					inputId={"vou_oth_ch_on_item"}
					value={
						pusaOthChNonTaxableRowHolder.othChId !== undefined &&
							pusaOthChNonTaxableRowHolder.othChId !== null &&
							vouOthChNonTaxableResMap.get(pusaOthChNonTaxableRowHolder.othChId) !== null &&
							vouOthChNonTaxableResMap.get(pusaOthChNonTaxableRowHolder.othChId) !== undefined
							?
							VouOthChHelper.getVouOthChForDd(vouOthChNonTaxableResMap.get(pusaOthChNonTaxableRowHolder.othChId)!)
							:
							null
					}
					onChange={(e) => handleUpdateRow(
						myIndex,
						{
							othChId: e,
							rateStr: pusaOthChNonTaxableRowHolder.rateStr,
							rateTypeId: pusaOthChNonTaxableRowHolder.rateTypeId
						}
					)}
					options={vouOthChNonTaxableResArrForDd}
					isLoading={false}
					isDisabled={false}
					placeholder=""
				/>
			</div>
			<div className="col-auto">
				<input
					type="text"
					className="form-control"
					aria-describedby="passwordHelpInline"
					value={pusaOthChNonTaxableRowHolder.rateStr ?? ""}
					onChange={(e) => handleUpdateRow(
						myIndex,
						{
							othChId: pusaOthChNonTaxableRowHolder.othChId,
							rateStr: e.target.value,
							rateTypeId: pusaOthChNonTaxableRowHolder.rateTypeId
						}
					)}
				/>
			</div>
			<div className="col-auto">
				<DownshiftSelect
					inputId={"rateType"}
					value={
						pusaOthChNonTaxableRowHolder.rateTypeId !== null &&
							pusaOthChNonTaxableRowHolder.rateTypeId !== undefined &&
							RateTypeEnum.getById(pusaOthChNonTaxableRowHolder.rateTypeId) !== null &&
							RateTypeEnum.getById(pusaOthChNonTaxableRowHolder.rateTypeId) !== undefined
							?
							RateTypeHelper.getRateTypeForDd(RateTypeEnum.getById(pusaOthChNonTaxableRowHolder.rateTypeId)!)
							:
							null
					}
					onChange={(e) => handleUpdateRow(
						myIndex,
						{
							othChId: pusaOthChNonTaxableRowHolder.othChId,
							rateStr: pusaOthChNonTaxableRowHolder.rateStr,
							rateTypeId: e
						}
					)}
					options={RateTypeHelper.getRateTypesForDd()}
					isLoading={false}
					isDisabled={false}
					placeholder=""
				/>
			</div>
			<div className="col-auto">
				<input
					type="text"
					className="form-control"
					value={vouOthChNonTaxable?.derivedAmt?.toString() ?? ""}
					readOnly={true}
				/>
			</div>
		</div>
	);
}

export default AddPusaOthChNonTaxableRowSv;
