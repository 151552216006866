import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Modal, Tab, Tabs } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import AcctApi from "../../api/AcctApi";
import AgentApi from "../../api/AgentApi";
import CompanyProfileApi from "../../api/CompanyProfileApi";
import CustomerAgentApi from "../../api/CustomerAgentApi";
import CustomerPriceListAApi from "../../api/CustomerPriceListAApi";
import CustomerPriceListBApi from "../../api/CustomerPriceListBApi";
import ProdApi from "../../api/ProdApi";
import SalesApi from "../../api/SalesApi";
import SalesOrderApi from "../../api/SalesOrderApi";
import TaxApi from "../../api/TaxApi";
import TaxCatApi from "../../api/TaxCatApi";
import TransporterApi from "../../api/TransporterApi";
import UnitApi from "../../api/UnitApi";
import VouOthChApi from "../../api/VouOthChApi";
import VoucherApi from "../../api/VoucherApi";
import CalcOnTypeEnum from "../../enums/CalcOnTypeEnum";
import VoucherTypeEnum from "../../enums/VoucherTypeEnum";
import AcctHelper from "../../helpers/AcctHelper";
import AcctJournalSumHelper from "../../helpers/AcctJournalSumHelper";
import CompanyProfileHelper from "../../helpers/CompanyProfileHelper";
import ProdHelper from "../../helpers/ProdHelper";
import SalesHelper from "../../helpers/SalesHelper";
import SalesItemHelper from "../../helpers/SalesItemHelper";
import TaxCatHelper from "../../helpers/TaxCatHelper";
import TaxHelper from "../../helpers/TaxHelper";
import TransporterHelper from "../../helpers/TransporterHelper";
import UnitHelper from "../../helpers/UnitHelper";
import VouOthChHelper from "../../helpers/VouOthChHelper";
import CommonCudType from "../../types/CommonCudType";
import DropDownItemType from "../../types/DropDownItemType";
import SalesItemType from "../../types/SalesItemType";
import VouOthChNonTaxableType from "../../types/VouOthChNonTaxableType";
import VouOthChOnItemType from "../../types/VouOthChOnItemType";
import { PriceListRateResType } from "../../types/res/PriceListRateResType";
import AcctForCustomerHdto from "../../types/res/list/AcctForCustomerHdto";
import AgentLdto from "../../types/res/list/AgentLdto";
import CompanyProfileLdto from "../../types/res/list/CompanyProfileLdto";
import ProdForEsimateLdto from "../../types/res/list/ProdForEsimateLdto";
import TaxCatLdto from "../../types/res/list/TaxCatLdto";
import TaxLdto from "../../types/res/list/TaxLdto";
import TransporterLdto from "../../types/res/list/TransporterLdto";
import UnitLdto from "../../types/res/list/UnitLdto";
import VouOthChargeLdto from "../../types/res/list/VouOthChargeLdto";
import SalesAgentRrDto from "../../types/rr/SalesAgentRrDto";
import SalesItemRrDto from "../../types/rr/SalesItemRrDto";
import SalesRrHoDto from "../../types/rr/SalesRrHoDto";
import ListCompanyProfileSm from "../../types/sm/ListCompanyProfileSm";
import ListTaxSm from "../../types/sm/ListTaxSm";
import DownshiftSelect from "../DownshiftSelectV2";
import CustomerSelectDialog from "../dialogs/CustomerSelectDialog";
import AddPusaOthChNonTaxableSv from "./AddPusaOthChNonTaxableSv";
import AddPusaOthChOnItemSv from "./AddPusaOthChOnItemSv";
import AddPusaTaxSv from "./AddPusaTaxSv";
import AddSalesTotalSv from "./AddPusaTotalSv";
import AddSalesItemsSv from "./AddSalesItemsSv";
import ListTransporterSm from "../../types/sm/ListTransporterSm";

interface AddSalesProps {
	id?: number;
	salesOrderId?: number;
	estimateId?: number;
}

function AddSales({
	id,
	salesOrderId,
	estimateId,
}: AddSalesProps): JSX.Element {


	const [noStr, setNoStr] = useState("");
	const [date, setDate] = useState<Date | null>(new Date());

	const [acctForCustomer, setAcctForCustomer] = useState<AcctForCustomerHdto | null>(null);

	const [orderNo, setOrderNo] = useState("");
	const [orderDate, setOrderDate] = useState<Date | null>(new Date());

	const [lrNo, setLrNo] = useState("");
	const [lrDate, setLrDate] = useState<Date | null>(new Date());
	const [transporterId, setTransporterId] = useState<number | null>(-1);
	const [destination, setDestination] = useState("");

	const [finalInvoiceNo, setFinalInvoiceNo] = useState("");
	const [companyProfileId, setCompanyProfileId] = useState<number | null>(-1);
	const [note, setNote] = useState("");

	const [noOfCartonStr, setNoOfCartonStr] = useState("");
	const [deliveryBy, setDeliveryBy] = useState("");

	const [creditDaysLimit, setCreditDaysLimit] = useState<string>("");
	const [creditDaysCurrentStatus, setCreditDaysCurrentStatus] = useState<string>("");
	const [overdueBal, setOverdueBal] = useState<string>("");
	const [isCreditDaysRight, setIsCreditDaysRight] = useState<boolean>(true);

	const [creditAmt, setCreditAmt] = useState<string>("");
	const [currentBal, setCurrentBal] = useState<string>("");
	const [currentBalDiff, setCurrentBalDiff] = useState<string>("");
	const [isCreditAmtRight, setIsCreditAmtRight] = useState<boolean>(true);

	const [salesItems, setSalesItems] = useState<SalesItemType[]>([]);
	const [salesOthChOnItemArr, setSalesOthChOnItemArr] = useState<VouOthChOnItemType[]>([]);

	const [isAddTax, setIsAddTax] = useState<boolean>(true);
	const [isIntra, setIsIntra] = useState<boolean>(true);

	const [SalesOthChNonTaxableArr, setSalesOthChNonTaxableArr] = useState<VouOthChNonTaxableType[]>([]);
	//
	const [accounts, setAccounts] = useState<AcctForCustomerHdto[] | null>(null);
	const [accountsMap, setAccountsMap] = useState<Map<number, AcctForCustomerHdto> | null>(null);

	const [companyProfileListResArr, setCompanyProfileListResArr] = useState<CompanyProfileLdto[] | null>(null);
	const [companyProfileListResArrForDd, setCompanyProfileListResArrForDd] = useState<DropDownItemType[] | null>(null);
	const [companyProfileListResMap, setCompanyProfileListResMap] = useState<Map<number, CompanyProfileLdto> | null>(null);

	const [agentLdtos, setAgentLdtos] = useState<AgentLdto[]>([]);
	const [checkedAgentIds, setCheckedAgentIds] = useState<Set<number>>(new Set([]));

	const [taxListResArr, setTaxListArr] = useState<TaxLdto[] | null>(null);
	const [taxListResArrForDd, setTaxListArrForDd] = useState<DropDownItemType[] | null>(null);
	const [taxListResMap, setTaxListResMap] = useState<Map<number, TaxLdto> | null>(null);

	const [taxCats, setTaxCats] = useState<TaxCatLdto[] | null>(null);
	const [taxCatMap, setTaxCatMap] = useState<Map<number, TaxCatLdto> | null>(null);

	const [transporterListResArr, setTransporterListArr] = useState<TransporterLdto[] | null>(null);
	const [transporterListResArrForDd, setTransporterListArrForDd] = useState<DropDownItemType[] | null>(null);
	const [transporterListResMap, setTransporterListResMap] = useState<Map<number, TransporterLdto> | null>(null);

	const [vouOthChOnItemResArr, setVouOthChOnItemResArr] = useState<VouOthChargeLdto[] | null>(null);
	const [vouOthChOnItemResArrForDd, setVouOthChOnItemResArrForDd] = useState<DropDownItemType[] | null>(null);
	const [vouOthChOnItemResMap, setVouOthChOnItemResMap] = useState<Map<number, VouOthChargeLdto> | null>(null);

	const [vouOthChNonTaxableResArr, setVouOthChNonTaxableResArr] = useState<VouOthChargeLdto[] | null>(null);
	const [vouOthChNonTaxableResArrForDd, setVouOthChNonTaxableResArrForDd] = useState<DropDownItemType[] | null>(null);
	const [vouOthChNonTaxableResMap, setVouOthChNonTaxableResMap] = useState<Map<number, VouOthChargeLdto> | null>(null);

	const [productsMap, setProductsMap] = useState<Map<number, ProdForEsimateLdto> | null>(null);
	const [products, setProducts] = useState<ProdForEsimateLdto[] | null>(null);
	const [productsForDd, setProductsForDd] = useState<DropDownItemType[] | null>(null);

	const [unitsMap, setUnitsMap] = useState<Map<number, UnitLdto> | null>(null);
	const [units, setUnits] = useState<UnitLdto[] | null>(null);

	const [customerPriceListAMap, setCustomerPriceListAMap] = useState<Map<number, PriceListRateResType>>(new Map());

	const [customerPriceListBMap, setCustomerPriceListBMap] = useState<Map<number, PriceListRateResType>>(new Map());

	//
	const [showCustomerDialog, setShowCustomerDialog] = useState(false);

	const [messageDialogMessage, setMessageDialogMessage] = useState("");
	const [showMessageDialog, setShowMessageDialog] = useState(false);

	useEffect(() => {
		async function process() {

			AcctApi.getAcctForCustomerHdtos().then((accounts) => {
				if (accounts.isSuccess) {
					setAccounts(accounts.items!);
					setAccountsMap(AcctHelper.getMap(accounts.items!));
				}
			});

			CompanyProfileApi.getCompanyProfileLdtos(new ListCompanyProfileSm()).then((listCompanyProfiles) => {
				if (listCompanyProfiles.isSuccess) {
					setCompanyProfileListResArr(listCompanyProfiles.items!);
					setCompanyProfileListResArrForDd(CompanyProfileHelper.getCompanyProfilesForDd(listCompanyProfiles.items!));
					setCompanyProfileListResMap(CompanyProfileHelper.getCompanyProfileListResMap(listCompanyProfiles.items!));
				}
			});

			AgentApi.getAgentLdtos().then((agentLdtos) => {
				if (agentLdtos.isSuccess) {
					setAgentLdtos(agentLdtos.items!);
				}
			});

			TaxApi.getTaxLdtos(new ListTaxSm()).then((taxListArr) => {
				if (taxListArr.isSuccess) {
					setTaxListArr(taxListArr.items!);
					setTaxListArrForDd(TaxHelper.getTaxListForDd(taxListArr.items!));
					setTaxListResMap(TaxHelper.getTaxMap(taxListArr.items!));
				}
			});

			TaxCatApi.getTaxCatLdtos().then((listTaxCats) => {
				if (listTaxCats.isSuccess) {
					setTaxCats(listTaxCats.items!);
					setTaxCatMap(TaxCatHelper.getTaxCatListResMap(listTaxCats.items!));
				}
			});

			TransporterApi.getTransporterLdtos(new ListTransporterSm()).then((transporterListResArr) => {
				if (transporterListResArr.isSuccess) {
					setTransporterListArr(transporterListResArr.items!);
					setTransporterListArrForDd(TransporterHelper.getTransporterListResArrForDd(transporterListResArr.items!));
					setTransporterListResMap(TransporterHelper.getTransporterListResMap(transporterListResArr.items!));
				}
			});

			VouOthChApi.getVouOthChargeResDtos(CalcOnTypeEnum.ITEM, false).then((vouOthChOnItemArray) => {
				if (vouOthChOnItemArray.isSuccess) {
					setVouOthChOnItemResArr(vouOthChOnItemArray.items!);
					setVouOthChOnItemResArrForDd(VouOthChHelper.getVouOthChArrForDd(vouOthChOnItemArray.items!));
					setVouOthChOnItemResMap(VouOthChHelper.getVouOthChListResMap(vouOthChOnItemArray.items!));
				}
			});

			VouOthChApi.getVouOthChargeResDtos(CalcOnTypeEnum.VOUCHER, false).then((vouOthChNonTaxableResArr) => {
				if (vouOthChNonTaxableResArr.isSuccess) {
					setVouOthChNonTaxableResArr(vouOthChNonTaxableResArr.items!);
					setVouOthChNonTaxableResArrForDd(VouOthChHelper.getVouOthChArrForDd(vouOthChNonTaxableResArr.items!));
					setVouOthChNonTaxableResMap(VouOthChHelper.getVouOthChListResMap(vouOthChNonTaxableResArr.items!));
				}
			});

			ProdApi.getProdForEstimate().then((listProducts) => {
				setProducts(listProducts);
				setProductsForDd(ProdHelper.getProdDdis(listProducts));
				setProductsMap(ProdHelper.getProductMap2(listProducts));
			});

			UnitApi.getUnitLdtos().then((listUnits) => {
				if (listUnits.isSuccess) {
					setUnits(listUnits.items!);
					setUnitsMap(UnitHelper.getUnitMap(listUnits.items!));
				}
			});

			if (id == null && salesOrderId == null) {
				let nextNoRes = await VoucherApi.getNextNo(VoucherTypeEnum.SALES);
				if (nextNoRes.isSuccess) {
					setNoStr(nextNoRes.item!.no);
				}
			}
		}
		process();

	}, []);

	useEffect(() => {
		async function process() {
			if (
				accounts != null &&
				accountsMap != null &&
				companyProfileListResArr != null &&
				companyProfileListResArrForDd != null &&
				companyProfileListResMap != null &&
				taxListResArr != null &&
				taxListResArrForDd != null &&
				taxListResMap != null &&
				taxCats != null &&
				taxCatMap != null &&
				transporterListResArr != null &&
				transporterListResArrForDd != null &&
				transporterListResMap != null &&
				vouOthChOnItemResArr != null &&
				vouOthChOnItemResArrForDd != null &&
				vouOthChOnItemResMap != null &&
				vouOthChNonTaxableResArr != null &&
				vouOthChNonTaxableResArrForDd != null &&
				vouOthChNonTaxableResMap != null &&
				productsMap != null &&
				products != null &&
				productsForDd != null &&
				unitsMap != null &&
				units != null
			) {

				if (id != null) {
					SalesApi.getById(id).then((result) => fillInUi(result));
				}
				else if (salesOrderId != null) {

					let salesOrderRrHoType = await SalesOrderApi.getById(salesOrderId);

					let salesRrDto = {
						...salesOrderRrHoType.salesOrderRrDto,
						salesOrderId: salesOrderId,
					};
					let nextNoRes = await VoucherApi.getNextNo(VoucherTypeEnum.SALES_ORDER);
					if (nextNoRes.isSuccess) {
						salesRrDto.no = nextNoRes.item!.no;
					}
					salesRrDto.date = new Date();

					let res = await CustomerAgentApi.getCustomerAgentResDtosByCustomerId(salesRrDto.customerId);

					let salesAgentRrDto: SalesAgentRrDto[] = [];
					if (res.isSuccess) {
						res.items!.forEach((item) => {
							salesAgentRrDto.push({
								agentId: item.agentId
							});
						});
					}
					else {
						setShowMessageDialog(true);
						setMessageDialogMessage(res.errorRes?.message!);
						return;
					}

					salesRrDto.transporterId = accountsMap.get(salesRrDto.customerId)?.prefTransId ?? null;

					let salesItemRrDtosLoc: SalesItemRrDto[] = [];
					for (let i = 0; i < salesOrderRrHoType.salesOrderItemRrDtos.length; i++) {
						let salesOrderItemRrDto = salesOrderRrHoType.salesOrderItemRrDtos[i];
						salesItemRrDtosLoc.push({
							productId: salesOrderItemRrDto.productId,
							desc: salesOrderItemRrDto.desc,
							qty: salesOrderItemRrDto.qty!,
							priceListTypeId: salesOrderItemRrDto.priceListTypeId,
							priceListRate: salesOrderItemRrDto.priceListRate,
							discRate: salesOrderItemRrDto.discRate,
							discRateTypeId: salesOrderItemRrDto.discRateTypeId
						});
					}
					let salesRrHoDto: SalesRrHoDto = {
						salesRrDto: salesRrDto,
						salesAgentRrDtos: salesAgentRrDto,
						salesItemRrDtos: salesItemRrDtosLoc,
						salesOthChOnItemRrDtos: salesOrderRrHoType.salesOrderOthChOnItemRrDtos,
						salesOthChTaxableRrDtos: salesOrderRrHoType.salesOrderOthChTaxableRrDtos,
						salesOthChNonTaxableRrDtos: salesOrderRrHoType.salesOrderOthChNonTaxableRrDtos
					};

					fillInUi(salesRrHoDto);
				}
			}
		}

		process();
	}, [accounts,
		accountsMap,
		companyProfileListResArr,
		companyProfileListResArrForDd,
		companyProfileListResMap,
		taxListResArr,
		taxListResArrForDd,
		taxListResMap,
		taxCats,
		taxCatMap,
		transporterListResArr,
		transporterListResArrForDd,
		transporterListResMap,
		vouOthChOnItemResArr,
		vouOthChOnItemResArrForDd,
		vouOthChOnItemResMap,
		vouOthChNonTaxableResArr,
		vouOthChNonTaxableResArrForDd,
		vouOthChNonTaxableResMap,
		productsMap,
		products,
		productsForDd,
		unitsMap,
		units,
	]);

	const handleAddItem = (AddSalesItem: SalesItemType) => {
		setSalesItems(SalesItemHelper.calItems(
			isAddTax,
			isIntra,
			taxListResMap ?? new Map(),
			taxCatMap ?? new Map(),
			unitsMap ?? new Map(),
			productsMap ?? new Map(),
			[...salesItems, AddSalesItem], salesOthChOnItemArr
		));
	}

	const handleUpdateItem = (index: number, SalesItem: SalesItemType) => {
		salesItems[index] = SalesItem;
		setSalesItems(SalesItemHelper.calItems(
			isAddTax,
			isIntra,
			taxListResMap ?? new Map(),
			taxCatMap ?? new Map(),
			unitsMap ?? new Map(),
			productsMap ?? new Map(),
			salesItems ?? new Map(),
			salesOthChOnItemArr
		));
	}

	const handleDeleteItem = (index: number) => {
		let SalesItemsLoc = [...salesItems];
		SalesItemsLoc.splice(index, 1);
		setSalesItems(SalesItemHelper.calItems(
			isAddTax,
			isIntra,
			taxListResMap ?? new Map(),
			taxCatMap ?? new Map(),
			unitsMap ?? new Map(),
			productsMap ?? new Map(),
			SalesItemsLoc ?? new Map(),
			salesOthChOnItemArr
		));
	}

	const handleOthChOnItemChange = (SalesOthChOnItemArr: VouOthChOnItemType[]) => {
		setSalesItems(SalesItemHelper.calItems(
			isAddTax,
			isIntra,
			taxListResMap ?? new Map(),
			taxCatMap ?? new Map(),
			unitsMap ?? new Map(),
			productsMap ?? new Map(),
			salesItems ?? new Map(),
			SalesOthChOnItemArr
		));
		setSalesOthChOnItemArr(SalesOthChOnItemArr);
	}

	const handleOthChNonTaxableChange = (SalesOthChNonTaxableArr: VouOthChNonTaxableType[]) => {
		SalesHelper.calOthChNonTax(salesItems, SalesOthChNonTaxableArr);
		setSalesOthChNonTaxableArr(SalesOthChNonTaxableArr);
	}

	const handleClickSave = async (event: React.FormEvent<HTMLFormElement> | KeyboardEvent) => {

		event.preventDefault();

		if (!isValid()) {
			return;
		}

		let salesAgentIds: number[] = Array.from(checkedAgentIds);
		let salesAgentRrDtosLoc: SalesAgentRrDto[] = [];

		for (let i = 0; i < salesAgentIds.length; i++) {
			salesAgentRrDtosLoc.push({
				agentId: salesAgentIds[i]
			});
		}

		let salesReqHo: SalesRrHoDto = {
			salesRrDto: {
				date: date!,
				no: noStr,
				customerId: acctForCustomer?.id!,
				isIntra: isIntra,
				transporterId: transporterId === -1 ? null : transporterId,
				isTax: isAddTax,
				note: note,
				companyProfileId: companyProfileId === -1 ? null : companyProfileId,
				salesOrderId: salesOrderId === undefined ? null : salesOrderId,
			},
			salesAgentRrDtos: salesAgentRrDtosLoc,
			salesItemRrDtos: salesItems,
			salesOthChOnItemRrDtos: salesOthChOnItemArr,
			salesOthChTaxableRrDtos: [],
			salesOthChNonTaxableRrDtos: SalesOthChNonTaxableArr,
		}

		let res: CommonCudType;

		if (id == null) {
			res = await SalesApi.createSales(salesReqHo);
		}
		else {
			res = await SalesApi.updateSales(id, salesReqHo);
		}

		if (!res!.isSuccess) {
			setMessageDialogMessage(res!.errorRes?.message!);
			setShowMessageDialog(true);
		}
		else {
			setMessageDialogMessage("Saved");
			setShowMessageDialog(true);
			clearBoxes();
		}

	}

	const fillInUi = (salesRrHoDto: SalesRrHoDto) => {
		setAcctForCustomer(accountsMap?.get(salesRrHoDto.salesRrDto.customerId)!);

		setNoStr(salesRrHoDto.salesRrDto.no.toString())
		setDate(moment(salesRrHoDto.salesRrDto.date).toDate());

		setOrderNo(salesRrHoDto.salesRrDto.orderNo ?? "");
		setOrderDate(salesRrHoDto.salesRrDto.orderDate ?? null);

		let checkedAgentIdsLoc = new Set<number>([]);
		for (let i = 0; i < salesRrHoDto.salesAgentRrDtos.length; i++) {
			checkedAgentIdsLoc.add(salesRrHoDto.salesAgentRrDtos[i].agentId);
		}
		setCheckedAgentIds(checkedAgentIdsLoc);

		setLrNo(salesRrHoDto.salesRrDto.lrNo ?? "");
		setLrDate(salesRrHoDto.salesRrDto.lrDate ?? null);
		setTransporterId(salesRrHoDto.salesRrDto.transporterId ?? null);
		setDestination(salesRrHoDto.salesRrDto.destination ?? "");

		setFinalInvoiceNo(salesRrHoDto.salesRrDto.finalInvoiceNo ?? "");
		setCompanyProfileId(salesRrHoDto.salesRrDto.companyProfileId ?? null);
		setNote(salesRrHoDto.salesRrDto.note);
		setNoOfCartonStr(salesRrHoDto.salesRrDto.noOfCarton?.toString() ?? "");
		setDeliveryBy(salesRrHoDto.salesRrDto.deliveryBy ?? "");

		let salesItemsCalculated = SalesItemHelper.calItems(
			salesRrHoDto.salesRrDto.isTax,
			salesRrHoDto.salesRrDto.isIntra,
			taxListResMap ?? new Map(),
			taxCatMap ?? new Map(),
			unitsMap ?? new Map(),
			productsMap ?? new Map(),
			salesRrHoDto.salesItemRrDtos,
			salesRrHoDto.salesOthChOnItemRrDtos);
		setSalesItems(salesItemsCalculated);
		setSalesOthChOnItemArr(salesRrHoDto.salesOthChOnItemRrDtos);
		setIsAddTax(salesRrHoDto.salesRrDto.isTax);
		setIsIntra(salesRrHoDto.salesRrDto.isIntra);
		let SalesOthChNonTaxableArrLoc = salesRrHoDto.salesOthChNonTaxableRrDtos;
		SalesHelper.calOthChNonTax(
			salesItemsCalculated,
			SalesOthChNonTaxableArrLoc);
		setSalesOthChNonTaxableArr(SalesOthChNonTaxableArrLoc);
	};

	const handleClose = () => setShowMessageDialog(false);

	const handleDateChange = (date: Date | null) => {
		setDate(date);

		loadCustomerPriceListA(acctForCustomer?.id, date);
	}

	const clearBoxes = () => {

		setNoStr("");
		setDate(new Date());
		setAcctForCustomer(null);
		setCompanyProfileId(-1);
		setTransporterId(-1);
		setNote("");

		setSalesItems([]);
		setSalesOthChOnItemArr([]);
		setSalesOthChNonTaxableArr([]);
		setIsAddTax(true);
		setIsIntra(true);
	};


	const mySetAcctForCustomer = (acctForCustomerSelected: AcctForCustomerHdto | null) => {

		let creditDaysLimitLoc = "";
		let creditDaysCurrentStatusLoc = "";
		let overdueBalLoc = "";
		let isCreditDaysRightLoc = false;

		let creditAmtLoc = "";
		let currentBalLoc = "";
		let currenBalDiffLoc = "";
		let isCreditAmtRightLoc = false;

		if (acctForCustomerSelected !== null) {

			loadCustomerPriceListA(acctForCustomerSelected.id, date);

			setIsIntra(13 === acctForCustomerSelected.stateId)

			if (acctForCustomerSelected.creditDays !== undefined
				&& acctForCustomerSelected.creditDays !== null) {
				creditDaysLimitLoc = acctForCustomerSelected.creditDays.toLocaleString('en-IN');
			}

			if (acctForCustomerSelected.overdueDays !== undefined
				&& acctForCustomerSelected.overdueDays !== null
			) {
				creditDaysCurrentStatusLoc = acctForCustomerSelected.overdueDays.toLocaleString('en-IN');
			}

			if (acctForCustomerSelected.overdueAmt !== undefined
				&& acctForCustomerSelected.overdueAmt !== null
				&& acctForCustomerSelected.overdueAmt !== 0
			) {
				overdueBalLoc = acctForCustomerSelected.overdueAmt.toLocaleString('en-IN');
			}

			if (acctForCustomerSelected.overdueAmt !== undefined
				&& acctForCustomerSelected.overdueAmt !== null
				&& acctForCustomerSelected.overdueAmt <= 0
			) {
				isCreditDaysRightLoc = true;
			}

			//

			if (acctForCustomerSelected.creditAmtLimit !== undefined
				&& acctForCustomerSelected.creditAmtLimit !== null

			) {
				creditAmtLoc = acctForCustomerSelected.creditAmtLimit.toLocaleString('en-IN');
			}

			let acctJournalSumHelper = new AcctJournalSumHelper(acctForCustomerSelected.sumDebit,
				acctForCustomerSelected.sumCredit);

			if (acctJournalSumHelper.isCrDrSame()) {
				currentBalLoc = "0";
			}
			else if (acctJournalSumHelper.isCrBig()) {
				currentBalLoc = " - " + acctJournalSumHelper.getCrBal().toLocaleString('en-IN');
			}
			else if (acctJournalSumHelper.isDrBig()) {
				currentBalLoc = acctJournalSumHelper.getDrBal().toLocaleString('en-IN');
			}

			if (acctForCustomerSelected.creditAmtLimit !== null) {
				acctJournalSumHelper.credit = acctJournalSumHelper.credit + acctForCustomerSelected.creditAmtLimit;
			}

			let acctJournalSumHelperTemp = new AcctJournalSumHelper(
				acctJournalSumHelper.debit,
				acctJournalSumHelper.credit
			);

			currenBalDiffLoc = acctJournalSumHelperTemp.getCrBal().toLocaleString('en-IN');

			if (acctJournalSumHelperTemp.getCrBal() >= 0) {
				isCreditAmtRightLoc = true;
			}

		}

		setCreditDaysLimit(creditDaysLimitLoc);
		setCreditDaysCurrentStatus(creditDaysCurrentStatusLoc);
		setOverdueBal(overdueBalLoc);
		setIsCreditDaysRight(isCreditDaysRightLoc);

		setCreditAmt(creditAmtLoc);
		setCurrentBal(currentBalLoc);
		setCurrentBalDiff(currenBalDiffLoc);
		setIsCreditAmtRight(isCreditAmtRightLoc);

		setAcctForCustomer(acctForCustomerSelected)
	}

	const loadCustomerPriceListA = (customerId: number | null | undefined, asOnDate: Date | null) => {

		if (customerId == null || customerId <= 0 || asOnDate === null) {
			setCustomerPriceListAMap(new Map());
		}
		else {
			CustomerPriceListAApi.getCustomerPricePriceListAArr(customerId, asOnDate).then((priceListRateArr) => {
				if (priceListRateArr.isSuccess) {
					let priceListMap = new Map<number, PriceListRateResType>([]);
					for (let index = 0; index < priceListRateArr.items!.length; index++) {
						const priceListRate = priceListRateArr.items![index];
						priceListMap.set(priceListRate.productId, priceListRate);
					}

					setCustomerPriceListAMap(priceListMap);
				}
			});

			CustomerPriceListBApi.getCustomerPricePriceListBArr(customerId, asOnDate).then((priceListRateArr) => {
				if (priceListRateArr.isSuccess) {
					let priceListMap = new Map<number, PriceListRateResType>([]);
					for (let index = 0; index < priceListRateArr.items!.length; index++) {
						const priceListRate = priceListRateArr.items![index];
						priceListMap.set(priceListRate.productId, priceListRate);
					}

					setCustomerPriceListBMap(priceListMap);
				}
			});
		}
	}

	const isValid = (): boolean => {

		if (date == null) {
			setMessageDialogMessage("Select date");
			setShowMessageDialog(true);
			return false;
		}

		if (acctForCustomer == null) {
			setMessageDialogMessage("Select customer");
			setShowMessageDialog(true);
			return false;
		}

		if (salesItems == null || salesItems.length == 0) {
			setMessageDialogMessage("Add items");
			setShowMessageDialog(true);
			return false;
		}

		return true;
	}

	return (
		<>
			<div className="container-fluid">
				<form onSubmit={(event) => handleClickSave(event)}>

					<div className="row col-sm-6">
						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="txtNo" className="col-form-label">No.</label>
							</div>
							<div className="col-sm-10">
								<input
									id="txtNo"
									type="text"
									className="form-control"
									value={noStr}
									onChange={(e) => setNoStr(e.target.value)}
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="dpDate" className="col-form-label">Date</label>
							</div>
							<div className="col-sm-10">
								<DatePicker
									id="dpDate"
									dateFormat="dd-MM-yyyy"
									selected={date}
									onChange={(e) => handleDateChange(e)}
									className="form-control"
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="txtCustomer" className="col-form-label">Customer</label>
							</div>
							<div className="col-sm-10">
								<div className="input-group">
									<input
										id="txtCustomer"
										type="text"
										className="form-control"
										value={acctForCustomer?.name ?? ""}
										readOnly={true}
									/>
									<button
										className="btn btn-outline-secondary" type="button"
										onClick={(e) => setShowCustomerDialog(true)}
									>...</button>
								</div>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="txtAddress" className="col-form-label">Address</label>
							</div>
							<div className="col-sm-10">
								<textarea
									id="txtAddress"
									className="form-control"
									rows={3}
									value={acctForCustomer?.address ?? ""}
									readOnly={true}
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="txtCity" className="col-form-label">City</label>
							</div>
							<div className="col-sm-10">
								<input
									id="txtCity"
									type="text"
									className="form-control"
									value={acctForCustomer?.city ?? ""}
									readOnly={true}
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label className="col-form-label">State</label>
							</div>
							<div className="col-sm-10">
								<input
									type="text"
									className="form-control"
									value={acctForCustomer?.stateName}
									readOnly={true}
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label className="col-form-label">Order No</label>
							</div>
							<div className="col-sm-10">
								<input
									type="text"
									className="form-control"
									value={orderNo}
									onChange={(e) => setOrderNo(e.target.value)}
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="dpDate" className="col-form-label">Order Date</label>
							</div>
							<div className="col-sm-10">
								<DatePicker
									id="dpDate"
									dateFormat="dd-MM-yyyy"
									selected={orderDate}
									onChange={(e) => setOrderDate(e)}
									className="form-control"
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="dpDate" className="col-form-label">Agent</label>
							</div>
							<div className="col-sm-10">
								{agentLdtos.map((agentLdto, i) => (
									<div className="form-check">
										<input
											className="form-check-input"
											type="checkbox"
											value={agentLdto.id}
											name="chkAgent"
											id={"chkAgent" + i}
											checked={checkedAgentIds.has(agentLdto.id)}
											onChange={(e) => {
												let updatedCheckedAgentIds = new Set(checkedAgentIds);
												if (e.target.checked) {
													updatedCheckedAgentIds.add(agentLdto.id);
													setCheckedAgentIds(updatedCheckedAgentIds);
												}
												else {
													updatedCheckedAgentIds.delete(agentLdto.id);
													setCheckedAgentIds(updatedCheckedAgentIds);
												}
											}}
										/>
										<label className="form-check-label" htmlFor={"chkAgent" + i}>
											{agentLdto.name}
										</label>
									</div>
								))}
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label className="col-form-label">LR No</label>
							</div>
							<div className="col-sm-10">
								<input
									type="text"
									className="form-control"
									value={lrNo}
									onChange={(e) => setLrNo(e.target.value)}
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="dpDate" className="col-form-label">LR Date</label>
							</div>
							<div className="col-sm-10">
								<DatePicker
									id="dpDate"
									dateFormat="dd-MM-yyyy"
									selected={lrDate}
									onChange={(e) => setLrDate(e)}
									className="form-control"
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="cmbTransporter" className="col-form-label">Transporter</label>
							</div>
							<div className="col-sm-10">
								<DownshiftSelect
									inputId={"cmbTransporter"}
									value={transporterId == null || transporterListResMap?.get(transporterId) == null ? null : TransporterHelper.getTransporterListResForDd(transporterListResMap.get(transporterId)!)}
									onChange={setTransporterId}
									options={transporterListResArrForDd ?? []}
									isLoading={false}
									isDisabled={false}
									placeholder=""
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label className="col-form-label">Destination</label>
							</div>
							<div className="col-sm-10">
								<input
									type="text"
									className="form-control"
									value={destination}
									onChange={(e) => setDestination(e.target.value)}
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label className="col-form-label">Final Invoice No.</label>
							</div>
							<div className="col-sm-10">
								<input
									type="text"
									className="form-control"
									value={finalInvoiceNo}
									onChange={(e) => setFinalInvoiceNo(e.target.value)}
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="cmbCompanyProfile" className="col-form-label">Company Profile</label>
							</div>
							<div className="col-sm-10">
								<DownshiftSelect
									inputId={"cmbCompanyProfile"}
									value={companyProfileId == null || companyProfileListResMap?.get(companyProfileId) == null ? null : CompanyProfileHelper.getCompanyProfileListResForDd(companyProfileListResMap.get(companyProfileId)!)}
									onChange={setCompanyProfileId}
									options={companyProfileListResArrForDd ?? []}
									isLoading={false}
									isDisabled={false}
									placeholder=""
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label className="col-form-label">Note</label>
							</div>
							<div className="col-sm-10">
								<input
									type="text"
									className="form-control"
									value={note}
									onChange={(e) => setNote(e.target.value)}
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label className="col-form-label">No Of Carton</label>
							</div>
							<div className="col-sm-10">
								<input
									type="text"
									className="form-control"
									value={noOfCartonStr}
									onChange={(e) => setNoOfCartonStr(e.target.value)}
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label className="col-form-label">Delivery By</label>
							</div>
							<div className="col-sm-10">
								<input
									type="text"
									className="form-control"
									value={deliveryBy}
									onChange={(e) => setDeliveryBy(e.target.value)}
								/>
							</div>
						</div>


					</div>

					<div className="row mb-2">
						<div className="col-md-2">
							&nbsp;
						</div>
						<div className="col-md-3">
							Limit
						</div>
						<div className="col-md-3">
							Current Status
						</div>
						<div className="col-md-3">
							Balance
						</div>
						<div className="col-md-3">
							&nbsp;
						</div>
					</div>
					<div className="row mb-2">
						<div className="col-md-2">
							Credit Days
						</div>
						<div className="col-md-3">
							<input
								type="text"
								className="form-control"
								value={creditDaysLimit}
								readOnly={true}
							/>
						</div>
						<div className="col-md-3">
							<input
								type="text"
								className="form-control"
								value={creditDaysCurrentStatus}
								readOnly={true}
							/>
						</div>
						<div className="col-md-3">
							<input
								type="text"
								className="form-control"
								value={overdueBal}
								readOnly={true}
							/>
						</div>
						<div className="col-md-1">
							{isCreditDaysRight ? "" : "X"}
						</div>
					</div>
					<div className="row mb-2">
						<div className="col-md-2">
							Credit Amount
						</div>
						<div className="col-md-3">
							<input
								type="text"
								className="form-control"
								value={creditAmt}
								readOnly={true}
							/>
						</div>
						<div className="col-md-3">
							<input
								type="text"
								className="form-control"
								value={currentBal}
								readOnly={true}
							/>
						</div>
						<div className="col-md-3">
							<input
								type="text"
								className="form-control"
								value={currentBalDiff}
								readOnly={true}
							/>
						</div>
						<div className="col-md-1">
							{isCreditAmtRight ? "" : "X"}
						</div>
					</div>

					<Tabs
						defaultActiveKey="tab-item-details"
						id="uncontrolled-tab-example"
						className="mb-3"
					>
						<Tab eventKey="tab-item-details" title="Item Details">

							<AddSalesItemsSv
								products={products ?? []}
								productsMap={productsMap ?? new Map()}
								productsForDd={productsForDd ?? []}
								units={units ?? []}
								unitsMap={unitsMap ?? new Map()}
								taxMap={taxListResMap ?? new Map()}
								taxListResMap={taxListResMap ?? new Map()}
								taxCatMap={taxCatMap ?? new Map()}
								handleAddItem={handleAddItem}
								handleUpdateItem={handleUpdateItem}
								handleDeleteItem={handleDeleteItem}
								customerPriceListAMap={customerPriceListAMap}
								customerPriceListBMap={customerPriceListBMap}
								addSalesItems={salesItems}
							/>
						</Tab>
						<Tab eventKey="tab-pending-items" title="Pending Items">
							Tab content for Profile
						</Tab>
					</Tabs>

					<hr />

					<div className="d-flex mb-3 g-3 justify-content-end">
						<div className="col-auto g-3">
							<label className="col-form-label">Total</label>
						</div>
						<div className="col-auto">
							<input
								value={SalesHelper.getSumAmtAfterItemDisc(salesItems)}
								type="text"
								className="form-control"
								readOnly={true}
							/>
						</div>
					</div>

					<hr />

					<AddPusaOthChOnItemSv
						vouOthChOnItemResMap={vouOthChOnItemResMap ?? new Map()}
						vouOthChOnItemResArrForDd={vouOthChOnItemResArrForDd ?? []}
						vouOthChOnItemArr={salesOthChOnItemArr}
						handleOthChOnItemChange={handleOthChOnItemChange}
					/>

					<hr />

					<div className="d-flex mb-3 g-3 justify-content-end">
						<div className="col-auto g-3">
							<label className="col-form-label">Total</label>
						</div>
						<div className="col-auto">
							<input
								value={SalesHelper.getSumItemTaxableAmt(salesItems)}
								type="text"
								className="form-control"
								readOnly={true}
							/>
						</div>
					</div>

					<hr />

					<AddPusaTaxSv
						isAddTax={isAddTax}
						setIsAddTax={setIsAddTax}
						isIntra={isIntra}
						taxAmtContainer={SalesHelper.getTaxSum(salesItems)}
					/>

					<hr />

					<AddPusaOthChNonTaxableSv
						vouOthChNonTaxableResMap={vouOthChNonTaxableResMap ?? new Map()}
						vouOthChNonTaxableResArrForDd={vouOthChNonTaxableResArrForDd ?? []}
						vouOthChNonTaxableArr={SalesOthChNonTaxableArr}
						handleOthChNonTaxableChange={handleOthChNonTaxableChange}
					/>

					<hr />

					<AddSalesTotalSv
						totalAmtContainer={SalesHelper.getTotalAmt(salesItems, SalesOthChNonTaxableArr)}
					/>

					<button type="submit" className="btn btn-primary">Submit</button>
				</form>

			</div >

			<Modal show={showMessageDialog} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Message</Modal.Title>
				</Modal.Header>
				<Modal.Body>{messageDialogMessage}</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						OK
					</Button>
				</Modal.Footer>
			</Modal>


			<CustomerSelectDialog
				showDialog={showCustomerDialog}
				setShowDialog={setShowCustomerDialog}
				accounts={accounts ?? []}
				setAcctForCustomer={mySetAcctForCustomer}
			/>

		</>
	);
}

export default AddSales;
