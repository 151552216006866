import { BASE_URL, TOKEN } from "../config";
import ServerNotReachableErrorCommonCudObj from "../default_objects/ServerNotReachableErrorCommonCudObj";
import ServerSideErrorCommonCudObj from "../default_objects/ServerSideErrorCommonCudObj";
import ResourceEnum from "../enums/ResourceEnum";
import strFormat from "../helpers/StringHelper";
import { getQueryString } from "../helpers/UrlSearchParamHelper";
import CommonCudType from "../types/CommonCudType";
import CommonGetListType from "../types/CommonGetListType";
import ErrorResType from "../types/res/ErrorResType";
import PurchaseLdto from "../types/res/list/PurchaseLdto";
import RptPurchaseByCityHdto from "../types/res/list/RptPurchaseByCityHdto";
import RptPurchaseByDayHdto from "../types/res/list/RptPurchaseByDayHdto";
import RptPurchaseByMonthHdto from "../types/res/list/RptPurchaseByMonthHdto";
import RptPurchaseByProductCategoryHdto from "../types/res/list/RptPurchaseByProductCategoryHdto";
import RptPurchaseByProductHdto from "../types/res/list/RptPurchaseByProductHdto";
import RptPurchaseByStateHdto from "../types/res/list/RptPurchaseByStateHdto";
import RptPurchaseBySupplierHdto from "../types/res/list/RptPurchaseBySupplierHdto";
import RptPurchaseDetailedByProductHdto from "../types/res/list/RptPurchaseDetailedByProductHdto";
import RptPurchaseDetailedByVoucherHdto from "../types/res/list/RptPurchaseDetailedByVoucherHdto";
import PurchaseRrHoDto from "../types/rr/PurchaseRrHoDto";
import ListPurchaseSm from "../types/sm/ListPurchaseSm";
import RptPurchaseByCitySm from "../types/sm/RptPurchaseByCitySm";
import RptPurchaseByDaySm from "../types/sm/RptPurchaseByDaySm";
import RptPurchaseByMonthSm from "../types/sm/RptPurchaseByMonthSm";
import RptPurchaseByProductCategorySm from "../types/sm/RptPurchaseByProdCatSm";
import RptPurchaseByProductSm from "../types/sm/RptPurchaseByProdSm";
import RptPurchaseByStateSm from "../types/sm/RptPurchaseByStateSm";
import RptPurchaseBySupplierSm from "../types/sm/RptPurchaseBySupplierSm";
import RptPurchaseDetailedByProductSm from "../types/sm/RptPurchaseDetailedByProdSm";
import RptPurchaseDetailedByVoucherSm from "../types/sm/RptPurchaseDetailedByVoucherSm";

export default class PurchaseApi {

	static async getPurchaseLdtos(listPurchaseSm: ListPurchaseSm): Promise<CommonGetListType<PurchaseLdto>> {
		try {

			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.PURCHASE.name)
				+ getQueryString(listPurchaseSm)
				, requestOptions);

			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as PurchaseLdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async createPurchase(purchaseRrHoDto: PurchaseRrHoDto): Promise<CommonCudType> {

		const requestOptions = {
			method: "POST",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(purchaseRrHoDto)
		}

		const res = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.PURCHASE.name), requestOptions);

		if (res.status !== 201) {
			const data: ErrorResType = await res.json();
			return {
				isSuccess: false,
				errorRes: data
			}
		}

		return {
			isSuccess: true,
		}

	}

	static async updatePurchase(id: number, purchaseRrHoDto: PurchaseRrHoDto): Promise<CommonCudType> {

		const requestOptions = {
			method: "PUT",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(purchaseRrHoDto)
		}

		const res = await fetch(strFormat("%s/%s/%s", BASE_URL, ResourceEnum.PURCHASE.name, id), requestOptions);

		if (res.status !== 201) {
			const data: ErrorResType = await res.json();
			return {
				isSuccess: false,
				errorRes: data
			}
		}

		return {
			isSuccess: true,
		}

	}

	static async getById(id: number) {

		const requestOptions = {
			method: "GET",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			}
		}

		const res = await fetch(strFormat("%s/%s/%s", BASE_URL, ResourceEnum.PURCHASE.name, id), requestOptions);
		const data: PurchaseRrHoDto = await res.json();
		return data;

	}

	static async delete(id: number): Promise<CommonCudType> {

		try {
			const requestOptions = {
				method: "DELETE",
				headers: {
					"Authorization": "Bearer " + TOKEN.val,
					"Content-Type": "application/json"
				}
			}

			const response = await fetch(strFormat("%s/%s/%s", BASE_URL, ResourceEnum.PURCHASE.name, id), requestOptions);

			if (response.status === 200) {
				return {
					isSuccess: true
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}


	}

	static async print(ids: number[]) {

		const requestOptions = {
			method: "GET",
			headers: {
				Authorization: "Bearer " + TOKEN.val,
				"Content-Type": "application/json",
			},
		};
		const res = await fetch(strFormat("%s/%s/print", BASE_URL, ResourceEnum.PURCHASE.name)
			+ getQueryString({
				ids: ids,
			})
			, requestOptions);

		var blob = await res.blob();
		return new Blob([blob], { type: 'application/pdf' });
	}

	// report
	static async getRptPurchaseByMonthHdtos(searchModel: RptPurchaseByMonthSm): Promise<CommonGetListType<RptPurchaseByMonthHdto>> {
		try {

			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s/%s",
					BASE_URL,
					ResourceEnum.PURCHASE.name,
					"reports",
					"summary",
					"by-month"
				)
				+ getQueryString(searchModel)
				, requestOptions);

			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptPurchaseByMonthHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async getRptPurchaseByDayHdtos(searchModel: RptPurchaseByDaySm): Promise<CommonGetListType<RptPurchaseByDayHdto>> {
		try {

			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s/%s",
					BASE_URL,
					ResourceEnum.PURCHASE.name,
					"reports",
					"summary",
					"by-day"
				)
				+ getQueryString(searchModel)
				, requestOptions);

			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptPurchaseByDayHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async getRptPurchaseByCityHdtos(searchModel: RptPurchaseByCitySm): Promise<CommonGetListType<RptPurchaseByCityHdto>> {
		try {

			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s/%s",
					BASE_URL,
					ResourceEnum.PURCHASE.name,
					"reports",
					"summary",
					"by-city"
				)
				+ getQueryString(searchModel)
				, requestOptions);

			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptPurchaseByCityHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async getRptPurchaseBySupplierHdtos(searchModel: RptPurchaseBySupplierSm): Promise<CommonGetListType<RptPurchaseBySupplierHdto>> {
		try {

			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s/%s",
					BASE_URL,
					ResourceEnum.PURCHASE.name,
					"reports",
					"summary",
					"by-supplier"
				)
				+ getQueryString(searchModel)
				, requestOptions);

			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptPurchaseBySupplierHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async getRptPurchaseByProdCatHdtos(searchModel: RptPurchaseByProductCategorySm): Promise<CommonGetListType<RptPurchaseByProductCategoryHdto>> {
		try {

			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s/%s",
					BASE_URL,
					ResourceEnum.PURCHASE.name,
					"reports",
					"summary",
					"by-product-category"
				)
				+ getQueryString(searchModel)
				, requestOptions);

			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptPurchaseByProductCategoryHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async getRptPurchaseByProdHdtos(searchModel: RptPurchaseByProductSm): Promise<CommonGetListType<RptPurchaseByProductHdto>> {
		try {

			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s/%s",
					BASE_URL,
					ResourceEnum.PURCHASE.name,
					"reports",
					"summary",
					"by-product"
				)
				+ getQueryString(searchModel)
				, requestOptions);

			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptPurchaseByProductHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async getRptPurchaseByStateHdtos(searchModel: RptPurchaseByStateSm): Promise<CommonGetListType<RptPurchaseByStateHdto>> {
		try {

			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s/%s",
					BASE_URL,
					ResourceEnum.PURCHASE.name,
					"reports",
					"summary",
					"by-state"
				)
				+ getQueryString(searchModel)
				, requestOptions);

			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptPurchaseByStateHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async getRptPurchaseDetailedByVoucherHdtos(searchModel: RptPurchaseDetailedByVoucherSm): Promise<CommonGetListType<RptPurchaseDetailedByVoucherHdto>> {
		try {

			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s/%s",
					BASE_URL,
					ResourceEnum.PURCHASE.name,
					"reports",
					"detailed",
					"by-voucher"
				)
				+ getQueryString(searchModel)
				, requestOptions);

			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptPurchaseDetailedByVoucherHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async getRptPurchaseDetailedByProdHdtos(searchModel: RptPurchaseDetailedByProductSm): Promise<CommonGetListType<RptPurchaseDetailedByProductHdto>> {
		try {

			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s/%s",
					BASE_URL,
					ResourceEnum.PURCHASE.name,
					"reports",
					"detailed",
					"by-product"
				)
				+ getQueryString(searchModel)
				, requestOptions);

			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptPurchaseDetailedByProductHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

}