export default class LstSalesReturnEnumCol {

	public static readonly NO = new LstSalesReturnEnumCol("no", "Number");
	public static readonly SALES_RETURN_LEDGER = new LstSalesReturnEnumCol("sales_return_ledger", "Sales Return Ledger");
	public static readonly DATE = new LstSalesReturnEnumCol("date", "Date");
	public static readonly CUSTOMER_NAME = new LstSalesReturnEnumCol("customer_name", "Customer Name");
	public static readonly CITY = new LstSalesReturnEnumCol("city", "City");
	public static readonly STATE_NAME = new LstSalesReturnEnumCol("state_name", "State Name");
	public static readonly AGENT_NAME = new LstSalesReturnEnumCol("agent_name", "Agent");
	public static readonly AMT_PAYABLE = new LstSalesReturnEnumCol("amt_payable", "Amount Payable");
	public static readonly AMT_CLRD = new LstSalesReturnEnumCol("amt_clrd", "Amount Cleared");
	public static readonly AMT_UNCLRD = new LstSalesReturnEnumCol("amt_unclrd", "Amount Uncleared");
	public static readonly NOTE = new LstSalesReturnEnumCol("note", "Note");
	public static readonly CREATED_BY = new LstSalesReturnEnumCol("created_by", "Created By");
	public static readonly CREATED_AT = new LstSalesReturnEnumCol("created_at", "Created At");

	private constructor(public readonly key: string, public readonly name: string) {
		
	}

	static get values(): LstSalesReturnEnumCol[] {
		return [
			this.NO,
			this.SALES_RETURN_LEDGER,
			this.DATE,
			this.CUSTOMER_NAME,
			this.CITY,
			this.STATE_NAME,
			this.AGENT_NAME,
			this.AMT_PAYABLE,
			this.AMT_CLRD,
			this.AMT_UNCLRD,
			this.NOTE,
			this.CREATED_BY,
			this.CREATED_AT,
		];
	}

	static getByKey(key: string): LstSalesReturnEnumCol | null {
		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.key === key) {
				return element;
			}
		}
		return null;
	}
}
