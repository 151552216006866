import { useState } from "react";
import useConfirm from "../dialogs/ConfirmDialog";
import MessageDialog from "../dialogs/MessageDialog";
import PriceListTypeEnum from "../../enums/PriceListTypeEnum";
import PurchaseOrderItemType from "../../types/PurchaseOrderItemType";
import UnitLdto from "../../types/res/list/UnitLdto";
import ProdForEsimateLdto from "../../types/res/list/ProdForEsimateLdto";
import TaxCatLdto from "../../types/res/list/TaxCatLdto";
import TaxLdto from "../../types/res/list/TaxLdto";

interface AddPurchaseOrderItemsTableProps {
	productsMap: Map<number, ProdForEsimateLdto>;
	unitsMap: Map<number, UnitLdto>;
	addPurchaseOrderItems: Array<PurchaseOrderItemType>;
	handleClickEditParent: (index: number) => void;
	handleClickDeleteParent: (index: number) => void;
}

function AddPurchaseOrderItemsTable({
	productsMap,
	unitsMap,
	addPurchaseOrderItems,
	handleClickEditParent,
	handleClickDeleteParent
}: AddPurchaseOrderItemsTableProps) {

	const [selectedIndex, setSelectedIndex] = useState<number>(-1);
	//
	const [messageDialogMessage, setMessageDialogMessage] = useState("");
	const [showMessageDialog, setShowMessageDialog] = useState(false);

	const confirm = useConfirm();

	const handleClickEdit = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {

		event.preventDefault();

		if (
			selectedIndex == null
			|| selectedIndex < 0
			|| selectedIndex >= addPurchaseOrderItems.length
		) {
			setMessageDialogMessage("Select a row to edit");
			setShowMessageDialog(true);
			return false;
		}

		handleClickEditParent(selectedIndex);

	}

	const handleClickDelete = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {

		event.preventDefault();

		if (
			selectedIndex == null
			|| selectedIndex < 0
			|| selectedIndex >= addPurchaseOrderItems.length
		) {
			setMessageDialogMessage("Select a row to delete");
			setShowMessageDialog(true);
			return false;
		}

		let choice = await confirm!({
			message: "Are you sure you want to delete?",
			title: "Delete confirmation"
		});

		if (choice === undefined || choice === null || choice === false)
			return;

		handleClickDeleteParent(selectedIndex);

	}

	return (
		<>
			<div className="flex-grow-1 mb-2">
				<button
					type="button"
					className="btn btn-primary me-2"
					onClick={handleClickEdit}
				>
					Edit
				</button>
				<button
					type="button"
					className="btn btn-primary me-2"
					onClick={handleClickDelete}
				>
					Delete
				</button>
				<button type="button" className="btn btn-primary me-2">Up</button>
				<button type="button" className="btn btn-primary me-2">Down</button>
			</div>

			<div className="flex-grow-1 mb-2 table-responsive text-nowrap">
				<table
					id="navigate"
					className="table table-hover table-bordered table-sm overflow-x-auto m-0 fixTableHead"
				>
					<thead className="bg-body-tertiary">
						<tr>
							<th scope="col">
								<input
									className="form-check-input"
									type="checkbox"
									id="chkCheckAll"
								/>
							</th>
							<th scope="col">Sr. No.</th>
							<th scope="col">Product</th>
							<th scope="col">Desc</th>
							<th scope="col">Qty</th>
							<th scope="col">Unit</th>
						</tr>
					</thead>
					<tbody className="">
						{addPurchaseOrderItems.map((addPurchaseOrderItem, i) => (
							<tr key={i} onClick={(e) => setSelectedIndex(i)}>
								<td>
									<div>
										<input
											className="form-check-input"
											type="checkbox"
											value=""
										/>
									</div>
								</td>
								<td tabIndex={0}>
									<div>{i + 1}</div>
								</td>
								<td tabIndex={0}><div>{productsMap.get(addPurchaseOrderItem.productId)?.name}</div></td>
								<td tabIndex={0}><div>{addPurchaseOrderItem.desc}</div></td>
								<td tabIndex={0}><div>{addPurchaseOrderItem.qty.toLocaleString('en-IN')}</div></td>
								<td tabIndex={0}><div>{unitsMap.get(productsMap.get(addPurchaseOrderItem.productId)?.unitId!)?.name}</div></td>
							</tr>
						))}
					</tbody>
					<tfoot className="bg-body-tertiary">
						<tr>
							<th scope="row">Total</th>
							<th>{addPurchaseOrderItems.length}</th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
						</tr>
					</tfoot>
				</table>
			</div >

			<MessageDialog
				showDialog={showMessageDialog}
				modalMessage={messageDialogMessage}
				setShowDialog={setShowMessageDialog} />
		</>
	);
}

export default AddPurchaseOrderItemsTable;
