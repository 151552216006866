import DropDownItemType from "../types/DropDownItemType";
import VouOthChargeLdto from "../types/res/list/VouOthChargeLdto";

export default class VouOthChHelper {

	static getVouOthChArrForDd(listVouOthCh: VouOthChargeLdto[]) {

		let finalArr: DropDownItemType[] = [];
		listVouOthCh.forEach(item => {
			finalArr.push({
				id: item.id,
				name: item.name
			});
		});
		return finalArr;

	}

	static getVouOthChForDd(listVouOthCh: VouOthChargeLdto): DropDownItemType {
		return {
			id: listVouOthCh.id,
			name: listVouOthCh.name
		};
	}

	static getVouOthChListResMap(itemArr: VouOthChargeLdto[]) {
		let map = new Map<number, VouOthChargeLdto>([]);
		for (let index = 0; index < itemArr.length; index++) {
			const item = itemArr[index];
			map.set(item.id, item);
		}
		return map;
	}

}
