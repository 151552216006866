import SortOrderEnum from "../../enums/SortOrderEnum";
import RptEstimatePendingByAcctSortByEnum from "../../enums/sort_by/RptEstimatePendingByAcctSortByEnum";

export default class RptEstimatePendingByCustomerSm {

	dateFrom: Date | undefined;
	dateTo: Date | undefined;

	prodId: number | undefined;
	acctId: number | undefined;

	rptEstimatePendingByAcctSortByEnum: RptEstimatePendingByAcctSortByEnum | undefined;
	sortOrderEnum: SortOrderEnum | undefined;

	doSet(searchModel: RptEstimatePendingByCustomerSm): void {
		this.dateFrom = searchModel.dateFrom;
		this.dateTo = searchModel.dateTo;

		this.prodId = searchModel.prodId;
		this.acctId = searchModel.acctId;

		this.rptEstimatePendingByAcctSortByEnum = searchModel.rptEstimatePendingByAcctSortByEnum;
		this.sortOrderEnum = searchModel.sortOrderEnum;
	}

	reset(): void {
		this.dateFrom = undefined;
		this.dateTo = undefined;

		this.prodId = undefined;
		this.acctId = undefined;

		this.rptEstimatePendingByAcctSortByEnum = undefined;
		this.sortOrderEnum = undefined;
	}

	isBlank(): boolean {
		return this.dateFrom === undefined
			&& this.dateTo === undefined
			&& this.prodId === undefined
			&& this.acctId === undefined
			&& this.rptEstimatePendingByAcctSortByEnum === undefined
			&& this.sortOrderEnum === undefined;
	}

}
