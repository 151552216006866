import React from "react";
import RateTypeEnum from "../../enums/RateTypeEnum";
import RateTypeHelper from "../../helpers/RateTypeHelper";
import VouOthChHelper from "../../helpers/VouOthChHelper";
import DropDownItemType from "../../types/DropDownItemType";
import PusaOthChOnItemRowHolder from "../../types/PusaOthChOnItemRowHolder";
import VouOthChOnItemType from "../../types/VouOthChOnItemType";
import VouOthChargeLdto from "../../types/res/list/VouOthChargeLdto";
import DownshiftSelect from "../DownshiftSelectV2";

interface AddPusaOthChOnItemRowSvProps {
	vouOthChOnItemResMap: Map<number, VouOthChargeLdto>;
	vouOthChOnItemResArrForDd: DropDownItemType[];
	vouOthChOnItem: VouOthChOnItemType | null;
	pusaOthChOnItemRowHolder: PusaOthChOnItemRowHolder;
	handleClickRemove: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => void;
	handleUpdateRow: (key: number, pusaOthChOnItemRowHolder: PusaOthChOnItemRowHolder) => void;
	myIndex: number;
}

function AddPusaOthChOnItemRowSv({
	vouOthChOnItemResMap,
	vouOthChOnItemResArrForDd,
	vouOthChOnItem,
	pusaOthChOnItemRowHolder,
	handleClickRemove,
	handleUpdateRow,
	myIndex
}: AddPusaOthChOnItemRowSvProps): JSX.Element {

	return (
		<div className="row g-3 align-items-center">
			<div className="col-auto">
				<button
					onClick={(e) => handleClickRemove(e, myIndex)}
					type="submit"
					className="btn btn-primary mb-3"
				>
					Remove
				</button>
			</div>
			<div className="col-auto">
				<DownshiftSelect
					inputId={"vou_oth_ch_on_item"}
					value={
						pusaOthChOnItemRowHolder.othChId !== undefined &&
							pusaOthChOnItemRowHolder.othChId !== null &&
							vouOthChOnItemResMap.get(pusaOthChOnItemRowHolder.othChId) !== null &&
							vouOthChOnItemResMap.get(pusaOthChOnItemRowHolder.othChId) !== undefined
							?
							VouOthChHelper.getVouOthChForDd(vouOthChOnItemResMap.get(pusaOthChOnItemRowHolder.othChId)!)
							:
							null
					}
					onChange={(e) => handleUpdateRow(
						myIndex,
						{
							othChId: e,
							rateStr: pusaOthChOnItemRowHolder.rateStr,
							rateTypeId: pusaOthChOnItemRowHolder.rateTypeId
						}
					)}
					options={vouOthChOnItemResArrForDd}
					isLoading={false}
					isDisabled={false}
					placeholder=""
				/>
			</div>
			<div className="col-auto">
				<input
					type="text"
					className="form-control"
					aria-describedby="passwordHelpInline"
					value={pusaOthChOnItemRowHolder.rateStr ?? ""}
					onChange={(e) => handleUpdateRow(
						myIndex,
						{
							othChId: pusaOthChOnItemRowHolder.othChId,
							rateStr: e.target.value,
							rateTypeId: pusaOthChOnItemRowHolder.rateTypeId
						}
					)}
				/>
			</div>
			<div className="col-auto">
				<DownshiftSelect
					inputId={"rateType"}
					value={
						pusaOthChOnItemRowHolder.rateTypeId !== null &&
							pusaOthChOnItemRowHolder.rateTypeId !== undefined &&
							RateTypeEnum.getById(pusaOthChOnItemRowHolder.rateTypeId) !== null &&
							RateTypeEnum.getById(pusaOthChOnItemRowHolder.rateTypeId) !== undefined
							?
							RateTypeHelper.getRateTypeForDd(RateTypeEnum.getById(pusaOthChOnItemRowHolder.rateTypeId)!)
							:
							null
					}
					onChange={(e) => handleUpdateRow(
						myIndex,
						{
							othChId: pusaOthChOnItemRowHolder.othChId,
							rateStr: pusaOthChOnItemRowHolder.rateStr,
							rateTypeId: e
						}
					)}
					options={RateTypeHelper.getRateTypesForDd()}
					isLoading={false}
					isDisabled={false}
					placeholder=""
				/>
			</div>
			<div className="col-auto">
				<input
					type="text"
					className="form-control"
					value={vouOthChOnItem?.derivedAmt?.toString() ?? ""}
					readOnly={true}
				/>
			</div>
		</div>
	);
}

export default AddPusaOthChOnItemRowSv;
