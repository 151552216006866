import PriceListTypeEnum from "../enums/PriceListTypeEnum";
import RateTypeEnum from "../enums/RateTypeEnum";
import SalesOrderItemType from "../types/SalesOrderItemType";
import VouOthChOnItemType from "../types/VouOthChOnItemType";
import ProdForEsimateLdto from "../types/res/list/ProdForEsimateLdto";
import TaxCatLdto from "../types/res/list/TaxCatLdto";
import TaxLdto from "../types/res/list/TaxLdto";
import UnitLdto from "../types/res/list/UnitLdto";
import TaxHelper from "./TaxHelper";
import UnitHelper from "./UnitHelper";

export default class SalesOrderItemHelper {

	static calItems(
		isAddTax: boolean,
		isIntra: boolean,
		taxMap: Map<number, TaxLdto>,
		taxCatMap: Map<number, TaxCatLdto>,
		unitMap: Map<number, UnitLdto>,
		productMap: Map<number, ProdForEsimateLdto>,
		salesOrderItems: SalesOrderItemType[],
		salesOrderOthChOnItemArr: VouOthChOnItemType[],
	) {
		if (salesOrderItems !== undefined && salesOrderItems !== null && salesOrderItems.length > 0) {

			let sumAmtAfterDisc = 0;
			for (let i = 0; i < salesOrderItems.length; i++) {
				let salesOrderItem = salesOrderItems[i];

				let product = productMap.get(salesOrderItem.productId);

				let taxId: number;

				if (product?.taxCatId === null) {
					taxId = product.taxId;
				}
				else {
					let taxCat = taxCatMap.get(product?.taxCatId!);
					taxId = taxCat?.taxId!;
				}
				salesOrderItem.derivedTaxId = taxId;

				salesOrderItem.derivedQty = UnitHelper.convert(
					salesOrderItem.orderQty,
					unitMap.get(salesOrderItem.orderUnitId)!,
					unitMap.get(productMap.get(salesOrderItem.productId)?.unitId!)!
				);

				let tax = taxMap.get(salesOrderItem.derivedTaxId);

				if (salesOrderItem.priceListTypeId === PriceListTypeEnum.A.id) {
					salesOrderItem.derivedRate = Math.floor(salesOrderItem.priceListRate * 100 / (100 + tax!.igst) * 100) / 100;
				}
				else {
					salesOrderItem.derivedRate = salesOrderItem.priceListRate;
				}

				salesOrderItem.derivedQxr = salesOrderItem.derivedQty * salesOrderItem.derivedRate;

				if (salesOrderItem.discRate === undefined || salesOrderItem.discRate === null) {
					salesOrderItem.derivedDiscA = 0;
				}
				else {
					switch (salesOrderItem.discRateTypeId) {
						case RateTypeEnum.FLAT.id:
							salesOrderItem.derivedDiscA = salesOrderItem.discRate * salesOrderItem.derivedQty;
							break;
						case RateTypeEnum.PERCENT.id:
							salesOrderItem.derivedDiscA = salesOrderItem.discRate * salesOrderItem.derivedQxr / 100;
							break;
						default:
							break;
					}
				}

				salesOrderItem.derivedAmtAfterDisc = salesOrderItem.derivedQxr - salesOrderItem.derivedDiscA!;

				salesOrderItem.derivedTaxableAmt = salesOrderItem.derivedAmtAfterDisc;

				sumAmtAfterDisc = sumAmtAfterDisc + salesOrderItem.derivedAmtAfterDisc;

				if (isAddTax) {

					if (salesOrderItem.priceListTypeId === PriceListTypeEnum.A.id) {
						salesOrderItem.derivedTaxId = tax?.id;
						salesOrderItem.derivedTaxRate = tax?.igst;
					}
					else {
						salesOrderItem.derivedTaxId = 1;
						salesOrderItem.derivedTaxRate = 0;
					}

				}
			}



			if (salesOrderOthChOnItemArr !== undefined && salesOrderOthChOnItemArr !== null && salesOrderOthChOnItemArr.length > 0) {
				for (let i = 0; i < salesOrderOthChOnItemArr.length; i++) {
					let vouOthChOnItem = salesOrderOthChOnItemArr[i];

					vouOthChOnItem.derivedAmt = 0;

					for (let j = 0; j < salesOrderItems.length; j++) {
						let salesOrderItem = salesOrderItems[j];
						this.applyOthChOnItem(sumAmtAfterDisc, salesOrderItem, vouOthChOnItem);
					}
					if (vouOthChOnItem.rateTypeId === RateTypeEnum.FLAT.id) {
						vouOthChOnItem.derivedAmt = vouOthChOnItem.rate;
					}
				}
			}

			if (isAddTax) {
				for (let i = 0; i < salesOrderItems.length; i++) {
					let salesOrderItem = salesOrderItems[i];

					let taxAmtContainer = TaxHelper.calTaxAmt(
						taxMap.get(salesOrderItem.derivedTaxId!)!,
						salesOrderItem.derivedTaxRate!,
						isIntra,
						salesOrderItem.derivedTaxableAmt!
					)

					salesOrderItem.derivedCgstA = taxAmtContainer.cgstA;
					salesOrderItem.derivedSgstA = taxAmtContainer.sgstA;
					salesOrderItem.derivedIgstA = taxAmtContainer.igstA;
					salesOrderItem.derivedTaxAmt = taxAmtContainer.cgstA + taxAmtContainer.sgstA + taxAmtContainer.igstA;
					salesOrderItem.derivedAmt = taxAmtContainer.amtWithTax;
				}
			}



		}

		return salesOrderItems;
	}

	private static applyOthChOnItem(sumAmtAftDisc: number, salesOrderItem: SalesOrderItemType, vouOthChOnItem: VouOthChOnItemType) {

		let itemOthChAmt = 0;

		switch (vouOthChOnItem.rateTypeId) {
			case RateTypeEnum.FLAT.id:
				itemOthChAmt = vouOthChOnItem.rate
					* salesOrderItem.derivedAmtAfterDisc!
					/ sumAmtAftDisc;
				break;
			case RateTypeEnum.PERCENT.id:
				itemOthChAmt = salesOrderItem.derivedTaxableAmt!
					* vouOthChOnItem.rate
					/ 100;

				vouOthChOnItem.derivedAmt = vouOthChOnItem.derivedAmt! + itemOthChAmt;
				break;
		}

		salesOrderItem.derivedTaxableAmt = salesOrderItem.derivedTaxableAmt! + itemOthChAmt;
	}

}