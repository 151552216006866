import SortOrderEnum from "../../enums/SortOrderEnum";
import RptEstimatePendingByProdSortByEnum from "../../enums/sort_by/RptEstimatePendingByProdSortByEnum";

export default class RptEstimatePendingByProdSm {

	dateFrom: Date | undefined;
	dateTo: Date | undefined;

	prodId: number | undefined;
	acctId: number | undefined;

	rptEstimatePendingByProdSortByEnum: RptEstimatePendingByProdSortByEnum | undefined;
	sortOrderEnum: SortOrderEnum | undefined;

	doSet(searchModel: RptEstimatePendingByProdSm): void {
		this.dateFrom = searchModel.dateFrom;
		this.dateTo = searchModel.dateTo;

		this.prodId = searchModel.prodId;
		this.acctId = searchModel.acctId;

		this.rptEstimatePendingByProdSortByEnum = searchModel.rptEstimatePendingByProdSortByEnum;
		this.sortOrderEnum = searchModel.sortOrderEnum;
	}

	reset(): void {
		this.dateFrom = undefined;
		this.dateTo = undefined;

		this.prodId = undefined;
		this.acctId = undefined;

		this.rptEstimatePendingByProdSortByEnum = undefined;
		this.sortOrderEnum = undefined;
	}

	isBlank(): boolean {
		return this.dateFrom === undefined
			&& this.dateTo === undefined
			&& this.prodId === undefined
			&& this.acctId === undefined
			&& this.rptEstimatePendingByProdSortByEnum === undefined
			&& this.sortOrderEnum === undefined;
	}

}
