export default class PriceListTypeEnum {
	public static readonly A = new PriceListTypeEnum(1, "A");
	public static readonly B = new PriceListTypeEnum(2, "B");

	private constructor(
		public readonly id: number,
		public readonly name: string) {
	}

	static get values(): PriceListTypeEnum[] {
		return [
			this.A,
			this.B,
		];
	}

	static getById(id: number): PriceListTypeEnum | null {
		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.id === id) {
				return element;
			}
		}
		return null;
	}

	static getByName(name: string): PriceListTypeEnum | null {
		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.name === name) {
				return element;
			}
		}
		return null;
	}

	static getNames(): string[] {

		let strings: string[] = [];
		this.values.forEach(element => {
			strings.push(element.name);
		});
		return strings;

	}

}
