export default class SalesPrintTypeEnum {
	public static readonly TYPE_A = new SalesPrintTypeEnum("typea", "TYPE_A");
	public static readonly TYPE_B = new SalesPrintTypeEnum("typec", "TYPE_B");
	public static readonly TYPE_C = new SalesPrintTypeEnum("typec", "TYPE_C");

	private constructor(
		public readonly fileNamePart: string,
		public readonly enumName: string) {
	}

}
