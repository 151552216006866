import { useEffect, useState } from "react";
import { useGvContext } from "../../GvContext";
import MenuApi from "../../api/MenuApi";
import SalesApi from "../../api/SalesApi";
import { BASE_URL, TOKEN } from "../../config";
import DateFormatEnum from "../../enums/DateFormatEnum";
import ListMenuEnum from "../../enums/ListMenuEnum";
import ResourceEnum from "../../enums/ResourceEnum";
import SalesPrintTypeEnum from "../../enums/SalesPrintTypeEnum";
import LstSalesEnumCol from "../../enums/col/LstSalesEnumCol";
import dateToString from "../../helpers/DateHelper";
import DateHelper2 from "../../helpers/DateHelper2";
import strFormat from "../../helpers/StringHelper";
import RowType from "../../types/RowType";
import ColSettingResType from "../../types/res/ColSettingResType";
import ListSalesSm from "../../types/sm/ListSalesSm";
import ListViewButtonBar from "../ListViewButtonBar";
import ListViewTable from "../ListViewTable";
import useConfirm from "../dialogs/ConfirmDialog";
import MessageDialog from "../dialogs/MessageDialog";
import SalesPrintDialog from "../dialogs/SalesPrintDialog";
import ListSalesSd from "../sd/ListSalesSd";

function ListSales(): JSX.Element {

	const resourceName = ResourceEnum.SALES.name;

	const [colSettings, setColSettings] = useState<ColSettingResType[]>([]);
	const [colNames, setColNames] = useState<string[]>([]);

	const [rows, setRows] = useState<RowType[]>([]);
	const [selectedId, setSelectedId] = useState<number | null>(null);

	const [listSalesSm, setListSalesSm] = useState<ListSalesSm>(new ListSalesSm(DateHelper2.getFirstDateOfCurrentMonth(), DateHelper2.getLastDateOfCurrentMonth()));
	//
	const [showSd, setShowSd] = useState<boolean>(false);
	
	const [messageDialogMessage, setMessageDialogMessage] = useState("");
	const [showMessageDialog, setShowMessageDialog] = useState(false);

	const [showSalesPrintDialog, setShowSalesPrintDialog] = useState(false);

	const confirm = useConfirm();
	const gv = useGvContext();

	useEffect(() => {
		async function process() {

			let res = await MenuApi.getColSettingDtos(ListMenuEnum.SALES);

			if (res.isSuccess) {

				setColSettings(res.items!);

				let colNamesLocal: string[] = [];
				res.items!.forEach((item) => {
					colNamesLocal.push(item.colName);
				});
				setColNames(colNamesLocal);

			}
			else {
				setShowMessageDialog(true);
				setMessageDialogMessage(res.errorRes?.message!);
			}

		}
		process();
	}, []);

	useEffect(() => {
		if (colSettings != null && colSettings.length > 0) {
			loadData();
		}
	}, [listSalesSm, colSettings]);

	const getListViewOptions = () => {
		return [
			{
				name: "Print Challan",
				myCallbackFunction: handleClickMiPrintChallan
			},
			{
				name: "Send message on Whatsapp",
				myCallbackFunction: handleClickMiSendWhatsapp
			},
			{
				name: "Send text Message on Whatsapp",
				myCallbackFunction: handleClickMiWhatsappBill
			},
			{
				name: "Duplicate",
				myCallbackFunction: handleClickMiDuplicate
			},
			{
				name: "Hide",
				myCallbackFunction: handleClickMiHide
			},
			{
				name: "Unhide",
				myCallbackFunction: handleClickMiUnhide
			},
		];
	}

	const handleClickAdd = () => {
		gv.menuHelper.openAddSales();
	};

	const handleClickEdit = () => {

		if (selectedId === null) {
			setMessageDialogMessage("Select atleast one row");
			setShowMessageDialog(true);
			return;
		}

		gv.menuHelper.openAddSales(selectedId);
	};

	const handleClickDelete = async () => {
		if (selectedId === null) {
			setMessageDialogMessage("Select atleast one row");
			setShowMessageDialog(true);
			return;
		}

		let choice = await confirm!({
			message: "Are you sure you want to delete?",
			title: "Delete confirmation"
		});

		if (choice === undefined || choice === null || choice === false)
			return;

		const requestOptions = {
			method: "DELETE",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			}
		}

		const res = await fetch(strFormat("%s/%s/%s", BASE_URL, resourceName, selectedId), requestOptions);

		if (res.status !== 204) {
			const data = await res.json();
			setMessageDialogMessage(data.message);
			setShowMessageDialog(true);
		}
		else {
			loadData();
		}
	};

	const handleClickExport = () => {
		console.log("handleClickExport");
	};

	const handleClickPrint = () => {

		if (selectedId === null) {
			setMessageDialogMessage("Select atleast one row");
			setShowMessageDialog(true);
			return;
		}

		setShowSalesPrintDialog(true);
	};

	const handleClickMiPrintChallan = () => {

	};

	const handleClickMiSendWhatsapp = () => {

	};
	const handleClickMiWhatsappBill = () => {

	};
	const handleClickMiDuplicate = () => {

	};
	const handleClickMiHide = () => {

	};
	const handleClickMiUnhide = () => {

	};

	const printSales = async (salesPrintType: SalesPrintTypeEnum) => {

		if (selectedId === null) {
			setMessageDialogMessage("Select atleast one row");
			setShowMessageDialog(true);
			return;
		}

		setShowSalesPrintDialog(true);

		const fileURL = window.URL.createObjectURL(await SalesApi.print([selectedId], salesPrintType));
		//Open the URL on new Window
		const pdfWindow = window.open();
		pdfWindow!.location.href = fileURL;
	};

	const handleClickOpen = () => {
		console.log("handleClickOpen");
	};

	const handleClickFilter = () => {
		setShowSd(true);
	};

	const handleClickClearFilter = () => {
		console.log("handleClickClearFilter");
	};

	const handleClickColSettings = () => {
		console.log("handleClickColSettings");
	};

	const loadData = async () => {

		let res = await SalesApi.getSalesLdtos(listSalesSm);

		if (res.isSuccess) {

			let dataToReturn: RowType[] = [];

			let sumAmtClrd = 0;
			let sumAmtPayable = 0;
			let sumAmtUnclrd = 0;
			let sumNoOfCarton = 0;

			for (let i = 0; i < res.items?.length!; i++) {
				let salesDto = res.items![i];

				let oneRowArray: string[] = [];

				for (let j = 0; j < colSettings.length; j++) {
					let colSetting = colSettings[j];
					switch (LstSalesEnumCol.getByKey(colSetting.colKey)) {
						case LstSalesEnumCol.AGENT_NAME:
							oneRowArray.push("");
							break;
						case LstSalesEnumCol.AMT_CLRD:
							oneRowArray.push(salesDto.amtClrd.toLocaleString('en-IN'));
							sumAmtClrd = sumAmtClrd + salesDto.amtClrd;
							break;
						case LstSalesEnumCol.AMT_PAYABLE:
							oneRowArray.push(salesDto.amtPayable.toLocaleString('en-IN'));
							sumAmtPayable = sumAmtPayable + salesDto.amtPayable;
							break;
						case LstSalesEnumCol.AMT_UNCLRD:
							oneRowArray.push(salesDto.amtUnclrd.toLocaleString('en-IN'));
							sumAmtUnclrd = sumAmtUnclrd + salesDto.amtUnclrd;
							break;
						case LstSalesEnumCol.CITY:
							oneRowArray.push(salesDto.city == null ? "" : salesDto.city);
							break;
						case LstSalesEnumCol.CUSTOMER_NAME:
							oneRowArray.push(salesDto.customerName);
							break;
						case LstSalesEnumCol.DATE:
							oneRowArray.push(dateToString(salesDto.date, DateFormatEnum.APP_DATETIME));
							break;
						case LstSalesEnumCol.LR_DATE:
							oneRowArray.push(salesDto.lrDate == null ? "" : dateToString(salesDto.lrDate, DateFormatEnum.APP_DATE));
							break;
						case LstSalesEnumCol.LR_NO:
							oneRowArray.push(salesDto.lrNo == null ? "" : salesDto.lrNo);
							break;
						case LstSalesEnumCol.NO:
							oneRowArray.push(salesDto.no.toString());
							break;
						case LstSalesEnumCol.NOTE:
							oneRowArray.push(salesDto.note);
							break;
						case LstSalesEnumCol.ORDER_DATE:
							oneRowArray.push(salesDto.orderDate == null ? "" : dateToString(salesDto.orderDate, DateFormatEnum.APP_DATE));
							break;
						case LstSalesEnumCol.ORDER_NO:
							oneRowArray.push(salesDto.orderNo == null ? "" : salesDto.orderNo);
							break;
						case LstSalesEnumCol.STATE_NAME:
							oneRowArray.push(salesDto.stateName == null ? "" : salesDto.stateName);
							break;
						case LstSalesEnumCol.TRANSPORTER_NAME:
							oneRowArray.push(salesDto.transporterName == null ? "" : salesDto.transporterName);
							break;
						case LstSalesEnumCol.COMPANY_PROFILE:
							oneRowArray.push(salesDto.companyProfileName);
							break;
						case LstSalesEnumCol.FINAL_INVOICE_NO:
							oneRowArray.push(salesDto.finalInvoiceNo);
							break;
						case LstSalesEnumCol.DAYS_CNT:
							oneRowArray.push(salesDto.daysCnt.toString());
							break;
						case LstSalesEnumCol.CREATED_BY:
							oneRowArray.push(salesDto.createdByUserName);
							break;
						case LstSalesEnumCol.CREATED_AT:
							oneRowArray.push(dateToString(salesDto.createdAt, DateFormatEnum.APP_DATETIME));
							break;
						case LstSalesEnumCol.SALES_LEDGER:
							oneRowArray.push(salesDto.salesLedger == null ? "" : salesDto.salesLedger);
							break;
						case LstSalesEnumCol.NO_OF_CARTON:
							oneRowArray.push(salesDto.noOfCarton == null ? "" : salesDto.noOfCarton.toString());
							if (salesDto.noOfCarton != null) {
								sumNoOfCarton = sumNoOfCarton + salesDto.noOfCarton;
							}
							break;
						case LstSalesEnumCol.DELIVERY_BY:
							oneRowArray.push(salesDto.deliveryBy == null ? "" : salesDto.deliveryBy);
							break;
					}
				};

				dataToReturn.push({
					id: salesDto.id,
					data: oneRowArray
				});
			};

			setRows(dataToReturn);
		}
		else {
			setShowMessageDialog(true);
			setMessageDialogMessage(res.errorRes?.message!);
		}
	}

	return (
		<>
			<ListViewButtonBar
				listViewOptions={getListViewOptions()}
				handleClickAdd={handleClickAdd}
				handleClickEdit={handleClickEdit}
				handleClickDelete={handleClickDelete}
				handleClickExport={handleClickExport}
				handleClickPrint={handleClickPrint}
				handleClickOpen={handleClickOpen}
				handleClickFilter={handleClickFilter}
				handleClickClearFilter={handleClickClearFilter}
				handleClickColSettings={handleClickColSettings}
			/>

			<ListViewTable
				colNames={colNames}
				rows={rows}
				setSelectedId={setSelectedId}
			/>

			<ListSalesSd
				showModal={showSd}
				setShowModal={setShowSd}
				listSalesSm={listSalesSm}
				processOnSuccess={(listSalesSm) => setListSalesSm(listSalesSm)}
			/>

			<MessageDialog
				showDialog={showMessageDialog}
				modalMessage={messageDialogMessage}
				setShowDialog={setShowMessageDialog} />

			<SalesPrintDialog
				showDialog={showSalesPrintDialog}
				setShowDialog={setShowSalesPrintDialog}
				print={printSales}
			/>
		</>
	);
}

export default ListSales;
