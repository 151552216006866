interface TopbarProps {
	title: string;
	goBack: () => void;
}

function Topbar({ title, goBack }: TopbarProps) {
	return (
		<div className="shadow-sm d-flex bg-body-tertiary border-bottom">
			<button
				tabIndex={0}
				className="p-3 btn btn-outline fw-medium rounded-0"
				type="submit"
				onClick={() => goBack()}
			>
				<i className="fa-solid fa-arrow-left fa-xl"></i>
			</button>
			<button
				tabIndex={-1}
				className="p-3 btn btn-outline fw-medium"
				type="submit"
			>
				{title === null || title === "" ? "Menu" : title}
			</button>
			<div className="ms-auto">
				<button
					tabIndex={0}
					className="p-3 btn btn-outline fw-medium rounded-0"
					type="submit"
				>
					Hem Softwares (01-04-2023 to 31-03-2024)
				</button>
				<button
					tabIndex={0}
					className="p-3 btn btn-outline fw-medium rounded-0"
					type="submit"
				>
					<i className="fa-solid fa-gear fa-lg"></i>
				</button>
			</div>
		</div>
	);
}

export default Topbar;
