import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, FormControl, Row } from "react-bootstrap";
import RateTypeEnum from "../../enums/RateTypeEnum";
import PriceListTypeHelper from "../../helpers/PriceListTypeHelper";
import ProdHelper from "../../helpers/ProdHelper";
import StringHelper2 from "../../helpers/StringHelper2";
import UnitHelper from "../../helpers/UnitHelper";
import YesNoHelper from "../../helpers/YesNoHelper";
import DropDownItemType from "../../types/DropDownItemType";
import PurchaseReturnItemType from "../../types/PurchaseReturnItemType";
import UnitLdto from "../../types/res/list/UnitLdto";
import ProdForEsimateLdto from "../../types/res/list/ProdForEsimateLdto";
import TaxCatLdto from "../../types/res/list/TaxCatLdto";
import TaxLdto from "../../types/res/list/TaxLdto";
import DownshiftSelect from "../DownshiftSelectV2";
import MessageDialog from "../dialogs/MessageDialog";

interface AddPurchaseReturnItemFormSvProps {
	products: ProdForEsimateLdto[];
	productsMap: Map<number, ProdForEsimateLdto>;
	productsForDd: DropDownItemType[];
	units: UnitLdto[];
	unitsMap: Map<number, UnitLdto>;
	taxMap: Map<number, TaxLdto>;
	taxCatMap: Map<number, TaxCatLdto>;
	handleItemChange: (AddPurchaseReturnItem: PurchaseReturnItemType) => void;
	purchaseReturnItemToEdit: PurchaseReturnItemType | null;
	id?: number
}

function AddPurchaseReturnItemFormSv({
	products,
	productsMap,
	productsForDd,
	units,
	unitsMap,
	taxMap,
	taxCatMap,
	handleItemChange,
	purchaseReturnItemToEdit,
	id
}: AddPurchaseReturnItemFormSvProps): JSX.Element {

	const [qtyCurrent, setQtyCurrent] = useState<number | null>(null);
	const [qtyPendingPurchaseReturn, setPendingPurchaseReturn] = useState<number | null>(null);
	const [qtyFinal, setFinal] = useState<number | null>(null);

	const [productId, setProductId] = useState<number | null>(null);
	const [qtyStr, setQtyStr] = useState<string>("");
	const [rateStr, setRateStr] = useState<string>("");
	const [discStr, setDiscStr] = useState<string>("");
	const [desc, setDesc] = useState<string>("");
	const [isSetInStock, setIsSetInStock] = useState(false);
	//
	const [yesNosForDd, setYesNosForDd] = useState<DropDownItemType[]>([]);

	const [unitsForDd, setUnitsForDd] = useState<DropDownItemType[]>([]);

	const [priceListTypesForDd, setPriceListTypesForDd] = useState<DropDownItemType[]>([]);

	//
	const cmbProductRef = useRef<HTMLInputElement | null>(null);

	const [messageDialogMessage, setMessageDialogMessage] = useState("");
	const [showMessageDialog, setShowMessageDialog] = useState(false);

	useEffect(() => {
		async function process() {

			setPriceListTypesForDd(PriceListTypeHelper.getPriceListTypesForDd());
			setYesNosForDd(YesNoHelper.getYesNosForDd());

		}
		process();
	}, []);

	useEffect(() => {
		if (purchaseReturnItemToEdit != null) {
			setProductId(purchaseReturnItemToEdit.productId);
			handleProdChange(purchaseReturnItemToEdit.productId);
			setQtyStr(purchaseReturnItemToEdit.qty.toString());
			setRateStr(purchaseReturnItemToEdit.rate.toString());
			if (purchaseReturnItemToEdit.discRate != null)
				setDiscStr(purchaseReturnItemToEdit.discRate.toString());
			setDesc(purchaseReturnItemToEdit.desc);
		}
	}, [purchaseReturnItemToEdit]);

	const handleProdChange = (prodIdLoc: number | null) => {

		let qtyCurrentLoc = null;
		let qtyPendingPurchaseReturnLoc = null;
		let qtyFinalLoc = null;
		let unitsForDdLoc: UnitLdto[] = [];

		if (
			prodIdLoc != null &&
			prodIdLoc > 0 &&
			products !== undefined &&
			products !== null &&
			productsMap !== undefined &&
			productsMap !== null &&
			products.length > 0) {

			let product = productsMap.get(prodIdLoc);

			qtyCurrentLoc = product?.qtyCurrent!;
			qtyPendingPurchaseReturnLoc = product?.pendingQtyEstimate!;

			if (qtyCurrentLoc === undefined || qtyCurrentLoc === null) {
				qtyCurrentLoc = 0;
			}
			if (qtyPendingPurchaseReturnLoc === undefined || qtyPendingPurchaseReturnLoc === null) {
				qtyPendingPurchaseReturnLoc = 0;
			}
			if (qtyPendingPurchaseReturnLoc === undefined || qtyPendingPurchaseReturnLoc === null) {
				qtyPendingPurchaseReturnLoc = 0;
			}

			qtyFinalLoc = qtyCurrentLoc - qtyPendingPurchaseReturnLoc - qtyPendingPurchaseReturnLoc;


			if (product?.unitId !== undefined && product?.unitId !== null) {
				unitsForDdLoc.push(unitsMap?.get(product.unitId)!);
			}

			checkAndSetRate(prodIdLoc);

		}

		setQtyCurrent(qtyCurrentLoc);
		setPendingPurchaseReturn(qtyPendingPurchaseReturnLoc);
		setPendingPurchaseReturn(qtyPendingPurchaseReturnLoc);
		setFinal(qtyFinalLoc);

		setProductId(prodIdLoc);

		setUnitsForDd(UnitHelper.getUnitsForDd(unitsForDdLoc));
	};


	const _handleClickSave = async (event: React.FormEvent<HTMLFormElement>) => {

		event.preventDefault();

		if (!isValid()) {
			return;
		}

	}

	const fillInUi = (/*productResType: PurchaseReturnResType*/) => {

	};

	const handleClose = () => setShowMessageDialog(false);
	const handleShow = () => setShowMessageDialog(true);

	const clearBoxes = () => {
		setProductId(-1);
		setQtyStr("");
		setRateStr("");
		setDiscStr("");
		setDesc("");
	};

	const handleSave = (e: React.MouseEvent) => {
		e.preventDefault();

		if (!isValid()) {
			return;
		}

		let productIdLoc: number = productId!;
		let qtyLoc: number = parseFloat(qtyStr);
		let rateLoc: number = parseFloat(rateStr);
		let discLoc: number = 0;
		let descLoc: string = desc;

		if (discStr !== undefined
			&& discStr !== null
			&& StringHelper2.isNum(discStr)
		) {
			discLoc = parseFloat(discStr);
		}

		let AddPurchaseReturnItemLoc: PurchaseReturnItemType = {
			productId: productIdLoc,
			desc: descLoc,
			qty: qtyLoc,
			rate: rateLoc,
			discRate: discLoc,
			discRateTypeId: RateTypeEnum.PERCENT.id,
			isSetInStock: isSetInStock
		};

		handleItemChange(AddPurchaseReturnItemLoc);

		clearBoxes();

		cmbProductRef.current?.focus();

	};

	const isValid = (): boolean => {

		if (
			productId === undefined
			|| productId === null
			|| productsMap?.get(productId) === undefined
			|| productsMap?.get(productId) === null
		) {
			setMessageDialogMessage("Select product");
			setShowMessageDialog(true);
			return false;
		}

		if (qtyStr === undefined
			|| qtyStr === null
			|| !StringHelper2.isNum(qtyStr)
		) {
			setMessageDialogMessage("Invalid quantity");
			setShowMessageDialog(true);
			return false;
		}

		if (
			rateStr === undefined
			|| rateStr === null
			|| !StringHelper2.isNum(rateStr)) {
			setMessageDialogMessage("Invalid rate");
			setShowMessageDialog(true);
			return false;
		}

		return true;
	}

	const checkAndSetRate = (productId: number | null) => {

		let rateStrLoc = "";
		let discStrLoc = "";

		if (
			productId == null
			|| productsMap?.get(productId) == null
		) {
			return;
		}

		setRateStr(rateStrLoc);
		setDiscStr(discStrLoc);

	}

	return (
		<>
			<div className="row g-3 align-items-center mb-3">
				<div className="col-auto">
					<label className="col-form-label">Quantity</label>
				</div>
				<div className="col-auto">
					<input type="text" className="form-control form-control-sm" readOnly={true}
						value={qtyCurrent ? qtyCurrent.toLocaleString('en-IN') : ""}
					/>
				</div>
				<div className="col-auto">
					<label className="col-form-label">-</label>
				</div>
				<div className="col-auto">
					<input type="text" className="form-control form-control-sm" readOnly={true}
						value={qtyPendingPurchaseReturn ? qtyPendingPurchaseReturn.toLocaleString('en-IN') : ""}
					/>
				</div>
				<div className="col-auto">
					<label className="col-form-label">-</label>
				</div>
				<div className="col-auto">
					<input type="text" className="form-control form-control-sm" readOnly={true}
						value={qtyPendingPurchaseReturn ? qtyPendingPurchaseReturn.toLocaleString('en-IN') : ""}
					/>
				</div>
				<div className="col-auto">
					<label className="col-form-label form-control-sm">=</label>
				</div>
				<div className="col-auto">
					<input type="text" className="form-control" readOnly={true}
						value={qtyFinal ? qtyFinal.toLocaleString('en-IN') : ""}
					/>
				</div>

			</div>

			<Row className="mb-3" xs="auto">
				<Form.Group as={Col}>
					<Form.Label>Product</Form.Label>
					<DownshiftSelect
						inputId={"product"}
						value={productId != null && productId > 0 && productsMap?.get(productId) ? ProdHelper.getProdDdi(productsMap?.get(productId)!) : null}
						onChange={handleProdChange}
						options={productsForDd}
						isLoading={false}
						isDisabled={false}
						placeholder=""
						forwardedRef={cmbProductRef}
					/>
				</Form.Group>

				<Form.Group as={Col}>
					<Form.Label>&nbsp;</Form.Label>
					<FormControl
						size="sm" as={Button}
					>...</FormControl>
				</Form.Group>

				<Form.Group as={Col}>
					<Form.Label>Quantity</Form.Label>
					<FormControl
						size="sm"
						type="text"
						value={qtyStr}
						onChange={(e) => setQtyStr(e.target.value)}
					/>
				</Form.Group>

				<Form.Group as={Col}>
					<Form.Label>Rate</Form.Label>
					<FormControl
						size="sm"
						type="text"
						value={rateStr}
						onChange={(e) => setRateStr(e.target.value)}
					/>
				</Form.Group>

				<Form.Group as={Col}>
					<Form.Label>Discount</Form.Label>
					<FormControl
						size="sm"
						type="text"
						value={discStr}
						onChange={(e) => setDiscStr(e.target.value)}
					/>
				</Form.Group>

				<Form.Group as={Col}>
					<Form.Label></Form.Label>
					<div className="form-check">
						<input
							className="form-check-input"
							type="checkbox"
							name="chkIsSetInStock"
							id="chkIsSetInStock"
							checked={isSetInStock}
							onChange={(e) => setIsSetInStock(e.target.checked)}
						/>
						<label className="form-check-label" htmlFor="chkIsSetInStock">
							Set in stock
						</label>
					</div>
				</Form.Group>

				<Form.Group as={Col}>
					<Form.Label>&nbsp;</Form.Label>
					<FormControl
						size="sm"
						as={Button}
						onClick={handleSave}
					>
						Save
					</FormControl>
				</Form.Group>

				<Form.Group as={Col}>
					<Form.Label>&nbsp;</Form.Label>
					<FormControl
						size="sm"
						as={Button}>Cancel</FormControl>
				</Form.Group>

				<Form.Group as={Col}>
					<Form.Label>Description</Form.Label>
					<FormControl
						size="sm"
						type="text"
						onChange={(e) => setDesc(e.target.value)}
					/>
				</Form.Group>
			</Row>

			<MessageDialog
				modalMessage={messageDialogMessage}
				showDialog={showMessageDialog}
				setShowDialog={setShowMessageDialog}
			/>

		</>
	);
}

export default AddPurchaseReturnItemFormSv;
