import { useEffect, useState } from "react";
import { useGvContext } from "../../GvContext";
import { BASE_URL, TOKEN } from "../../config";
import ResourceEnum from "../../enums/ResourceEnum";
import strFormat from "../../helpers/StringHelper";
import { getQueryString } from "../../helpers/UrlSearchParamHelper";
import RowType from "../../types/RowType";
import ColSettingResType from "../../types/res/ColSettingResType";
import ProdLdto from "../../types/res/list/ProdLdto";
import ListProdSmType from "../../types/sm/ListProdSm";
import ListViewButtonBar from "../ListViewButtonBar";
import ListViewTable from "../ListViewTable";
import useConfirm from "../dialogs/ConfirmDialog";
import MessageDialog from "../dialogs/MessageDialog";

function ListService(): JSX.Element {

	const resourceName = ResourceEnum.SERVICE.name;

	const [rows, setRows] = useState<RowType[]>([]);
	const [selectedId, setSelectedId] = useState<number | null>(null);

	const [listProductSm, setListProductSm] = useState<ListProdSmType>(new ListProdSmType());

	const [showSd, setShowSd] = useState<boolean>(false);
	
	const [messageDialogMessage, setMessageDialogMessage] = useState("");
	const [showMessageDialog, setShowMessageDialog] = useState(false);

	const confirm = useConfirm();
	const gv = useGvContext();

	const colNames = [
		"Name",
		"Name To Print",
		"Purchase Price",
		"Sales Price",
		"MRP",
		"Discount",
		"Category",
		"Tax Category",
		"HSN",
		"Tax",
		"Note",
	];

	useEffect(() => {
		async function process() {
			loadData(listProductSm);
		}
		process();
	}, []);

	const handleClickAdd = () => {
		gv.menuHelper.openAddService();
	};

	const handleClickEdit = () => {
		if (selectedId === null) {
			setMessageDialogMessage("Select atleast one row");
			setShowMessageDialog(true);
			return;
		}

		gv.menuHelper.openAddService(selectedId);
	};

	const handleClickDelete = async () => {
		if (selectedId === null) {
			setMessageDialogMessage("Select atleast one row");
			setShowMessageDialog(true);
			return;
		}

		let choice = await confirm!({
			message: "Are you sure you want to delete?",
			title: "Delete confirmation"
		});

		if (choice === undefined || choice === null || choice === false)
			return;

		const requestOptions = {
			method: "DELETE",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			}
		}

		const res = await fetch(strFormat("%s/%s/%s", BASE_URL, resourceName, selectedId), requestOptions);

		if (res.status !== 204) {
			const data = await res.json();
			setMessageDialogMessage(data.message);
			setShowMessageDialog(true);
		}
		else {
			loadData(listProductSm);
		}
	};

	const handleClickExport = () => {
		console.log("handleClickExport");
	};

	const handleClickPrint = () => {
		console.log("handleClickPrint");
	};

	const handleClickOpen = () => {
		console.log("handleClickOpen");
	};

	const handleClickFilter = () => {
		setShowSd(true);
	};

	const handleClickClearFilter = () => {
		console.log("handleClickClearFilter");
	};

	const handleClickColSettings = () => {
		console.log("handleClickColSettings");
	};

	const loadData = async (
		listServiceSm: ListProdSmType,
		colSettingsLocal?: ColSettingResType[]
	) => {

		const requestOptions = {
			method: "GET",
			headers: {
				Authorization: "Bearer " + TOKEN.val,
				"Content-Type": "application/json",
			},
		};
		const res = await fetch(strFormat("%s/%s", BASE_URL, resourceName)
			//+ new URLSearchParams(listVouOthChargeSm.toUrlSearchParams())
			+ getQueryString(listProductSm)
			, requestOptions);
		const results: ProdLdto[] = await res.json();

		let dataToReturn: RowType[] = [];
		results.forEach((result) => {
			let oneRowArray: string[] = [];

			oneRowArray.push(result.name);
			oneRowArray.push(result.nameToPrint);
			oneRowArray.push(result.purchasePrice == null ? "" : result.purchasePrice.toString());
			oneRowArray.push(result.salesPrice == null ? "" : result.salesPrice.toString());
			oneRowArray.push(result.mrp == null ? "" : result.mrp.toString());
			oneRowArray.push(result.disc == null ? "" : result.disc.toString());
			oneRowArray.push(result.productCategoryName == null ? "" : result.productCategoryName);
			oneRowArray.push(result.taxCatName == null ? "" : result.taxCatName);
			oneRowArray.push(result.hsn == null ? "" : result.hsn.toString());
			oneRowArray.push(result.taxName == null ? "" : result.taxName);
			oneRowArray.push(result.note);

			dataToReturn.push({
				id: result.id,
				data: oneRowArray
			});
		});

		setRows(dataToReturn);
	}

	return (
		<>
			<ListViewButtonBar
				handleClickEdit={handleClickEdit}
				handleClickDelete={handleClickDelete}
				handleClickExport={handleClickExport}
				handleClickPrint={handleClickPrint}
				handleClickOpen={handleClickOpen}
				handleClickFilter={handleClickFilter}
				handleClickClearFilter={handleClickClearFilter}
				handleClickColSettings={handleClickColSettings}
			/>
			<ListViewTable
					colNames={colNames}
					rows={rows}
					setSelectedId={setSelectedId}
				/>

			<MessageDialog
				showDialog={showMessageDialog}
				modalMessage={messageDialogMessage}
				setShowDialog={setShowMessageDialog} />
		</>
	);
}

export default ListService;
