import { BASE_URL, TOKEN } from "../config";
import ServerNotReachableErrorCommonCudObj from "../default_objects/ServerNotReachableErrorCommonCudObj";
import ServerSideErrorCommonCudObj from "../default_objects/ServerSideErrorCommonCudObj";
import ResourceEnum from "../enums/ResourceEnum";
import SalesPrintTypeEnum from "../enums/SalesPrintTypeEnum";
import strFormat from "../helpers/StringHelper";
import { getQueryString } from "../helpers/UrlSearchParamHelper";
import CommonCudType from "../types/CommonCudType";
import CommonGetListType from "../types/CommonGetListType";
import ErrorResType from "../types/res/ErrorResType";
import EstimateLdto from "../types/res/list/EstimateLdto";
import RptEstimatePendingByAcctHdto from "../types/res/list/RptEstimatePendingByAcctHdto";
import RptEstimatePendingByProdHdto from "../types/res/list/RptEstimatePendingByProdHdto";
import RptEstimatePendingDetailedHdto from "../types/res/list/RptEstimatePendingDetailedHdto";
import EstimateRrHoType from "../types/rr/EstimateRrHoType";
import ListEstimateSm from "../types/sm/ListEstimateSm";
import RptEstimatePendingByCustomerSm from "../types/sm/RptEstimatePendingByCustomerSm";
import RptEstimatePendingByProdSm from "../types/sm/RptEstimatePendingByProdSm";
import RptEstimatePendingDetailedSm from "../types/sm/RptEstimatePendingDetailedSm";

export default class EstimateApi {

	static async getEstimateLdtos(listEstimateSm: ListEstimateSm): Promise<CommonGetListType<EstimateLdto>> {
		try {

			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.ESTIMATE.name)
				+ getQueryString(listEstimateSm)
				, requestOptions);

			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as EstimateLdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async createEstimate(estimateReqHo: EstimateRrHoType): Promise<CommonCudType> {

		const requestOptions = {
			method: "POST",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(estimateReqHo)
		}

		const res = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.ESTIMATE.name), requestOptions);

		if (res.status !== 201) {
			const data: ErrorResType = await res.json();
			return {
				isSuccess: false,
				errorRes: data
			}
		}

		return {
			isSuccess: true,
		}

	}

	static async updateEstimate(id: number, estimateReqHo: EstimateRrHoType): Promise<CommonCudType> {

		const requestOptions = {
			method: "PUT",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(estimateReqHo)
		}

		const res = await fetch(strFormat("%s/%s/%s", BASE_URL, ResourceEnum.ESTIMATE.name, id), requestOptions);

		if (res.status !== 201) {
			const data: ErrorResType = await res.json();
			return {
				isSuccess: false,
				errorRes: data
			}
		}

		return {
			isSuccess: true,
		}

	}

	static async getById(id: number) {

		const requestOptions = {
			method: "GET",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			}
		}

		const res = await fetch(strFormat("%s/%s/%s", BASE_URL, ResourceEnum.ESTIMATE.name, id), requestOptions);
		const data: EstimateRrHoType = await res.json();
		return data;

	}

	static async delete(id: number): Promise<CommonCudType> {

		try {
			const requestOptions = {
				method: "DELETE",
				headers: {
					"Authorization": "Bearer " + TOKEN.val,
					"Content-Type": "application/json"
				}
			}

			const response = await fetch(strFormat("%s/%s/%s", BASE_URL, ResourceEnum.ESTIMATE.name, id), requestOptions);

			if (response.status === 200) {
				return {
					isSuccess: true
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}


	}

	static async print(ids: number[], isPrintEstimate: boolean, isPrintPendingItems: boolean, salesPrintType: SalesPrintTypeEnum) {

		const requestOptions = {
			method: "GET",
			headers: {
				Authorization: "Bearer " + TOKEN.val,
				"Content-Type": "application/json",
			},
		};
		const res = await fetch(strFormat("%s/%s/print", BASE_URL, ResourceEnum.ESTIMATE.name)
			+ getQueryString({
				ids: ids,
				isPrintEstimate: isPrintEstimate,
				isPrintPendingItems: isPrintPendingItems,
				salesPrintType: salesPrintType.enumName
			})
			, requestOptions);

		var blob = await res.blob();
		return new Blob([blob], { type: 'application/pdf' });
	}

	// report
	static async getRptEstimatePendingByProdHdtos(searchModel: RptEstimatePendingByProdSm): Promise<CommonGetListType<RptEstimatePendingByProdHdto>> {
		try {

			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s/%s/%s",
					BASE_URL,
					ResourceEnum.ESTIMATE.name,
					"reports",
					"pending",
					"summary",
					"by-product"
				)
				+ getQueryString(searchModel)
				, requestOptions);

			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptEstimatePendingByProdHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async getRptEstimatePendingByCustomerHdtos(searchModel: RptEstimatePendingByCustomerSm): Promise<CommonGetListType<RptEstimatePendingByAcctHdto>> {
		try {

			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s/%s/%s",
					BASE_URL,
					ResourceEnum.ESTIMATE.name,
					"reports",
					"pending",
					"summary",
					"by-customer"
				)
				+ getQueryString(searchModel)
				, requestOptions);

			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptEstimatePendingByAcctHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

	static async getRptEstimatePendingDetailedHdtos(searchModel: RptEstimatePendingDetailedSm): Promise<CommonGetListType<RptEstimatePendingDetailedHdto>> {
		try {

			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(
				strFormat(
					"%s/%s/%s/%s/%s",
					BASE_URL,
					ResourceEnum.ESTIMATE.name,
					"reports",
					"pending",
					"by-product"
				)
				+ getQueryString(searchModel)
				, requestOptions);

			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as RptEstimatePendingDetailedHdto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

}