import DropDownItemType from "../types/DropDownItemType";
import TransporterLdto from "../types/res/list/TransporterLdto";

export default class TransporterHelper {

	static getTransporterListResArrForDd(listTransporters: TransporterLdto[]) {

		let finalArr: DropDownItemType[] = [];
		listTransporters.forEach(item => {
			finalArr.push({
				id: item.id,
				name: item.name
			});
		});
		return finalArr;

	}

	static getTransporterListResForDd(transporter: TransporterLdto): DropDownItemType {
		return {
			id: transporter.id,
			name: transporter.name
		};
	}

	static getTransporterListResMap(itemArr: TransporterLdto[]) {
		let map = new Map<number, TransporterLdto>([]);
		for (let index = 0; index < itemArr.length; index++) {
			const item = itemArr[index];
			map.set(item.id, item);
		}
		return map;
	}

}
