import { useEffect, useState } from "react";
import { useGvContext } from "../../GvContext";
import EstimateApi from "../../api/EstimateApi";
import MenuApi from "../../api/MenuApi";
import ListMenuEnum from "../../enums/ListMenuEnum";
import LstEstimatePendingDetailedEnumCol from "../../enums/col/LstEstimatePendingDetailedEnumCol";
import RowType from "../../types/RowType";
import ColSettingResType from "../../types/res/ColSettingResType";
import RptEstimatePendingDetailedSm from "../../types/sm/RptEstimatePendingDetailedSm";
import ListViewButtonBar from "../ListViewButtonBar";
import ListViewTable from "../ListViewTable";
import useConfirm from "../dialogs/ConfirmDialog";
import MessageDialog from "../dialogs/MessageDialog";
import RptEstimatePendingDetailedSd from "../sd/RptEstimatePendingDetailedSd";

function RptEstimatePendingDetailed(): JSX.Element {
	
	const [colSettings, setColSettings] = useState<ColSettingResType[]>([]);
	const [colNames, setColNames] = useState<string[]>([]);

	const [rows, setRows] = useState<RowType[]>([]);
	const [selectedId, setSelectedId] = useState<number | null>(null);

	const [searchModel, setSearchModel] = useState(new RptEstimatePendingDetailedSm());
	//
	const [showSd, setShowSd] = useState<boolean>(false);
	
	const [messageDialogMessage, setMessageDialogMessage] = useState("");
	const [showMessageDialog, setShowMessageDialog] = useState(false);

	const confirm = useConfirm();
	const gv = useGvContext();

	useEffect(() => {
		async function process() {

			let res = await MenuApi.getColSettingDtos(ListMenuEnum.RPT_ESTIMATE_PENDING_PRODUCT);

			if (res.isSuccess) {

				setColSettings(res.items!);

				let colNamesLocal: string[] = [];
				res.items!.forEach((item) => {
					colNamesLocal.push(item.colName);
				});
				setColNames(colNamesLocal);

			}
			else {
				setShowMessageDialog(true);
				setMessageDialogMessage(res.errorRes?.message!);
			}

		}
		process();
	}, []);

	useEffect(() => {
		if (colSettings != null && colSettings.length > 0) {
			loadData();
		}
	}, [searchModel, colSettings]);

	const handleClickExport = () => {
		console.log("handleClickExport");
	};

	const handleClickOpen = () => {
		console.log("handleClickOpen");
	};

	const handleClickFilter = () => {
		setShowSd(true);
	};

	const handleClickClearFilter = () => {
		let searchModelLoc = new RptEstimatePendingDetailedSm();
		searchModelLoc.reset();
		setSearchModel(searchModelLoc);
	};

	const handleClickColSettings = () => {
		console.log("handleClickColSettings");
	};

	const loadData = async () => {

		let res = await EstimateApi.getRptEstimatePendingDetailedHdtos(searchModel);

		if (res.isSuccess) {

			let dataToReturn: RowType[] = [];

			for (let i = 0; i < res.items?.length!; i++) {
				let row = res.items![i];

				let oneRowArray: string[] = [];

				for (let j = 0; j < colSettings.length; j++) {
					let colSetting = colSettings[j];

					switch (LstEstimatePendingDetailedEnumCol.getByKey(colSetting.colKey)) {
						case LstEstimatePendingDetailedEnumCol.CUSTOMER_NAME:
							oneRowArray.push(row.customerName == null ? "" : row.customerName);
							break;
						case LstEstimatePendingDetailedEnumCol.DATE:
							oneRowArray.push(row.date == null ? "" : row.date.toString());
							break;
						case LstEstimatePendingDetailedEnumCol.NO:
							oneRowArray.push(row.no == null ? "" : row.no.toString());
							break;
						case LstEstimatePendingDetailedEnumCol.PRODUCT_NAME:
							oneRowArray.push(row.productName == null ? "" : row.productName);
							break;
						case LstEstimatePendingDetailedEnumCol.QTY:
							oneRowArray.push(row.qty == null ? "" : row.qty.toString());
							break;
						case LstEstimatePendingDetailedEnumCol.CURRENT_STOCK_QTY:
							oneRowArray.push(row.currentStockQty == null ? "" : row.currentStockQty.toString());
							break;
					}
				};

				dataToReturn.push({
					id: row.estimateId,
					data: oneRowArray
				});
			};

			setRows(dataToReturn);
		}
		else {
			setShowMessageDialog(true);
			setMessageDialogMessage(res.errorRes?.message!);
		}

	}

	return (
		<>
			<ListViewButtonBar
				handleClickExport={handleClickExport}
				handleClickOpen={handleClickOpen}
				handleClickFilter={handleClickFilter}
				handleClickClearFilter={handleClickClearFilter}
				handleClickColSettings={handleClickColSettings}
			/>

			<ListViewTable
				colNames={colNames}
				rows={rows}
				setSelectedId={setSelectedId}
			/>

			<RptEstimatePendingDetailedSd
				showModal={showSd}
				setShowModal={setShowSd}
				searchModel={searchModel}
				processOnSuccess={(searchModel) => setSearchModel(searchModel)}
			/>

			<MessageDialog
				showDialog={showMessageDialog}
				modalMessage={messageDialogMessage}
				setShowDialog={setShowMessageDialog} />
		</>
	);
}

export default RptEstimatePendingDetailed;
