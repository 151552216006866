export default class ListCommVouSm {

	no: string | undefined;
	salesNo: string | undefined;
	dateFrom: Date | undefined;
	dateTo: Date | undefined;
	salesDateFrom: Date | undefined;
	salesDateTo: Date | undefined;
	customerId: number | undefined;
	customerName: string | undefined;
	agentId: number | undefined;
	agentName: string | undefined;

	reset(): void {

		this.no = undefined;
		this.salesNo = undefined;
		this.dateFrom = undefined;
		this.dateTo = undefined;
		this.salesDateFrom = undefined;
		this.salesDateTo = undefined;
		this.customerId = undefined;
		this.customerName = undefined;
		this.agentId = undefined;
		this.agentName = undefined;

	}

	isBlank(): boolean {
		return this.no === undefined
			&& this.salesNo === undefined
			&& this.dateFrom === undefined
			&& this.dateTo === undefined
			&& this.salesDateFrom === undefined
			&& this.salesDateTo === undefined
			&& this.customerId === undefined
			&& this.customerName === undefined
			&& this.agentId === undefined
			&& this.agentName === undefined;

	}
}
