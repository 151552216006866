import { BASE_URL, TOKEN } from "../config";
import ServerNotReachableErrorCommonCudObj from "../default_objects/ServerNotReachableErrorCommonCudObj";
import ServerSideErrorCommonCudObj from "../default_objects/ServerSideErrorCommonCudObj";
import ResourceEnum from "../enums/ResourceEnum";
import strFormat from "../helpers/StringHelper";
import CommonGetListType from "../types/CommonGetListType";
import CustomerAgentResDto from "../types/res/CustomerAgentResDto";
import ErrorResType from "../types/res/ErrorResType";

export default class CustomerAgentApi {

	static async getCustomerAgentResDtosByCustomerId(customerId: number): Promise<CommonGetListType<CustomerAgentResDto>> {
		try {

			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const response = await fetch(strFormat("%s/%s/%s/%s",
				BASE_URL,
				ResourceEnum.CUSTOMER.name,
				customerId,
				ResourceEnum.AGENT.name)
				, requestOptions);

			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as CustomerAgentResDto[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}

}