export default class ListSalesOrderSm {

	no: number | undefined;
	dateFrom: Date | undefined | null;
	dateTo: Date | undefined | null;
	customerId: number | undefined | null;
	customerName: string | undefined;
	city: string | undefined;
	stateId: number | undefined | null;
	state: string | undefined;
	agentId: number | undefined;
	agentName: string | undefined;
	note: string | undefined;
	isConvertedToSales: boolean | undefined;
	isCompleted: boolean | undefined;

	constructor(
		isConvertedToSales?: boolean | undefined,
		isCompleted?: boolean | undefined
	) {
		this.isConvertedToSales = isConvertedToSales;
		this.isCompleted = isCompleted;
	}

	reset(): void {
		this.no = undefined;
		this.dateFrom = undefined;
		this.dateTo = undefined;
		this.customerId = undefined;
		this.customerName = undefined;
		this.city = undefined;
		this.stateId = undefined;
		this.state = undefined;
		this.agentId = undefined;
		this.agentName = undefined;
		this.note = undefined;
		this.isConvertedToSales = undefined;
		this.isCompleted = undefined;

	}

	isBlank(): boolean {
		return this.no === undefined
			&& this.dateFrom === undefined
			&& this.dateTo === undefined
			&& this.customerId === undefined
			&& this.customerName === undefined
			&& this.city === undefined
			&& this.stateId === undefined
			&& this.state === undefined
			&& this.agentId === undefined
			&& this.agentName === undefined
			&& this.note === undefined
			&& this.isConvertedToSales === undefined
			&& this.isCompleted === undefined;
	}
}
