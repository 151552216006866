import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import AcctApi from "../../api/AcctApi";
import CompanyProfileApi from "../../api/CompanyProfileApi";
import StateApi from "../../api/StateApi";
import TransporterApi from "../../api/TransporterApi";
import AcctHelper from "../../helpers/AcctHelper";
import CompanyProfileHelper from "../../helpers/CompanyProfileHelper";
import StateHelper from "../../helpers/StateHelper";
import StringHelper2 from "../../helpers/StringHelper2";
import TransporterHelper from "../../helpers/TransporterHelper";
import DropDownItemType from "../../types/DropDownItemType";
import AcctForCustomerHdto from "../../types/res/list/AcctForCustomerHdto";
import CompanyProfileLdto from "../../types/res/list/CompanyProfileLdto";
import StateLdto from "../../types/res/list/StateLdto";
import TransporterLdto from "../../types/res/list/TransporterLdto";
import ListSalesSm from "../../types/sm/ListSalesSm";
import DownshiftSelect from "../DownshiftSelectV2";
import MessageDialog from "../dialogs/MessageDialog";
import ListCompanyProfileSm from "../../types/sm/ListCompanyProfileSm";
import ListTransporterSm from "../../types/sm/ListTransporterSm";

interface ListSalesSdPropsType {
	showModal: boolean;
	setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
	listSalesSm: ListSalesSm;
	processOnSuccess: (listSalesSm: ListSalesSm) => void;
}

function ListSalesSd({
	showModal,
	setShowModal,
	listSalesSm,
	processOnSuccess
}: ListSalesSdPropsType) {

	const [dateFrom, setDateFrom] = useState(listSalesSm.dateFrom);
	const [dateTo, setDateTo] = useState(listSalesSm.dateTo);
	const [customerId, setCustomerId] = useState<number | null>(listSalesSm.customerId ?? null);
	const [customerName, setCustomerName] = useState(listSalesSm.customerName);
	const [noStr, setNoStr] = useState<string>(listSalesSm.no?.toString() ?? "");
	const [city, setCity] = useState(listSalesSm.city);
	const [stateId, setStateId] = useState<number | null>(listSalesSm.stateId ?? null);
	const [stateName, setStateName] = useState(listSalesSm.state);
	const [orderNo, setOrderNo] = useState(listSalesSm.orderNo);
	const [orderDateFrom, setOrderDateFrom] = useState(listSalesSm.orderDateFrom);
	const [orderDateTo, setOrderDateTo] = useState(listSalesSm.orderDateTo);
	const [agentId, setAgentId] = useState<number | null>(listSalesSm.agentId ?? null);
	const [agentName, setAgentName] = useState(listSalesSm.agentName);
	const [lrNo, setLrNo] = useState(listSalesSm.lrNo);
	const [lrDateFrom, setLrDateFrom] = useState(listSalesSm.lrDateFrom);
	const [lrDateTo, setLrDateTo] = useState(listSalesSm.lrDateTo);
	const [transporterId, setTransporterId] = useState<number | null>(listSalesSm.transporterId ?? null);
	const [transporterName, setTransporterName] = useState(listSalesSm.transporterName);
	const [companyProfileId, setCompanyProfileId] = useState<number | null>(listSalesSm.companyProfileId ?? null);
	const [note, setNote] = useState(listSalesSm.note);
	//
	const [accounts, setAccounts] = useState<AcctForCustomerHdto[] | null>(null);
	const [accountsForDd, setAcctsForDd] = useState<DropDownItemType[] | null>(null);
	const [accountsMap, setAccountsMap] = useState<Map<number, AcctForCustomerHdto> | null>(null);

	const [states, setStates] = useState<StateLdto[] | null>(null);
	const [statesForDd, setStatesForDd] = useState<DropDownItemType[] | null>(null);
	const [statesMap, setStatesMap] = useState<Map<number, StateLdto> | null>(null);

	const [companyProfileListResArr, setCompanyProfileListResArr] = useState<CompanyProfileLdto[] | null>(null);
	const [companyProfileListResArrForDd, setCompanyProfileListResArrForDd] = useState<DropDownItemType[] | null>(null);
	const [companyProfileListResMap, setCompanyProfileListResMap] = useState<Map<number, CompanyProfileLdto> | null>(null);

	const [transporterListResArr, setTransporterListArr] = useState<TransporterLdto[] | null>(null);
	const [transporterListResArrForDd, setTransporterListArrForDd] = useState<DropDownItemType[] | null>(null);
	const [transporterListResMap, setTransporterListResMap] = useState<Map<number, TransporterLdto> | null>(null);

	const [messageDialogMessage, setMessageDialogMessage] = useState("");
	const [showMessageDialog, setShowMessageDialog] = useState(false);

	useEffect(() => {
		async function process() {

			AcctApi.getAcctForCustomerHdtos().then((accounts) => {
				if (accounts.isSuccess) {
					setAccounts(accounts.items!);
					setAcctsForDd(AcctHelper.getArrForDd(accounts.items!));
					setAccountsMap(AcctHelper.getMap(accounts.items!));
				}
			});

			StateApi.getStateLdtos().then((states) => {
				if (states.isSuccess) {
					setStates(states.items!);
					setStatesForDd(StateHelper.getStateListResArrForDd(states.items!));
					setStatesMap(StateHelper.getStateListResMap(states.items!));
				}
			});

			TransporterApi.getTransporterLdtos(new ListTransporterSm()).then((transporterListResArr) => {
				if (transporterListResArr.isSuccess) {
					setTransporterListArr(transporterListResArr.items!);
					setTransporterListArrForDd(TransporterHelper.getTransporterListResArrForDd(transporterListResArr.items!));
					setTransporterListResMap(TransporterHelper.getTransporterListResMap(transporterListResArr.items!));
				}
			});

			CompanyProfileApi.getCompanyProfileLdtos(new ListCompanyProfileSm()).then((listCompanyProfiles) => {
				if (listCompanyProfiles.isSuccess) {
					setCompanyProfileListResArr(listCompanyProfiles.items!);
					setCompanyProfileListResArrForDd(CompanyProfileHelper.getCompanyProfilesForDd(listCompanyProfiles.items!));
					setCompanyProfileListResMap(CompanyProfileHelper.getCompanyProfileListResMap(listCompanyProfiles.items!));
				}
			});

		}
		process();
	}, []);

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		handleClickSearch();
	}

	const handleClickSearch = () => {
		if (!isValid) {
			return;
		}

		let listSalesSmNew = new ListSalesSm();

		listSalesSmNew.dateFrom = dateFrom;
		listSalesSmNew.dateTo = dateTo;
		listSalesSmNew.customerId = customerId;
		listSalesSmNew.customerName = customerName;
		if (noStr != null && noStr !== "") {
			listSalesSmNew.no = parseInt(noStr);
		}
		listSalesSmNew.city = city;
		listSalesSmNew.stateId = stateId;
		listSalesSmNew.state = stateName;
		listSalesSmNew.orderNo = orderNo;
		listSalesSmNew.orderDateFrom = orderDateFrom;
		listSalesSmNew.orderDateTo = orderDateTo;
		listSalesSmNew.agentId = agentId;
		listSalesSmNew.agentName = agentName;
		listSalesSmNew.lrNo = lrNo;
		listSalesSmNew.lrDateFrom = lrDateFrom;
		listSalesSmNew.lrDateTo = lrDateTo;
		listSalesSmNew.transporterId = transporterId;
		listSalesSmNew.transporterName = transporterName;
		listSalesSmNew.companyProfileId = companyProfileId;
		listSalesSmNew.note = note;

		setShowModal(false);

		processOnSuccess(listSalesSmNew);
	}

	const handleClickClose = () => {
		setShowModal(false);
	};

	const isValid = (): boolean => {

		if (noStr != null && noStr !== "" && !StringHelper2.isInt(noStr)) {
			setMessageDialogMessage("Invalid number");
			setShowMessageDialog(true);
			return false;
		}

		return true;
	}

	return (
		<>
			<Modal show={showModal} onHide={handleClickClose}>
				<Modal.Header closeButton>
					<Modal.Title>Filter</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group as={Row} className="mb-3">
							<Form.Label column sm="3">
								Date From
							</Form.Label>
							<Col sm="9">
								<ReactDatePicker
									id="dpDate"
									dateFormat="dd-MM-yyyy"
									selected={dateFrom}
									onChange={(e) => setDateFrom(e)}
									className="form-control"
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} className="mb-3">
							<Form.Label column sm="3">
								Date To
							</Form.Label>
							<Col sm="9">
								<ReactDatePicker
									id="dpDate"
									dateFormat="dd-MM-yyyy"
									selected={dateTo}
									onChange={(e) => setDateTo(e)}
									className="form-control"
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} className="mb-3">
							<Form.Label column sm="3">
								Customer
							</Form.Label>
							<Col sm="9">
								<DownshiftSelect
									inputId={"cmbCustomer"}
									value={customerId == null || accountsMap?.get(customerId) == null ? null : AcctHelper.getOneForDd(accountsMap.get(customerId)!)}
									onChange={setCustomerId}
									options={accountsForDd ?? []}
									isLoading={false}
									isDisabled={false}
									placeholder=""
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} className="mb-3">
							<Form.Label column sm="3">
								Customer
							</Form.Label>
							<Col sm="9">
								<Form.Control
									type="text"
									value={customerName == null ? "" : customerName}
									onChange={(e) => setCustomerName(e.target.value)} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} className="mb-3">
							<Form.Label column sm="3">
								No
							</Form.Label>
							<Col sm="9">
								<Form.Control
									type="text"
									value={noStr} onChange={(e) => setNoStr(e.target.value)}
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} className="mb-3">
							<Form.Label column sm="3">
								City
							</Form.Label>
							<Col sm="9">
								<Form.Control
									type="text"
									value={city == null ? "" : city}
									onChange={(e) => setCity(e.target.value)} />
							</Col>
						</Form.Group>

						<Form.Group as={Row} className="mb-3">
							<Form.Label column sm="3">
								State
							</Form.Label>
							<Col sm="9">
								<DownshiftSelect
									inputId={"cmbState"}
									value={stateId == null || statesMap?.get(stateId) == null ? null : StateHelper.getStateListResForDd(statesMap.get(stateId)!)}
									onChange={setStateId}
									options={statesForDd ?? []}
									isLoading={false}
									isDisabled={false}
									placeholder=""
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} className="mb-3">
							<Form.Label column sm="3">
								State
							</Form.Label>
							<Col sm="9">
								<Form.Control
									type="text"
									value={stateName == null ? "" : stateName}
									onChange={(e) => setStateName(e.target.value)} />
							</Col>
						</Form.Group>


						<Form.Group as={Row} className="mb-3">
							<Form.Label column sm="3">
								Order No
							</Form.Label>
							<Col sm="9">
								<Form.Control
									type="text"
									value={orderNo} onChange={(e) => setOrderNo(e.target.value)}
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} className="mb-3">
							<Form.Label column sm="3">
								Order Date From
							</Form.Label>
							<Col sm="9">
								<ReactDatePicker
									id="dpDate"
									dateFormat="dd-MM-yyyy"
									selected={orderDateFrom}
									onChange={(e) => setOrderDateFrom(e)}
									className="form-control"
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} className="mb-3">
							<Form.Label column sm="3">
								Order Date To
							</Form.Label>
							<Col sm="9">
								<ReactDatePicker
									id="dpDate"
									dateFormat="dd-MM-yyyy"
									selected={orderDateTo}
									onChange={(e) => setOrderDateTo(e)}
									className="form-control"
								/>
							</Col>
						</Form.Group>


						<Form.Group as={Row} className="mb-3">
							<Form.Label column sm="3">
								Agent
							</Form.Label>
							<Col sm="9">
								<DownshiftSelect
									inputId={"cmbAgent"}
									value={agentId == null || accountsMap?.get(agentId) == null ? null : AcctHelper.getOneForDd(accountsMap.get(agentId)!)}
									onChange={setAgentId}
									options={accountsForDd ?? []}
									isLoading={false}
									isDisabled={false}
									placeholder=""
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} className="mb-3">
							<Form.Label column sm="3">
								Agent
							</Form.Label>
							<Col sm="9">
								<Form.Control
									type="text"
									value={agentName == null ? "" : agentName}
									onChange={(e) => setAgentName(e.target.value)} />
							</Col>
						</Form.Group>


						<Form.Group as={Row} className="mb-3">
							<Form.Label column sm="3">
								Lr No
							</Form.Label>
							<Col sm="9">
								<Form.Control
									type="text"
									value={lrNo} onChange={(e) => setLrNo(e.target.value)}
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} className="mb-3">
							<Form.Label column sm="3">
								Lr Date From
							</Form.Label>
							<Col sm="9">
								<ReactDatePicker
									id="dpDate"
									dateFormat="dd-MM-yyyy"
									selected={lrDateFrom}
									onChange={(e) => setLrDateFrom(e)}
									className="form-control"
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} className="mb-3">
							<Form.Label column sm="3">
								Lr Date To
							</Form.Label>
							<Col sm="9">
								<ReactDatePicker
									id="dpDate"
									dateFormat="dd-MM-yyyy"
									selected={lrDateTo}
									onChange={(e) => setLrDateTo(e)}
									className="form-control"
								/>
							</Col>
						</Form.Group>

						<Form.Group as={Row} className="mb-3">
							<Form.Label column sm="3">
								Transporter
							</Form.Label>
							<Col sm="9">
								<DownshiftSelect
									inputId={"cmbTransporter"}
									value={transporterId == null || transporterListResMap?.get(transporterId) == null ? null : TransporterHelper.getTransporterListResForDd(transporterListResMap.get(transporterId)!)}
									onChange={setTransporterId}
									options={transporterListResArrForDd ?? []}
									isLoading={false}
									isDisabled={false}
									placeholder=""
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} className="mb-3">
							<Form.Label column sm="3">
								Transporter
							</Form.Label>
							<Col sm="9">
								<Form.Control
									type="text"
									value={transporterName == null ? "" : transporterName}
									onChange={(e) => setTransporterName(e.target.value)} />
							</Col>
						</Form.Group>

						<Form.Group as={Row} className="mb-3">
							<Form.Label column sm="3">
								Company Profile
							</Form.Label>
							<Col sm="9">
								<DownshiftSelect
									inputId={"cmbCompanyProfile"}
									value={companyProfileId == null || companyProfileListResMap?.get(companyProfileId) == null ? null : CompanyProfileHelper.getCompanyProfileListResForDd(companyProfileListResMap.get(companyProfileId)!)}
									onChange={setCompanyProfileId}
									options={companyProfileListResArrForDd ?? []}
									isLoading={false}
									isDisabled={false}
									placeholder=""
								/>
							</Col>
						</Form.Group>

						<Form.Group as={Row} className="mb-3">
							<Form.Label column sm="3">
								Note
							</Form.Label>
							<Col sm="9">
								<Form.Control
									type="text"
									value={note == null ? "" : note}
									onChange={(e) => setNote(e.target.value)} />
							</Col>
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClickClose}>
						Cancel
					</Button>
					<Button variant="primary" type="submit" onClick={handleClickSearch}>
						SAVE
					</Button>
				</Modal.Footer>
			</Modal>

			<MessageDialog
				showDialog={showMessageDialog}
				modalMessage={messageDialogMessage}
				setShowDialog={setShowMessageDialog} />
		</>
	);
}

export default ListSalesSd;

