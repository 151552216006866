export default class LstCommVouEnumCol {

	public static readonly NO = new LstCommVouEnumCol("no");
	public static readonly DATE = new LstCommVouEnumCol("date");
	public static readonly SALES_NO = new LstCommVouEnumCol("sales_no");
	public static readonly SALES_DATE = new LstCommVouEnumCol("sales_date");
	public static readonly SALES_AMT = new LstCommVouEnumCol("sales_amt");
	public static readonly CUSTOMER_NAME = new LstCommVouEnumCol("customer_name");
	public static readonly AGENT_NAME = new LstCommVouEnumCol("agent_name");
	public static readonly ESTIMATED_COMM = new LstCommVouEnumCol("estimated_comm");
	public static readonly PAYABLE_COMM = new LstCommVouEnumCol("payable_comm");
	public static readonly DUE_COMM = new LstCommVouEnumCol("due_comm");
	public static readonly CREATED_BY = new LstCommVouEnumCol("created_by");
	public static readonly CREATED_AT = new LstCommVouEnumCol("created_at");

	private constructor(
		public readonly key: string) {
	}

	static get values(): LstCommVouEnumCol[] {
		return [
			this.NO,
			this.DATE,
			this.SALES_NO,
			this.SALES_DATE,
			this.SALES_AMT,
			this.CUSTOMER_NAME,
			this.AGENT_NAME,
			this.ESTIMATED_COMM,
			this.PAYABLE_COMM,
			this.DUE_COMM,
			this.CREATED_BY,
			this.CREATED_AT,
		];
	}

	static getByKey(key: string): LstCommVouEnumCol | null {
		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.key === key) {
				return element;
			}
		}
		return null;
	}
}
