import { useEffect, useState } from "react";
import { useGvContext } from "../../GvContext";
import MenuApi from "../../api/MenuApi";
import SalesOrderApi from "../../api/SalesOrderApi";
import ListMenuEnum from "../../enums/ListMenuEnum";
import OrderReportByTypeEnum from "../../enums/OrderReportByTypeEnum";
import OrderTypeEnum from "../../enums/OrderTypeEnum";
import RptSalesOrderLedgerEnumCol from "../../enums/col/RptSalesOrderLedgerEnumCol";
import RptSalesOrderPendingSummByCustomerEnumCol from "../../enums/col/RptSalesOrderPendingSummByCustomerEnumCol";
import RptSalesOrderPendingSummByProdEnumCol from "../../enums/col/RptSalesOrderPendingSummByProdEnumCol";
import CommonGetListType from "../../types/CommonGetListType";
import RowType from "../../types/RowType";
import ColSettingResType from "../../types/res/ColSettingResType";
import RptSalesOrderLedgerSm from "../../types/sm/RptSalesOrderLedgerSm";
import RptSalesOrderPendingSm from "../../types/sm/RptSalesOrderPendingSm";
import RptSalesOrderPendingSummByProdSm from "../../types/sm/RptSalesOrderPendingSummByProdSm";
import ListViewButtonBar from "../ListViewButtonBar";
import ListViewTable from "../ListViewTable";
import useConfirm from "../dialogs/ConfirmDialog";
import MessageDialog from "../dialogs/MessageDialog";
import RptSalesOrderPendingSd from "../sd/RptSalesOrderPendingSd";

function RptSalesOrderPending(): JSX.Element {

	const [colSettings, setColSettings] = useState<ColSettingResType[]>([]);
	const [colNames, setColNames] = useState<string[]>([]);

	const [rows, setRows] = useState<RowType[]>([]);
	const [selectedId, setSelectedId] = useState<number | null>(null);

	const [searchModel, setSearchModel] = useState(new RptSalesOrderPendingSm());
	//
	const [showSd, setShowSd] = useState<boolean>(true);

	const [messageDialogMessage, setMessageDialogMessage] = useState("");
	const [showMessageDialog, setShowMessageDialog] = useState(false);

	const confirm = useConfirm();
	const gv = useGvContext();

	useEffect(() => {
		async function process() {

			if (searchModel != null) {

				let res: CommonGetListType<ColSettingResType> | null = null;

				if (searchModel.orderReportByType === OrderReportByTypeEnum.ACCT) {
					res = await MenuApi.getColSettingDtos(ListMenuEnum.SALES_ORDER_PENDING_BY_CUSTOMER);
				}
				else if (searchModel.orderReportByType === OrderReportByTypeEnum.PRODUCT) {
					res = await MenuApi.getColSettingDtos(ListMenuEnum.SALES_ORDER_PENDING_BY_PROD);
				}
				else {
					res = await MenuApi.getColSettingDtos(ListMenuEnum.SALES_ORDER_PENDING_DET);
				}

				if (res != null) {
					if (res.isSuccess) {

						setColSettings(res.items!);

						let colNamesLocal: string[] = [];
						res.items!.forEach((item) => {
							colNamesLocal.push(item.colName);
						});
						setColNames(colNamesLocal);

					}
					else {
						setShowMessageDialog(true);
						setMessageDialogMessage(res.errorRes?.message!);
					}
				}

			}

			if (colSettings != null && colSettings.length > 0) {
				loadData();
			}
		}
		process();
	}, [searchModel]);

	useEffect(() => {
		async function process() {

			if (colSettings != null && colSettings.length > 0) {
				loadData();
			}
		}
		process();
	}, [colSettings]);

	const handleClickExport = () => {
		console.log("handleClickExport");
	};

	const handleClickOpen = () => {
		console.log("handleClickOpen");
	};

	const handleClickFilter = () => {
		setShowSd(true);
	};

	const handleClickClearFilter = () => {
		let searchModelLoc = new RptSalesOrderPendingSm();
		searchModelLoc.reset();
		setSearchModel(searchModelLoc);
	};

	const loadData = async () => {

		if (searchModel != null) {

			if (searchModel.orderReportByType == OrderReportByTypeEnum.PRODUCT) {

				let searchModelNew = new RptSalesOrderPendingSummByProdSm();
				searchModelNew.acctId = searchModel.acctId;
				searchModelNew.prodId = searchModel.prodId;
				searchModelNew.pending = searchModel.pending;
				searchModelNew.orderType = OrderTypeEnum.SALES;

				let res = await SalesOrderApi.getRptSalesOrderPendingSummByProdHdtos(searchModelNew);

				if (res.isSuccess) {

					let dataToReturn: RowType[] = [];

					for (let row of res.items!) {

						if (row == null)
							continue;

						let oneRowArray: string[] = [];

						// write for in loop for colsettings
						for (let colSetting of colSettings) {

							switch (RptSalesOrderPendingSummByProdEnumCol.getByKey(colSetting.colKey)) {
								case RptSalesOrderPendingSummByProdEnumCol.PROD:
									oneRowArray.push(row.productName == null ? "" : row.productName);
									break;
								case RptSalesOrderPendingSummByProdEnumCol.SUM_QTY:
									oneRowArray.push(row.qtyTotal == null ? "" : row.qtyTotal.toString());
									break;
								case RptSalesOrderPendingSummByProdEnumCol.CUSTOMER_CNT:
									oneRowArray.push(row.acctCnt == null ? "" : row.acctCnt.toString());
									break;
							}
						};

						dataToReturn.push({
							id: 0,
							data: oneRowArray
						});
					};

					setRows(dataToReturn);
				}
				else {
					setShowMessageDialog(true);
					setMessageDialogMessage(res.errorRes?.message!);
				}
			}
			else if (searchModel.orderReportByType == OrderReportByTypeEnum.ACCT) {

				let searchModelNew = new RptSalesOrderLedgerSm();
				searchModelNew.acctId = searchModel.acctId;
				searchModelNew.acctId = searchModel.acctId;
				searchModelNew.pending = searchModel.pending;
				searchModelNew.orderType = OrderTypeEnum.SALES;

				let res = await SalesOrderApi.getRptSalesOrderPendingSummByCustomerHdtos(searchModelNew);

				if (res.isSuccess) {

					let dataToReturn: RowType[] = [];

					for (let row of res.items!) {

						if (row == null)
							continue;

						let oneRowArray: string[] = [];

						// write for in loop for colsettings
						for (let colSetting of colSettings) {

							switch (RptSalesOrderPendingSummByCustomerEnumCol.getByKey(colSetting.colKey)) {
								case RptSalesOrderPendingSummByCustomerEnumCol.PROD_CNT:
									oneRowArray.push(row.productCnt == null ? "" : row.productCnt.toString());
									break;
								case RptSalesOrderPendingSummByCustomerEnumCol.SUM_QTY:
									oneRowArray.push(row.qtyTotal == null ? "" : row.qtyTotal.toString());
									break;
								case RptSalesOrderPendingSummByCustomerEnumCol.CUSTOMER_NAME:
									oneRowArray.push(row.acctName == null ? "" : row.acctName);
									break;
							}
						};

						dataToReturn.push({
							id: 0,
							data: oneRowArray
						});
					};

					setRows(dataToReturn);
				}
				else {
					setShowMessageDialog(true);
					setMessageDialogMessage(res.errorRes?.message!);
				}
			}
			else {

				let searchModelNew = new RptSalesOrderLedgerSm();
				searchModelNew.acctId = searchModel.acctId;
				searchModelNew.prodId = searchModel.prodId;
				searchModelNew.pending = searchModel.pending;
				searchModelNew.orderType = OrderTypeEnum.SALES;

				let res = await SalesOrderApi.getRptSalesOrderLedgerHdtos(searchModelNew);

				if (res.isSuccess) {

					let dataToReturn: RowType[] = [];

					let bal = 0;

					for (let row of res.items!) {

						if (row == null)
							continue;

						if (row.orderQty != null) {
							bal = bal + row.orderQty;
						}
						if (row.pusaQty != null) {
							bal = bal - row.pusaQty;
						}

						if (bal < 0) {
							bal = 0;
						}

						let oneRowArray: string[] = [];

						// write for in loop for colsettings
						for (let colSetting of colSettings) {

							switch (RptSalesOrderLedgerEnumCol.getByKey(colSetting.colKey)) {
								case RptSalesOrderLedgerEnumCol.DATE:
									oneRowArray.push(row.date == null ? "" : row.date.toString());
									break;
								case RptSalesOrderLedgerEnumCol.NO:
									oneRowArray.push(row.no == null ? "" : row.no.toString());
									break;
								case RptSalesOrderLedgerEnumCol.PRODUCT_NAME:
									oneRowArray.push(row.productName == null ? "" : row.productName.toString());
									break;
								case RptSalesOrderLedgerEnumCol.QTY_ORDER:
									oneRowArray.push(row.orderQty == null ? "" : row.orderQty.toString());
									break;
								case RptSalesOrderLedgerEnumCol.QTY_PENDING:
									oneRowArray.push(bal == null ? "" : bal.toString());
									break;
								case RptSalesOrderLedgerEnumCol.QTY_SOLD:
									oneRowArray.push(row.pusaQty == null ? "" : row.pusaQty.toString());
									break;
								case RptSalesOrderLedgerEnumCol.CUSTOMER_NAME:
									oneRowArray.push(row.acctName == null ? "" : row.acctName.toString());
									break;
								case RptSalesOrderLedgerEnumCol.VOUCHER_TYPE_ID:
									oneRowArray.push(row.voucherTypeId == null ? "" : row.voucherTypeId.toString());
									break;
							}
						};

						dataToReturn.push({
							id: 0,
							data: oneRowArray
						});
					};

					setRows(dataToReturn);
				}
				else {
					setShowMessageDialog(true);
					setMessageDialogMessage(res.errorRes?.message!);
				}
			}
		}

	}

	return (
		<>
			<ListViewButtonBar
				handleClickExport={handleClickExport}
				handleClickOpen={handleClickOpen}
				handleClickFilter={handleClickFilter}
				handleClickClearFilter={handleClickClearFilter}
			/>

			<ListViewTable
				colNames={colNames}
				rows={rows}
				setSelectedId={setSelectedId}
			/>

			<RptSalesOrderPendingSd
				showModal={showSd}
				setShowModal={setShowSd}
				searchModel={searchModel}
				processOnSuccess={(searchModel) => setSearchModel(searchModel)}
			/>

			<MessageDialog
				showDialog={showMessageDialog}
				modalMessage={messageDialogMessage}
				setShowDialog={setShowMessageDialog} />
		</>
	);
}

export default RptSalesOrderPending;
