export default class ListProdCatSmType {

	name: string | undefined;
	withParent: boolean | undefined;
	parentId: number | undefined;
	searchInSubCat: boolean | undefined;
	parentCatName: string | undefined;

	reset(): void {
		this.name = undefined;
		this.withParent = undefined;
		this.parentId = undefined;
		this.searchInSubCat = undefined;
		this.parentCatName = undefined;
	}

	isBlank(): boolean {
		return this.name === undefined
			&& this.withParent === undefined
			&& this.parentId === undefined
			&& this.searchInSubCat === undefined
			&& this.parentCatName === undefined;
	}
}
