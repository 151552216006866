import { useEffect, useState } from "react";
import { GvContextProvider } from "../GvContext";
import GvDataContainer from "../GvDataContainer";
import KbShortcutApi from "../api/KbShortcutApi";
import MenuApi from "../api/MenuApi";
import {
	CURRENT_LAYOUT,
	CURRENT_TITLE,
	MENU_STACK,
	TITLE_STACK
} from "../config";
import KbShortcutEnum from "../enums/KbShortcutEnum";
import KbscHelper from "../helpers/KbscHelper";
import MenuHelper from "../helpers/MenuHelper";
import KbShortcutModel from "../types/res/KbShortcutModel";
import MenuResType from "../types/res/MenuResType";
import Menu from "./Menu";
import Topbar from "./Topbar";

function Container() {

	const [loading, setLoading] = useState(true);
	// const [menu, setMenu] = useState(null);
	const [componentToRender, setComponentToRender] = useState<JSX.Element | null>(null);
	const [pageTitle, setPageTitle] = useState<string>("Menu");
	const [kbShortcuts, setKbShortcuts] = useState<KbShortcutModel[]>([]);

	const openMenuView = (parentMenu: MenuResType) => {

		if (!(parentMenu.id === 0)) {
			MENU_STACK.push(CURRENT_LAYOUT.val);
			TITLE_STACK.push(CURRENT_TITLE.val);
		}

		CURRENT_LAYOUT.val = (
			<Menu
				menus={parentMenu.children}
				openMenuView={openMenuView}
			/>
		);
		CURRENT_TITLE.val =
			parentMenu.displayName === null
				? "Menu"
				: parentMenu.displayName === ""
					? parentMenu.name
					: parentMenu.displayName;

		setPageTitle(CURRENT_TITLE.val);
		setComponentToRender(CURRENT_LAYOUT.val);
	};

	const goBack = () => {
		if (MENU_STACK.length > 0) {

			CURRENT_LAYOUT.val = MENU_STACK.pop()!;
			CURRENT_TITLE.val = TITLE_STACK.pop()!;

			setPageTitle(CURRENT_TITLE.val);
			setComponentToRender(CURRENT_LAYOUT.val);
		}
	};

	const openLayout = (element: JSX.Element, title: string) => {

		MENU_STACK.push(CURRENT_LAYOUT.val);
		TITLE_STACK.push(CURRENT_TITLE.val);

		CURRENT_LAYOUT.val = element;
		CURRENT_TITLE.val = title;

		setPageTitle(CURRENT_TITLE.val);
		setComponentToRender(CURRENT_LAYOUT.val);
	}

	const menuHelper = new MenuHelper(openLayout);

	useEffect(() => {

		async function process() {
			MenuApi.getMenus().then((menus) => {
				openMenuView(menus);
			});

			KbShortcutApi.getKbShortcutModels().then((kbShortcutModels) => {
				if (kbShortcutModels.isSuccess) {
					setKbShortcuts(kbShortcutModels.items!);
				}
			});
		}
		process();
		GvDataContainer.goForward = openLayout;

	}, []);

	useEffect(() => {

		window.addEventListener('keydown', handleKeyDown);

		// Clean up event listener on component unmount
		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};

	}, [kbShortcuts]);

	// Function to handle keyboard events
	const handleKeyDown = (event: KeyboardEvent) => {

		let kbShortcutModel = KbscHelper.findKbShortcut(event, kbShortcuts);
		if (kbShortcutModel == null) {
			return;
		}

		let kbShortcutEnum = KbShortcutEnum.getById(kbShortcutModel.id);
		if (kbShortcutEnum == null) {
			return;
		}

		menuHelper.handleKbsc(kbShortcutEnum);

		switch (kbShortcutEnum) {
			case KbShortcutEnum.BACK: {
				goBack();
				event.preventDefault();
				event.stopPropagation();
				break;
			}
		}
	};

	return (
		<GvContextProvider value={{ kbKbShortcutModel: kbShortcuts, menuHelper: menuHelper }}>
			<div className="d-flex flex-column vh-100">
				<Topbar title={pageTitle} goBack={goBack} />
				{componentToRender}
			</div>
		</GvContextProvider >
	);
}

export default Container;
