import { useEffect, useState } from "react";
import { useGvContext } from "../../GvContext";
import { BASE_URL, TOKEN } from "../../config";
import DateFormatEnum from "../../enums/DateFormatEnum";
import ListMenuEnum from "../../enums/ListMenuEnum";
import OpeningBalanceTypeEnum from "../../enums/OpeningBalanceTypeEnum";
import ResourceEnum from "../../enums/ResourceEnum";
import LstAgentEnumCol from "../../enums/col/LstAgentEnumCol";
import dateToString from "../../helpers/DateHelper";
import strFormat from "../../helpers/StringHelper";
import { getQueryString } from "../../helpers/UrlSearchParamHelper";
import RowType from "../../types/RowType";
import ColSettingResType from "../../types/res/ColSettingResType";
import AgentLdto from "../../types/res/list/AgentLdto";
import ListAgentSm from "../../types/sm/ListAgentSm";
import ListViewButtonBar from "../ListViewButtonBar";
import ListViewTable from "../ListViewTable";
import useConfirm from "../dialogs/ConfirmDialog";
import MessageDialog from "../dialogs/MessageDialog";

function ListAgent(): JSX.Element {

	const resourceName = ResourceEnum.AGENT.name;

	const [colSettings, setColSettings] = useState<ColSettingResType[]>([]);
	const [colNames, setColNames] = useState<string[]>([]);

	const [rows, setRows] = useState<RowType[]>([]);
	const [selectedId, setSelectedId] = useState<number | null>(null);

	const [listAgentSm, setListAgentSm] = useState<ListAgentSm>(new ListAgentSm());

	const [showSd, setShowSd] = useState<boolean>(false);
	
	const [messageDialogMessage, setMessageDialogMessage] = useState("");
	const [showMessageDialog, setShowMessageDialog] = useState(false);

	const confirm = useConfirm();
	const gv = useGvContext();

	useEffect(() => {
		async function process() {

			const requestOptions = {
				method: "GET",
				headers: {
					"Authorization": "Bearer " + TOKEN.val,
					"Content-Type": "application/json"
				}
			}

			const res = await fetch(strFormat("%s/%s/%s/%s",
				BASE_URL,
				ResourceEnum.MENU.name,
				ListMenuEnum.AGENT.id,
				ResourceEnum.COL_SETTING.name,
			), requestOptions);

			if (res.status !== 200) {
				setMessageDialogMessage("Server error");
				setShowMessageDialog(true);
			}
			else {
				const data: ColSettingResType[] = await res.json();
				setColSettings(data);

				let colNamesLocal: string[] = [];
				data.forEach((item) => {
					colNamesLocal.push(item.colName);
				});
				setColNames(colNamesLocal);
				loadData(listAgentSm, data);
			}

		}
		process();
	}, []);

	const handleClickAdd = () => {
		gv.menuHelper.openAddAgent();
	};

	const handleClickEdit = () => {
		if (selectedId === null) {
			setMessageDialogMessage("Select atleast one row");
			setShowMessageDialog(true);
			return;
		}

		gv.menuHelper.openAddAgent(selectedId);
	};

	const handleClickDelete = async () => {
		if (selectedId === null) {
			setMessageDialogMessage("Select atleast one row");
			setShowMessageDialog(true);
			return;
		}

		let choice = await confirm!({
			message: "Are you sure you want to delete?",
			title: "Delete confirmation"
		});

		if (choice === undefined || choice === null || choice === false)
			return;

		const requestOptions = {
			method: "DELETE",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			}
		}

		const res = await fetch(strFormat("%s/%s/%s", BASE_URL, resourceName, selectedId), requestOptions);

		if (res.status !== 204) {
			const data = await res.json();
			setMessageDialogMessage(data.message);
			setShowMessageDialog(true);
		}
		else {
			loadData(listAgentSm);
		}
	};

	const handleClickExport = () => {
		console.log("handleClickExport");
	};

	const handleClickPrint = () => {
		console.log("handleClickPrint");
	};

	const handleClickOpen = () => {
		console.log("handleClickOpen");
	};

	const handleClickFilter = () => {
		setShowSd(true);
	};

	const handleClickClearFilter = () => {
		console.log("handleClickClearFilter");
	};

	const handleClickColSettings = () => {
		console.log("handleClickColSettings");
	};

	const loadData = async (
		listAgentSm: ListAgentSm,
		colSettingsLocal?: ColSettingResType[]
	) => {

		let colSettingsToUse: ColSettingResType[];

		if (colSettingsLocal === undefined) {
			colSettingsToUse = colSettings;
		}
		else {
			colSettingsToUse = colSettingsLocal;
		}

		setListAgentSm(listAgentSm);

		const requestOptions = {
			method: "GET",
			headers: {
				Authorization: "Bearer " + TOKEN.val,
				"Content-Type": "application/json",
			},
		};
		const res = await fetch(strFormat("%s/%s", BASE_URL, resourceName)
			//+ new URLSearchParams(listVouOthChargeSm.toUrlSearchParams())
			+ getQueryString(listAgentSm)
			, requestOptions);
		const results: AgentLdto[] = await res.json();

		let dataToReturn: RowType[] = [];
		results.forEach((result) => {
			let oneRowArray: string[] = [];

			colSettingsToUse.forEach(colSetting => {
				switch (LstAgentEnumCol.getByKey(colSetting.colKey)) {
					case LstAgentEnumCol.ACCT_TYPE_NAME:
						oneRowArray.push(result.acctTypeName);
						break;
					case LstAgentEnumCol.ADDRESS:
						oneRowArray.push(result.address);
						break;
					case LstAgentEnumCol.BANK_ACCT_NAME:
						oneRowArray.push(result.bankAcctName);
						break;
					case LstAgentEnumCol.BANK_ACCT_NO:
						oneRowArray.push(result.bankAcctNo);
						break;
					case LstAgentEnumCol.BANK_BRANCH_NAME:
						oneRowArray.push(result.bankBranchName);
						break;
					case LstAgentEnumCol.BANK_IFSC:
						oneRowArray.push(result.bankIfsc);
						break;
					case LstAgentEnumCol.BANK_NAME:
						oneRowArray.push(result.bankName);
						break;
					case LstAgentEnumCol.CITY:
						oneRowArray.push(result.city);
						break;
					case LstAgentEnumCol.EMAIL:
						oneRowArray.push(result.email);
						break;
					case LstAgentEnumCol.GST_NO:
						oneRowArray.push(result.gstNo);
						break;
					case LstAgentEnumCol.NAME:
						oneRowArray.push(result.name);
						break;
					case LstAgentEnumCol.NAME_TO_PRINT:
						oneRowArray.push(result.nameToPrint);
						break;
					case LstAgentEnumCol.OB:
						oneRowArray.push(result.ob == null ? "" : result.ob.toLocaleString('en-IN'));
						break;
					case LstAgentEnumCol.OB_TYPE_ID:
						oneRowArray.push(result.obTypeId == null ? "" : OpeningBalanceTypeEnum.getById(result.obTypeId)?.name!);
						break;
					case LstAgentEnumCol.PIN:
						oneRowArray.push(result.pin);
						break;
					case LstAgentEnumCol.STATE_NAME:
						oneRowArray.push(result.stateName == null ? "" : result.stateName);
						break;
					case LstAgentEnumCol.CREATED_BY:
						oneRowArray.push(result.createdByUserName);
						break;
					case LstAgentEnumCol.CREATED_AT:
						oneRowArray.push(dateToString(result.createdAt, DateFormatEnum.APP_DATETIME));
						break;
				}
			});

			dataToReturn.push({
				id: result.id,
				data: oneRowArray
			});
		});

		setRows(dataToReturn);
	}

	return (
		<>
			<ListViewButtonBar
				handleClickEdit={handleClickEdit}
				handleClickDelete={handleClickDelete}
				handleClickExport={handleClickExport}
				handleClickPrint={handleClickPrint}
				handleClickOpen={handleClickOpen}
				handleClickFilter={handleClickFilter}
				handleClickClearFilter={handleClickClearFilter}
				handleClickColSettings={handleClickColSettings}
			/>
			<ListViewTable
					colNames={colNames}
					rows={rows}
					setSelectedId={setSelectedId}
				/>

			<MessageDialog
				showDialog={showMessageDialog}
				modalMessage={messageDialogMessage}
				setShowDialog={setShowMessageDialog} />
		</>
	);
}

export default ListAgent;
