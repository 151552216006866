import Layout from "../components/Layout";
import AddEstimate from "../components/add/AddEstimate";
import AddPurchase from "../components/add/AddPurchase";
import AddPurchaseOrder from "../components/add/AddPurchaseOrder";
import AddPurchaseReturn from "../components/add/AddPurchaseReturn";
import AddSales from "../components/add/AddSales";
import AddSalesOrder from "../components/add/AddSalesOrder";
import AddTransporter from "../components/add/AddTransporter";
import AddUnit from "../components/add/AddUnit";
import ListAcct from "../components/list/ListAcct";
import ListAcctType from "../components/list/ListAcctType";
import ListAgent from "../components/list/ListAgent";
import ListBank from "../components/list/ListBank";
import ListCdpJournalVou from "../components/list/ListCdpJournalVou";
import ListCommVou from "../components/list/ListCommVou";
import ListCommissionList from "../components/list/ListCommissionList";
import ListCommnc from "../components/list/ListCommnc";
import ListCompanyProfile from "../components/list/ListCompanyProfile";
import ListContra from "../components/list/ListContra";
import ListCustomer from "../components/list/ListCustomer";
import ListEstimate from "../components/list/ListEstimate";
import ListInvAdjust from "../components/list/ListInvAdjust";
import ListJournal from "../components/list/ListJournal";
import ListLead from "../components/list/ListLead";
import ListLeadSrc from "../components/list/ListLeadSrc";
import ListLeadStatus from "../components/list/ListLeadStatus";
import ListMsgTemplate from "../components/list/ListMsgTemplate";
import ListOrderAdjust from "../components/list/ListOrderAdjust";
import ListPayment from "../components/list/ListPayment";
import ListPi from "../components/list/ListPi";
import ListProduct from "../components/list/ListProduct";
import ListProductCategory from "../components/list/ListProductCategory";
import ListProductOthCharge from "../components/list/ListProductOthCharge";
import ListPurchase from "../components/list/ListPurchase";
import ListPurchaseOrder from "../components/list/ListPurchaseOrder";
import ListPurchasePriceList from "../components/list/ListPurchasePriceList";
import ListPurchaseReturn from "../components/list/ListPurchaseReturn";
import ListReceipt from "../components/list/ListReceipt";
import ListReminder from "../components/list/ListReminder";
import ListSales from "../components/list/ListSales";
import ListSalesOrder from "../components/list/ListSalesOrder";
import ListSalesPriceList from "../components/list/ListSalesPriceList";
import ListSalesReturn from "../components/list/ListSalesReturn";
import ListService from "../components/list/ListService";
import ListSupplier from "../components/list/ListSupplier";
import ListTax from "../components/list/ListTax";
import ListTaxCategory from "../components/list/ListTaxCategory";
import ListTransporter from "../components/list/ListTransporter";
import ListUnit from "../components/list/ListUnit";
import ListVoucherOtherCharge from "../components/list/ListVouOthCharge";
import RptAcctSummByAcctType from "../components/list/RptAcctSummByAcctType";
import RptCommVouLedger from "../components/list/RptCommVouLedger";
import RptCurrentStock from "../components/list/RptCurrentStock";
import RptCurrentStockVal from "../components/list/RptCurrentStockVal";
import RptDueRcvableByCustomer from "../components/list/RptDueRcvableByCustomer";
import RptDueRcvableBySales from "../components/list/RptDueRcvableBySales";
import RptEstimatePendingByCustomer from "../components/list/RptEstimatePendingByCustomer";
import RptEstimatePendingByProd from "../components/list/RptEstimatePendingByProd";
import RptEstimatePendingDetailed from "../components/list/RptEstimatePendingDetailed";
import RptInvIoDetailed from "../components/list/RptInvIoDetailed";
import RptInvIoSummByMonth from "../components/list/RptInvIoSummByMonth";
import RptInvIoSummByProd from "../components/list/RptInvIoSummByProd";
import RptInvIoSummByProdCat from "../components/list/RptInvIoSummByProdCat";
import RptInventoryLedger from "../components/list/RptInventoryLedger";
import RptLedger from "../components/list/RptLedger";
import RptLowStock from "../components/list/RptLowStock";
import RptPurchaseByCity from "../components/list/RptPurchaseByCity";
import RptPurchaseByDay from "../components/list/RptPurchaseByDay";
import RptPurchaseByMonth from "../components/list/RptPurchaseByMonth";
import RptPurchaseByProd from "../components/list/RptPurchaseByProd";
import RptPurchaseByProdCat from "../components/list/RptPurchaseByProdCat";
import RptPurchaseByState from "../components/list/RptPurchaseByState";
import RptPurchaseBySupplier from "../components/list/RptPurchaseBySupplier";
import RptPurchaseDetailedByProd from "../components/list/RptPurchaseDetailedByProd";
import RptPurchaseDetailedByVoucher from "../components/list/RptPurchaseDetailedByVoucher";
import RptPurchaseOrderPending from "../components/list/RptPurchaseOrderPending";
import RptPurchaseReturnByCity from "../components/list/RptPurchaseReturnByCity";
import RptPurchaseReturnByDay from "../components/list/RptPurchaseReturnByDay";
import RptPurchaseReturnByMonth from "../components/list/RptPurchaseReturnByMonth";
import RptPurchaseReturnByProd from "../components/list/RptPurchaseReturnByProd";
import RptPurchaseReturnByProdCat from "../components/list/RptPurchaseReturnByProdCat";
import RptPurchaseReturnByState from "../components/list/RptPurchaseReturnByState";
import RptPurchaseReturnBySupplier from "../components/list/RptPurchaseReturnBySupplier";
import RptPurchaseReturnDetailedByProd from "../components/list/RptPurchaseReturnDetailedByProd";
import RptPurchaseReturnDetailedByVoucher from "../components/list/RptPurchaseReturnDetailedByVoucher";
import RptSalesByAgent from "../components/list/RptSalesByAgent";
import RptSalesByCity from "../components/list/RptSalesByCity";
import RptSalesByCustomer from "../components/list/RptSalesByCustomer";
import RptSalesByDay from "../components/list/RptSalesByDay";
import RptSalesByMonth from "../components/list/RptSalesByMonth";
import RptSalesByProd from "../components/list/RptSalesByProd";
import RptSalesByProdCat from "../components/list/RptSalesByProdCat";
import RptSalesByState from "../components/list/RptSalesByState";
import RptSalesDetailedByProd from "../components/list/RptSalesDetailedByProd";
import RptSalesDetailedByVoucher from "../components/list/RptSalesDetailedByVoucher";
import RptSalesOrderPending from "../components/list/RptSalesOrderPending";
import RptSalesProfitByCustomer from "../components/list/RptSalesProfitByCustomer";
import RptSalesProfitByProd from "../components/list/RptSalesProfitByProd";
import RptSalesProfitByProdCat from "../components/list/RptSalesProfitByProdCat";
import RptSalesProfitBySalesItem from "../components/list/RptSalesProfitBySalesItem";
import RptSalesProfitBySalesVoucher from "../components/list/RptSalesProfitBySalesVoucher";
import RptSalesReturnByAgent from "../components/list/RptSalesReturnByAgent";
import RptSalesReturnByCity from "../components/list/RptSalesReturnByCity";
import RptSalesReturnByCustomer from "../components/list/RptSalesReturnByCustomer";
import RptSalesReturnByDay from "../components/list/RptSalesReturnByDay";
import RptSalesReturnByMonth from "../components/list/RptSalesReturnByMonth";
import RptSalesReturnByProd from "../components/list/RptSalesReturnByProd";
import RptSalesReturnByProdCat from "../components/list/RptSalesReturnByProdCat";
import RptSalesReturnByState from "../components/list/RptSalesReturnByState";
import RptSalesReturnDetailedByProd from "../components/list/RptSalesReturnDetailedByProd";
import RptSalesReturnDetailedByVoucher from "../components/list/RptSalesReturnDetailedByVoucher";
import RptTrialBalanceController from "../components/list/RptTrialBalance";
import KbShortcutEnum from "../enums/KbShortcutEnum";
import MenuEnum from "../enums/MenuEnum";
import RptAcctSummByAcctTypeSm from "../types/sm/RptAcctSummByAcctTypeSm";
import RptCommVouLedgerSm from "../types/sm/RptCommVouLedgerSm";
import RptCurrentStockSm from "../types/sm/RptCurrentStockSm";
import RptCurrentStockValSm from "../types/sm/RptCurrentStockValSm";
import RptDueRcvableByCustomerSm from "../types/sm/RptDueRcvableByCustomerSm";
import RptDueRcvableBySalesSm from "../types/sm/RptDueRcvableBySalesSm";
import RptEstimatePendingByCustomerSm from "../types/sm/RptEstimatePendingByCustomerSm";
import RptEstimatePendingByProdSm from "../types/sm/RptEstimatePendingByProdSm";
import RptEstimatePendingDetailedSm from "../types/sm/RptEstimatePendingDetailedSm";
import RptInvIoDetailedSm from "../types/sm/RptInvIoDetailedSm";
import RptInvIoSummByMonthSm from "../types/sm/RptInvIoSummByMonthSm";
import RptInvIoSummByProdCatSm from "../types/sm/RptInvIoSummByProdCatSm";
import RptInvIoSummByProdSm from "../types/sm/RptInvIoSummByProdSm";
import RptInventoryLedgerSm from "../types/sm/RptInventoryLedgerSm";
import RptLedgerSm from "../types/sm/RptLedgerSm";
import RptLowStockSm from "../types/sm/RptLowStockSm";
import RptPurchaseByCitySm from "../types/sm/RptPurchaseByCitySm";
import RptPurchaseByDaySm from "../types/sm/RptPurchaseByDaySm";
import RptPurchaseByMonthSm from "../types/sm/RptPurchaseByMonthSm";
import RptPurchaseByProductCategorySm from "../types/sm/RptPurchaseByProdCatSm";
import RptPurchaseByProductSm from "../types/sm/RptPurchaseByProdSm";
import RptPurchaseByStateSm from "../types/sm/RptPurchaseByStateSm";
import RptPurchaseBySupplierSm from "../types/sm/RptPurchaseBySupplierSm";
import RptPurchaseDetailedByProductSm from "../types/sm/RptPurchaseDetailedByProdSm";
import RptPurchaseDetailedByVoucherSm from "../types/sm/RptPurchaseDetailedByVoucherSm";
import RptPurchaseOrderPendingSm from "../types/sm/RptPurchaseOrderPendingSm";
import RptPurchaseReturnByCitySm from "../types/sm/RptPurchaseReturnByCitySm";
import RptPurchaseReturnByDaySm from "../types/sm/RptPurchaseReturnByDaySm";
import RptPurchaseReturnByMonthSm from "../types/sm/RptPurchaseReturnByMonthSm";
import RptPurchaseReturnByProductCategorySm from "../types/sm/RptPurchaseReturnByProdCatSm";
import RptPurchaseReturnByProductSm from "../types/sm/RptPurchaseReturnByProdSm";
import RptPurchaseReturnByStateSm from "../types/sm/RptPurchaseReturnByStateSm";
import RptPurchaseReturnBySupplierSm from "../types/sm/RptPurchaseReturnBySupplierSm";
import RptPurchaseReturnDetailedByProductSm from "../types/sm/RptPurchaseReturnDetailedByProdSm";
import RptPurchaseReturnDetailedByVoucherSm from "../types/sm/RptPurchaseReturnDetailedByVoucherSm";
import RptSalesByAgentSm from "../types/sm/RptSalesByAgentSm";
import RptSalesByCitySm from "../types/sm/RptSalesByCitySm";
import RptSalesByCustomerSm from "../types/sm/RptSalesByCustomerSm";
import RptSalesByDaySm from "../types/sm/RptSalesByDaySm";
import RptSalesByMonthSm from "../types/sm/RptSalesByMonthSm";
import RptSalesByProductCategorySm from "../types/sm/RptSalesByProdCatSm";
import RptSalesByProductSm from "../types/sm/RptSalesByProdSm";
import RptSalesByStateSm from "../types/sm/RptSalesByStateSm";
import RptSalesDetailedByProductSm from "../types/sm/RptSalesDetailedByProdSm";
import RptSalesDetailedByVoucherSm from "../types/sm/RptSalesDetailedByVoucherSm";
import RptSalesOrderPendingSm from "../types/sm/RptSalesOrderPendingSm";
import RptSalesProfitByCustomerSm from "../types/sm/RptSalesProfitByCustomerSm";
import RptSalesProfitByProdCatSm from "../types/sm/RptSalesProfitByProdCatSm";
import RptSalesProfitByProductSm from "../types/sm/RptSalesProfitByProdSm";
import RptSalesProfitBySalesItemSm from "../types/sm/RptSalesProfitBySalesItemSm";
import RptSalesProfitBySalesVoucherSm from "../types/sm/RptSalesProfitBySalesVoucherSm";
import RptSalesReturnByAgentSm from "../types/sm/RptSalesReturnByAgentSm";
import RptSalesReturnByCitySm from "../types/sm/RptSalesReturnByCitySm";
import RptSalesReturnByCustomerSm from "../types/sm/RptSalesReturnByCustomerSm";
import RptSalesReturnByDaySm from "../types/sm/RptSalesReturnByDaySm";
import RptSalesReturnByMonthSm from "../types/sm/RptSalesReturnByMonthSm";
import RptSalesReturnByProductCategorySm from "../types/sm/RptSalesReturnByProdCatSm";
import RptSalesReturnByProductSm from "../types/sm/RptSalesReturnByProdSm";
import RptSalesReturnByStateSm from "../types/sm/RptSalesReturnByStateSm";
import RptSalesReturnDetailedByProductSm from "../types/sm/RptSalesReturnDetailedByProdSm";
import RptSalesReturnDetailedByVoucherSm from "../types/sm/RptSalesReturnDetailedByVoucherSm";
import RptTrialBalSm from "../types/sm/RptTrialBalSm";

export default class MenuHelper {

	constructor(private openLayout: (element: JSX.Element, title: string) => void) {
	}

	openCommncBook() {
	}

	openListReminder() {
		this.openLayout(<ListReminder />, MenuEnum.REMINDER.displayName);
	}

	openAddReminder(id?: number) {
	}

	openListCommnc() {
		this.openLayout(<ListCommnc />, MenuEnum.COMMNC.displayName);
	}

	openAddCommnc(id?: number) {
	}

	openListLead() {
		this.openLayout(<ListLead />, MenuEnum.LEAD.displayName);
	}

	openAddLead(id?: number) {
	}

	openAcctBook() {
	}

	openDayBook() {
	}

	//
	openListCdpJournalVou() {
		this.openLayout(<ListCdpJournalVou />, MenuEnum.CDP_JOURNAL_VOU.displayName);
	}

	openAddCdpJournalVou(id?: number) {

	}

	//
	openListJournal() {
		this.openLayout(<ListJournal />, MenuEnum.JOURNAL.displayName);
	}

	openAddJournal(id?: number) {
	}

	//
	openListContra() {
		this.openLayout(<ListContra />, MenuEnum.CONTRA.displayName);
	}

	openAddContra(id?: number) {
	}

	//
	openListReceipt() {
		this.openLayout(<ListReceipt />, MenuEnum.RECEIPT.displayName);
	}

	openAddReceipt(id?: number) {
	}

	//
	openListPayment() {
		this.openLayout(<ListPayment />, MenuEnum.PAYMENT.displayName);
	}

	openAddPayment(id?: number) {
	}

	//
	openListSalesReturn() {
		this.openLayout(<ListSalesReturn />, MenuEnum.SALES_RETURN.displayName);
	}

	openAddSalesReturn(id?: number) {
		this.openLayout(<AddSales id={id} />, MenuEnum.SALES.displayName);
	}

	//
	openListSales() {
		this.openLayout(<ListSales />, MenuEnum.SALES.displayName);
	}

	openAddSales(id?: number) {
		this.openLayout(<AddSales />, Layout.SALES.displayName);
	}

	openAddSalesByEstimateId(estimateId: number) {
		this.openLayout(<AddSales estimateId={estimateId} />, Layout.SALES.displayName);
	}

	openAddSalesBySalesOrderId(salesOrderId: number) {
		this.openLayout(<AddSales salesOrderId={salesOrderId} />, Layout.SALES.displayName);
	}

	//
	openListPi() {
		this.openLayout(<ListPi />, MenuEnum.PI.displayName);
	}

	openAddPiFromEstimateId(id?: number) {
	}

	openAddPi(id?: number) {
	}

	//
	openListSalesOrder() {
		this.openLayout(<ListSalesOrder />, MenuEnum.SALES_ORDER.displayName);
	}

	openAddSalesOrder(id?: number) {
		this.openLayout(<AddSalesOrder id={id} />, MenuEnum.SALES_ORDER.displayName);
	}

	openAddSalesOrderByEstimateId(estimateId: number) {
		this.openLayout(<AddSalesOrder estimateId={estimateId} />, Layout.SALES_ORDER.displayName);
	}

	openAddSalesOrderByPiId(piId: number) {
		this.openLayout(<AddSalesOrder piId={piId} />, Layout.SALES_ORDER.displayName);
	}

	//
	openListEstimate() {
		this.openLayout(<ListEstimate />, MenuEnum.ESTIMATE.displayName);
	}

	openAddEstimate(id?: number) {
		this.openLayout(<AddEstimate id={id} />, MenuEnum.ESTIMATE.displayName);
	}

	//
	openListPurchaseReturn() {
		this.openLayout(<ListPurchaseReturn />, MenuEnum.PURCHASE_RETURN.displayName);
	}

	openAddPurchaseReturn(id?: number) {
		this.openLayout(<AddPurchaseReturn id={id} />, MenuEnum.PURCHASE_RETURN.displayName);
	}

	//
	openListPurchase() {
		this.openLayout(<ListPurchase />, MenuEnum.PURCHASE.displayName);
	}

	openAddPurchase(id?: number) {
		this.openLayout(<AddPurchase id={id} />, MenuEnum.PURCHASE.displayName);
	}

	//
	openListPurchaseOrder() {
		this.openLayout(<ListPurchaseOrder />, MenuEnum.PURCHASE_ORDER.displayName);
	}

	openAddPurchaseOrder(id?: number) {
		this.openLayout(<AddPurchaseOrder id={id} />, MenuEnum.PURCHASE_ORDER.displayName);
	}

	//
	openListCommVou() {
		this.openLayout(<ListCommVou />, MenuEnum.COMM_VOU.displayName);
	}

	openAddCommVou(id?: number) {
	}

	//
	openListOrderAdjust() {
		this.openLayout(<ListOrderAdjust />, MenuEnum.ORDER_ADJUST.displayName);
	}

	openAddOrderAdjust(id?: number) {
	}

	//
	openListBank() {
		this.openLayout(<ListBank />, MenuEnum.BANK.displayName);
	}

	openAddBank(id?: number) {
	}

	//
	openListCustomer() {
		this.openLayout(<ListCustomer />, MenuEnum.CUSTOMER.displayName);
	}

	openAddCustomer(id?: number) {
	}

	//
	openListSupplier() {
		this.openLayout(<ListSupplier />, MenuEnum.SUPPLIER.displayName);
	}

	openAddSupplier(id?: number) {
	}

	//
	openListAgent() {
		this.openLayout(<ListAgent />, MenuEnum.AGENT.displayName);
	}

	openAddAgent(id?: number) {
	}

	//
	openListAcct() {
		this.openLayout(<ListAcct />, MenuEnum.ACCT.displayName);
	}

	openAddAcct(id?: number) {
	}

	//
	openListAcctType() {
		this.openLayout(<ListAcctType />, MenuEnum.ACCT_TYPE.displayName);
	}

	openAddAcctType(id?: number) {
	}

	//
	openListInventoryAdjust() {
		this.openLayout(<ListInvAdjust />, MenuEnum.INV_ADJUST.displayName);
	}

	openAddInventoryAdjust(id?: number) {
	}

	//
	openListCommList() {
		this.openLayout(<ListCommissionList />, MenuEnum.COMM_LIST.displayName);
	}

	openAddCommList(id?: number) {
	}

	//
	openListPurchasePriceList() {
		this.openLayout(<ListPurchasePriceList />, MenuEnum.PURCHASE_PRICE_LIST.displayName);
	}

	openAddPurchasePriceList(id?: number) {
	}

	//
	openListSalesPriceList() {
		this.openLayout(<ListSalesPriceList />, MenuEnum.SALES_PRICE_LIST.displayName);
	}

	openAddSalesPriceList(id?: number) {
	}

	//
	openListService() {
		this.openLayout(<ListService />, MenuEnum.SERVICE.displayName);
	}

	openAddService(id?: number) {
	}

	//
	openListProduct() {
		this.openLayout(<ListProduct />, MenuEnum.PROD.displayName);
	}

	openAddProduct(id?: number) {
	}

	//
	openListProductOthCh() {
		this.openLayout(<ListProductOthCharge />, MenuEnum.PROD_OTH_CH.displayName);
	}

	openAddProductOthCh(id?: number) {
	}

	//
	openListTaxCat() {
		this.openLayout(<ListTaxCategory />, MenuEnum.TAX_CAT.displayName);
	}

	openAddTaxCat(id?: number) {
	}

	//
	openListProductCat() {
		this.openLayout(<ListProductCategory />, MenuEnum.CAT.displayName);
	}

	openAddProductCat(id?: number) {
	}

	//
	openListUnit() {
		this.openLayout(<ListUnit />, MenuEnum.UNIT.displayName);
	}

	openAddUnit(id?: number) {
		this.openLayout(<AddUnit />, MenuEnum.UNIT.displayName);
	}

	// Reports
	openRptSalesReturnByDay(searchModel?: RptSalesReturnByDaySm) {
		this.openLayout(<RptSalesReturnByDay />, MenuEnum.RPT_SALES_RETURN_BY_DAY.displayName);
	}

	openRptSalesReturnByMonth(searchModel?: RptSalesReturnByMonthSm) {
		this.openLayout(<RptSalesReturnByMonth />, MenuEnum.RPT_SALES_RETURN_BY_MONTH.displayName);
	}

	openRptSalesReturnByCustomer(searchModel?: RptSalesReturnByCustomerSm) {
		this.openLayout(<RptSalesReturnByCustomer />, MenuEnum.RPT_SALES_RETURN_BY_CUSTOMER.displayName);
	}

	openRptSalesReturnByProdCat(searchModel?: RptSalesReturnByProductCategorySm) {
		this.openLayout(<RptSalesReturnByProdCat />, MenuEnum.RPT_SALES_RETURN_BY_CATEGORY.displayName);
	}

	openRptSalesReturnByProd(searchModel?: RptSalesReturnByProductSm) {
		this.openLayout(<RptSalesReturnByProd />, MenuEnum.RPT_SALES_RETURN_BY_PRODUCT.displayName);
	}

	openRptSalesReturnByCity(searchModel?: RptSalesReturnByCitySm) {
		this.openLayout(<RptSalesReturnByCity />, MenuEnum.RPT_SALES_RETURN_BY_CITY.displayName);
	}

	openRptSalesReturnByState(searchModel?: RptSalesReturnByStateSm) {
		this.openLayout(<RptSalesReturnByState />, MenuEnum.RPT_SALES_RETURN_BY_STATE.displayName);
	}

	openRptSalesReturnByAgent(searchModel?: RptSalesReturnByAgentSm) {
		this.openLayout(<RptSalesReturnByAgent />, MenuEnum.RPT_SALES_RETURN_BY_AGENT.displayName);
	}

	openRptSalesReturnDetailedByVoucher(searchModel?: RptSalesReturnDetailedByVoucherSm) {
		this.openLayout(<RptSalesReturnDetailedByVoucher />, MenuEnum.RPT_SALES_RETURN_DETAILED_BY_VOUCHER.displayName);
	}

	openRptSalesReturnDetailedByProd(searchModel?: RptSalesReturnDetailedByProductSm) {
		this.openLayout(<RptSalesReturnDetailedByProd />, MenuEnum.RPT_SALES_RETURN_DETAILED_BY_PROD.displayName);
	}

	openRptSalesByDay(searchModel?: RptSalesByDaySm) {
		this.openLayout(<RptSalesByDay />, MenuEnum.RPT_SALES_BY_DAY.displayName);
	}

	openRptSalesByMonth(searchModel?: RptSalesByMonthSm) {
		this.openLayout(<RptSalesByMonth />, MenuEnum.RPT_SALES_BY_MONTH.displayName);
	}

	openRptSalesByCustomer(searchModel?: RptSalesByCustomerSm) {
		this.openLayout(<RptSalesByCustomer />, MenuEnum.RPT_SALES_BY_CUSTOMER.displayName);
	}

	openRptSalesByProdCat(searchModel?: RptSalesByProductCategorySm) {
		this.openLayout(<RptSalesByProdCat />, MenuEnum.RPT_SALES_BY_CATEGORY.displayName);
	}

	openRptSalesByProd(searchModel?: RptSalesByProductSm) {
		this.openLayout(<RptSalesByProd />, MenuEnum.RPT_SALES_BY_PRODUCT.displayName);
	}

	openRptSalesByCity(searchModel?: RptSalesByCitySm) {
		this.openLayout(<RptSalesByCity />, MenuEnum.RPT_SALES_BY_CITY.displayName);
	}

	openRptSalesByState(searchModel?: RptSalesByStateSm) {
		this.openLayout(<RptSalesByState />, MenuEnum.RPT_SALES_BY_STATE.displayName);
	}

	openRptSalesByAgent(searchModel?: RptSalesByAgentSm) {
		this.openLayout(<RptSalesByAgent />, MenuEnum.RPT_SALES_BY_AGENT.displayName);
	}

	openRptSalesDetailedByVoucher(searchModel?: RptSalesDetailedByVoucherSm) {
		this.openLayout(<RptSalesDetailedByVoucher />, MenuEnum.RPT_SALES_DETAILED_BY_VOUCHER.displayName);
	}

	openRptSalesDetailedByProd(searchModel?: RptSalesDetailedByProductSm) {
		this.openLayout(<RptSalesDetailedByProd />, MenuEnum.RPT_SALES_DETAILED_BY_PROD.displayName);
	}

	openRptSalesProfitByCustomer(searchModel?: RptSalesProfitByCustomerSm) {
		this.openLayout(<RptSalesProfitByCustomer />, MenuEnum.RPT_SALES_PROFIT_BY_CUSTOMER.displayName);
	}

	openRptSalesProfitByProdCat(searchModel?: RptSalesProfitByProdCatSm) {
		this.openLayout(<RptSalesProfitByProdCat />, MenuEnum.RPT_SALES_PROFIT_BY_CATEGORY.displayName);
	}

	openRptSalesProfitByProd(searchModel?: RptSalesProfitByProductSm) {
		this.openLayout(<RptSalesProfitByProd />, MenuEnum.RPT_SALES_PROFIT_BY_PROD.displayName);
	}

	openRptSalesProfitBySalesVoucher(searchModel?: RptSalesProfitBySalesVoucherSm) {
		this.openLayout(<RptSalesProfitBySalesVoucher />, MenuEnum.RPT_SALES_PROFIT_BY_SALES_VOUCHER.displayName);
	}

	openRptSalesProfitBySalesItem(searchModel?: RptSalesProfitBySalesItemSm) {
		this.openLayout(<RptSalesProfitBySalesItem />, MenuEnum.RPT_SALES_PROFIT_BY_SALES_ITEM.displayName);
	}

	openRptPurchaseReturnByDay(searchModel?: RptPurchaseReturnByDaySm) {
		this.openLayout(<RptPurchaseReturnByDay />, MenuEnum.RPT_PURCHASE_RETURN_BY_DAY.displayName);
	}

	openRptPurchaseReturnByMonth(searchModel?: RptPurchaseReturnByMonthSm) {
		this.openLayout(<RptPurchaseReturnByMonth />, MenuEnum.RPT_PURCHASE_RETURN_BY_MONTH.displayName);
	}

	openRptPurchaseReturnBySupplier(searchModel?: RptPurchaseReturnBySupplierSm) {
		this.openLayout(<RptPurchaseReturnBySupplier />, MenuEnum.RPT_PURCHASE_RETURN_BY_SUPPLIER.displayName);
	}

	openRptPurchaseReturnByProdCat(searchModel?: RptPurchaseReturnByProductCategorySm) {
		this.openLayout(<RptPurchaseReturnByProdCat />, MenuEnum.RPT_PURCHASE_RETURN_BY_CATEGORY.displayName);
	}

	openRptPurchaseReturnByProd(searchModel?: RptPurchaseReturnByProductSm) {
		this.openLayout(<RptPurchaseReturnByProd />, MenuEnum.RPT_PURCHASE_RETURN_BY_PRODUCT.displayName);
	}

	openRptPurchaseReturnByCity(searchModel?: RptPurchaseReturnByCitySm) {
		this.openLayout(<RptPurchaseReturnByCity />, MenuEnum.RPT_PURCHASE_RETURN_BY_CITY.displayName);
	}

	openRptPurchaseReturnByState(searchModel?: RptPurchaseReturnByStateSm) {
		this.openLayout(<RptPurchaseReturnByState />, MenuEnum.RPT_PURCHASE_RETURN_BY_STATE.displayName);
	}

	openRptPurchaseReturnDetailedByVoucher(searchModel?: RptPurchaseReturnDetailedByVoucherSm) {
		this.openLayout(<RptPurchaseReturnDetailedByVoucher />, MenuEnum.RPT_PURCHASE_RETURN_DETAILED_BY_VOUCHER.displayName);
	}

	openRptPurchaseReturnDetailedByProd(searchModel?: RptPurchaseReturnDetailedByProductSm) {
		this.openLayout(<RptPurchaseReturnDetailedByProd />, MenuEnum.RPT_PURCHASE_RETURN_DETAILED_BY_PROD.displayName);
	}

	openRptPurchaseByDay(searchModel?: RptPurchaseByDaySm) {
		this.openLayout(<RptPurchaseByDay />, MenuEnum.RPT_PURCHASE_BY_DAY.displayName);
	}

	openRptPurchaseByMonth(searchModel?: RptPurchaseByMonthSm) {
		this.openLayout(<RptPurchaseByMonth />, MenuEnum.RPT_PURCHASE_BY_MONTH.displayName);
	}

	openRptPurchaseBySupplier(searchModel?: RptPurchaseBySupplierSm) {
		this.openLayout(<RptPurchaseBySupplier />, MenuEnum.RPT_PURCHASE_BY_SUPPLIER.displayName);
	}

	openRptPurchaseByProdCat(searchModel?: RptPurchaseByProductCategorySm) {
		this.openLayout(<RptPurchaseByProdCat />, MenuEnum.RPT_PURCHASE_BY_CATEGORY.displayName);
	}

	openRptPurchaseByProd(searchModel?: RptPurchaseByProductSm) {
		this.openLayout(<RptPurchaseByProd />, MenuEnum.RPT_PURCHASE_BY_PRODUCT.displayName);
	}

	openRptPurchaseByCity(searchModel?: RptPurchaseByCitySm) {
		this.openLayout(<RptPurchaseByCity />, MenuEnum.RPT_PURCHASE_BY_CITY.displayName);
	}

	openRptPurchaseByState(searchModel?: RptPurchaseByStateSm) {
		this.openLayout(<RptPurchaseByState />, MenuEnum.RPT_PURCHASE_BY_STATE.displayName);
	}

	openRptPurchaseDetailedByVoucher(searchModel?: RptPurchaseDetailedByVoucherSm) {
		this.openLayout(<RptPurchaseDetailedByVoucher />, MenuEnum.RPT_PURCHASE_DETAILED_BY_VOUCHER.displayName);
	}

	openRptPurchaseDetailedByProd(searchModel?: RptPurchaseDetailedByProductSm) {
		this.openLayout(<RptPurchaseDetailedByProd />, MenuEnum.RPT_PURCHASE_DETAILED_BY_PROD.displayName);
	}

	openRptEstimatePendingByProd(searchModel?: RptEstimatePendingByProdSm) {
		this.openLayout(<RptEstimatePendingByProd />, MenuEnum.RPT_ESTIMATE_PENDING_PRODUCTS.displayName);
	}

	openRptEstimatePendingByAcct(searchModel?: RptEstimatePendingByCustomerSm) {
		this.openLayout(<RptEstimatePendingByCustomer />, MenuEnum.RPT_ESTIMATE_PENDING_BY_ACCT.displayName);
	}

	openRptEstimatePendingDet(searchModel?: RptEstimatePendingDetailedSm) {
		this.openLayout(<RptEstimatePendingDetailed />, MenuEnum.RPT_ESTIMATE_PENDING_PRODUCTS.displayName);
	}

	openRptDueRcvableByCustomer(searchModel?: RptDueRcvableByCustomerSm) {
		this.openLayout(<RptDueRcvableByCustomer />, MenuEnum.DUE_RCVABLE_BY_CUSTOMER.displayName);
	}

	openRptDueRcvableBySales(searchModel?: RptDueRcvableBySalesSm) {
		this.openLayout(<RptDueRcvableBySales />, MenuEnum.DUE_RCVABLE_BY_SALES.displayName);
	}

	openRptInvIoSummByProdCat(searchModel?: RptInvIoSummByProdCatSm) {
		this.openLayout(<RptInvIoSummByProdCat />, MenuEnum.RPT_IO_SUMM_BY_PROD_CAT.displayName);
	}

	openRptInvIoSummByProd(searchModel?: RptInvIoSummByProdSm) {
		this.openLayout(<RptInvIoSummByProd />, MenuEnum.RPT_IO_SUMM_BY_PROD.displayName);
	}

	openRptInvIoSummByMonth(searchModel?: RptInvIoSummByMonthSm) {
		this.openLayout(<RptInvIoSummByMonth />, MenuEnum.RPT_IO_SUMM_BY_MONTH.displayName);
	}

	openRptInvIoDet(searchModel?: RptInvIoDetailedSm) {
		this.openLayout(<RptInvIoDetailed />, MenuEnum.RPT_IO_DET.displayName);
	}

	openRptLedger(searchModel?: RptLedgerSm) {
		this.openLayout(<RptLedger />, MenuEnum.LEDGER.displayName);
	}

	openRptAcctSumm(searchModel?: RptAcctSummByAcctTypeSm) {
		this.openLayout(<RptAcctSummByAcctType />, MenuEnum.ACCT_SUMM_BY_ACCT_TYPE.displayName);
	}

	openRptTrialBalance(searchModel?: RptTrialBalSm) {
		this.openLayout(<RptTrialBalanceController />, MenuEnum.TRIAL_BAL.displayName);
	}

	openTradingAcct() {

	}

	openPlAcct() {

	}

	openRptBalanceSheet() {

	}

	openGstLedger() {

	}

	openRptInventoryLedger(searchModel?: RptInventoryLedgerSm) {
		this.openLayout(<RptInventoryLedger />, MenuEnum.INVENTORY_LEDGER.displayName);
	}

	openRptCurrentStock(searchModel?: RptCurrentStockSm) {
		this.openLayout(<RptCurrentStock />, MenuEnum.CURRENT_STOCK.displayName);
	}

	openRptCurrentStockVal(searchModel?: RptCurrentStockValSm) {
		this.openLayout(<RptCurrentStockVal />, MenuEnum.CURRENT_STOCK_VAL.displayName);
	}

	openRptLowStock(searchModel?: RptLowStockSm) {
		this.openLayout(<RptLowStock />, MenuEnum.LOW_STOCK.displayName);
	}

	openRptCommVouLedger(searchModel?: RptCommVouLedgerSm) {
		this.openLayout(<RptCommVouLedger />, MenuEnum.COMM_VOU_LEDGER.displayName);
	}

	openRptSalesOrderPending(searchModel?: RptSalesOrderPendingSm) {
		this.openLayout(<RptSalesOrderPending />, MenuEnum.SALES_ORDER_PENDING.displayName);
	}

	openRptPurchaseOrderPending(searchModel?: RptPurchaseOrderPendingSm) {
		this.openLayout(<RptPurchaseOrderPending />, MenuEnum.PURCHASE_ORDER_PENDING.displayName);
	}

	//
	openListTransporter() {
		this.openLayout(<ListTransporter />, MenuEnum.TRANSPORTER.displayName);
	}

	openAddTransporter(id?: number) {
	}

	//
	openListTax() {
		this.openLayout(<ListTax />, MenuEnum.TAX.displayName);
	}

	openAddTax(id?: number) {
		this.openLayout(<AddTransporter id={id} />, MenuEnum.TRANSPORTER.displayName);
	}

	//
	openListMsgTemplate() {
		this.openLayout(<ListMsgTemplate />, MenuEnum.MSG_TEMPLATE.displayName);
	}

	openAddMsgTemplate(id?: number) {
	}

	//
	openCompanyInfo() {
	}

	//
	openListCompanyProfile() {
		this.openLayout(<ListCompanyProfile />, MenuEnum.COMPANY_PROFILE.displayName);
	}

	openAddCompanyProfile(id?: number) {
	}

	//
	openListVouOthCh() {
		this.openLayout(<ListVoucherOtherCharge />, MenuEnum.VOU_OTH_CH.displayName);
	}

	openAddVouOthCh(id?: number) {
	}

	//
	openListLeadSrc() {
		this.openLayout(<ListLeadSrc />, MenuEnum.LEAD_SRC.displayName);
	}

	openAddLeadSrc(id?: number) {
	}

	//
	openListLeadStatus() {
		this.openLayout(<ListLeadStatus />, MenuEnum.LEAD_STATUS.displayName);
	}

	openAddLeadStatus(id?: number) {
	}

	//
	openListState() {
	}

	openAddState(id?: number) {
	}

	// == 
	//
	openListAccessRole() {
	}

	openAddAccessRole(id?: number) {
	}

	//
	openListUser() {
	}

	openAddUser(id?: number) {
	}

	//
	openListView(menu: MenuEnum) {
		switch (menu) {
			case MenuEnum.REMINDER:
				return this.openListReminder();
			case MenuEnum.COMMNC:
				return this.openListCommnc();
			case MenuEnum.LEAD:
				return this.openListLead();
			case MenuEnum.ACCT_BOOK:
				return this.openAcctBook();
			case MenuEnum.DAY_BOOK:
				return this.openDayBook();
			case MenuEnum.CDP_JOURNAL_VOU:
				return this.openListCdpJournalVou();
			case MenuEnum.JOURNAL:
				return this.openListJournal();
			case MenuEnum.CONTRA:
				return this.openListContra();
			case MenuEnum.RECEIPT:
				return this.openListReceipt();
			case MenuEnum.PAYMENT:
				return this.openListPayment();
			case MenuEnum.SALES_RETURN:
				return this.openListSalesReturn();
			case MenuEnum.SALES:
				return this.openListSales();
			case MenuEnum.PI:
				return this.openListPi();
			case MenuEnum.SALES_ORDER:
				return this.openListSalesOrder();
			case MenuEnum.ESTIMATE:
				return this.openListEstimate();
			case MenuEnum.PURCHASE_RETURN:
				return this.openListPurchaseReturn();
			case MenuEnum.PURCHASE:
				return this.openListPurchase();
			case MenuEnum.PURCHASE_ORDER:
				return this.openListPurchaseOrder();
			case MenuEnum.COMM_VOU:
				return this.openListCommVou();
			case MenuEnum.ORDER_ADJUST:
				return this.openListOrderAdjust();
			case MenuEnum.BANK:
				return this.openListBank();
			case MenuEnum.CUSTOMER:
				return this.openListCustomer();
			case MenuEnum.SUPPLIER:
				return this.openListSupplier();
			case MenuEnum.AGENT:
				return this.openListAgent();
			case MenuEnum.ACCT:
				return this.openListAcct();
			case MenuEnum.ACCT_TYPE:
				return this.openListAcctType();
			case MenuEnum.INV_ADJUST:
				return this.openListInventoryAdjust();
			case MenuEnum.COMM_LIST:
				return this.openListCommList();
			case MenuEnum.PURCHASE_PRICE_LIST:
				return this.openListPurchasePriceList();
			case MenuEnum.SALES_PRICE_LIST:
				return this.openListSalesPriceList();
			case MenuEnum.SERVICE:
				return this.openListService();
			case MenuEnum.PROD:
				return this.openListProduct();
			case MenuEnum.PROD_OTH_CH:
				return this.openListProductOthCh();
			case MenuEnum.TAX_CAT:
				return this.openListTaxCat();
			case MenuEnum.CAT:
				return this.openListProductCat();
			case MenuEnum.UNIT:
				return this.openListUnit();

			case MenuEnum.RPT_SALES_RETURN_BY_MONTH:
				return this.openRptSalesReturnByMonth();
			case MenuEnum.RPT_SALES_RETURN_BY_DAY:
				return this.openRptSalesReturnByDay();
			case MenuEnum.RPT_SALES_RETURN_BY_CUSTOMER:
				return this.openRptSalesReturnByCustomer();
			case MenuEnum.RPT_SALES_RETURN_BY_CATEGORY:
				return this.openRptSalesReturnByProdCat();
			case MenuEnum.RPT_SALES_RETURN_BY_PRODUCT:
				return this.openRptSalesReturnByProd();
			case MenuEnum.RPT_SALES_RETURN_BY_CITY:
				return this.openRptSalesReturnByCity();
			case MenuEnum.RPT_SALES_RETURN_BY_STATE:
				return this.openRptSalesReturnByState();
			case MenuEnum.RPT_SALES_RETURN_BY_AGENT:
				return this.openRptSalesReturnByAgent();
			case MenuEnum.RPT_SALES_RETURN_DETAILED_BY_VOUCHER:
				return this.openRptSalesReturnDetailedByVoucher();
			case MenuEnum.RPT_SALES_RETURN_DETAILED_BY_PROD:
				return this.openRptSalesReturnDetailedByProd();

			case MenuEnum.RPT_SALES_BY_MONTH:
				return this.openRptSalesByMonth();
			case MenuEnum.RPT_SALES_BY_DAY:
				return this.openRptSalesByDay();
			case MenuEnum.RPT_SALES_BY_CUSTOMER:
				return this.openRptSalesByCustomer();
			case MenuEnum.RPT_SALES_BY_CATEGORY:
				return this.openRptSalesByProdCat();
			case MenuEnum.RPT_SALES_BY_PRODUCT:
				return this.openRptSalesByProd();
			case MenuEnum.RPT_SALES_BY_CITY:
				return this.openRptSalesByCity();
			case MenuEnum.RPT_SALES_BY_STATE:
				return this.openRptSalesByState();
			case MenuEnum.RPT_SALES_BY_AGENT:
				return this.openRptSalesByAgent();
			case MenuEnum.RPT_SALES_DETAILED_BY_VOUCHER:
				return this.openRptSalesDetailedByVoucher();
			case MenuEnum.RPT_SALES_DETAILED_BY_PROD:
				return this.openRptSalesDetailedByProd();

			case MenuEnum.RPT_SALES_PROFIT_BY_CUSTOMER:
				return this.openRptSalesProfitByCustomer();
			case MenuEnum.RPT_SALES_PROFIT_BY_CATEGORY:
				return this.openRptSalesProfitByProdCat();
			case MenuEnum.RPT_SALES_PROFIT_BY_PROD:
				return this.openRptSalesProfitByProd();
			case MenuEnum.RPT_SALES_PROFIT_BY_SALES_VOUCHER:
				return this.openRptSalesProfitBySalesVoucher();
			case MenuEnum.RPT_SALES_PROFIT_BY_SALES_ITEM:
				return this.openRptSalesProfitBySalesItem();

			case MenuEnum.RPT_PURCHASE_RETURN_BY_MONTH:
				return this.openRptPurchaseReturnByMonth();
			case MenuEnum.RPT_PURCHASE_RETURN_BY_DAY:
				return this.openRptPurchaseReturnByDay();
			case MenuEnum.RPT_PURCHASE_RETURN_BY_SUPPLIER:
				return this.openRptPurchaseReturnBySupplier();
			case MenuEnum.RPT_PURCHASE_RETURN_BY_CATEGORY:
				return this.openRptPurchaseReturnByProdCat();
			case MenuEnum.RPT_PURCHASE_RETURN_BY_PRODUCT:
				return this.openRptPurchaseReturnByProd();
			case MenuEnum.RPT_PURCHASE_RETURN_BY_CITY:
				return this.openRptPurchaseReturnByCity();
			case MenuEnum.RPT_PURCHASE_RETURN_BY_STATE:
				return this.openRptPurchaseReturnByState();
			case MenuEnum.RPT_PURCHASE_RETURN_DETAILED_BY_VOUCHER:
				return this.openRptPurchaseReturnDetailedByVoucher();
			case MenuEnum.RPT_PURCHASE_RETURN_DETAILED_BY_PROD:
				return this.openRptPurchaseReturnDetailedByProd();

			case MenuEnum.RPT_PURCHASE_BY_MONTH:
				return this.openRptPurchaseByMonth();
			case MenuEnum.RPT_PURCHASE_BY_DAY:
				return this.openRptPurchaseByDay();
			case MenuEnum.RPT_PURCHASE_BY_SUPPLIER:
				return this.openRptPurchaseBySupplier();
			case MenuEnum.RPT_PURCHASE_BY_CATEGORY:
				return this.openRptPurchaseByProdCat();
			case MenuEnum.RPT_PURCHASE_BY_PRODUCT:
				return this.openRptPurchaseByProd();
			case MenuEnum.RPT_PURCHASE_BY_CITY:
				return this.openRptPurchaseByCity();
			case MenuEnum.RPT_PURCHASE_BY_STATE:
				return this.openRptPurchaseByState();
			case MenuEnum.RPT_PURCHASE_DETAILED_BY_VOUCHER:
				return this.openRptPurchaseDetailedByVoucher();
			case MenuEnum.RPT_PURCHASE_DETAILED_BY_PROD:
				return this.openRptPurchaseDetailedByProd();

			case MenuEnum.RPT_ESTIMATE_PENDING_BY_PROD:
				return this.openRptEstimatePendingByProd();
			case MenuEnum.RPT_ESTIMATE_PENDING_BY_ACCT:
				return this.openRptEstimatePendingByAcct();
			case MenuEnum.RPT_ESTIMATE_PENDING_PRODUCTS:
				return this.openRptEstimatePendingDet();

			case MenuEnum.DUE_RCVABLE_BY_CUSTOMER:
				return this.openRptDueRcvableByCustomer();
			case MenuEnum.DUE_RCVABLE_BY_SALES:
				return this.openRptDueRcvableBySales();

			case MenuEnum.RPT_IO_SUMM_BY_PROD_CAT:
				return this.openRptInvIoSummByProdCat();
			case MenuEnum.RPT_IO_SUMM_BY_PROD:
				return this.openRptInvIoSummByProd();
			case MenuEnum.RPT_IO_SUMM_BY_MONTH:
				return this.openRptInvIoSummByMonth();
			case MenuEnum.RPT_IO_DET:
				return this.openRptInvIoDet();

			case MenuEnum.LEDGER:
				return this.openRptLedger();
			case MenuEnum.ACCT_SUMM_BY_ACCT_TYPE:
				return this.openRptAcctSumm();
			case MenuEnum.TRIAL_BAL:
				return this.openRptTrialBalance();
			case MenuEnum.TRADING_ACCT:
			case MenuEnum.PL_ACCT:
			case MenuEnum.BALANCE_SHEET:
				return this.openRptBalanceSheet();
			case MenuEnum.INVENTORY_LEDGER:
				return this.openRptInventoryLedger();
			case MenuEnum.CURRENT_STOCK:
				return this.openRptCurrentStock();
			case MenuEnum.CURRENT_STOCK_VAL:
				return this.openRptCurrentStockVal();
			case MenuEnum.LOW_STOCK:
				return this.openRptLowStock();
			case MenuEnum.COMM_VOU_LEDGER:
				return this.openRptCommVouLedger();
			case MenuEnum.SALES_ORDER_PENDING:
				return this.openRptSalesOrderPending();
			case MenuEnum.PURCHASE_ORDER_PENDING:
				return this.openRptPurchaseOrderPending();


			case MenuEnum.TRANSPORTER:
				return this.openListTransporter();
			case MenuEnum.TAX:
				return this.openListTax();
			case MenuEnum.MSG_TEMPLATE:
				return this.openListMsgTemplate();
			case MenuEnum.COMPANY_INFO:
				return this.openCompanyInfo();
			case MenuEnum.COMPANY_PROFILE:
				return this.openListCompanyProfile();
			case MenuEnum.VOU_OTH_CH:
				return this.openListVouOthCh();
			case MenuEnum.LEAD_SRC:
				return this.openListLeadSrc();
			case MenuEnum.LEAD_STATUS:
				return this.openListLeadStatus();
		}
	};

	//
	openAddView(menu: MenuEnum) {
		switch (menu) {
			case MenuEnum.REMINDER:
				return this.openAddReminder();
			case MenuEnum.COMMNC:
				return this.openAddCommnc();
			case MenuEnum.LEAD:
				return this.openAddLead();
			case MenuEnum.JOURNAL:
				return this.openAddJournal();
			case MenuEnum.CONTRA:
				return this.openAddContra();
			case MenuEnum.RECEIPT:
				return this.openAddReceipt();
			case MenuEnum.PAYMENT:
				return this.openAddPayment();
			case MenuEnum.SALES_RETURN:
				return this.openAddSalesReturn();
			case MenuEnum.SALES:
				return this.openAddSales();
			case MenuEnum.PI:
				return this.openAddPi();
			case MenuEnum.SALES_ORDER:
				return this.openAddSalesOrder();
			case MenuEnum.ESTIMATE:
				return this.openAddEstimate();
			case MenuEnum.PURCHASE_RETURN:
				return this.openAddPurchaseReturn();
			case MenuEnum.PURCHASE:
				return this.openAddPurchase();
			case MenuEnum.PURCHASE_ORDER:
				return this.openAddPurchaseOrder();
			case MenuEnum.COMM_VOU:
				return this.openAddCommVou();
			case MenuEnum.ORDER_ADJUST:
				return this.openAddOrderAdjust();
			case MenuEnum.BANK:
				return this.openAddBank();
			case MenuEnum.CUSTOMER:
				return this.openAddCustomer();
			case MenuEnum.SUPPLIER:
				return this.openAddSupplier();
			case MenuEnum.AGENT:
				return this.openAddAgent();
			case MenuEnum.ACCT:
				return this.openAddAcct();
			case MenuEnum.ACCT_TYPE:
				return this.openAddAcctType();
			case MenuEnum.INV_ADJUST:
				return this.openAddInventoryAdjust();
			case MenuEnum.SERVICE:
				return this.openAddService();
			case MenuEnum.PROD:
				return this.openAddProduct();
			case MenuEnum.PROD_OTH_CH:
				return this.openAddProductOthCh();
			case MenuEnum.TAX_CAT:
				return this.openAddTaxCat();
			case MenuEnum.CAT:
				return this.openAddProductCat();
			case MenuEnum.UNIT:
				return this.openAddUnit();
			case MenuEnum.TRANSPORTER:
				return this.openAddTransporter();
			case MenuEnum.TAX:
				return this.openAddTax();
			case MenuEnum.MSG_TEMPLATE:
				return this.openAddMsgTemplate();
			case MenuEnum.COMPANY_PROFILE:
				return this.openAddCompanyProfile();
			case MenuEnum.VOU_OTH_CH:
				return this.openAddVouOthCh();
			case MenuEnum.LEAD_SRC:
				return this.openAddLeadSrc();
			case MenuEnum.LEAD_STATUS:
				return this.openAddLeadStatus();
		}
	};

	//
	openEditVoucher(id: number) {

	};

	//
	handleKbsc(kbShortcutEnum: KbShortcutEnum) {

		switch (kbShortcutEnum) {

			case KbShortcutEnum.COMMNC_BOOK: {
				return this.openCommncBook();
			}
			case KbShortcutEnum.REMINDER: {
				return this.openListReminder();
			}
			case KbShortcutEnum.REMINDER_ADD: {
				return this.openAddReminder();
			}
			case KbShortcutEnum.COMMNC: {
				return this.openListCommnc();
			}
			case KbShortcutEnum.COMMNC_ADD: {
				return this.openAddCommnc();
			}
			case KbShortcutEnum.LEAD: {
				return this.openListLead();
			}
			case KbShortcutEnum.LEAD_ADD: {
				return this.openAddLead();
			}
			case KbShortcutEnum.ACCT_BOOK: {
				return this.openAcctBook();
			}
			case KbShortcutEnum.DAY_BOOK: {
				return this.openDayBook();
			}
			case KbShortcutEnum.CDP: {
				return this.openListCdpJournalVou();
			}
			case KbShortcutEnum.CDP_ADD: {
				return this.openAddCdpJournalVou();
			}
			case KbShortcutEnum.JOURNAL: {
				return this.openListJournal();
			}
			case KbShortcutEnum.JOURNAL_ADD: {
				return this.openAddJournal();
			}
			case KbShortcutEnum.CONTRA: {
				return this.openListContra();
			}
			case KbShortcutEnum.CONTRA_ADD: {
				return this.openAddContra();
			}
			case KbShortcutEnum.RECEIPT: {
				return this.openListReceipt();
			}
			case KbShortcutEnum.RECEIPT_ADD: {
				return this.openAddReceipt();
			}
			case KbShortcutEnum.PAYMENT: {
				return this.openListPayment();
			}
			case KbShortcutEnum.PAYMENT_ADD: {
				return this.openAddPayment();
			}
			case KbShortcutEnum.SALES_RETURN: {
				return this.openListSalesReturn();
			}
			case KbShortcutEnum.SALES_RETURN_ADD: {
				return this.openAddSalesReturn();
			}
			case KbShortcutEnum.SALES: {
				return this.openListSales();
			}
			case KbShortcutEnum.SALES_ADD: {
				return this.openAddSales();
			}
			case KbShortcutEnum.PI: {
				return this.openListPi();
			}
			case KbShortcutEnum.PI_ADD: {
				return this.openAddPi();
			}
			case KbShortcutEnum.SALES_ORDER: {
				return this.openListSalesOrder();
			}
			case KbShortcutEnum.SALES_ORDER_ADD: {
				return this.openAddSalesOrder();
			}
			case KbShortcutEnum.ESTIMATE: {
				return this.openListEstimate();
			}
			case KbShortcutEnum.ESTIMATE_ADD: {
				return this.openAddEstimate();
			}
			case KbShortcutEnum.PURCHASE_RETURN: {
				return this.openListPurchaseReturn();
			}
			case KbShortcutEnum.PURCHASE_RETURN_ADD: {
				return this.openAddPurchaseOrder();
			}
			case KbShortcutEnum.PURCHASE: {
				return this.openListPurchase();
			}
			case KbShortcutEnum.PURCHASE_ADD: {
				return this.openAddPurchase();
			}
			case KbShortcutEnum.PURCHASE_ORDER: {
				return this.openListPurchaseOrder();
			}
			case KbShortcutEnum.PURCHASE_ORDER_ADD: {
				return this.openAddPurchaseOrder();
			}
			case KbShortcutEnum.COMM_VOU: {
				return this.openListCommVou();
			}
			case KbShortcutEnum.COMM_VOU_ADD: {
				return this.openAddCommVou();
			}
			case KbShortcutEnum.CASH_DISC_VOU: {
				break;
			}
			case KbShortcutEnum.CASH_DISC_VOU_ADD: {
				break;
			}
			case KbShortcutEnum.BANK: {
				return this.openListBank();
			}
			case KbShortcutEnum.BANK_ADD: {
				return this.openAddBank();
			}
			case KbShortcutEnum.CUSTOMER: {
				return this.openListCustomer();
			}
			case KbShortcutEnum.CUSTOMER_ADD: {
				return this.openAddCustomer();
			}
			case KbShortcutEnum.SUPPLIER: {
				return this.openListSupplier();
			}
			case KbShortcutEnum.SUPPLIER_ADD: {
				return this.openAddSupplier();
			}
			case KbShortcutEnum.AGENT: {
				return this.openListAgent();
			}
			case KbShortcutEnum.AGENT_ADD: {
				return this.openAddAgent();
			}
			case KbShortcutEnum.ACCT: {
				return this.openListAcct();
			}
			case KbShortcutEnum.ACCT_ADD: {
				return this.openAddAcct();
			}
			case KbShortcutEnum.ACCT_TYPE: {
				return this.openListAcctType();
			}
			case KbShortcutEnum.ACCT_TYPE_ADD: {
				return this.openAddAcctType();
			}
			case KbShortcutEnum.INV_ADJUST: {
				return this.openListInventoryAdjust();
			}
			case KbShortcutEnum.INV_ADJUST_ADD: {
				return this.openAddInventoryAdjust();
			}
			case KbShortcutEnum.COMM_LIST: {
				return this.openListCommList();
			}
			case KbShortcutEnum.COMM_LIST_ADD: {
				return this.openAddCommList();
			}
			case KbShortcutEnum.SALES_PRICE_LIST: {
				return this.openListSalesPriceList();
			}
			case KbShortcutEnum.SALES_PRICE_LIST_ADD: {
				return this.openAddSalesPriceList();
			}
			case KbShortcutEnum.SERVICE: {
				return this.openListService();
			}
			case KbShortcutEnum.SERVICE_ADD: {
				return this.openAddService();
			}
			case KbShortcutEnum.PRODUCT: {
				return this.openListProduct();
			}
			case KbShortcutEnum.PRODUCT_ADD: {
				return this.openAddProduct();
			}
			case KbShortcutEnum.PROD_OTH_CHARGE: {
				return this.openListProductOthCh();
			}
			case KbShortcutEnum.PROD_OTH_CHARGE_ADD: {
				return this.openAddProductOthCh();
			}
			case KbShortcutEnum.TAX_CAT: {
				return this.openListTaxCat();
			}
			case KbShortcutEnum.TAX_CAT_ADD: {
				return this.openAddTaxCat();
			}
			case KbShortcutEnum.PROD_CAT: {
				return this.openListProductCat();
			}
			case KbShortcutEnum.PROD_CAT_ADD: {
				return this.openAddProductCat();
			}
			case KbShortcutEnum.UNIT: {
				return this.openListUnit();
			}
			case KbShortcutEnum.UNIT_ADD: {
				return this.openAddUnit();
			}
			case KbShortcutEnum.RPT_SALES_RETURN_BY_MONTH: {
				return this.openRptSalesReturnByMonth();
			}
			case KbShortcutEnum.RPT_SALES_RETURN_BY_DAY: {
				return this.openRptSalesReturnByDay();
			}
			case KbShortcutEnum.RPT_SALES_RETURN_BY_CUSTOMER: {
				return this.openRptSalesReturnByCustomer();
			}
			case KbShortcutEnum.RPT_SALES_RETURN_BY_CATEGORY: {
				break;
			}
			case KbShortcutEnum.RPT_SALES_RETURN_BY_PRODUCT: {
				return this.openRptSalesReturnByProd();
			}
			case KbShortcutEnum.RPT_SALES_RETURN_BY_CITY: {
				return this.openRptSalesReturnByCity();
			}
			case KbShortcutEnum.RPT_SALES_RETURN_BY_STATE: {
				return this.openRptSalesReturnByState();
			}
			case KbShortcutEnum.RPT_SALES_RETURN_BY_AGENT: {
				return this.openRptSalesReturnByAgent();
			}
			case KbShortcutEnum.RPT_SALES_BY_MONTH: {
				return this.openRptSalesByMonth();
			}
			case KbShortcutEnum.RPT_SALES_BY_DAY: {
				return this.openRptSalesByDay();
			}
			case KbShortcutEnum.RPT_SALES_BY_CUSTOMER: {
				return this.openRptSalesByCustomer();
			}
			case KbShortcutEnum.RPT_SALES_BY_CATEGORY: {
				return this.openRptSalesByProdCat();
			}
			case KbShortcutEnum.RPT_SALES_BY_PRODUCT: {
				return this.openRptSalesByProd();
			}
			case KbShortcutEnum.RPT_SALES_BY_CITY: {
				return this.openRptSalesByCity();
			}
			case KbShortcutEnum.RPT_SALES_BY_STATE: {
				return this.openRptSalesByState();
			}
			case KbShortcutEnum.RPT_SALES_BY_AGENT: {
				return this.openRptSalesByAgent();
			}
			case KbShortcutEnum.RPT_PURCHASE_RETURN_BY_MONTH: {
				return this.openRptPurchaseReturnByMonth();
			}
			case KbShortcutEnum.RPT_PURCHASE_RETURN_BY_DAY: {
				return this.openRptPurchaseReturnByDay();
			}
			case KbShortcutEnum.RPT_PURCHASE_RETURN_BY_SUPPLIER: {
				return this.openRptPurchaseReturnBySupplier();
			}
			case KbShortcutEnum.RPT_PURCHASE_RETURN_BY_CATEGORY: {
				return this.openRptPurchaseReturnByProdCat();
			}
			case KbShortcutEnum.RPT_PURCHASE_RETURN_BY_PRODUCT: {
				return this.openRptPurchaseReturnByProd();
			}
			case KbShortcutEnum.RPT_PURCHASE_RETURN_BY_CITY: {
				return this.openRptPurchaseReturnByCity();
			}
			case KbShortcutEnum.RPT_PURCHASE_RETURN_BY_STATE: {
				return this.openRptPurchaseReturnByState();
			}
			case KbShortcutEnum.RPT_PURCHASE_BY_MONTH: {
				return this.openRptPurchaseByMonth();
			}
			case KbShortcutEnum.RPT_PURCHASE_BY_DAY: {
				return this.openRptPurchaseByDay();
			}
			case KbShortcutEnum.RPT_PURCHASE_BY_SUPPLIER: {
				return this.openRptPurchaseBySupplier();
			}
			case KbShortcutEnum.RPT_PURCHASE_BY_CATEGORY: {
				return this.openRptPurchaseByProdCat();
			}
			case KbShortcutEnum.RPT_PURCHASE_BY_PRODUCT: {
				return this.openRptPurchaseByProd();
			}
			case KbShortcutEnum.RPT_PURCHASE_BY_CITY: {
				return this.openRptPurchaseByCity();
			}
			case KbShortcutEnum.RPT_PURCHASE_BY_STATE: {
				return this.openRptPurchaseByState();
			}
			case KbShortcutEnum.LEDGER: {
				return this.openRptLedger();
			}
			case KbShortcutEnum.TRIAL_BALANCE: {
				return this.openRptTrialBalance();
			}
			case KbShortcutEnum.TRADING_ACCT: {
				break;
			}
			case KbShortcutEnum.PL_ACCT: {
				return this.openPlAcct();
			}
			case KbShortcutEnum.BALANCE_SHEET: {
				return this.openRptBalanceSheet();
			}
			case KbShortcutEnum.INVENTORY_LEDGER: {
				return this.openRptInventoryLedger();
			}
			case KbShortcutEnum.CURRENT_STOCK: {
				return this.openRptCurrentStock();
			}
			case KbShortcutEnum.CURRENT_STOCK_VAL: {
				return this.openRptCurrentStockVal();
			}
			case KbShortcutEnum.LOW_STOCK: {
				return this.openRptLowStock();
			}
			case KbShortcutEnum.CASH_DISC_VOU_LEDGER: {
				break;
			}
			case KbShortcutEnum.CASH_DISC_VOU_LEDGER_2: {
				break;
			}
			case KbShortcutEnum.COMM_VOU_LEDGER: {
				break;
			}
			case KbShortcutEnum.SALES_ORDER_PENDING: {
				break;
			}
			case KbShortcutEnum.PURCHASE_ORDER_PENDING: {
				break;
			}
			case KbShortcutEnum.SCHEME: {
				break;
			}
			case KbShortcutEnum.SCHEME_ADD: {
				break;
			}
			case KbShortcutEnum.TRANSPORTER: {
				return this.openListTransporter();
			}
			case KbShortcutEnum.TRANSPORTER_ADD: {
				return this.openAddTransporter();
			}
			case KbShortcutEnum.TAX: {
				return this.openListTax();
			}
			case KbShortcutEnum.TAX_ADD: {
				return this.openAddTax();
			}
			case KbShortcutEnum.MSG_TEMPLATE: {
				return this.openListMsgTemplate();
			}
			case KbShortcutEnum.MSG_TEMPLATE_ADD: {
				return this.openAddMsgTemplate();
			}
			case KbShortcutEnum.COMPANY_INFO: {
				return this.openCompanyInfo();
			}
			case KbShortcutEnum.COMPANY_PROFILE: {
				return this.openListCompanyProfile();
			}
			case KbShortcutEnum.COMPANY_PROFILE_ADD: {
				return this.openAddCompanyProfile();
			}
			case KbShortcutEnum.VOU_OTH_CH: {
				return this.openListVouOthCh();
			}
			case KbShortcutEnum.VOU_OTH_CH_ADD: {
				return this.openAddVouOthCh();
			}
			case KbShortcutEnum.MENU_CONFIG: {
				break;
			}

		}

	};
}