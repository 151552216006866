import DateFormatEnum from "../enums/DateFormatEnum";
import dateToString from "./DateHelper";

export const getQueryString = (obj: any) => {

	const params = new URLSearchParams();

	for (const key in obj) {
		if (obj.hasOwnProperty(key)
			&& obj[key] !== undefined
			&& obj[key] !== null) {
			if (obj[key] instanceof Date) {
				params.append(key, dateToString(obj[key], DateFormatEnum.DB_DATETIME));
			}
			else {
				params.append(key, obj[key].toString());
			}
		}
	}

	return "?" + params.toString();
};