import { BASE_URL, TOKEN } from "../config";
import ServerNotReachableErrorCommonCudObj from "../default_objects/ServerNotReachableErrorCommonCudObj";
import ServerSideErrorCommonCudObj from "../default_objects/ServerSideErrorCommonCudObj";
import DateFormatEnum from "../enums/DateFormatEnum";
import ResourceEnum from "../enums/ResourceEnum";
import dateToString from "../helpers/DateHelper";
import strFormat from "../helpers/StringHelper";
import { getQueryString } from "../helpers/UrlSearchParamHelper";
import CommonGetListType from "../types/CommonGetListType";
import ErrorResType from "../types/res/ErrorResType";
import { PriceListRateResType } from "../types/res/PriceListRateResType";

export default class CustomerPriceListAApi {

	static async getCustomerPricePriceListAArr(customerId: number, asOnDate: Date): Promise<CommonGetListType<PriceListRateResType>> {
		try {
			const requestOptions = {
				method: "GET",
				headers: {
					"Authorization": "Bearer " + TOKEN.val,
					"Content-Type": "application/json"
				}
			}
			const response = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.CUSTOMER_PRICE_LIST_A.name)
				+ getQueryString({
					customerId: customerId,
					asOnDate: dateToString(asOnDate, DateFormatEnum.DB_DATE)
				})
				, requestOptions);

			if (response.status === 200) {
				return {
					isSuccess: true,
					items: await response.json() as PriceListRateResType[]
				};
			}
			else if (response.status === 400) {
				return {
					isSuccess: false,
					errorRes: await response.json() as ErrorResType
				};
			}
			return ServerSideErrorCommonCudObj;
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}
}