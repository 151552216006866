import { useState } from "react";
import DropDownItemType from "../../types/DropDownItemType";
import SalesItemType from "../../types/SalesItemType";
import { PriceListRateResType } from "../../types/res/PriceListRateResType";
import UnitLdto from "../../types/res/list/UnitLdto";
import ProdForEsimateLdto from "../../types/res/list/ProdForEsimateLdto";
import TaxCatLdto from "../../types/res/list/TaxCatLdto";
import TaxLdto from "../../types/res/list/TaxLdto";
import AddSalesItemsTable from "./AddSalesItemsTable";
import AddSalesItemFormSv from "./AddSalesItemFormSv";


interface AddSalesItemsSvProps {
	products: ProdForEsimateLdto[];
	productsMap: Map<number, ProdForEsimateLdto>;
	productsForDd: DropDownItemType[];
	units: UnitLdto[];
	unitsMap: Map<number, UnitLdto>;
	taxMap: Map<number, TaxLdto>;
	taxListResMap: Map<number, TaxLdto>;
	taxCatMap: Map<number, TaxCatLdto>;
	handleAddItem: (AddSalesItem: SalesItemType) => void;
	handleUpdateItem: (index: number, salesItem: SalesItemType) => void;
	handleDeleteItem: (index: number) => void;
	customerPriceListAMap: Map<number, PriceListRateResType>;
	customerPriceListBMap: Map<number, PriceListRateResType>;
	addSalesItems: Array<SalesItemType>;
	id?: number;
}

function AddSalesItemsSv({
	products,
	productsMap,
	productsForDd,
	units,
	unitsMap,
	taxMap,
	taxListResMap,
	taxCatMap,
	handleAddItem,
	handleUpdateItem,
	handleDeleteItem,
	customerPriceListAMap,
	customerPriceListBMap,
	addSalesItems,
	id
}: AddSalesItemsSvProps) {

	const [selectedIndex, setSelectedIndex] = useState<number>(-1);
	const [modeAdd, setModeAdd] = useState<boolean>(true);
	const [salesItemToEdit, setSalesItemToEdit] = useState<SalesItemType | null>(null);

	const handleItemChange = (salesItem: SalesItemType) => {
		if (modeAdd) {
			handleAddItem(salesItem);
		}
		else {
			handleUpdateItem(selectedIndex, salesItem);
		}
		setModeAdd(true);
	}

	const handleClickEdit = (index: number) => {
		setModeAdd(false);
		setSelectedIndex(index);
		setSalesItemToEdit(addSalesItems[index]);
	}

	return (
		<>
			<AddSalesItemFormSv
				products={products}
				productsForDd={productsForDd}
				productsMap={productsMap}
				units={units}
				unitsMap={unitsMap}
				taxMap={taxListResMap}
				taxCatMap={taxCatMap}
				handleItemChange={handleItemChange}
				customerPriceListAMap={customerPriceListAMap}
				customerPriceListBMap={customerPriceListBMap}
				salesItemToEdit={salesItemToEdit}
			/>

			<AddSalesItemsTable
				taxMap={taxListResMap}
				taxCatMap={taxCatMap}
				addSalesItems={addSalesItems}
				productsMap={productsMap}
				unitsMap={unitsMap}
				handleClickEditParent={handleClickEdit}
				handleClickDeleteParent={handleDeleteItem}
			/>

		</>
	);
}

export default AddSalesItemsSv;
