import { useEffect, useState } from "react";
import { useGvContext } from "../../GvContext";
import MenuApi from "../../api/MenuApi";
import { BASE_URL, TOKEN } from "../../config";
import DateFormatEnum from "../../enums/DateFormatEnum";
import ListMenuEnum from "../../enums/ListMenuEnum";
import ResourceEnum from "../../enums/ResourceEnum";
import LstPurchaseOrderEnumCol from "../../enums/col/LstPurchaseOrderEnumCol";
import dateToString from "../../helpers/DateHelper";
import DateHelper2 from "../../helpers/DateHelper2";
import strFormat from "../../helpers/StringHelper";
import { getQueryString } from "../../helpers/UrlSearchParamHelper";
import RowType from "../../types/RowType";
import ColSettingResType from "../../types/res/ColSettingResType";
import PurchaseOrderLdto from "../../types/res/list/PurchaseOrderLdto";
import ListPurchaseOrderSm from "../../types/sm/ListPurchaseOrderSm";
import ListViewButtonBar from "../ListViewButtonBar";
import ListViewTable from "../ListViewTable";
import useConfirm from "../dialogs/ConfirmDialog";
import MessageDialog from "../dialogs/MessageDialog";

function ListPurchaseOrder(): JSX.Element {

	const resourceName = ResourceEnum.PURCHASE_ORDER.name;

	const [colSettings, setColSettings] = useState<ColSettingResType[]>([]);
	const [colNames, setColNames] = useState<string[]>([]);

	const [rows, setRows] = useState<RowType[]>([]);
	const [selectedId, setSelectedId] = useState<number | null>(null);

	const [listPurchaseOrderSm, setListPurchaseOrderSm] = useState<ListPurchaseOrderSm>(new ListPurchaseOrderSm(DateHelper2.getFirstDateOfCurrentMonth(), DateHelper2.getLastDateOfCurrentMonth()));

	const [showSd, setShowSd] = useState<boolean>(false);
	
	const [messageDialogMessage, setMessageDialogMessage] = useState("");
	const [showMessageDialog, setShowMessageDialog] = useState(false);

	const confirm = useConfirm();
	const gv = useGvContext();

	useEffect(() => {
		async function process() {

			let res = await MenuApi.getColSettingDtos(ListMenuEnum.PURCHASE_ORDER);

			if (res.isSuccess) {

				setColSettings(res.items!);

				let colNamesLocal: string[] = [];
				res.items!.forEach((item) => {
					colNamesLocal.push(item.colName);
				});
				setColNames(colNamesLocal);

			}
			else {
				setShowMessageDialog(true);
				setMessageDialogMessage(res.errorRes?.message!);
			}

		}
		process();
	}, []);

	const handleClickAdd = () => {
		gv.menuHelper.openAddPurchaseOrder();
	};

	const handleClickEdit = () => {
		if (selectedId === null) {
			setMessageDialogMessage("Select atleast one row");
			setShowMessageDialog(true);
			return;
		}

		gv.menuHelper.openAddPurchaseOrder(selectedId);
	};

	const handleClickDelete = async () => {
		if (selectedId === null) {
			setMessageDialogMessage("Select atleast one row");
			setShowMessageDialog(true);
			return;
		}

		let choice = await confirm!({
			message: "Are you sure you want to delete?",
			title: "Delete confirmation"
		});

		if (choice === undefined || choice === null || choice === false)
			return;

		const requestOptions = {
			method: "DELETE",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			}
		}

		const res = await fetch(strFormat("%s/%s/%s", BASE_URL, resourceName, selectedId), requestOptions);

		if (res.status !== 204) {
			const data = await res.json();
			setMessageDialogMessage(data.message);
			setShowMessageDialog(true);
		}
		else {
			loadData(listPurchaseOrderSm);
		}
	};

	const handleClickExport = () => {
		console.log("handleClickExport");
	};

	const handleClickPrint = () => {
		console.log("handleClickPrint");
	};

	const handleClickOpen = () => {
		console.log("handleClickOpen");
	};

	const handleClickFilter = () => {
		setShowSd(true);
	};

	const handleClickClearFilter = () => {
		console.log("handleClickClearFilter");
	};

	const handleClickColSettings = () => {
		console.log("handleClickColSettings");
	};

	const loadData = async (
		listPurchaseOrderSm: ListPurchaseOrderSm,
		colSettingsLocal?: ColSettingResType[]
	) => {

		let colSettingsToUse: ColSettingResType[];

		if (colSettingsLocal === undefined) {
			colSettingsToUse = colSettings;
		}
		else {
			colSettingsToUse = colSettingsLocal;
		}

		setListPurchaseOrderSm(listPurchaseOrderSm);

		const requestOptions = {
			method: "GET",
			headers: {
				Authorization: "Bearer " + TOKEN.val,
				"Content-Type": "application/json",
			},
		};
		const res = await fetch(strFormat("%s/%s", BASE_URL, resourceName)
			//+ new URLSearchParams(listVouOthChargeSm.toUrlSearchParams())
			+ getQueryString(listPurchaseOrderSm)
			, requestOptions);
		const results: PurchaseOrderLdto[] = await res.json();

		let dataToReturn: RowType[] = [];
		results.forEach((result) => {
			let oneRowArray: string[] = [];

			colSettingsToUse.forEach(colSetting => {
				switch (LstPurchaseOrderEnumCol.getByKey(colSetting.colKey)) {
					case LstPurchaseOrderEnumCol.CITY:
						oneRowArray.push(result.city == null ? "" : result.city);
						break;
					case LstPurchaseOrderEnumCol.SUPPLIER:
						oneRowArray.push(result.supplierName);
						break;
					case LstPurchaseOrderEnumCol.DATE:
						oneRowArray.push(dateToString(result.date, DateFormatEnum.APP_DATETIME));
						break;
					case LstPurchaseOrderEnumCol.NO:
						oneRowArray.push(result.no.toString());
						break;
					case LstPurchaseOrderEnumCol.STATE:
						oneRowArray.push(result.stateName == null ? "" : result.stateName);
						break;
					case LstPurchaseOrderEnumCol.NOTE:
						oneRowArray.push(result.note);
						break;
					case LstPurchaseOrderEnumCol.QTY:
						oneRowArray.push(result.sumQty == null ? "" : result.sumQty.toString());
						break;
					case LstPurchaseOrderEnumCol.CREATED_BY:
						oneRowArray.push(result.createdByUserName);
						break;
					case LstPurchaseOrderEnumCol.CREATED_AT:
						oneRowArray.push(dateToString(result.createdAt, DateFormatEnum.APP_DATETIME));
						break;
				}
			});

			dataToReturn.push({
				id: result.id,
				data: oneRowArray
			});
		});

		setRows(dataToReturn);
	}

	return (
		<>
			<ListViewButtonBar
				handleClickEdit={handleClickEdit}
				handleClickDelete={handleClickDelete}
				handleClickExport={handleClickExport}
				handleClickPrint={handleClickPrint}
				handleClickOpen={handleClickOpen}
				handleClickFilter={handleClickFilter}
				handleClickClearFilter={handleClickClearFilter}
				handleClickColSettings={handleClickColSettings}
			/>
			<ListViewTable
					colNames={colNames}
					rows={rows}
					setSelectedId={setSelectedId}
				/>

			<MessageDialog
				showDialog={showMessageDialog}
				modalMessage={messageDialogMessage}
				setShowDialog={setShowMessageDialog} />
		</>
	);
}

export default ListPurchaseOrder;
