import RptCurrentStockValReportByEnum from "../enums/RptCurrentStockValReportByEnum";
import DropDownItemType from "../types/DropDownItemType";

export default class RptCurrentStockValReportByHelper {

	static getRptCurrentStockValReportByDdis() {
		let finalArr: DropDownItemType[] = [];
		for (let rptCurrentStockValReportByEnum of RptCurrentStockValReportByEnum.values) {
			finalArr.push({
				id: rptCurrentStockValReportByEnum.id,
				name: rptCurrentStockValReportByEnum.name
			});
		}
		return finalArr;
	}

	static getRptCurrentStockValReportByDdi(paymentTerm: RptCurrentStockValReportByEnum): DropDownItemType {
		return {
			id: paymentTerm.id,
			name: paymentTerm.name
		};
	}

	static getRptCurrentStockValReportByMap(itemArr: RptCurrentStockValReportByEnum[]) {
		let map = new Map<number, RptCurrentStockValReportByEnum>([]);
		for (let index = 0; index < itemArr.length; index++) {
			const item = itemArr[index];
			map.set(item.id, item);
		}
		return map;
	}

}
