import { useState } from "react";
import DropDownItemType from "../../types/DropDownItemType";
import SalesOrderItemType from "../../types/SalesOrderItemType";
import { PriceListRateResType } from "../../types/res/PriceListRateResType";
import ProdForEsimateLdto from "../../types/res/list/ProdForEsimateLdto";
import TaxCatLdto from "../../types/res/list/TaxCatLdto";
import TaxLdto from "../../types/res/list/TaxLdto";
import UnitLdto from "../../types/res/list/UnitLdto";
import AddSalesOrderItemFormSv from "./AddSalesOrderItemFormSv";
import AddSalesOrderItemsTable from "./AddSalesOrderItemsTable";

interface AddSalesOrderItemsSvProps {
	products: ProdForEsimateLdto[];
	productsMap: Map<number, ProdForEsimateLdto>;
	productsForDd: DropDownItemType[];
	units: UnitLdto[];
	unitsMap: Map<number, UnitLdto>;
	taxMap: Map<number, TaxLdto>;
	taxListResMap: Map<number, TaxLdto>;
	taxCatMap: Map<number, TaxCatLdto>;
	handleAddItem: (addSalesOrderItem: SalesOrderItemType) => void;
	handleUpdateItem: (index: number, salesOrderItem: SalesOrderItemType) => void;
	handleDeleteItem: (index: number) => void;
	customerPriceListAMap: Map<number, PriceListRateResType>;
	customerPriceListBMap: Map<number, PriceListRateResType>;
	addSalesOrderItems: Array<SalesOrderItemType>;
	id?: number;
}

function AddSalesOrderItemsSv({
	products,
	productsMap,
	productsForDd,
	units,
	unitsMap,
	taxMap,
	taxListResMap,
	taxCatMap,
	handleAddItem,
	handleUpdateItem,
	handleDeleteItem,
	customerPriceListAMap,
	customerPriceListBMap,
	addSalesOrderItems,
	id
}: AddSalesOrderItemsSvProps) {

	const [selectedIndex, setSelectedIndex] = useState<number>(-1);
	const [modeAdd, setModeAdd] = useState<boolean>(true);
	const [salesOrderItemToEdit, setSalesOrderItemToEdit] = useState<SalesOrderItemType | null>(null);

	const handleItemChange = (salesOrderItem: SalesOrderItemType) => {
		if (modeAdd) {
			handleAddItem(salesOrderItem);
		}
		else {
			handleUpdateItem(selectedIndex, salesOrderItem);
		}
		setModeAdd(true);
	}

	const handleClickEdit = (index: number) => {
		setModeAdd(false);
		setSelectedIndex(index);
		setSalesOrderItemToEdit(addSalesOrderItems[index]);
	}

	return (
		<>
			<AddSalesOrderItemFormSv
				products={products}
				productsForDd={productsForDd}
				productsMap={productsMap}
				units={units}
				unitsMap={unitsMap}
				taxMap={taxListResMap}
				taxCatMap={taxCatMap}
				handleItemChange={handleItemChange}
				customerPriceListAMap={customerPriceListAMap}
				customerPriceListBMap={customerPriceListBMap}
				salesOrderItemToEdit={salesOrderItemToEdit}
			/>

			<AddSalesOrderItemsTable
				taxMap={taxListResMap}
				taxCatMap={taxCatMap}
				addSalesOrderItems={addSalesOrderItems}
				productsMap={productsMap}
				unitsMap={unitsMap}
				handleClickEditParent={handleClickEdit}
				handleClickDeleteParent={handleDeleteItem}
			/>

		</>
	);
}

export default AddSalesOrderItemsSv;
