export default class CalcOnTypeEnum {
	public static readonly ITEM = new CalcOnTypeEnum(1, "ITEM", "Item");
	public static readonly VOUCHER = new CalcOnTypeEnum(2, "VOUCHER", "Voucher");

	private constructor(
		public readonly id: number,
		public readonly enumName: string,
		public readonly name: string) {
	}

	static get values(): CalcOnTypeEnum[] {
		return [
			this.ITEM,
			this.VOUCHER,
		];
	}

	static getById(id: number): CalcOnTypeEnum | null {
		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.id === id) {
				return element;
			}
		}
		return null;
	}

	static getByName(name: string): CalcOnTypeEnum | null {
		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.name === name) {
				return element;
			}
		}
		return null;
	}

}
