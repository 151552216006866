import RptIoSummByProdSortByEnum from "../../enums/sort_by/RptIoSummByProdSortByEnum";
import SortOrderEnum from "../../enums/SortOrderEnum";

export default class RptInvIoSummByProdSm {

	dateFrom: Date | undefined;
	dateTo: Date | undefined;
	prodId: number | undefined;
	prodCatId: number | undefined;
	acctId: number | undefined;
	rptIoSummByProdSortByEnum: RptIoSummByProdSortByEnum | undefined;
	sortOrderEnum: SortOrderEnum | undefined;

	reset(): void {
		this.dateFrom = undefined;
		this.dateTo = undefined;
		this.prodId = undefined;
		this.prodCatId = undefined;
		this.acctId = undefined;
		this.rptIoSummByProdSortByEnum = undefined;
		this.sortOrderEnum = undefined;
	}

	isBlank(): boolean {
		return this.dateFrom === undefined
			&& this.dateTo === undefined
			&& this.prodId === undefined
			&& this.prodCatId === undefined
			&& this.acctId === undefined
			&& this.rptIoSummByProdSortByEnum === undefined
			&& this.sortOrderEnum === undefined;
	}
}
