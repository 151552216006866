import { useEffect, useState } from "react";
import { useGvContext } from "../../GvContext";
import MenuApi from "../../api/MenuApi";
import SalesApi from "../../api/SalesApi";
import ListMenuEnum from "../../enums/ListMenuEnum";
import DueRcvableBySalesEnumCol from "../../enums/col/DueRcvableBySalesEnumCol";
import RowType from "../../types/RowType";
import ColSettingResType from "../../types/res/ColSettingResType";
import RptDueRcvableBySalesSm from "../../types/sm/RptDueRcvableBySalesSm";
import ListViewButtonBar from "../ListViewButtonBar";
import ListViewTable from "../ListViewTable";
import useConfirm from "../dialogs/ConfirmDialog";
import MessageDialog from "../dialogs/MessageDialog";
import RptDueRcvableBySalesSd from "../sd/RptDueRcvableBySalesSd";

function RptDueRcvableBySales(): JSX.Element {

	const [colSettings, setColSettings] = useState<ColSettingResType[]>([]);
	const [colNames, setColNames] = useState<string[]>([]);


	const [rows, setRows] = useState<RowType[]>([]);
	const [selectedId, setSelectedId] = useState<number | null>(null);

	const [searchModel, setSearchModel] = useState(new RptDueRcvableBySalesSm());
	//
	const [showSd, setShowSd] = useState<boolean>(false);
	
	const [messageDialogMessage, setMessageDialogMessage] = useState("");
	const [showMessageDialog, setShowMessageDialog] = useState(false);

	const confirm = useConfirm();
	const gv = useGvContext();

	useEffect(() => {
		async function process() {

			let res = await MenuApi.getColSettingDtos(ListMenuEnum.DUE_RCVABLE_BY_SALES);

			if (res.isSuccess) {

				setColSettings(res.items!);

				let colNamesLocal: string[] = [];
				res.items!.forEach((item) => {
					colNamesLocal.push(item.colName);
				});
				setColNames(colNamesLocal);

			}
			else {
				setShowMessageDialog(true);
				setMessageDialogMessage(res.errorRes?.message!);
			}

		}
		process();
	}, []);

	useEffect(() => {
		if (colSettings != null && colSettings.length > 0) {
			loadData();
		}
	}, [searchModel, colSettings]);

	const handleClickExport = () => {
		console.log("handleClickExport");
	};

	const handleClickOpen = () => {
		console.log("handleClickOpen");
	};

	const handleClickFilter = () => {
		setShowSd(true);
	};

	const handleClickClearFilter = () => {
		let searchModelLoc = new RptDueRcvableBySalesSm();
		searchModelLoc.reset();
		setSearchModel(searchModelLoc);
	};

	const handleClickColSettings = () => {
		console.log("handleClickColSettings");
	};

	const loadData = async () => {

		let res = await SalesApi.getRptDueRcvableBySalesHdtos(searchModel);

		if (res.isSuccess) {

			let dataToReturn: RowType[] = [];

			for (let i = 0; i < res.items?.length!; i++) {
				let row = res.items![i];

				let oneRowArray: string[] = [];

				for (let j = 0; j < colSettings.length; j++) {
					let colSetting = colSettings[j];

					switch (DueRcvableBySalesEnumCol.getByKey(colSetting.colKey)) {
						case DueRcvableBySalesEnumCol.AGENT_NAME:
							oneRowArray.push(row.agentName == null ? "" : row.agentName.toString());
							break;
						case DueRcvableBySalesEnumCol.AGE_DAYS:
							oneRowArray.push(row.ageDays == null ? "" : row.ageDays.toString());
							break;
						case DueRcvableBySalesEnumCol.AMT_CLRD:
							oneRowArray.push(row.amtClrd == null ? "" : row.amtClrd.toString());
							break;
						case DueRcvableBySalesEnumCol.AMT_PAYABLE:
							oneRowArray.push(row.amtPayable == null ? "" : row.amtPayable.toString());
							break;
						case DueRcvableBySalesEnumCol.AMT_UNCLRD:
							oneRowArray.push(row.amtUnclrd == null ? "" : row.amtUnclrd.toString());
							break;
						case DueRcvableBySalesEnumCol.CITY:
							oneRowArray.push(row.city == null ? "" : row.city.toString());
							break;
						case DueRcvableBySalesEnumCol.COMPANY_PROFILE:
							oneRowArray.push(row.companyProfileName == null ? "" : row.companyProfileName.toString());
							break;
						case DueRcvableBySalesEnumCol.CREATED_AT:
							oneRowArray.push(row.createdAt == null ? "" : row.createdAt.toString());
							break;
						case DueRcvableBySalesEnumCol.CREATED_BY:
							oneRowArray.push(row.createdByUserName == null ? "" : row.createdByUserName.toString());
							break;
						case DueRcvableBySalesEnumCol.CREDIT_DAYS:
							oneRowArray.push(row.creditDays == null ? "" : row.creditDays.toString());
							break;
						case DueRcvableBySalesEnumCol.CUSTOMER_NAME:
							oneRowArray.push(row.customerName == null ? "" : row.customerName.toString());
							break;
						case DueRcvableBySalesEnumCol.DATE:
							oneRowArray.push(row.date == null ? "" : row.date.toString());
							break;
						case DueRcvableBySalesEnumCol.DUE_DATE:
							oneRowArray.push(row.dueDate == null ? "" : row.dueDate.toString());
							break;
						case DueRcvableBySalesEnumCol.FINAL_INVOICE_NO:
							oneRowArray.push(row.finalInvoiceNo == null ? "" : row.finalInvoiceNo.toString());
							break;
						case DueRcvableBySalesEnumCol.LR_DATE:
							oneRowArray.push(row.lrDate == null ? "" : row.lrDate.toString());
							break;
						case DueRcvableBySalesEnumCol.LR_NO:
							oneRowArray.push(row.lrNo == null ? "" : row.lrNo.toString());
							break;
						case DueRcvableBySalesEnumCol.NO:
							oneRowArray.push(row.no == null ? "" : row.no.toString());
							break;
						case DueRcvableBySalesEnumCol.NOTE:
							oneRowArray.push(row.note == null ? "" : row.note.toString());
							break;
						case DueRcvableBySalesEnumCol.ORDER_DATE:
							oneRowArray.push(row.orderDate == null ? "" : row.orderDate.toString());
							break;
						case DueRcvableBySalesEnumCol.ORDER_NO:
							oneRowArray.push(row.orderNo == null ? "" : row.orderNo.toString());
							break;
						case DueRcvableBySalesEnumCol.OVERDUE_DAYS:
							oneRowArray.push(row.overdueDays == null ? "" : row.overdueDays.toString());
							break;
						case DueRcvableBySalesEnumCol.STATE_NAME:
							oneRowArray.push(row.stateName == null ? "" : row.stateName.toString());
							break;
						case DueRcvableBySalesEnumCol.TRANSPORTER_NAME:
							oneRowArray.push(row.transporterName == null ? "" : row.transporterName.toString());
							break;
					}
				};


				dataToReturn.push({
					id: row.id,
					data: oneRowArray
				});
			};

			setRows(dataToReturn);
		}
		else {
			setShowMessageDialog(true);
			setMessageDialogMessage(res.errorRes?.message!);
		}

	}

	return (
		<>
			<ListViewButtonBar
				handleClickExport={handleClickExport}
				handleClickOpen={handleClickOpen}
				handleClickFilter={handleClickFilter}
				handleClickClearFilter={handleClickClearFilter}
				handleClickColSettings={handleClickColSettings}
			/>

			<ListViewTable
				colNames={colNames}
				rows={rows}
				setSelectedId={setSelectedId}
			/>

			<RptDueRcvableBySalesSd
				showModal={showSd}
				setShowModal={setShowSd}
				searchModel={searchModel}
				processOnSuccess={(searchModel) => setSearchModel(searchModel)}
			/>

			<MessageDialog
				showDialog={showMessageDialog}
				modalMessage={messageDialogMessage}
				setShowDialog={setShowMessageDialog} />
		</>
	);
}

export default RptDueRcvableBySales;
